import './HappyHoursPage.css'
import { React, useEffect, useRef } from "../../libraries/index";
import { CustomImage, CustomSpinner, EmptyComponent, Footer } from "../../common";
import { BookNowModalLogic, NavbarLogic } from "../../components";
import { BreadCrumb } from "../BreadCrumb/BreadCrumb";
import { CurrencyDisplay } from "../../jsonData";
import { DefaultServiceImg } from "../../assets/Images";
import InfiniteScroll from 'react-infinite-scroll-component';
const HappyHoursPage = ({ currentPage, isLoader, setIsLoader, handleLoadMore, updateCartItem, breadCrumbData, isErrorPage, serviceData, navigateServiceSection }) => {
    const footerRef = useRef(null);
    const isThaiLanguage = localStorage.getItem('i18nextLng') === 'thai'

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    if (currentPage < serviceData?.total_pages && !isLoader) {
                        setIsLoader(true); // Start loader
                        handleLoadMore(); // Fetch more data
                    }
                }
            },
            {
                root: null, // Observe in the viewport
                threshold: 1.0, // Trigger when 100% of the footer is visible
            }
        );

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        // Cleanup observer on component unmount
        return () => {
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, [currentPage, serviceData?.total_pages, isLoader, handleLoadMore]);


    const getServiceName = (service) => {
        return isThaiLanguage ? (service?.service_name_thai) : (service?.service_name_en)
    }

    const formatTimeTo12Hour = (time24) => {
        if (!time24) return '';
        const [hours, minutes] = time24.split(':').map(Number);
        const period = hours >= 12 ? 'PM' : 'AM';
        const hours12 = hours % 12 || 12;
        const formattedMinutes = minutes.toString().padStart(2, '0');

        return `${hours12}:${formattedMinutes} ${period}`;
    };

    const getDiscountTime = (service) => {
        const startTime24 = service?.happy_hours_discount_slot?.[0]?.start_time;
        const endTime24 = service?.happy_hours_discount_slot?.[0]?.end_time;

        if (!startTime24 || !endTime24) return "No time available";

        const startTime12 = formatTimeTo12Hour(startTime24);
        const endTime12 = formatTimeTo12Hour(endTime24);

        return `${startTime12} To ${endTime12}`;
    };

    const formatedDayTimeDiscount = (service, type) => {
        if (!service?.happy_hours_discount_slot?.length) {
            console.log("No discount data available.");
            return;
        }

        const discountData = service.happy_hours_discount_slot[0];
        const { discount_days } = discountData;
        const discountTime = getDiscountTime(service);

        // Get current day name in lowercase
        let today = new Date().toLocaleDateString("en-US", { weekday: "long" }).toLowerCase();
        const days = Object.keys(discount_days);

        let currentDayIndex = days.indexOf(today);
        let loopedDays = 0; // To track full week iteration

        while (loopedDays < days.length) { // Ensure we don't enter an infinite loop
            let discount = discount_days[days[currentDayIndex]];

            if (discount > 0) {
                if (type === 'time') {
                    return `${discountTime} ${discount}% Off`
                }
                else {
                    return `${days[currentDayIndex].toUpperCase()}`
                }
            }

            // Move to the next day
            currentDayIndex = (currentDayIndex + 1) % days.length;
            loopedDays++;
        }

    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return <>
        <NavbarLogic
            handleAddToCartCount={updateCartItem}
        />
        <section className="container mb-5 about-us-outer-container">
            <BreadCrumb data={breadCrumbData} />
            <div className="happy-hours-section-outer">
                <InfiniteScroll
                    dataLength={serviceData?.services?.length || 0} // Ensure dataLength is always a number
                    next={handleLoadMore} // Function to fetch the next page
                    hasMore={currentPage <= serviceData?.totalPages} // Check if more data is available
                >
                    <div className="row top-review-shop-row">
                        {isErrorPage?.dataIsEmpty ? <>
                            <EmptyComponent type="notFound" />
                        </> : <>

                            {serviceData?.services?.map((service, index) => (<React.Fragment key={index}>
                                <div className="col-lg-4 col-md-6  col-sm-12 box">
                                    <div className="top-review-shop-card">
                                        <div className="happy-hours-card-img-section">
                                            <div>
                                                <CustomImage
                                                    onClick={(e) => navigateServiceSection(e, service?.service_info?.id, service?.service_info?.service_name_en)}
                                                    className={'happy-hours-card-img img-fluid'}
                                                    src={service?.service_info?.service_image || DefaultServiceImg}
                                                />
                                            </div>
                                            <div className="discount-section">
                                                <div className="discount-section-inner">
                                                    <span className="discount-percent">
                                                        <span className="d-block">
                                                            {formatedDayTimeDiscount(service?.service_info, 'time')}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="happy-hours-card-text-section">
                                            <div className="service-name-outer">
                                                <h3 class="service-name" onClick={(e) => navigateServiceSection(e, service?.service_info?.id, service?.service_info?.service_name_en)}>
                                                    {getServiceName(service?.service_info)}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="happy-hours-card-action-section">
                                            <div className="service-card-footer">
                                                <div className="price-section">
                                                    <CurrencyDisplay
                                                        value={service?.service_info?.special_price}
                                                        subPrice={service?.service_info?.price}
                                                    />
                                                </div>
                                                <div className="btn-section">
                                                    <BookNowModalLogic
                                                        className="display-service-service-section-bookn-button"
                                                        service_id={
                                                            service?.service_info?.id
                                                        }
                                                        vender_details={
                                                            service?.vendor_info
                                                        }
                                                        service_details={
                                                            service?.service_info
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* ------------------- day section ------------------------ */}

                                        <div class="ribbon ribbon-top-left"><span>{formatedDayTimeDiscount(service?.service_info, 'day')}</span></div>
                                    </div>
                                </div>
                            </React.Fragment>))}
                        </>}
                        {isLoader && <div className='mt-5'>
                            <CustomSpinner displayService={true} />
                        </div>}
                    </div>
                </InfiniteScroll>
            </div >
        </section >
        <div

            className={`${!serviceData || isLoader ? 'alterNative-footer-div-20' : 'alterNative-footer-div-1'}`}
        >
            <div ref={footerRef} id="footer">
                <Footer />
            </div>
        </div>
    </>
}

export default HappyHoursPage;