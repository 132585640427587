import '../BookNowModal/BookNowModal.css'; // Import your CSS file
import { React, Modal, Button, DatePicker, Select, dayjs, Locale, timezone, utc, useState, useEffect } from '../../libraries';

const CheckoutCalendarModal = ({
    isModalVisible,
    handleCancel,
    selectServiceDetails,
    handleOk,
    handleLocationChange,
    disabledDate,
    uniqueId,
    options,
    onDateChange,
    calendarFormValue,
    handleBranchChange,
    branchOptions,
    showBranch,
    showAtLocation,
    timeSlots,
    handleTimeSlotClick,
    selectedDate,
    selectedTimeSlot,
    disableConfirmBtn
}) => {
    const { t } = Locale();
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const serviceDetails = calendarFormValue?.[selectServiceDetails?.service_id];
    const bookingDateFromCalendar = serviceDetails?.booking_date ? dayjs(serviceDetails.booking_date, "DD-MM-YYYY") : null;
    const bookingTimeFromCalendar = serviceDetails?.booking_time ? dayjs(serviceDetails.booking_time, "HH:mm") : null;
    const bookingDateTime = bookingDateFromCalendar && bookingTimeFromCalendar ? bookingDateFromCalendar.hour(bookingTimeFromCalendar.hour()).minute(bookingTimeFromCalendar.minute()).second(bookingTimeFromCalendar.second()) : null;

    // const bookingDateTime = bookingDateFromCalendar && bookingTimeFromCalendar ? bookingDateFromCalendar.hour(bookingTimeFromCalendar.hour()).minute(bookingTimeFromCalendar.minute()).second(bookingTimeFromCalendar.second()) : null;
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 445);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 445);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return <>
        <Modal
            title={t('field.scheduleDate')}
            open={isModalVisible}
            className="add-to-cart-modal book-now-modal-date-picker"
            footer={[
                <Button key="1" onClick={handleCancel}>
                    {t('button.cancelButton')}
                </Button>,
                <Button

                    key="3"
                    className={` ${disableConfirmBtn ? 'confirm confirm-btn-disable':'confirm'}`}
                    onClick={(e) => handleOk(e)}
                >
                    {t('field.confirm')}
                </Button>,
            ]}
        >
            <div className="row ime-pick-btn book-now-modal-date-picker-outer-div book-now-modal-single-booking">
                <div className="col-12 book-now-modal-calendar-col-9 hide-book-btn-modal-new">
                    <div className="d-flex justify-content-between">
                        <div>
                            <Select
                                onChange={(e) => handleLocationChange(selectServiceDetails?.service_id, e)}
                                options={options}
                                style={{ width: 120 }}
                                value={showAtLocation}
                            />
                        </div>
                        <div className={`${((calendarFormValue?.[selectServiceDetails?.service_id]?.atServiceLocation) === 'at_shop') ? 'branch-name-book-modal' : 'd-none'}`}>
                            <div className='branch-name-heading'>
                                {t('vendor.branch')} :-
                            </div>
                            <Select
                                value={(showBranch?.branch_name) || selectServiceDetails?.shop_name}
                                // defaultValue={}
                                onChange={handleBranchChange}
                                options={branchOptions}
                                style={{ width: 160 }}
                            />
                        </div>
                    </div>
                    <div className="row time-pick-btn book-now-modal-date-picker-outer-div">
                        <div className="col-9 custom-datepicker-div book-now-modal-calendar-col-9">
                            <DatePicker
                                className="custom-datepicker book-now-modal-date-picker hide-ok-button-new"
                                getPopupContainer={() => document.getElementById(uniqueId)}
                                popupStyle={{
                                    position: 'relative',
                                }}
                                open={true}
                                showNow={false}
                                disabledDate={disabledDate}
                                value={selectedDate || bookingDateFromCalendar}
                                onChange={(e) => onDateChange(e)} // Call the onChange handler
                            />
                            <div id={uniqueId} />
                        </div>
                        <div className="col-3 book-now-modal-calendar-col-3">
                            {!isMobileView && <>
                                <div className="pick-time-container mt-3">
                                    {timeSlots && timeSlots.map((timeSlot, index) => (
                                        <div key={index} className={`pick-time mb-1 ${selectedTimeSlot === timeSlot ? "selected-time" : ""}`}
                                            onClick={() => handleTimeSlotClick(timeSlot)}>
                                            {timeSlot}
                                        </div>
                                    ))}
                                </div>
                            </>}
                            {isMobileView && <>
                                <div className="pick-time-container mt-3 grid-container">
                                    {timeSlots && timeSlots.map((timeSlot, index) => (
                                        <div key={index} className={`pick-time mb-1 ${selectedTimeSlot === timeSlot ? "selected-time" : ""}`}
                                            onClick={() => handleTimeSlotClick(timeSlot)}>
                                            {timeSlot}
                                        </div>
                                    ))}
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default CheckoutCalendarModal;