import { CustomSpinner, EmptyComponent, Footer } from "..";
import { DefaultShop } from "../../assets/Images";
import { NavbarLogic } from "../../components";
import { CustomImage } from "../../lazyComponent";
import { BreadCrumb } from "../BreadCrumb/BreadCrumb";
import { Locale, Rate, React, useEffect, useState } from '../../libraries/index'
import './TopReviewShop.css'
const TopReviewShopPage = ({ data, breadCrumbData, isLoader, navigatePage, isEmpty }) => {
    const { t } = Locale()
    const [itemsToShow, setItemsToShow] = useState(8); // Default to 8 items
    const selectedLanguage = localStorage.getItem('i18nextLng');
    const isThaiLanguage = selectedLanguage === 'thai';

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth > 1024) { // Web
                setItemsToShow(8);
            } else if (screenWidth <= 1024 && screenWidth > 768) { // iPad
                setItemsToShow(9);
            } else { // Mobile
                setItemsToShow(10);
            }
        };

        handleResize(); // Call once on component mount
        window.addEventListener('resize', handleResize); // Add event listener

        return () => {
            window.removeEventListener('resize', handleResize); // Clean up on unmount
        };
    }, []);

    const getOrganizationName = (obj) => {
        return isThaiLanguage ? (obj?.name_thai || obj?.name) : obj?.name;
    }

    const formatRating = (rating) => {
        if (rating) {
            // Check if the rating is a whole number
            return rating % 1 === 0 ? rating.toFixed(1) : rating.toFixed(1).slice(0, 3);
        }
        return "0.0"; // Default to 0.0 if rating is not available
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return <>
        <NavbarLogic />
        <section className="container mb-5 about-us-outer-container">
            <BreadCrumb data={breadCrumbData} />
            <div className="top-review-outer">
                {((!isLoader) && !(isEmpty)) ? <>
                    <div className="row top-review-shop-row">
                        {data?.slice(0, itemsToShow)?.map((obj, index) => (
                            <React.Fragment key={index}>
                                <div className="col-lg-3 col-sm-12 col-md-4 box">
                                    <div className="top-review-shop-card">
                                        <div className="shop-card-inner">
                                            <div>
                                                <div className="top-review-shop-image-section">
                                                    <div className="shop-image" onClick={(e) => navigatePage(e, obj)}>
                                                        <CustomImage
                                                            src={obj?.images || DefaultShop}
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    <div className="shop-rating">
                                                        <div className="card-rating">
                                                            <span className="top-review-shop-rating-star">
                                                                <Rate
                                                                    allowHalf
                                                                    disabled
                                                                    value={
                                                                       obj?.rating
                                                                    }
                                                                />
                                                            </span>
                                                            <span className="card-rating__value">
                                                                {formatRating(obj?.rating)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="top-review-shop-content-section">
                                                    <h2 className="card-main__heading" onClick={(e) => navigatePage(e, obj)}>
                                                        {getOrganizationName(obj)}
                                                    </h2>
                                                    <span className="card-main-address-section">
                                                        <i
                                                            className="bi bi-geo-alt-fill"
                                                            aria-hidden="true"
                                                        ></i>&nbsp;
                                                        {obj?.address}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-main__footer">
                                            <div className="card-main-footer-section">
                                                <div className="btn-section">
                                                    <div className="btn-text" onClick={(e) => navigatePage(e, obj)}>
                                                        {t('button.viewDetails')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </> : <>
                    <CustomSpinner />
                </>
                }
                {(isEmpty) && (
                    <>
                        <EmptyComponent type="highlyRatedShop" />
                    </>
                )}
            </div>

        </section>
        <div className={`${(isLoader) ? 'loader-blog-section' : ''}`}>
            <Footer />
        </div>


    </>
}

export default TopReviewShopPage;