import { dynamicTimeZone } from '../../../../customHooks';
import { useEffect, useState, DateTime } from '../../../../libraries';
import { getServiceById, handleErrorResponse, handleSuccessResponse, editVendorBookings, getBookingById, vendoreCustomerBooking } from '../../../../services/index';
const timeZone = process.env.REACT_APP_SURA_360_TIME_ZONE;

const useEditBooking = ({
  id,
  closeEditBookingModal,
  bookings,
  setBookings,
  setLoader,
}) => {
  const [formValues, setFormValues] = useState({
    customerName: '',
    serviceName: '',
    duration: '',
    listedPrice: '',
    paidAmount: '',
    discount: '',
    paymentType: '',
    status: '',
    scheduleDate: '',
    teamMember: '',
    scheduleTime: '',
  });
  const [formErrors, setFormErrors] = useState({
    customerName: '',
    serviceName: '',
    duration: '',
    listedPrice: '',
    paidAmount: '',
    discount: '',
    paymentType: '',
    status: '',
    scheduleDate: '',
    teamMember: '',
    scheduleTime: '',
  });
  const [formValuesNewBooking, setFormValuesNewBooking] = useState({
    customerName: '',
    serviceName: '',
    duration: '',
    listedPrice: '',
    paidAmount: '',
    discount: '',
    paymentType: 'online',
    status: 'confirmed',
    scheduleDate: '',
    teamMember: '',
    time: '',
    scheduleTime: '',
  });
  const [formErrorsNewBooking, setFormErrorsNewBooking] = useState({
    customerName: '',
    serviceName: '',
    duration: '',
    listedPrice: '',
    paidAmount: '',
    discount: '',
    paymentType: '',
    status: '',
    scheduleDate: '',
    teamMember: '',
    time: '',
    scheduleTime: '',
  });
  
  const handleChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };
  const [dayName, setDayName] = useState();
  const [timeSlots, setTimeSlots] = useState();
  const [vendorOpenignHours, setVendorOpenignHours] = useState();
  const [bussinessHours, setBussinessHours] = useState();

  const handleChangeNewBooking = (name, value) => {
    setFormValuesNewBooking({ ...formValuesNewBooking, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    if (Object.values(errors).every((value) => value === '')) {
      closeEditBookingModal();
      setLoader(true);
      let params = {
        booking_date: formValues.scheduleDate,
        status: formValues.status.toLowerCase(),
        team_member_id: parseInt(formValues.teamMember),
        booking_time: formValues.scheduleTime,
      };
      try {
        const response = await editVendorBookings(id, params);
        const booking = await getBookingById(id);
        setBookings(
          bookings?.map((item) =>
            item.booking_service.id === id ? booking.data : item,
          ),
        );
        handleSuccessResponse(response, response.data.message);
      } catch (err) {
        handleErrorResponse(err);
      } finally {
        setLoader(false);
      }
    }
  };

  const validateNewBooking = (values) => {
    const errors = {
      status: !values.status ? 'Status is required' : '',
      teamMember: !values.teamMember ? 'Team member is required' : '',
      customerName: !values.customerName ? 'Customer is required' : '',
      paymentType: !values.paymentType ? 'Payment is required' : '',
      scheduleDate: !values.scheduleDate ? 'Date is required' : '',
      serviceName: !values.serviceName ? 'Service is required' : '',
      time: !values.time ? 'Time is required' : '',
    };
    return errors;
  };

  const handleSubmitBooking = async (e) => {
    e.preventDefault();
    const errors = validateNewBooking(formValuesNewBooking);
    setFormErrorsNewBooking(errors);
    if (Object.values(errors).every((value) => value === '')) {
      let params = {
        total_amount: formValuesNewBooking.paidAmount,
        payment_type: formValuesNewBooking.paymentType,
        total_discount: formValuesNewBooking.discount,
        user_id: formValuesNewBooking.customerName,
        paid_amount: formValuesNewBooking.paidAmount,
        booking_services: {
          service_id: formValuesNewBooking.serviceName,
          booking_date: formValuesNewBooking.scheduleDate,
          booking_time: formValuesNewBooking.time,
          team_member_id: formValuesNewBooking.teamMember,
          price: formValuesNewBooking.paidAmount,
        },
      };
      try {
        const response = await vendoreCustomerBooking(params);
        const booking = await getBookingById(response.data.id);
        setBookings([booking.data, ...bookings]);
        handleSuccessResponse(response, response.data.message);
        setFormValuesNewBooking({
          customerName: '',
          serviceName: '',
          duration: '',
          listedPrice: '',
          paidAmount: '',
          discount: '',
          paymentType: 'online',
          status: 'confirmed',
          scheduleDate: '',
          teamMember: '',
          time: '',
        });
        setFormErrorsNewBooking({
          customerName: '',
          serviceName: '',
          duration: '',
          listedPrice: '',
          paidAmount: '',
          discount: '',
          paymentType: '',
          status: '',
          scheduleDate: '',
          teamMember: '',
          time: '',
        });
        closeEditBookingModal();
      } catch (err) {
        handleErrorResponse(err);
      }
    }
  };

  const validate = (values) => ({
    status: !values.status ? 'Status is required' : '',
    teamMember: !values.teamMember ? 'Team member is required' : '',
    scheduleTime: !values.scheduleTime ? 'Schedule time is required' : '',
    scheduleDate: !values.scheduleDate ? 'Schedule date is required' : '',
  });

  const getSingleSeviceData = async (id) => {
    const response = await getServiceById(id);
    setFormValuesNewBooking((prev) => ({
      ...prev,
      duration: response.data.service.duration,
      listedPrice: response.data.service.price,
      paidAmount: response.data.service.special_price,
      discount:
        response.data.service.price - response.data.service.special_price,
    }));
  };

  const onChangeNewBooking = (type, value) => {
    if (type === 'serviceName') getSingleSeviceData(value);
    handleChangeNewBooking(type, value);
  };

  const onChange = (value) => {
    handleChange('teamMember', value);
  };
  const generateTimeSlots = async () => {
    if (dayName && bussinessHours) {
      const selectedDayData = await bussinessHours[dayName.toLowerCase()];

      if (!selectedDayData || !selectedDayData.shop_open) {
        setTimeSlots([]);
        return;
      }

      const openTime = selectedDayData.open;
      const closeTime = selectedDayData.close;

      // Use Luxon to handle timezone conversion and time slot generation
      let startTime = DateTime.fromFormat(openTime, 'HH:mm', {
        zone: dynamicTimeZone(),
      });
      const endTime = DateTime.fromFormat(closeTime, 'HH:mm', {
        zone: dynamicTimeZone(),
      });

      const timeSlots = [];
      while (startTime <= endTime) {
        const formattedTime = startTime.toFormat('HH:mm');
        timeSlots.push(formattedTime);
        startTime = startTime.plus({ minutes: 30 });
      }

      setTimeSlots(timeSlots);
    } else {
      // Use Luxon to generate a full day's worth of time slots in Bangkok timezone
      let startTime = DateTime.now().setZone(dynamicTimeZone()).startOf('day');
      const timeSlots = [];
      for (let i = 0; i < 48; i++) {
        const formattedTime = startTime.toFormat('HH:mm');
        timeSlots.push(formattedTime);
        startTime = startTime.plus({ minutes: 30 });
      }

      setTimeSlots(timeSlots);
    }
  };

  const vendorOpenignHoursFunction = (bussiness_hours) => {
    setBussinessHours(bussiness_hours);
    let newBuissnessDay = {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };
    if (bussiness_hours) {
      setVendorOpenignHours((prevState) => ({
        ...prevState,
        monday:
          bussiness_hours.monday && bussiness_hours.monday.shop_open === 'true'
            ? true
            : false,
        tuesday:
          bussiness_hours.tuesday &&
            bussiness_hours.tuesday.shop_open === 'true'
            ? true
            : false,
        wednesday:
          bussiness_hours.wednesday &&
            bussiness_hours.wednesday.shop_open === 'true'
            ? true
            : false,
        thursday:
          bussiness_hours.thursday &&
            bussiness_hours.thursday.shop_open === 'true'
            ? true
            : false,
        friday:
          bussiness_hours.friday && bussiness_hours.friday.shop_open === 'true'
            ? true
            : false,
        saturday:
          bussiness_hours.saturday &&
            bussiness_hours.saturday.shop_open === 'true'
            ? true
            : false,
        sunday:
          bussiness_hours.sunday && bussiness_hours.sunday.shop_open === 'true'
            ? true
            : false,
      }));
    } else {
      setVendorOpenignHours(newBuissnessDay);
    }
  };

  useEffect(() => {
    if (dayName) generateTimeSlots();
  }, [dayName]);
  return {
    vendorOpenignHoursFunction,
    timeSlots,
    setDayName,
    vendorOpenignHours,
    onChangeNewBooking,
    onChange,
    setFormValuesNewBooking,
    setFormErrorsNewBooking,
    getSingleSeviceData,
    handleChangeNewBooking,
    formErrorsNewBooking,
    formValuesNewBooking,
    handleSubmitBooking,
    formValues,
    setFormValues,
    handleChange,
    handleSubmit,
    formErrors,
    setFormErrors,
  };
};
export default useEditBooking;
