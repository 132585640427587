import { getCode } from 'country-list';
import ct from 'countries-and-timezones';
import { CountryCodeJson } from '../jsonData';

const phoneCodeMap = new Map();
const countryNameMap = new Map();

CountryCodeJson.forEach(country => {
    phoneCodeMap.set(country.dial_code, country.code.toLowerCase());
    countryNameMap.set(country.name.toLowerCase(), country.code.toLowerCase());
});

export const dynamicTimeZone = () => {
    const currentCountry = sessionStorage.getItem('country');
    if (!currentCountry) return 'UTC';
    const countryCode = getCode(currentCountry);
    if (!countryCode) return 'UTC';
    const countryData = ct.getCountry(countryCode);
    return countryData?.timezones[0] || 'UTC';
}

export const getPhoneCode = (phone_code) => {
    if (phone_code) {
        const countryCode = phoneCodeMap.get(phone_code);
        if (countryCode) {
            return countryCode;
        }
    }

    const currentCountry = sessionStorage.getItem('country');
    if ((currentCountry) && (currentCountry !== 'undefined')) {
        return (getCode(currentCountry))?.toLowerCase();;
    }

    return 'th';
};
