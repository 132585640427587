
import './NewBranch.css';
import { React, Locale, PropTypes, Button, useState } from '../../../libraries';
import { CustomModal, DragDropImage } from '../../../common';
import { User } from '../../../assets/Images.js';
import { CustomImage } from '../../../lazyComponent/index.js';
import AddressSelectLogic from '../../../common/addressSelect/addressSelectLogic.js';

const NewBranch = ({
  setFormErrors,
  updatedData,
  formErrors,
  setFormValues,
  formValues,
  setSelectedImages,
  setSelectedImage,
  selectedImage,
  selectedImages,
  handleSubmit,
  closeEditBookingModal,
  handleImageUpload,
  handleDeselectImage,
  handleChange,
  addressFormValue,
  setAddressFormValue
}) => {
  const { t } = Locale();
  const [images, setImages] = useState({});

  const feildblanck = () => {
    setFormValues(() => ({
      branchName: "",
      subDistrict: "",
      postalCode: "",
      address: "",
      shopImages: [],
      selectedImageArray: [],
      is_enabled: true,
    }));
    setAddressFormValue((prev) => ({
      ...prev,
      state: { id: '', name: '', code: '' },
      city: { name: '' },
      postalCode: { value: '' }
    }))
    setFormErrors(() => { });
    setSelectedImages([]);
    setSelectedImage(null);
  };


  const closeButtonElement = document.getElementsByClassName(
    "ant-modal-close"
  )[0];


  return (
    <>
      <CustomModal
        text={
          <>
            <form className="form">
              <div className="edit-branch-header">
                <h5 className="title">{t("vendor.newBranch")}</h5>
              </div>
              <div>
                <div className="form-row-branch mt-4 ">
                  <div className="form-group edit-profile edit-branch-image-section">
                    {!selectedImage && (
                      <div className="avatar-edit">
                        <img
                          src={User}
                          alt="Selected"
                          className="branch-avatar"
                          width={"unset"}
                          height={"unset"}
                        />
                      </div>
                    )}
                    {selectedImage && (
                      <div className="avatar-edit">
                        <img
                          src={selectedImage}
                          alt="Selected"
                          className="branch-avatar"
                          width={"unset"}
                          height={"unset"}
                        />
                      </div>
                    )}
                    <div className="avatar-edit">
                      <label htmlFor="imageUpload">
                        <div className="edit-branch-plus-icon">
                          <div className="branch-circle-background">
                            <i className="bi bi-plus-lg" aria-hidden="true"></i>
                          </div>
                        </div>
                      </label>
                    </div>
                    <DragDropImage
                      handleChange={handleImageUpload}
                      accept={["JPG", "PNG", "JPEG", "WEBP"]}
                      className={"hideInput"}
                    />
                  </div>
                </div>
                <div className="mt-4 multiImage-div">
                  <div className="multiImage-div-inner">
                    {selectedImages &&
                      selectedImages.map((image, index) => (
                        <div key={index} className="multiImage-div-inner-main">
                          <CustomImage
                            src={image}
                            alt={`Selected ${index}`}
                            className="selected-image"
                            width={"unset"}
                            height={"unset"}
                          />
                          <div
                            className="deselect-icon"
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                              handleDeselectImage(
                                index,
                                images,
                                setImages,
                                updatedData
                              )
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.key === " ") {
                                handleDeselectImage(
                                  index,
                                  images,
                                  setImages,
                                  updatedData
                                );
                              }
                            }}
                          >
                            <i
                              className="bi bi-x new-branch-icon"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="form-row-branch mt-4">
                <div className="form-group">
                  <label className="width100">
                    <span className="">{t("vendor.branchName")}</span>
                    <span className="custom-estric">*</span>
                    <input
                      id="branchName"
                      type="text"
                      placeholder={t("vendor.branchName")}
                      value={formValues && formValues.branchName}
                      onChange={(e) =>
                        handleChange("branchName", e.target.value)
                      }
                      className={
                        "form-control vendor-account-details-input-field"
                      }
                    />
                  </label>
                  <small className="input-field-err">{formErrors?.branchName}</small>
                </div>
              </div>
              <div className='mt-3'>
                <AddressSelectLogic
                  section='branchSection'
                  setFormErrors={setFormErrors}
                  addressFormValue={addressFormValue}
                  setAddressFormValue={setAddressFormValue}
                  formErrors={formErrors}
                />
              </div>

              <div className="form-row-branch mt-2">
                <div className="form-group">
                  <label className="width100">
                    <span className="">{t("field.address")}</span>
                    <span className="custom-estric">*</span>
                    <input
                      id="address"
                      type="text"
                      placeholder={t("field.address")}
                      value={formValues && formValues.address}
                      onChange={(e) => handleChange("address", e.target.value)}
                      className={
                        "form-control vendor-account-details-input-field"
                      }
                    />
                  </label>
                  <small className="input-field-err">{formErrors?.address}</small>
                </div>
              </div>

              <div className="form-group mt-5">
                <center>
                  <Button
                    className="form-cancel-branch"
                    onClick={() => {
                      feildblanck();
                      closeEditBookingModal();
                    }}
                  >
                    {t("button.cancelButton")}
                  </Button>
                  <input
                    type="submit"
                    onClick={(e) => {
                      handleSubmit(
                        e,
                        closeButtonElement,
                        updatedData,
                        closeEditBookingModal
                      );
                    }}
                    value={t("button.saveButton")}
                    className="form-submit-branch"
                  />
                </center>
              </div>
            </form>
          </>
        }
      />
    </>
  );
};
NewBranch.propTypes = {
  subDistrictOption: PropTypes.array,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  filterOption: PropTypes.func,
  postalCode: PropTypes.array,
  newModal: PropTypes.any,
  updatedData: PropTypes.any,
  formErrors: PropTypes.object,
  setFormValues: PropTypes.func,
  formValues: PropTypes.object,
  setSelectedImages: PropTypes.func,
  setSelectedImage: PropTypes.func,
  selectedImage: PropTypes.string,
  selectedImages: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleImageUpload: PropTypes.func,
  handleDeselectImage: PropTypes.func,
  handleChange: PropTypes.func,
};
export default NewBranch;