import React from "react";
import { Link, Dropdown, Space, useNavigate, Locale } from "../../libraries/index";
import "./customDropDown.css";
import { ThemeIcon } from "../../assets/Images";

export const CustomThemeDropDown = ({ isSection, text, items, color, fontSize, placement, className, textLableClass, selectedLanguage }) => {
  const navigate = useNavigate();
  const {t} = Locale();

  const setLocalStorageSection = (e, path, section) => {
    e.preventDefault();
    isSection && localStorage.setItem("isSection", section)
    navigate(path)

  }
  const dropdownMenu = (

    <div className={"custom-dropdown-menu"}>
      {items && (
        items.map((item, index) => (
          <div key={item.key} className="custom-dropdown-item">
            {item.onClick ? (
              <span onClick={item.onClick}>{item.label}</span>
            ) : (
              <Link onClick={(e) => setLocalStorageSection(e, item.path, item.section)} className="navbar-signin-dropdow-toggle">
                {item.label}
              </Link>
            )}
            {index < items.length - 1 && (
              <hr className={"custom-dropdown-divider mt-2"} />
            )}
          </div>
        ))
      )}
    </div>
  );
  return (
    <>
      <Dropdown overlay={dropdownMenu} placement={placement}>
        <div  title={t("header.theme")}>
          {/*<img
            width={15}
            height={20}
            src={ThemeIcon}
            alt={ThemeIcon}
          />*/}
          <Space className={className} style={{ color: color, fontSize: fontSize }}>
            <label className={textLableClass}>{text}</label>
            <div className="downArrow" style={{ color: color }}>
              <i className="fa-solid fa-angle-down"></i>
            </div>
          </Space>
        </div>
      </Dropdown>

    </>
  );
};