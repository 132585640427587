import './DealOfTheDay.css'
import { CustomSpinner, EmptyComponent, Footer } from "../../common"
import { Locale, React, useEffect, useState } from '../../libraries'
import { BookNowModalLogic, NavbarLogic } from "../../components"
import { BreadCrumb } from "../BreadCrumb/BreadCrumb"
import { CustomImage } from '../../lazyComponent'
import { DefaultServiceImg, ServiceImg1 } from '../../assets/Images'
import { CurrencyDisplay } from '../../jsonData'
import SignIn from '../../components/login/signIn'

const DealOfTheDayPage = ({ data, favouriteServicesIds, updateCartItem, handleAddToCart, handleLikeClick, breadCrumbData, isLoader, isEmpty, navigateServiceSection, cartItem, showModal, setShowModal, type, commonId, userType }) => {

    const { t } = Locale();
    const [itemsToShow, setItemsToShow] = useState(8); // Default to 8 items

    const isThaiLanguage = localStorage.getItem('i18nextLng') === 'thai';

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth > 1024) { // Web
                setItemsToShow(8);
            } else if (screenWidth <= 1024 && screenWidth > 768) { // iPad
                setItemsToShow(9);
            } else { // Mobile
                setItemsToShow(10);
            }
        };

        handleResize(); // Call once on component mount
        window.addEventListener('resize', handleResize); // Add event listener

        return () => {
            window.removeEventListener('resize', handleResize); // Clean up on unmount
        };
    }, []);

    const getServiceName = (obj) => {
        return isThaiLanguage ? (obj?.service_name_thai || obj?.service_name_en) : obj?.service_name_en;
    }

    const getCartDisable = (serviceIs) => {
        if (cartItem?.includes(serviceIs)) {
            return 'disabled-icon';
        } else {
            return 'inabled-icon';
        }
    };


    const getFavouriteIconClassName = (favouriteServiceId) => {
        return favouriteServicesIds.some(fav => fav.service_id === favouriteServiceId)
            ? "bi bi-suit-heart-fill"
            : "bi bi-suit-heart";
    };

    const getFullAddress = (vendorInfo) => {
        return `${vendorInfo?.address} ${vendorInfo?.city} ${vendorInfo?.postcode}`
    }

    const getDiscountPercent = (serviceInfo) => {
        return (serviceInfo?.discount_on_service) ? (serviceInfo?.discount_on_service) : '0.00'
    }


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>
        <NavbarLogic
            handleAddToCartCount={updateCartItem}
        />
        <section className="container mb-5 about-us-outer-container">
            <BreadCrumb data={breadCrumbData} />
            <div className="top-review-outer">
                {((!isLoader) && !(isEmpty)) && <>
                    <div className="row top-review-shop-row">
                        {data?.slice(0, itemsToShow)?.map((obj, index) => (
                            <React.Fragment key={index}>
                                <div className="col-lg-3 col-sm-12 col-md-4 box">
                                    <div className="top-review-shop-card">
                                        <div className="shop-card-inner">
                                            <div className="deal-of-day-image-section">
                                                <div className="shop-image" onClick={(e) => navigateServiceSection(e, obj)}>
                                                    <CustomImage
                                                        src={obj?.service_info?.service_image || DefaultServiceImg}
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <div className='add-to-favourite-secton'>
                                                    <i
                                                        className={`
                                                        ${getFavouriteIconClassName(obj?.service_info?.id)}`}
                                                        onClick={() =>
                                                            handleLikeClick(
                                                                obj?.service_info.id,
                                                                obj?.service_info?.favourite?.favourite_id
                                                            )
                                                        }
                                                    ></i>
                                                    <div
                                                        className={`modal fade ${showModal ? 'd-block show' : 'd-none'}`}
                                                        id="exampleModal"
                                                        tabIndex="-1"
                                                        aria-labelledby="exampleModalLabel"
                                                        aria-hidden="true"
                                                    >
                                                        <SignIn
                                                            setShowModal={
                                                                setShowModal
                                                            }
                                                            showModal={
                                                                showModal
                                                            }
                                                            type={type}
                                                            commonId={
                                                                commonId
                                                            }
                                                            userType={
                                                                userType
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="discount-section">
                                                    <div className="discount-section-inner">
                                                        <span className="discount-percent">
                                                            {getDiscountPercent(obj?.service_info)} % {t('field.off')}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='deal-of-the-day-content-section'>
                                                <h2 className="deal-of-the-day-service-heading" onClick={(e) => { navigateServiceSection(e, obj) }}>
                                                    {getServiceName(obj?.service_info)}
                                                </h2>
                                                <span className="card-main-address-section">
                                                    <i
                                                        className="bi bi-geo-alt-fill"
                                                        aria-hidden="true"
                                                    ></i>&nbsp;
                                                    {getFullAddress(obj?.vendor_info)}
                                                </span>
                                                <div className='time-section'>
                                                    <i className="bi bi-clock-fill clock-icon"></i>
                                                    &nbsp;
                                                    {obj?.service_info?.duration} &nbsp;
                                                    {t('paymentSection.min')}
                                                </div>
                                                <div className='price-section'>
                                                    <CurrencyDisplay
                                                        component={'dealOfTheDay'}
                                                        value={obj?.service_info?.special_price}
                                                        subPrice={obj?.service_info?.price}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-main__footer">
                                            <div className="deal-of-day-footer-section">
                                                <div className="action-section">
                                                    <div className='cart-section'>
                                                        <div
                                                            onClick={() =>
                                                                handleAddToCart(
                                                                    obj?.service_info?.id,
                                                                )
                                                            }
                                                            data_bs_toggle="modal"
                                                            data_bs_target="#exampleModal"

                                                            className={` me-2 ${getCartDisable(obj?.service_info?.id)}`}
                                                        >
                                                            <span>
                                                                <i
                                                                    className={`bi bi-cart-plus-fill shop-card-share`}
                                                                ></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='book-now-section'>
                                                        <div>
                                                            <BookNowModalLogic
                                                                index={index}
                                                                className="display-service-service-section-bookn-button"
                                                                service_id={
                                                                    obj?.service_info?.id
                                                                }
                                                                vender_details={
                                                                    obj?.vendor_info
                                                                }
                                                                service_details={
                                                                    obj?.service_info
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </>}
                {isLoader && <>
                    <CustomSpinner />
                </>
                }
                {(isEmpty) && (
                    <>
                        <EmptyComponent type="isDeals" />
                    </>
                )}
            </div>
        </section>
        <div className={`${(isLoader) ? 'loader-blog-section' : ''}`}>
            <Footer />
        </div>
    </>
}

export default DealOfTheDayPage