import { SignIn, TripPlanLogic, User, Vendor, AboutUsLogic, Blog, BlogDetails, BussinessPolicyLogic, CancelPolicyLogic, CartItemsLogic, ContactUsLogic, DisplayServiceLogic, FAQLogic, ForgotPasswordPage, Home, MobileResponseId, OrderHistoryLogic, Payment, PaymentSuccess, PrivacyPolicyLogic, SearchAndDisplayDescLogic, SignUp, TermConditionLogic, VendorShopMap, TopReviewShopLogic, DealOfTheDayLogic, HappyHoursLogic, ServiceSection } from "../../lazyComponent";
import { CustomerRoutes, HomeRoutes, AuthRoutes, BusinessRoutes } from "../routes/index";
const appRoutings = [
   
    { path: `${CustomerRoutes.successCallback}`, element: PaymentSuccess, exact: true },
    { path: 'mobile/:id/payment', element: MobileResponseId, exact: true },
    { path: `${HomeRoutes.home}`, element: Home, exact: true },
    { path: `${AuthRoutes.signIn}`, element: SignIn, exact: true },
    { path: `${AuthRoutes.signUp}/:value`, element: SignUp, exact: true },
    { path: `${AuthRoutes.forgotPassword}`, element: ForgotPasswordPage, exact: true },
    { path: `${AuthRoutes.verify}`, element: ForgotPasswordPage, exact: true },
    { path: `${AuthRoutes.createNewPassword}`, element: ForgotPasswordPage, exact: true },

    // --------------- website pages / footer section 
    { path: `${HomeRoutes.about}`, element: AboutUsLogic },
    { path: `${HomeRoutes.privacy}`, element: PrivacyPolicyLogic },
    { path: `${HomeRoutes.contact}`, element: ContactUsLogic },
    { path: `${HomeRoutes.faqs}`, element: FAQLogic },
    { path: `${HomeRoutes.businessPolicy}`, element: BussinessPolicyLogic },
    { path: `${HomeRoutes.cancelPolicy}`, element: CancelPolicyLogic },
    { path: `${HomeRoutes.termCondition}`, element: TermConditionLogic },
    { path: `${HomeRoutes.blog}`, element: Blog, exact: true },
    { path: `${HomeRoutes.blogDetails}/:encryptedId`, element: BlogDetails, exact: true },




    { path: `${HomeRoutes.dealOfDay}`, element: DealOfTheDayLogic },
    { path: `${HomeRoutes.topReviewShop}`, element: TopReviewShopLogic },
    { path: `${HomeRoutes.tripPlan}`, element: TripPlanLogic },
    { path: `${HomeRoutes.happyHours}`, element: HappyHoursLogic },
    { path: `${HomeRoutes.service}/:serviceId/:serviceName`, element: ServiceSection },
    { path: `${HomeRoutes.services}/:categoryName/:subCategoryName/:encryptedData`, element: DisplayServiceLogic, exact: true },
    { path: `${HomeRoutes.services}${HomeRoutes.search}:searchText`, element: DisplayServiceLogic, exact: true },
    { path: `${HomeRoutes.services}/:value`, element: DisplayServiceLogic, exact: true },
    { path: `${CustomerRoutes.payment}/:unique_id`, element: Payment },
    { path: `${CustomerRoutes.cartPayment}/:unique_id`, element: CartItemsLogic },
    { path: `${CustomerRoutes.orderHistory}/:bookingIdsArray`, element: OrderHistoryLogic },
    { path: `${HomeRoutes.serviceDescription}/:organizationId/:shopName`, element: SearchAndDisplayDescLogic },
    { path: `${HomeRoutes.shopNearMe}`, element: VendorShopMap },
    { path: `${CustomerRoutes.users}`, element: User },
    { path: `${BusinessRoutes.vendors}`, element: Vendor },
];
export default appRoutings;