import './Dashbord.css';
import { React, PropTypes, Locale, useEffect, useRef, useState, useNavigate } from '../../../libraries/index';
import { DashBoardGraph, VendorHeader } from '../index';
import { User, vendor_shares } from '../../../assets/Images';
import { CustomSpinner, Heading } from '../../../common';
import {BusinessRoutes } from '../../../routes/index';
import { UnderReviewLogic } from '../../component';
import { CurrencyDisplay } from '../../../jsonData';
import { CustomImage } from '../../../lazyComponent';
const Dashbord = ({
  isEmpty,
  filterValue,
  handleFilter,
  isOrganizationStatus,
  isLoader,
  topService,
  upCommingBooking,
  customerRating,
  dashBoardData,
  customerRevenue,
  handleFilterRevenue,
  filterRevenue,
  deletionRequest
}) => {
  const [isServiceIcon, setIsserviceIcon] = useState(false);
  const [isSalesIcon, setIsSalesIcon] = useState(false);
  const currentLocation=sessionStorage.getItem('country');
  const currencyIcon = currentLocation === 'India' ? '₹' : '฿';
  const navigate = useNavigate();
  const { t } = Locale();

  const handleNavigation = (e, path) => {
    e.preventDefault();
    localStorage.setItem(
      'isSection',
      path === `${BusinessRoutes.bussiness}${BusinessRoutes.bookings}`
        ? 'isBusinessBooking'
        : 'isBusinessReview',
    );
    navigate(path);
  };

  const getBookingPaymentType = (booking) => {
    return booking.payment_type === "online"
      ? `${t("userBooking.payOnline")}`
      : `${t("userBooking.payAtShop")}`;
  };

  const menuRef = useRef(null);
  const menuRef1 = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !event.target.closest('.mdl-button')
      ) {
        setIsSalesIcon(false);
      }
      if (
        menuRef1.current &&
        !menuRef1.current.contains(event.target) &&
        !event.target.closest('.mdl-button')
      ) {
        setIsserviceIcon(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => { }, [isOrganizationStatus]);

  const selectedLanguage = localStorage.getItem('i18nextLng');

  const getServiceName = (data) => {
    return selectedLanguage === 'en' ? data.service_name : data.service_name_thai;
  };

  const getCategoryName = (data) => {
    return selectedLanguage === 'en' ? data.category : data.category_name_thai;
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className={`heading-outer-div  ${deletionRequest && 'vendor-deletionRequest-user-header'}`}>
        <VendorHeader />
        <Heading text={t('vendor.dashboard')} icon={'bi bi-speedometer2'} />
        <div className="after-heading-outer-div">
          {isOrganizationStatus !== 'verified' ? (
            <>
              <UnderReviewLogic />
            </>
          ) : (
            <>
              <div
                className={`finance-outer-div-cart user-common-box  ${!isLoader ? `user-inner-content-scrollar` : 'd-none'}`}
              >
                <div className="row ">
                  <div className="col-lg-4 col-md-6 col-sm-12  mt-2">
                    <div className="dashboard_card">
                      <div className="container">
                        <div className="row box-shadow-all">
                          <div className="col-6 revenue-div-css">
                            <div className="revenue-div">
                              <i className="text-center">{currencyIcon}</i>
                            </div>
                          </div>
                          <div className="col-6 revenue-div_text">
                            <p>{t('vendorDashboard.totalRevenue')}</p>

                            {!Array.isArray(customerRevenue) ? (
                              <h4>
                                <CurrencyDisplay value={customerRevenue} />
                              </h4>
                            ) : (
                              <h4>
                                <CurrencyDisplay value={0} />
                              </h4>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-3 col-sm-12 mt-2">
                    <div className="dashboard_card">
                      <div className="container">
                        <div className="row box-shadow-all">
                          <div className="col-6">
                            <div className="visitors-div">
                              <i className="bi bi-people-fill"></i>
                            </div>
                          </div>
                          <div className="col-6 visitors-div_text">
                            <p className="dashboard-inner-table-heading">
                              {t('vendorDashboard.visitors')}
                            </p>
                            <div className='venue-name-h4'>{dashBoardData?.visitors_user_count}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-3 col-sm-12 mt-2">
                    <div className="dashboard_card">
                      <div className="container">
                        <div className="row box-shadow-all">
                          <div className="col-6">
                            <div className="shares-div">
                              <CustomImage
                                src={vendor_shares}
                                alt="vendor"
                                width={'unset'}
                                height={'unset'}
                              />
                            </div>
                          </div>
                          <div className="col-6 shares-div_text">
                            <p>{t('vendorDashboard.shares')}</p>
                            <div className='venue-name-h4'>{dashBoardData?.share_count}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-6 company-details">
                    <div className="finance-inner-cart-div company-details-inner">
                      <div className="box-heading">
                        <div className="box-heading-content">
                          {t('vendorDashboard.sales')}
                        </div>
                        <div>
                          <button
                            id="panel-button"
                            className={`mdl-button mdl-js-button mdl-button--icon pull-right  ${isSalesIcon && 'is-btn-active'}`}
                            onClick={() => setIsSalesIcon(!isSalesIcon)}
                            data-upgraded=",MaterialButton"
                          >
                            <i className="material-icons bi bi-three-dots-vertical"></i>
                          </button>
                          <div
                            ref={menuRef}
                            className={`dashbord-right mdl-menu__container is-upgraded ${isSalesIcon && 'is-visible'}`}
                          >
                            <div>
                              <div>
                                {upCommingBooking.length > 0 ?
                                  upCommingBooking.map((bookingData, index) => {
                                    const year = new Date(
                                      bookingData.booking_date,
                                    ).getFullYear();
                                    const isDuplicate = upCommingBooking.some(
                                      (data, i) =>
                                        i < index &&
                                        new Date(
                                          data.booking_date,
                                        ).getFullYear() === year,
                                    );

                                    if (!isDuplicate) {
                                      return (
                                        <div
                                          key={index}
                                          className={`dashboard-filter-inner-div`}
                                        >
                                          <span
                                            className="dashoboard-menu-li"
                                            onClick={() =>
                                              handleFilterRevenue(year, setIsSalesIcon)
                                            }
                                          >
                                            {year}
                                          </span>
                                        </div>
                                      );
                                    }

                                    return null;
                                  }) :
                                  <div
                                    className={`dashboard-filter-inner-div`}
                                  >
                                    <span
                                      className="dashoboard-menu-li"
                                      onClick={() =>
                                        handleFilterRevenue(currentYear, setIsSalesIcon)
                                      }
                                    >
                                      {currentYear}
                                    </span>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="box-content">
                        <DashBoardGraph filterRevenue={filterRevenue} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 contact-person-details">
                    <div className="finance-inner-cart-div contact-person-details-inner">
                      <div className="box-heading">
                        <div className="box-heading-content">
                          {t('vendorDashboard.topService')}
                        </div>
                        <div>
                          <button
                            id="panel-button"
                            className={`mdl-button mdl-js-button mdl-button--icon pull-right ${isServiceIcon && 'is-btn-active'}`}
                            onClick={() => setIsserviceIcon(!isServiceIcon)}
                            data-upgraded=",MaterialButton"
                          >
                            <i className="material-icons bi bi-three-dots-vertical"></i>
                          </button>
                          <div
                            ref={menuRef1}
                            className={`dashbord-right mdl-menu__container is-upgraded ${isServiceIcon && 'is-visible'}`}
                          >
                            <div>
                              <div
                                className={`dashboard-filter-inner-div ${filterValue.all && 'active-filter'}`}
                                onClick={() => handleFilter('all', setIsserviceIcon)}
                              >
                                <span>{t('vendorDashboard.allTime')}</span>
                              </div>
                              <div
                                className={`dashboard-filter-inner-div ${filterValue.monthly && 'active-filter'}`}
                                onClick={() => handleFilter('monthly', setIsserviceIcon)}
                              >
                                <span>{t('vendorDashboard.monthly')}</span>
                              </div>
                              <div
                                className={`dashboard-filter-inner-div last-list ${filterValue.weekly && 'active-filter'}`}
                                onClick={() => handleFilter('weekly', setIsserviceIcon)}
                              >
                                <span>{t('vendorDashboard.weekly')}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="box-content">
                        <div className="vendor_graph_service_table">
                          <table className="table table-bordered">
                            <thead>
                              <tr className="table-light vendor-dashboard-table-heading">
                                <th>{t('vendorDashboard.service')}</th>
                                <th>{t('vendorDashboard.category')}</th>
                                <th>{t('vendorDashboard.booking')}</th>
                                <th>{t('vendorDashboard.totalRevenue')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {topService &&
                                topService.length > 0 &&
                                topService.slice(0, 5).map((data, index) => (
                                  <tr
                                    key={index}
                                    className="dashboard_table_row_divider"
                                  >
                                    <td>{getServiceName(data)}</td>
                                    <td>{getCategoryName(data)}</td>
                                    <td>{data.count}</td>
                                    <td className="vendor-dashbord-price-td">
                                      <CurrencyDisplay
                                        value={data.service_revenue}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              {isEmpty.isTopService && (
                                <>
                                  <tr className="text-center">
                                    <td colSpan="4" className="notAvailable">
                                      {t('ServiceField.noServiceAvailable')}
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="finance-inner-cart-div mt-3 dashbord-upcoming-booking">
                  <div className="box-heading d-block">
                    <div className="box-heading-content d-flex justify-content-between">
                      <div>{t('vendorDashboard.upComingBooking')}</div>
                      <div
                        onClick={(e) =>
                          handleNavigation(e, `${BusinessRoutes.bussiness}${BusinessRoutes.bookings}`)
                        }
                        className="vendor-dashboardd-viewAll"
                      >
                        {t('validation.viewAll')}
                      </div>
                    </div>
                  </div>
                  <div className="box-content">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-light vendor-dashboard-table-heading">
                          <th>{t('vendorDashboard.bookingId')}</th>
                          <th>{t('vendorDashboard.date')}</th>
                          <th>{t('vendorDashboard.user')}</th>
                          <th>{t('vendorDashboard.service')}</th>
                          <th>{t('vendorDashboard.price')}</th>
                          <th>{t('vendorDashboard.paymentType')}</th>
                          <th>{t('vendorDashboard.status')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {upCommingBooking &&
                          upCommingBooking.length > 0 &&
                          upCommingBooking.slice(0, 5).map((data) => {
                            const date = new Date(data.booking_date);
                            const formattedDate = `${date.getDate()}/${date.getMonth() + 1
                              }/${date.getFullYear()}`;
                            return (
                              <tr key={data.booking_service_id}>
                                <td>{data.booking_service_id}</td>
                                <td>{formattedDate}</td>
                                <td>{data.customer_name}</td>
                                <td>{getServiceName(data)}</td>
                                <td className="vendor-dashbord-price-td">
                                  <CurrencyDisplay
                                    value={!data.price ? 0 : data.price}
                                  />
                                </td>
                                <td>{getBookingPaymentType(data)}</td>
                                <td>{data.status}</td>
                              </tr>
                            );
                          })}
                        {isEmpty.isUpComingBooking && (
                          <>
                            <tr className="text-center">
                              <td colSpan="7" className="notAvailable">
                                {t('ServiceField.noBookingsAvailable')}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="finance-inner-cart-div mt-3 dashbord-upcoming-booking">
                  <div className="box-heading d-block">
                    <div className="box-heading-content d-flex justify-content-between">
                      <div>{t('vendorDashboard.recentReviews')}</div>
                      <div
                        onClick={(e) =>
                          handleNavigation(e, `${BusinessRoutes.bussiness}${BusinessRoutes.businessReview}`)
                        }
                        className="vendor-dashboardd-viewAll"
                      >
                        {t('validation.viewAll')}
                      </div>
                    </div>
                  </div>
                  <div className="box-content">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-light vendor-dashboard-table-heading">
                          <th className="vendor-dashboard-user">
                            {t('vendorDashboard.user')}
                          </th>
                          <th>{t('vendorDashboard.date')}</th>
                          <th className="vendor-dashboard-rating">
                            {t('vendorDashboard.rating')}
                          </th>
                          <th>{t('vendorDashboard.comment')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerRating &&
                          customerRating.length > 0 &&
                          customerRating.slice(0, 5).map((data, index) => {
                            const date = new Date(data.created_at);
                            const formattedDate = `${date.getDate()}/${date.getMonth() + 1
                              }/${date.getFullYear()}`;
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="dashboard_username">
                                    <CustomImage
                                      className="dashboard_user_image"
                                      src={data?.image ? data?.image : User}
                                      alt="User"
                                      width={'unset'}
                                      height={'unset'}
                                    />
                                    <p className="mt-1">
                                      &nbsp;{data.user_name}
                                    </p>
                                  </div>
                                </td>
                                <td>{formattedDate}</td>
                                <td>
                                  <div className="dashboard_ratings">
                                    {[...Array(5)].map((_, index) => {
                                      let starClass = 'bi-star-fill';
                                      let starColor = 'lightgray';

                                      if (index < Math.floor(data.rating)) {
                                        starColor = 'gold';
                                      } else if (index === Math.floor(data.rating) && data.rating % 1 >= 0.5) {
                                        starClass = 'bi-star-half';
                                        starColor = 'gold';
                                      }

                                      return (
                                        <i
                                          key={index}
                                          className={`bi ${starClass}`}
                                          style={{ color: starColor }}
                                          aria-hidden="true"
                                        ></i>
                                      );
                                    })}
                                  </div>
                                </td>

                                <td>{data.comment}</td>
                              </tr>
                            );
                          })}
                        {isEmpty.isCustomerRating && (
                          <>
                            <tr className="text-center">
                              <td colSpan="4" className="notAvailable">
                                {t('ServiceField.noReviewAvailable')}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {isLoader && (
                <>
                  <CustomSpinner />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
Dashbord.propTypes = {
  topService: PropTypes.array,
  upCommingBooking: PropTypes.array,
  customerRating: PropTypes.array,
};
export default Dashbord;