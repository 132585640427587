import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { React, useEffect, Suspense, useMemo, ToastContainer, Route, Routes, useDispatch, Helmet, ScrollToTop, AOS } from './libraries';
import { BrowserRouter as Router } from 'react-router-dom';
import { fetchUserFooterInformation } from './redux/index.js';
import { useUserRoleAndSection, MetaTag, fetchIpBasedLocation } from './customHooks/index.js';
import { PaymentDone, PaymentFailed, PageNotFound, CustomSpinner } from './pages/index.js';
import { AppRouting } from './routes/index.js';
import HomeDataProvider from './contextApi/home/homeContextApi.js';

const App = () => {
  const dispatch = useDispatch();
  const { role, isSection } = useUserRoleAndSection();
  const vendorRegisteredLocation = localStorage.getItem('registeredCountry')
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'default-theme';
    document.body.className = '';
    document.body.classList.add(savedTheme);
    if ((role === 'Customer' && !isSection) || !role) {
      dispatch(fetchUserFooterInformation());
    }
    if ((vendorRegisteredLocation) && (vendorRegisteredLocation !== 'null')) {
      sessionStorage.setItem('country', vendorRegisteredLocation);
    }
    else {
      fetchIpBasedLocation();
    }
  }, [dispatch, role, isSection]);

  const memoizedRoutes = useMemo(() => AppRouting.map(({ path, element, exact = false }) => (
    <>
      <Route
        index={path}
        key={path}
        exact={exact}
        path={path}
        element={
          <Suspense fallback={<CustomSpinner />}>
            {React.createElement(element)}
          </Suspense>
        }
      />
      <Route path={'/payment-done'} element={<PaymentDone />} />
      <Route path='/payment-fail' element={<PaymentFailed />} />
      <Route path="*" element={<PageNotFound />} />
    </>

  )), []);




  return (
    <>
      <Helmet>
        <link rel="icon" href="/images.png" />
      </Helmet>
      <MetaTag />
      <HomeDataProvider>
        <Router>
          <Routes>
            {memoizedRoutes}
          </Routes>
          <ScrollToTop smooth color="white" height="50%" />
        </Router>
      </HomeDataProvider>
      <ToastContainer />
    </>
  );
};

export default App;
