
import './Finance.css';
import { React, useEffect, useState, PropTypes, Locale, Select, IntlTelInput } from '../../../libraries';
import { CustomSpinner, Label, Small, Heading } from '../../../common';
import { PreviewFileModal, VendorHeader } from '../index';
import { getPhoneCode } from '../../../customHooks';

const Finance = ({
  handleCityTypeChange,
  handlePhoneCountryCodeChange,
  handlePhoneNumberChange,
  isLoader,
  isUpdate,
  uploadedCheque,
  formValue,
  handleSubmit,
  handleChange,
  postalCode,
  formError,
  country,
  buttonDisabled,
  handleStateChange,
  handleCityChange,
  handlePostalCode,
  addressFormValue,
  stateDropdown,
  companyCityDropDown,
  bankCityDropDown,
  filterOption,
  deletionRequest,
  handleCountryChange
}) => {
  const { t } = Locale();
  const [preferredCountriesCodePhone, setPreferredCountriesCodePhone] = useState('')
  const [preferredCountriesCodeBilling, setBreferredCountriesCodeBilling] = useState('');
  const isThailandVendor = localStorage.getItem('registeredCountry') === 'Thailand'
  const getEyeClassName = () => {
    if (!formValue?.cheque && !uploadedCheque) {
      return "is-disable-eye-icon-service-section";
    } else {
      return "";
    }
  };

  useEffect(() => {
    let tempCode = getPhoneCode(formValue?.billingContactPhoneCountryCode)
    setBreferredCountriesCodeBilling(tempCode)
  }, [formValue?.billingContactPhoneCountryCode])

  useEffect(() => {
    let tempCode = getPhoneCode(formValue?.phoneCountryCode)
    setPreferredCountriesCodePhone(tempCode)
  }, [formValue?.phoneCountryCode])




  return (
    <>
      <PreviewFileModal formValue={formValue} uploadedCheque={uploadedCheque} />
      <div className={`heading-outer-div  ${deletionRequest && 'vendor-deletionRequest-user-header'}`}>
        <VendorHeader />
        <Heading text={t("vendor.finance")} icon={"bi bi-bank"} />
        <div className="after-heading-outer-div">
          <div
            className={`finance-outer-div-cart finance-section user-common-box ${!isLoader ? `user-inner-content-scrollar` : "d-none"
              }`}
          >
            <div className="row">
              <div className="col-lg-6 company-details">
                <div className="finance-inner-cart-div">
                  <div className="box-heading">
                    <div className="box-heading-content">
                      {t("financeField.companyDetails")}
                    </div>
                  </div>
                  <div className="box-content">
                    <div className="row">
                      <div className={`${addressFormValue?.country?.name === "Thailand" ? 'col-lg-6 col-md-6 col-sm-6' : 'col-lg-4 col-md-4 col-sm-4'} `}>
                        <Label
                          className="form-label"
                          text={
                            <>
                              {t("financeField.companyTitle")}
                              <small className="small-err">*</small>
                            </>
                          }
                        />
                        <input
                          placeholder={`${t("field.pleaseEnter")} ${t(
                            "financeField.companyTitle"
                          )}`}
                          name="title"
                          value={formValue.title}
                          onChange={(e) => handleChange(e)}
                          className="form-control vendor-account-details-input-field"
                          type="text"
                        />
                        <Small name={"tittle"} err={formError.title} />
                      </div>
                      <div className={`${addressFormValue?.country?.name === "Thailand" ? 'col-lg-6 col-md-6 col-sm-6 finance-col-lg-4-div' : 'col-lg-4 col-md-4 col-sm-4 finance-col-lg-4-div'} `} >
                        <Label
                          className="form-label field-space-finance"
                          text={isThailandVendor ? t("financeField.taxId") : t("financeField.gstNumber")}
                        />
                        {isThailandVendor ? <>
                          <input
                            name="taxId"
                            value={formValue.taxId}
                            onChange={(e) => handleChange(e)}
                            placeholder={`${t("field.pleaseEnter")} ${t(
                              "financeField.taxId"
                            )}`}
                            className="form-control vendor-account-details-input-field"
                            type="text"
                          />
                        </> : <>
                          <input
                            name="gstNumber"
                            value={formValue.gstNumber}
                            onChange={(e) => handleChange(e)}
                            placeholder={`${t("field.pleaseEnter")} ${t(
                              "financeField.gstNumber"
                            )}`}
                            className="form-control vendor-account-details-input-field"
                            type="text"
                          />
                        </>}

                      </div>
                      {addressFormValue?.country?.name !== 'Thailand' && <>
                        <div className="col-lg-4 col-md-4 col-sm-4 finance-col-lg-4-div">
                          <Label
                            className="form-label field-space-finance"
                            text={
                              <>
                                {t("field.state")}
                                <small className="small-err">*</small>
                              </>
                            }
                          />
                          <Select
                            className="finace-ant-select-form w-100"
                            placeholder={`${t("field.pleaseSelect")} ${t(
                              "field.state"
                            )}`}
                            optionFilterProp="children"
                            onChange={(e) => handleStateChange(e, 'companyDetails')}
                            options={stateDropdown}
                            value={addressFormValue?.companyState?.name || undefined}
                            filterOption={filterOption}
                            showSearch
                          />
                          <Small name={"state"} err={formError.companyState} />
                        </div>
                      </>}
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-4 col-md-4 col-sm-4 finance-col-lg-4-div finance-col-field">
                        {addressFormValue?.country?.name !== 'Thailand' ? <>
                          <Label
                            className="form-label field-space-finance"
                            text={
                              <>
                                {t("field.city")}
                                <small className="small-err">*</small>
                              </>
                            }
                          />
                          {((companyCityDropDown?.length)) ? <>
                            <Select
                              className="finace-ant-select-form w-100"
                              placeholder={`${t("field.pleaseSelect")} ${t(
                                "field.city"
                              )}`}
                              optionFilterProp="children"
                              onChange={(e) => handleCityChange(e, 'companyDetails')}
                              options={companyCityDropDown}
                              value={addressFormValue?.companyCity?.name || undefined}
                              filterOption={filterOption}
                              showSearch
                            />
                          </> : <>
                            <input
                              name="companyCity"
                              className="countryCode-class"
                              placeholder={`Enter ${t("field.city")}`}
                              onChange={handleCityTypeChange}
                              value={addressFormValue?.companyCity?.name}
                            />
                          </>}

                          <Small
                            name={"city"}
                            err={formError.companyCity}
                          />
                        </> : <>
                          <Label
                            className="form-label field-space-finance"
                            text={
                              <>
                                {t('financeField.country')}
                                <small className="small-err">*</small>
                              </>
                            }
                          />
                          <Select
                            className="finace-ant-select-form"
                            showSearch
                            placeholder={`${t("field.pleaseSelect")} ${t(
                              'financeField.country',
                            )}`}
                            optionFilterProp="children"
                            onChange={(value) => handleCountryChange('company_country', value)}
                            filterOption={filterOption}
                            options={country}
                            value={formValue.company_country || undefined}
                          />
                          <Small name={'country'} err={formError.company_country} />
                        </>}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 finance-col-lg-4-div">

                        <Label
                          className="form-label field-space-finance"
                          text={
                            <>
                              {t("field.postalCode")}
                              <small className="small-err">*</small>
                            </>
                          }
                        />
                        {addressFormValue?.country?.name !== 'Thailand' ? <>
                          <input
                            name="postalCode"
                            className="countryCode-class"
                            placeholder="Enter Postal Code"
                            value={addressFormValue?.companyPostalCode?.value}
                            onChange={(e) => handlePostalCode(e.target.value, 'companyDetails')}
                          />
                        </> : <>
                          <Select
                            className="finace-ant-select-form"
                            showSearch
                            placeholder={`${t("field.pleaseSelect")} ${t(
                              'field.postalCode',
                            )}`}
                            optionFilterProp="children"
                            onChange={(e) => handlePostalCode(e, 'companyDetails')}
                            filterOption={filterOption}
                            options={postalCode}
                            value={addressFormValue?.companyPostalCode?.value || undefined}
                          />
                        </>}
                        <small className="small-err">{formError.companyPostalCode}</small>
                      </div>

                      <div className="col-lg-5 col-md-5 col-sm-5 ">
                        <Label
                          className="form-label field-space-finance"
                          text={
                            <>
                              {t("financeField.billingAddress")}
                              <small className="small-err">*</small>
                            </>
                          }
                        />


                        <input
                          name="billingAddress"
                          value={formValue.billingAddress}
                          onChange={(e) => handleChange(e)}
                          placeholder={`${t("field.pleaseEnter")} ${t(
                            "financeField.billingAddress"
                          )}`}
                          className="form-control vendor-account-details-input-field"
                          type="text"
                        />
                        <Small
                          name={"billingAddress"}
                          err={formError.billingAddress}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 contact-person-details">
                <div className="finance-inner-cart-div">
                  <div className="box-heading">
                    <div className="box-heading-content">
                      {t("financeField.contactDetail")}
                    </div>
                  </div>
                  <div className="box-content">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <Label
                          className="form-label"
                          text={
                            <>
                              {t("financeField.contactName")}
                              <small className="small-err">*</small>
                            </>
                          }
                        />
                        <input
                          name="contactName"
                          placeholder={`${t("field.pleaseEnter")} ${t(
                            "financeField.contactName"
                          )}`}
                          value={formValue.contactName}
                          onChange={(e) => handleChange(e)}
                          className="form-control vendor-account-details-input-field"
                          type="text"
                        />
                        <Small
                          name={"contactName"}
                          err={formError.contactName}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 finance-col-lg-4-div finance-col-field">
                        <Label
                          className="form-label field-space-finance"
                          text={
                            <>
                              {t("field.email")}
                              <small className="small-err">*</small>
                            </>
                          }
                        />
                        <input
                          name="email"
                          value={formValue.email}
                          onChange={(e) => handleChange(e)}
                          placeholder={`${t("field.pleaseEnter")} ${t(
                            "field.email"
                          )}`}
                          className="form-control vendor-account-details-input-field"
                          type="text"
                        />
                        <Small name={"email"} err={formError.email} />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-6">
                        <div className="accountDetails_phonenumber_Div ">
                          <div className="row ">
                            <Label
                              className="form-label"
                              text={
                                <>
                                  {t("field.phoneNumber")}
                                  <small className="small-err">*</small>
                                </>
                              }
                            />
                            <div className="col-12 finace-IntlTelInput-form">

                              <IntlTelInput

                                key={preferredCountriesCodePhone}
                                id="phoneNumber"
                                name="phone"
                                preferredCountries={[preferredCountriesCodePhone]}
                                separateDialCode={true}
                                containerClassName={`intl-tel-input ${formError.phone ? "error-class" : ""
                                  }`}
                                onPhoneNumberChange={(
                                  isValid,
                                  phoneNumber,
                                  countryData
                                ) => {
                                  handlePhoneNumberChange(
                                    phoneNumber,
                                    countryData,
                                    "phone",
                                    "phoneCountryCode"
                                  );
                                }}
                                onSelectFlag={(isValid, countryCode, countryData) => {
                                  handlePhoneCountryCodeChange(countryCode, countryData, 'phoneCountryCode', formValue?.phoneCountryCode);
                                }}
                                value={(formValue.phone) ? (formValue.phone) : ''}

                              // value={formValue?.phone}
                              />
                              <Small name={"phone"} err={formError.phone} />
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 finance-col-field-billing-contact">
                        <div className="accountDetails_phonenumber_Div ">
                          <div className="row ">
                            <Label
                              className="form-label"
                              text={
                                <>
                                  {t("financeField.billContactPhoneNumber")}
                                  <small className="small-err">*</small>
                                </>
                              }
                            />
                            <div className="col-12 finace-IntlTelInput-form">
                              <IntlTelInput
                                key={preferredCountriesCodeBilling}
                                id="phoneNumber"
                                name="billingContactPhone"
                                preferredCountries={[preferredCountriesCodeBilling]}
                                separateDialCode={true}
                                containerClassName={`intl-tel-input ${formError.phone ? "error-class" : ""
                                  }`}
                                onPhoneNumberChange={(
                                  isValid,
                                  phoneNumber,
                                  countryData
                                ) => {
                                  handlePhoneNumberChange(
                                    phoneNumber,
                                    countryData,
                                    "billingContactPhone",
                                    "billingContactPhoneCountryCode"

                                  );
                                }}
                                onSelectFlag={(isValid, countryCode, countryData) => {
                                  handlePhoneCountryCodeChange(countryCode, countryData, 'billingContactPhoneCountryCode', formValue?.billingContactPhoneCountryCode);
                                }}
                                value={(formValue?.billingContactPhone) ? (formValue?.billingContactPhone) : ''}

                              // value={formValue?.billingContactPhone}
                              />
                              <Small
                                name={"billingContactPhone"}
                                err={formError.billingContactPhone}
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="finance-inner-cart-div mt-3">
              <div className="box-heading">
                <div className="box-heading-content">
                  {t("financeField.bankDetail")}
                </div>
              </div>
              <div className="box-content">
                <div className="row ">
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <Label
                      className="form-label"
                      text={
                        <>
                          {t("financeField.accountHolderName")}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    <input

                      name="accountHolderName"
                      placeholder={`${t("field.pleaseEnter")} ${t(
                        "financeField.accountHolderName"
                      )}`}
                      value={formValue.accountHolderName}
                      onChange={(e) => handleChange(e)}
                      className="form-control vendor-account-details-input-field"
                      type="text"
                    />
                    <Small
                      name={"accountHolderName"}
                      err={formError.accountHolderName}
                    />
                  </div>
                  <div className="col-lg-4 finance-col-lg-4-div col-md-4 col-sm-4 finance-col-field">
                    <Label
                      className="form-label field-space-finance"
                      text={
                        <>
                          {t("financeField.accountNumber")}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    <input
                      name="accountNumber"
                      placeholder={`${t("field.pleaseEnter")} ${t(
                        "financeField.accountNumber"
                      )}`}
                      value={formValue.accountNumber}
                      onChange={(e) => handleChange(e)}
                      className="form-control vendor-account-details-input-field"
                      type="text"
                    />
                    <Small
                      name={"accountNumber"}
                      err={formError.accountNumber}
                    />
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <Label
                      className="form-label field-space-finance"
                      text={
                        <>
                          {isThailandVendor ? t("financeField.bankBranchCode") : t("financeField.micrCode")}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    {isThailandVendor ? <>
                      <input
                        name="bankBranchCode"
                        placeholder={`${t("field.pleaseEnter")} ${t(
                          "financeField.bankBranchCode"
                        )}`}
                        value={formValue.bankBranchCode}
                        onChange={(e) => handleChange(e)}
                        className="form-control vendor-account-details-input-field"
                        type="text"
                      />
                      <Small
                        name={"bankBranchCode"}
                        err={formError.bankBranchCode}
                      />

                    </> : <>
                      <input
                        name="micrCode"
                        placeholder={`${t("field.pleaseEnter")} ${t(
                          "financeField.micrCode"
                        )}`}
                        value={formValue.micrCode}
                        onChange={(e) => handleChange(e)}
                        className="form-control vendor-account-details-input-field"
                        type="text"
                      />
                      <Small
                        name={"micrCode"}
                        err={formError.micrCode}
                      />
                    </>}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-4 finance-col-lg-4-div col-md-4 col-sm-4 finance-col-field">
                    <Label
                      className="form-label"
                      text={
                        <>
                          {isThailandVendor ? t("financeField.iban") : t("financeField.ifscCode")}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    {isThailandVendor ? <>
                      <input
                        name="iban"
                        placeholder={`${t("field.pleaseEnter")} ${t(
                          "financeField.iban"
                        )}`}
                        value={formValue.iban}
                        onChange={(e) => handleChange(e)}
                        className="form-control vendor-account-details-input-field"
                        type="text"
                      />
                      <Small name={"iban"} err={formError.iban} />

                    </> : <>
                      <input
                        name="ifscCode"
                        placeholder={`${t("field.pleaseEnter")} ${t(
                          "financeField.ifscCode"
                        )}`}
                        value={formValue.ifscCode}
                        onChange={(e) => handleChange(e)}
                        className="form-control vendor-account-details-input-field"
                        type="text"
                      />
                      <Small name={"ifscCode"} err={formError.ifscCode} />
                    </>}

                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <Label
                      className="form-label field-space-finance"
                      text={
                        <>
                          {t("financeField.bankName")}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    <input
                      name="bankName"
                      placeholder={`${t("field.pleaseEnter")} ${t(
                        "financeField.bankName"
                      )}`}
                      value={formValue.bankName}
                      onChange={(e) => handleChange(e)}
                      className="form-control vendor-account-details-input-field"
                      type="text"
                    />
                    <Small name={"bankName"} err={formError.bankName} />
                  </div>
                  <div className="col-lg-4 col-md-4 finance-col-lg-4-div col-sm-4 finance-col-field">
                    {addressFormValue?.country?.name !== 'Thailand' ? <>
                      <Label
                        className="form-label"
                        text={
                          <>
                            {t("field.state")}
                            <small className="small-err">*</small>
                          </>
                        }
                      />
                      <Select
                        className="finace-ant-select-form w-100"
                        placeholder={`${t("field.pleaseSelect")} ${t(
                          "field.state"
                        )}`}
                        optionFilterProp="children"
                        onChange={(e) => handleStateChange(e, 'bankDetails')}
                        options={stateDropdown}
                        value={addressFormValue?.bankState?.name || undefined}
                        filterOption={filterOption}
                        showSearch
                      />
                      <Small name={"bankState"} err={formError.bankState} />
                    </> : <>
                      <Label
                        className="form-label field-space-finance"
                        text={
                          <>
                            {t('financeField.country')}
                            <small className="small-err">*</small>
                          </>
                        }
                      />
                      <Select
                        className="finace-ant-select-form"
                        showSearch
                        placeholder={`${t("field.pleaseSelect")} ${t(
                          'financeField.country',
                        )}`}
                        optionFilterProp="children"
                        onChange={(value) => handleCountryChange('bank_country', value)}
                        filterOption={filterOption}
                        options={country}
                        value={formValue.bank_country || undefined}
                      />
                      <Small name={'bankCountry'} err={formError.bank_country} />
                    </>}
                  </div>
                </div>
                <div className="row mt-2">
                  {addressFormValue?.country?.name !== 'Thailand' && <>
                    <div className="col-lg-3 col-md-3 col-sm-3">
                      <Label
                        className="form-label field-space-finance"
                        text={
                          <>
                            {t("field.city")}
                            <small className="small-err">*</small>
                          </>
                        }
                      />
                      {((bankCityDropDown?.length)) ? <>
                        <Select
                          className="finace-ant-select-form w-100"
                          placeholder={`${t("field.pleaseSelect")} ${t(
                            "field.city"
                          )}`}
                          optionFilterProp="children"
                          onChange={(e) => handleCityChange(e, 'bankDetails')}
                          options={bankCityDropDown}
                          value={addressFormValue?.bankCity?.name || undefined}
                          filterOption={filterOption}
                          showSearch
                        />
                      </> : <>
                        <input
                          name="bankCity"
                          className="countryCode-class"
                          placeholder={`${t("field.pleaseEnter")} ${t("field.city")}`}
                          onChange={handleCityTypeChange}
                          value={addressFormValue?.bankCity?.name}
                        />

                      </>}

                      <Small name={"bankCity"} err={formError.bankCity} />
                    </div>
                  </>}
                  <div className={`${addressFormValue?.country?.name !== 'Thailand' ? 'col-lg-3 col-md-3 col-sm-3' : 'col-lg-4 col-md-4 col-sm-4'}  finance-col-lg-4-div finance-col-field`}>
                    <Label
                      className="form-label field-space-finance"
                      text={
                        <>
                          {t("field.postalCode")}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    {addressFormValue?.country?.name !== 'Thailand' ? <>
                      <input
                        value={addressFormValue?.bankPostalCode?.value}
                        name="postalCode"
                        className="countryCode-class"
                        placeholder={`${t('header.select')} ${t(
                          'field.postalCode',
                        )}`}
                        onChange={(e) => handlePostalCode(e.target.value, 'bankDetails')}
                      />
                    </> : <>
                      <Select
                        className="finace-ant-select-form w-100"
                        placeholder={`${t("field.pleaseSelect")} ${t(
                          "financeField.postalCode"
                        )}`}
                        optionFilterProp="children"
                        onChange={(e) => handlePostalCode(e, 'bankDetails')}
                        options={postalCode}
                        value={addressFormValue?.bankPostalCode?.value || undefined}
                        filterOption={filterOption}
                        showSearch
                      />
                    </>}
                    <small className="small-err">{formError.bankPostalCode}</small>
                  </div>
                  <div className={`${addressFormValue?.country?.name !== 'Thailand' ? 'col-lg-3 col-md-3 col-sm-3' : 'col-lg-4 col-md-4 col-sm-4'}`}>
                    <Label
                      className="form-label"
                      text={
                        <>
                          {t("financeField.bankAddress")}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    <input
                      name="bankAddress"
                      placeholder={`${t("field.pleaseEnter")} ${t(
                        "financeField.bankAddress"
                      )}`}
                      value={formValue.bankAddress}
                      onChange={(e) => handleChange(e)}
                      className="form-control vendor-account-details-input-field"
                      type="text"
                    />
                    <Small name={"bankAddress"} err={formError.bankAddress} />
                  </div>
                  <div className={`${addressFormValue?.country?.name !== 'Thailand' ? 'col-lg-3 col-md-3 col-sm-3' : 'col-lg-4 col-md-4 col-sm-4'}  mb-5`}>
                    <div className="row">
                      <Label
                        className="form-label field-space-finance"
                        text={
                          <>
                            {t("financeField.uploadCheque")}
                            <small className="small-err">*</small>
                          </>
                        }
                      />
                      <div className="col-lg-11 col-md-11 col-sm-12 vendor-finance-form-cheque-outer-div">
                        {!formValue.cheque ? (
                          <>
                            <div className="finance-cheque-field-after-cheque-outer-div">
                              <div>
                                <input
                                  type="file"
                                  className="hidden-input"
                                  id="file"
                                  name="cheque"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={(e) => handleChange(e)}
                                />
                                <label
                                  htmlFor="file"
                                  className="custom-file-upload"
                                >
                                  <i className="bi bi-upload"></i>
                                </label>
                              </div>
                              <div className="vendor-finance-file-name">
                                {t("financeField.noFileChoosen")}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="finance-cheque-field-after-cheque-outer-div">
                              <div>
                                <input
                                  type="file"
                                  className="hidden-input"
                                  id="file"
                                  name="cheque"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={(e) => handleChange(e)}
                                />
                                <label
                                  htmlFor="file"
                                  className="custom-file-upload"
                                >
                                  <i className="bi bi-upload "></i>
                                </label>
                              </div>
                              <div className="vendor-finance-file-name">
                                {typeof formValue.cheque === "string" ? (
                                  <>{formValue.chequeName}</>
                                ) : (
                                  <>{formValue.cheque.name}</>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="vendor-finance-img-inst-eye">
                          <Small
                            className="vendor-finance-support-inst"
                            err={t("vendor.supportsFormateFinance")}
                          ></Small>
                          <div className="eye-icon-mobile-view">
                            <span className="finance-previewbutton-Div">
                              {!(formValue?.cheque instanceof File) &&
                                formValue?.cheque?.endsWith(".pdf") ? (
                                <>
                                  <a
                                    className="btn finance-previewbutton"
                                    href={formValue.cheque}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      alt="User"
                                      className="img-fluid finance-cheque-availabe-img-tag"
                                      src={
                                        uploadedCheque ||
                                        formValue.cheque ||
                                        "defaultImageURL"
                                      }
                                    />
                                    <i
                                      className="bi bi-eye"
                                      title="View Your File"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </>
                              ) : (
                                <>
                                  <i
                                    className="bi bi-eye mt-2"
                                    title="View Your File"
                                    aria-hidden="true"
                                    data-bs-toggle="modal"
                                    data-bs-target="#previewFileModal"
                                  ></i>
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-12 vendor-finance-web-eye">
                        <span className="finance-previewbutton-Div">
                          {!(formValue?.cheque instanceof File) &&
                            formValue?.cheque?.endsWith(".pdf") ? (
                            <>
                              <a
                                className="btn finance-previewbutton"
                                href={formValue.cheque}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  alt="User"
                                  className="img-fluid finance-cheque-availabe-img-tag"
                                  src={
                                    uploadedCheque ||
                                    formValue.cheque ||
                                    "defaultImageURL"
                                  }
                                />
                                <i
                                  className={`bi bi-eye ${getEyeClassName()}`}
                                  title="View Your File"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </>
                          ) : (
                            <>
                              <i
                                className={`bi bi-eye mt-2 ${getEyeClassName()}`}
                                title="View Your File"
                                aria-hidden="true"
                                data-bs-toggle="modal"
                                data-bs-target="#previewFileModal"
                              ></i>
                            </>
                          )}
                        </span>
                      </div>
                      <Small name="cheque" err={formError.cheque} />
                    </div>
                  </div>
                </div>
                <div>
                  <span className="finance-save-button-div">
                    {isUpdate ? (
                      <>
                        <button
                          className={`btn cursor-point-class activeClass`}
                          name={'Save'}
                          onClick={handleSubmit}
                          disabled={buttonDisabled}
                        >
                          {t("button.update")}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className={`btn cursor-point-class`}
                          name={'Save'}
                          onClick={handleSubmit}
                          disabled={buttonDisabled}
                        >
                          {t("button.saveButton")}
                        </button>
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {isLoader && (
            <>
              <CustomSpinner />
            </>
          )}
        </div>
      </div>
    </>
  );
};
Finance.propTypes = {
  postalCode: PropTypes.array,
  country: PropTypes.array,
  onChange: PropTypes.func,
  filterOption: PropTypes.func,
  countryCode: PropTypes.array,
  isUpdate: PropTypes.bool,
  uploadedCheque: PropTypes.string,
  formValue: PropTypes.object,
  handleSubmit: PropTypes.func,
  formError: PropTypes.object,
};

export default Finance;