import axios from "axios";
import { GetCity, GetCountries, GetState } from "react-country-state-city";

export const getPostalCode = async (city, addressFormValue) => {
    try {
        const countyCode = addressFormValue?.country?.code;
        const stateCode = addressFormValue?.state?.code;
        const response = await axios.get(`http://api.zippopotam.us/${countyCode}/${stateCode}/${city}`)
        const postalCodesArray = response?.data?.places.map((place) => ({
            value: place['post code'],
            label: `${place['post code']} - ${place['place name']}`,
        }));
        return postalCodesArray;
    }
    catch (err) {
        return [];
    }
}

export const getPostalCodeFinanceSection = async (city, addressFormValue, section) => {
    try {
        const countyCode = addressFormValue?.country?.code;
        const stateCode = ((section === 'companyDetails') ? (addressFormValue?.companyState?.code) : (addressFormValue?.bankState?.code));
        const response = await axios.get(`http://api.zippopotam.us/${countyCode}/${stateCode}/${city}`)
        const postalCodesArray = response?.data?.places.map((place) => ({
            value: place['post code'],
            label: `${place['post code']} - ${place['place name']}`,
        }));
        return postalCodesArray;
    }
    catch (err) {
        return [];
    }
}

export const fetchCountries = async (currentCountry) => {
    try {
        const allCountries = await GetCountries(); // Await the promise
        if (Array.isArray(allCountries)) {
            if (currentCountry) {
                const countryData = allCountries.find(country => (country.name).toLowerCase() === currentCountry.toLowerCase());
                if (countryData) {
                    return countryData;
                }
            }
        }
    } catch (error) {
        console.error("Error fetching countries:", error);
    }
};

export const fetchStates = async (countryId, currentState) => {
    try {
        const allStates = await GetState(countryId); // Fetch states based on the countryId
        if (Array.isArray(allStates)) {
            if (currentState) {
                // Find the state by name if a current state is passed
                const stateData = allStates.find(state => state.name === currentState);
                if (stateData) {
                    return stateData; // Return the matched state
                }
            }
            // Return the entire list of states if currentState is not found
            return allStates;
        }
    } catch (error) {
        console.error("Error fetching states:", error);
    }
};

export const fetchCities = async (countryId, stateId, cityName) => {
    try {
        const allCities = await GetCity(countryId, stateId); // Fetch states based on the countryId
        if (Array.isArray(allCities)) {
            if (cityName) {
                // Find the state by name if a current state is passed
                const cityData = allCities.find(city => city.name === cityName);
                if (cityData) {
                    return cityData; // Return the matched state
                }
            }
            // Return the entire list of states if currentState is not found
            return allCities;
        }
    } catch (error) {
        console.error("Error fetching states:", error);
    }
};

export const fetchStateDropdown = async (countryId) => {
    try {
        const allStates = await GetState(countryId); // Fetch states based on the countryId
        if (Array.isArray(allStates)) {
            const statesArray = allStates.map((state) => ({
                value: state?.name,
                label: state?.name,
                id: state?.id,
                state_code: state?.state_code,
                name: state?.name,
            }));
            return statesArray;
        }
        else {
            return []
        }

    } catch (error) {
        console.error("Error fetching city:", error);
        return []
    }
}

export const fetchCitiesDropdown = async (countryId, stateId) => {
    try {
        const allCities = await GetCity(countryId, stateId); // Fetch states based on the countryId
        if (Array.isArray(allCities)) {
            const citiesArray = allCities.map((city) => ({
                value: city?.name,
                label: city?.name,
            }));
            return citiesArray;
        }
        else {
            return []

        }

    } catch (error) {

        console.error("Error fetching city:", error);
        return []
    }
}

export const fetchCountriesDropdown = async () => {
    try {
        const allCountries = await GetCountries(); // Fetch all countries
        const filteredCountries = allCountries.filter(country =>
            country.name === "India" || country.name === "Thailand"
        );
        const countryArray = filteredCountries.map((country) => ({
            value: country?.name,
            label: country?.name,
            id: country?.id,
            name: country?.name,
            code: country?.iso2
        }));
        return countryArray;

    } catch (error) {
        console.error("Error fetching countries:", error);
        return [];
    }
}

export const fetchAllCities = async () => {
    const currentCountry = sessionStorage.getItem('country'); // Get the country from sessionStorage

    if (!currentCountry) {
        console.error("Country is not set in session storage");
        return false;
    }

    try {
        // Step 1: Fetch the country data based on country name
        const countryData = await fetchCountries(currentCountry);
        if (!countryData) {
            console.error("Country not found");
            return [];
        }

        const countryId = countryData.id; // Get country ID from fetched data

        // Step 2: Fetch all states in the country
        const allStates = await fetchStateDropdown(countryId);
        if (!allStates.length) {
            console.error("No states found for this country");
            return [];
        }

        // Step 3: Loop through all states to fetch cities for each state
        let allCities = [];
        for (let state of allStates) {
            const stateCities = await fetchCitiesDropdown(countryId, state.id);
            if (stateCities.length > 0) {
                allCities = [...allCities, ...stateCities]; // Merge cities from each state into allCities array
            }
        }

        if (!allCities.length) {
            console.error("No cities found for any state");
            return [];
        }

        // Step 4: Sort the cities alphabetically
        allCities.sort((a, b) => a.label.localeCompare(b.label));

        return allCities; // Return the sorted list of cities
    } catch (error) {
        return [];
    }
};