import './SavedAddress.css';
import { React, useEffect, useState, Locale, Accordion } from '../../libraries/index';
import { deleteSavedAddress, getSavedAddresses, handleErrorResponse, handleSuccessResponse } from '../../services';

const SavedAddressComponent = ({ handleSavedAddress }) => {
  const { t } = Locale();
  const [activeKey, setActiveKey] = useState("0");
  const [storedAddresses, setStoredAddresses] = useState([]);

  useEffect(() => {
    fetchSavedAddresses();
  }, [])

  const fetchSavedAddresses = async () => {
    try {
      let response = await getSavedAddresses();
      setStoredAddresses(response?.data?.locations)
    }
    catch (err) {
    }
  }

  const handleDeleteAddress = async (addressObj) => {
    try {
      const response = await deleteSavedAddress(addressObj?.id);
      const updateSavedAddress = storedAddresses?.filter((address) => address?.id !== addressObj?.id);
      setStoredAddresses(updateSavedAddress);
      handleSuccessResponse(response, response?.data?.message)
    }
    catch (err) {
      handleErrorResponse(err)
    }
  }

  return (
    <>
      {(storedAddresses?.length > 0) && <>
        <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
          <Accordion.Item eventKey="1" className="saved-address-item">
            <Accordion.Header className="saved-address-header">
              {t('field.savedAddress')}
            </Accordion.Header>
            <Accordion.Body className="saved-address-body">
              {storedAddresses?.map((addressObj, index) => (
                <div
                  className="card mt-2 saved-address-card"
                key={index}

                >
                  <div className='row saved-address-section-outer'>
                    <div className="col-lg-11 col-md-10 saved-address-section" onClick={() => handleSavedAddress(addressObj, setActiveKey)}>
                      <div className="saved-address-section-icon">
                        <i className="bi bi-house-fill"></i>
                      </div>
                      <div className="saved-address-content">{addressObj?.address}</div>
                    </div>
                    <div className="col-lg-1 col-md-2 saved-address-section-icon" onClick={() => handleDeleteAddress(addressObj)}>
                      <i class="bi bi-trash-fill "></i>
                    </div>
                  </div>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>}
    </>
  );
};

export default SavedAddressComponent;
