import {
  useLocation,
  useNavigate,
  useState,
  Locale,
  message
} from '../../libraries';
import {
  isCheckAgree,
  isConditionCheck,
  isSelect,
  isStrongPassword,
  isValidAddress,
  isValidConformPassword,
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
  isValidPostalCode,
  isValidVenueName,
} from './fieldRegex';
import {
  addCartItem,
  updateForgetPassword,
  codeVerification,
  forgetPassword,
  login,
  showUserDetails,
  signUpApi,
  handleErrorResponse,
  handleSuccessResponse,
  addToFavourite,
  organizationStatus,
  showCartList,
} from '../../services';
import { HomeRoutes, BusinessRoutes, AuthRoutes, CustomerRoutes } from '../../routes/index';
import { AddressJson } from '../../jsonData';

const FormValidation = (initialValues, initialValuesAddress) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [addressFormValue, setAddressFormValue] = useState(initialValuesAddress)
  const [formErrors, setFormErrors] = useState({});
  const [breadcrumbCheck, setBreadcrumbCheck] = useState(false);
  const [breadcrumbCheckContact, setBreadcrumbCheckContact] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [formType, setFormType] = useState('');
  const [subDistrictOption, setSubDistrictOption] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const isVendor = currentPath.includes(BusinessRoutes.vendor);
  const isUpVendor = currentPath.includes(`${AuthRoutes.signUp}${BusinessRoutes.vendor}`);
  const isUpFreelancer = currentPath.includes(`${AuthRoutes.signUp}${BusinessRoutes.freelancer}`);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const { t } = Locale();
  const cartCountLocal = localStorage.getItem('cartCount');

  const resendCode = async () => {
    setIsLoader(true);
    try {
      let param;
      if (formValues.femail) {
        param = { email: formValues.femail };
      } else param = { email: localStorage.getItem('email') };
      const response = await forgetPassword(param);
      setIsLoader(false);
      handleSuccessResponse(response, response.data.message);
    } catch (err) {
      handleErrorResponse(err);
      setIsLoader(false);
    }
  };

  const handleChange = (e, type) => {
    const { name, value, checked } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    if (name !== 'agree' && name !== 'countrycode' && name !== 'ccountrycode') {
      const errorLogs = checkValidation({ ...formValues, [name]: value }, type);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorLogs[name],
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
    if (name === 'agree') {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: checked,
      }));
    } else if (name === 'countrycode' || name === 'ccountrycode') {
      let countryCode = value.split(' ');
      let countryCode1 = countryCode[0].toString();
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: countryCode1,
      }));
    }
  };

  const favhandleSubmit = async (e, type, s_id, userType) => {
    e.preventDefault();
    let err = {};
    setFormErrors(err);
    if (Object.values(err).every((value) => !value)) {
      try {
        const response = await login(formValues);
        if (response.status === 201 && response.data.user.type === 'Customer') {
          const cartResponse = await showCartList();
          let cartCount = cartResponse?.data?.data?.length;
          localStorage.setItem('cartCount', cartCount);
          localStorage.setItem('role', response.data.user.type);
          localStorage.setItem(
            'name',
            response.data.user.first_name + ' ' + response.data.user.last_name,
          );
          if (response.data.user.type !== 'Customer')
            localStorage.setItem('isSection', 'isDashboard');
          const getShowResponse = await showUserDetails();
          if (getShowResponse.data.image_ur)
            localStorage.setItem(
              'profilePhoto',
              getShowResponse.data.image_url,
            );
          if (response && s_id) {
            try {
              const response = await (type === 'like'
                ? addToFavourite({
                  favoritable_id: s_id,
                  favoritable_type: userType,
                })
                : addCartItem({ service_id: s_id }));
              if (type !== 'like') {
                localStorage.setItem('cartCount', cartCountLocal * 1 + 1);
              }
              if (!response.data.status) {
                handleSuccessResponse(response, response.data.message);
                setTimeout(() => window.location.reload(), 1000);
              } else handleSuccessResponse(response, response.data.message);
            } catch (err) {
              handleSuccessResponse(response, response.data.message);
              if (type === 'like')
                setTimeout(() => window.location.reload(), 1000);
            }
          } else {
            handleSuccessResponse(response, response.data.message);
            setTimeout(() => window.location.reload(), 1000);
          }
        } else message.error(`${t('validation.invalidCredential')}!!!`);
      } catch (err) {
        handleErrorResponse(err);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation()
    const errorlogs = checkValidation(formValues);
    setFormErrors(errorlogs);
    let response;
    try {
      const isVendorSignupWithoutErrors = Object.keys(errorlogs).length === 0 && isUpVendor;
      const isFreelancerContactWithSingleError = Object.keys(errorlogs).length === 1 && isUpFreelancer && formType === 'contact-information';
      const isFreelancerSignupWithoutErrors = Object.keys(errorlogs).length === 0 && isUpFreelancer;
      const isFormTypeContactPerson = (location.pathname === `${AuthRoutes.signUp}${BusinessRoutes.vendor}` || location.pathname === `${AuthRoutes.signUp}${BusinessRoutes.freelancer}`) && formType === 'contact-information'
      const isFormTypeBuisnessInfo = (location.pathname === `${AuthRoutes.signUp}${BusinessRoutes.vendor}` || location.pathname === `${AuthRoutes.signUp}${BusinessRoutes.freelancer}`) && formType === 'business-information'
      if ((isVendorSignupWithoutErrors) || (isFreelancerContactWithSingleError) || (isFreelancerSignupWithoutErrors)) {
        if (isFormTypeContactPerson) {
          setBreadcrumbCheck(true);
          setIsContact(true);
          setFormType('business-information');
        }
        if (isFormTypeBuisnessInfo) {
          if (!location) {
            setIsLoader(false);
            return;
          }
          setIsLoader(true);
          setBreadcrumbCheckContact(true);
          setButtonClicked(true);
          let param = {
            first_name: formValues.name,
            last_name: formValues.lastName,
            email: formValues.vemail,
            password: formValues.vpassword,
            password_confirmation: formValues.cpassword,
            know_about: formValues.cinfo,
            organization: {
              name: formValues.venuename,
              phone: formValues.ccountrycode + ' ' + formValues.phone,
              location: formValues.address,
              email: formValues.cemail,
              is_freelancer: isUpFreelancer ? true : false,
              country: addressFormValue?.country?.name,
              state: addressFormValue?.state?.name,
              city: addressFormValue?.city?.name,
              postcode: addressFormValue?.postalCode?.value,
            },
          };
          response = await signUpApi(param);
          setIsLoader(false);
          handleSuccessResponse(response, response.data.message);
          navigate(AuthRoutes.signIn);
        }
      }
      if ((Object.keys(errorlogs).length === 0)) {
        if (location.pathname === (AuthRoutes.signIn)) {
          await signInFun()
        }
        else if (location.pathname === `${AuthRoutes.signUp}${CustomerRoutes.user}` && formValues.agree) {
          await signUpUser();
        }
        else if (location.pathname === (AuthRoutes.forgotPassword) || formType === 'resend-code') {
          await forgotPasswordResendCode();
        }
        else if (location.pathname === (AuthRoutes.verify) || (location.pathname === (AuthRoutes.verify) && !(formType === 'resend-code'))) {
          await verifyPassword();
        }
        else if (location.pathname === (AuthRoutes.createNewPassword)) {
          await createNewPasswordFun();
        }
      }
    } catch (error) {
      handleErrorResponse(error);
      setIsLoader(false);
      setButtonClicked(false);
    }
  };

  // const requestLocation = async () => {
  //   const userConsent = window.confirm(
  //     "Please click OK to allow your current location to be used for your shop."
  //   );
  //   if (!userConsent) {
  //     alert("You need to allow location access to continue.");
  //     return null; // Exit if the user doesn't provide consent
  //   }
  //   try {
  //     const location = await getVendorCurrentLocation();
  //     return location;
  //   } catch (error) {
  //     alert("Unable to fetch location. Please try again.");
  //     return null;
  //   }
  // };


  const signUpUser = async () => {
    setIsLoader(true);
    setButtonClicked(!buttonClicked);
    let param = {
      first_name: formValues.name,
      last_name: formValues.lastName,
      email: formValues.email,
      password: formValues.password,
      password_confirmation: formValues.cpassword,
      know_about: formValues.cinfo,
    };
    let response = await signUpApi(param);
    setIsLoader(false);
    handleSuccessResponse(response, response.data.message);
    navigate(AuthRoutes.signIn);
  }

  const signInFun = async () => {
    setIsLoader(true);
    setButtonClicked(true);
    let response = await login(formValues);
    setIsLoader(false);
    if (response.status === 201 && response.data.user.type === 'Customer') {
      customerSignIn(response);
    } else if (response.data.user.type === 'Vendor') {
      vendorSignIn(response);
    } else if (response.data.user.type === 'TeamMember') {
      teamMemberSignIn(response)
    } else message.error(`${t('validation.oneProduct')}!!!`);
  }

  const customerSignIn = async (response) => {
    let getShowResponse;
    const cartResponse = await showCartList();
    let cartCount = cartResponse?.data?.data?.length;
    localStorage.setItem('role', response.data.user.type);
    localStorage.setItem('status', response.data.user.status);
    (response?.data?.initial_user) && localStorage.setItem('firstCashback', true)
    if (cartCount === null || isNaN(cartCount) || cartCount === undefined) {
      cartCount = 0;
    }
    localStorage.setItem('cartCount', cartCount);
    let name = response.data.user.first_name;
    let lastName = response.data.user.last_name
      ? response.data.user.last_name
      : '';
    let fullName = name + ' ' + lastName;
    localStorage.setItem('name', fullName);
    getShowResponse = await showUserDetails();
    localStorage.setItem('userId', response.data.user.id);

    localStorage.setItem(
      'profilePhoto',
      getShowResponse.data.image_url
        ? getShowResponse.data.image_url
        : '',
    );
    handleSuccessResponse(response, response.data.message);
    navigate(HomeRoutes.home);
  }

  const vendorSignIn = async (response) => {
    let country = response?.data?.registered_country;

    if (country && country !== country.charAt(0).toUpperCase() + country.slice(1).toLowerCase()) {
      country = country.charAt(0).toUpperCase() + country.slice(1).toLowerCase();
    }
    localStorage.setItem('registeredCountry', country);
    sessionStorage.setItem('country', country);

    const getShowResponse = await showUserDetails();
    const getVerification = await organizationStatus();
    if (response?.data?.user.status === 'requested') {
      localStorage.setItem('deletionRequestStatus', 'requested');
    }
    if (getVerification.data.status === 'verified') {
      localStorage.setItem('status', 'verified');
    } else if (
      getVerification.data.account &&
      getVerification.data.finance
    ) {
      localStorage.setItem('status', 'underReview');
    } else {
      localStorage.setItem('status', 'completProfile');
    }
    localStorage.setItem('userId', response.data.user.id);
    localStorage.setItem('orgId', response.data.organization_id);
    if (getShowResponse.data.image_url) {
      localStorage.setItem(
        'profilePhoto',
        getShowResponse.data.image_url,
      );
    }
    localStorage.setItem('isSection', 'isDashboard');
    localStorage.setItem('role', response.data.user.type);
    localStorage.setItem('type', response.data.is_freelancer);
    localStorage.setItem(
      'name',
      response.data.user.first_name +
      ' ' +
      response.data.user.last_name,
    );
    handleSuccessResponse(response, response.data.message);
    window.history.pushState(null, null, `${BusinessRoutes.bussiness}${BusinessRoutes.dashboard}`);
    navigate(`${BusinessRoutes.bussiness}${BusinessRoutes.dashboard}`);
    const disableBack = (event) => {
      window.history.pushState(null, null, `${BusinessRoutes.bussiness}${BusinessRoutes.dashboard}`);
      event.preventDefault();
    };
    window.addEventListener('popstate', disableBack);
    return () => window.removeEventListener('popstate', disableBack);
  }

  const teamMemberSignIn = async (response) => {
    const getShowResponse = await showUserDetails();
    let country = response?.data?.registered_country;

    if (country && country !== country.charAt(0).toUpperCase() + country.slice(1).toLowerCase()) {
      country = country.charAt(0).toUpperCase() + country.slice(1).toLowerCase();
    }
    localStorage.setItem('registeredCountry', country);
    sessionStorage.setItem('country', country);
    
    localStorage.setItem('status', 'verified');
    localStorage.setItem('userId', response?.data?.user?.id);
    if (getShowResponse.data.image_url) {
      localStorage.setItem(
        'profilePhoto',
        getShowResponse.data.image_url,
      );
    }
    localStorage.setItem('isSection', 'isVendorProfile');
    localStorage.setItem('role', response.data.user.type);
    localStorage.setItem(
      'name',
      response?.data?.user?.first_name +
      ' ' +
      response?.data?.user?.last_name,
    );
    handleSuccessResponse(response, response?.data?.message);
    window.history.pushState(null, null, `${BusinessRoutes.bussiness}${BusinessRoutes.profile}`);
    navigate(`${BusinessRoutes.bussiness}${BusinessRoutes.profile}`);
    const disableBack = (event) => {
      window.history.pushState(null, null, `${BusinessRoutes.bussiness}${BusinessRoutes.profile}`);
      event.preventDefault();
    };
    window.addEventListener('popstate', disableBack);
    return () => window.removeEventListener('popstate', disableBack);
  }

  const forgotPasswordResendCode = async () => {
    setIsLoader(true);
    setButtonClicked(true);
    const role = location.pathname.includes('user')
      ? 'user'
      : isVendor
        ? 'vendor'
        : 'vendor';
    let param;
    if (formValues.femail) param = { email: formValues.femail };
    else param = { email: localStorage.getItem('email'), role };

    let response = await forgetPassword(param);
    handleSuccessResponse(response, response.data.message);
    setIsLoader(false);
    setFormType(null);
    setButtonClicked(false);
    navigate(AuthRoutes.verify);
  }

  const verifyPassword = async () => {
    setIsLoader(true);
    setButtonClicked(true);
    const role = location.pathname.includes('user')
      ? 'user'
      : isVendor
        ? 'vendor'
        : 'vendor';

    const param = {
      verification_code: formValues.verificationCode,
      role,
    };
    let response = await codeVerification(param);
    setIsLoader(false);
    handleSuccessResponse(response, response.data.message);
    setIsLoader(false);
    setButtonClicked(false);
    navigate(AuthRoutes.createNewPassword);
  }

  const createNewPasswordFun = async () => {
    setIsLoader(true);
    const role = location.pathname.includes('user')
      ? 'user'
      : isVendor
        ? 'vendor'
        : 'vendor';
    let param = {
      password: formValues.forgetpassword,
      password_confirmation: formValues.newforgetpassword,
      role,
    };
    setButtonClicked(true);
    let response = await updateForgetPassword(param);
    handleSuccessResponse(response, response.data.message);
    setIsLoader(false);
    setButtonClicked(false);
    navigate(AuthRoutes.signIn);
  }

  const checkValidation = (values, type) => {
    var errors = {};
    if (location.pathname === (AuthRoutes.signIn) || type === 'signInModal') {
    }
    else if (
      (location.pathname === `${AuthRoutes.signUp}${BusinessRoutes.vendor}` ||
        location.pathname === `${AuthRoutes.signUp}${BusinessRoutes.freelancer}`) &&
      formType === 'business-information'
    ) {
      errors = isValidName(
        values.name,
        errors,
        'name',
        `${t('field.fname')}`,
        t,
      );
      errors = isValidName(
        values.lastName,
        errors,
        'lastName',
        `${t('field.lName')}`,
        t,
      );
      errors = isValidEmail(
        values.vemail,
        errors,
        'vemail',
        `${t('field.email')}`,
        t,
      );
      errors = isStrongPassword(values.vpassword, errors, 'vpassword', t);
      errors = isValidConformPassword(
        { password: values.vpassword, cpassword: values.cpassword },
        errors,
        'cpassword',
        t,
      );
      errors = isCheckAgree(values.agree, errors, 'agree', t);
    } else if (
      (location.pathname === `${AuthRoutes.signUp}${BusinessRoutes.vendor}` ||
        location.pathname === `${AuthRoutes.signUp}${BusinessRoutes.freelancer}`) &&
      formType === 'contact-information'
    ) {
      errors = isValidEmail(
        values.cemail,
        errors,
        'cemail',
        `${t('field.semail')}`,
        t,
      );
      errors = isValidPhoneNumber(
        values.phone,
        errors,
        'phone',
        `${t('field.phoneNumber')}`,
        t,
      );
      errors = isValidVenueName(
        values.venuename,
        errors,
        'venuename',
        `${t('field.venueName')}`,
        t,
      );
      errors = isValidAddress(
        values.address,
        errors,
        'address',
        `${t('field.address')}`,
        t,
      );
      errors = isSelect(
        addressFormValue.city?.name,
        errors,
        'city',
        addressFormValue?.country?.name === "Thailand"
          ? t('field.subDistrict')
          : t('field.city'),
        t,
      );
      if (addressFormValue?.country?.name !== "Thailand") {
        errors = isSelect(
          addressFormValue.state?.name,
          errors,
          'state',
          `${t('field.state')}`,
          t,
        );
      }
      errors = isValidPostalCode(
        addressFormValue.postalCode?.value,
        errors,
        'postalCode',
        `${t('financeField.postalCode')}`,
        t, addressFormValue?.country?.name
      );
    } else if (location.pathname === `${AuthRoutes.signUp}${CustomerRoutes.user}`) {
      errors = isValidName(
        values.name,
        errors,
        'name',
        `${t('field.fname')}`,
        t,
      );
      errors = isValidName(
        values.lastName,
        errors,
        'lastName',
        `${t('field.lName')}`,
        t,
      );
      errors = isValidEmail(
        values.email,
        errors,
        'email',
        `${t('field.semail')}`,
        t,
      );
      errors = isStrongPassword(values.password, errors, 'upassword', t);
      errors = isValidConformPassword(
        { password: values.password, cpassword: values.cpassword },
        errors,
        'cpassword',
        t,
      );
      errors = isConditionCheck(values.capture, errors, 'capture', t);
      errors = isConditionCheck(values.agree, errors, 'agree', t);
    } else if (location.pathname === (AuthRoutes.forgotPassword))
      errors = isValidEmail(
        values.femail,
        errors,
        'femail',
        `${t('field.email')}`,
        t,
      );
    else if (location.pathname === (AuthRoutes.createNewPassword)) {
      errors = isValidConformPassword(
        {
          password: values.forgetpassword,
          cpassword: values.newforgetpassword,
        },
        errors,
        'newforgetpassword',
        t,
      );
      errors = isStrongPassword(
        values.forgetpassword,
        errors,
        'forgetpassword',
        t,
      );
    }
    return errors;
  };

  const onChange = (name, value, countryData, id) => {
    if (name === 'postalCode') {
      const selectedPostalCode = value;
      const selectedPostalCodeData = AddressJson.filter(
        (item) => item.postalCode === selectedPostalCode,
      );
      const subDistrictOP = selectedPostalCodeData.map((item) => ({
        value: item.districtNameEn,
        label: item.districtNameEn,
      }));
      setSubDistrictOption(subDistrictOP);
      setFormValues({ ...formValues, [name]: value });
    } else if (id === 'phoneNumber') {
      let errorLogs = handleChangeValidation('phone', value);
      setFormErrors(errorLogs);
      setFormValues({
        ...formValues,
        phone: value,
        ccountrycode: `+${countryData.dialCode}`,
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handlePhoneCountryCodeChange = (countryCode, countryData) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      ccountrycode: `+${countryCode?.dialCode}`,
    }));
  }



  const handleChangeValidation = (name, value) => {
    let error = { ...formErrors };

    // Clear the error for the specific field before validating it
    if (error.hasOwnProperty(name)) {
      delete error?.[name];
    }


    switch (name) {
      case 'phone':
        error = isValidPhoneNumber(
          value,
          error,
          'phone',
          `${t('field.phoneNumber')}`,
          t,
        );
        break;
    }


    return error

  }




  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return {
    handlePhoneCountryCodeChange,
    isLoader,
    subDistrictOption,
    onChange,
    filterOption,
    resendCode,
    formValues,
    formErrors,
    setFormErrors,
    handleChange,
    handleSubmit,
    setFormValues,
    setFormType,
    isContact,
    setIsContact,
    favhandleSubmit,
    buttonClicked,
    breadcrumbCheck,
    setBreadcrumbCheck,
    setBreadcrumbCheckContact,
    breadcrumbCheckContact,
    addressFormValue, setAddressFormValue
  };
};
export default FormValidation;