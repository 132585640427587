import '../Profile/EditProfile.css';
import { React, PropTypes, Locale, Modal, Select, useState, useEffect } from '../../../libraries';
import { User } from '../../../assets/Images';
import { CustomSelectCheckbox, WorkingHours } from '../index';
import { DragDropImage, ImageCropper } from '../../../common';
import IntlTelInput from "react-intl-tel-input";
import { getPhoneCode } from '../../../customHooks';

const VendorTeamListModal = ({
  isBtnDisable,
  filterOption,
  showModal,
  handleOk,
  type,
  selectedImage,
  handleImageUpload,
  countryCode,
  image,
  formError,
  formValue,
  handleSubmit,
  handleChange,
  selectedServices,
  selectedItems,
  setSelectedItems,
  services,
  selectItemVisible,
  setSelectItemVisible,
  searchText,
  setSearchText,
  toggleSelectItemVisibility,
  options,
  setOpeningHourseFormValue,
  openingHourseFormValue,
  profileVisible,
  crop,
  setCrop,
  zoom,
  setZoom,
  onCropComplete,
  handlePhoneCountryCodeChange
}) => {
  const { t } = Locale();
  const role = localStorage.getItem("type") === "true";
  const [preferredCountries, setPreferredCountry] = useState('')
  
  useEffect(() => {
    let tempCode = getPhoneCode(formValue?.countryCode)
    setPreferredCountry(tempCode)
  }, [])

  return (
    <>
      <Modal
        open={showModal}
        onOk={handleOk}
        closeIcon={null}
        className="vendor-team-list-add-modal"
      >
        <form className="form " method="POST">
          <div className="edit-team-person-header">
            {type === "updateTeamMember" ? (
              <h5 className="title">
                {t("button.editButton")} {t("field.member")}
              </h5>
            ) : (
              <h5 className="title">
                {t("button.addButton")} {t("field.new")} {t("field.member")}
              </h5>
            )}
          </div>

          <div className="team-member-modal">
            <div className="form-row mt-4">
              <div className="form-group edit-profile vendor-team-list-modal">
                {selectedImage ? (
                  <>
                    <img
                      src={
                        selectedImage instanceof File
                          ? URL.createObjectURL(selectedImage)
                          : selectedImage || User
                      }
                      alt="User"
                      className={profileVisible ? "avatar" : "d-none"}
                    />

                    <div className="avatar-edit">
                      <label htmlFor="imageUpload">
                        {type === "updateTeamMember" ? (
                          <div className="edit-profile-plus-icon">
                            <div className="circle-background">
                              <i
                                className="bi bi-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        ) : (
                          <div className="edit-profile-plus-icon">
                            <div className="circle-background">
                              <i className="bi bi-plus" aria-hidden="true"></i>
                            </div>
                          </div>
                        )}
                      </label>
                    </div>

                    {!profileVisible &&
                      <ImageCropper uploadImage={selectedImage} onCropComplete={onCropComplete} crop={crop} zoom={zoom} setZoom={setZoom} setCrop={setCrop} handleChange={handleImageUpload} className="avatar my-profile-avtar-img hide-file" />
                    }
                  </>
                ) : (
                  <>
                    <img
                      src={image ? image : User}
                      className={profileVisible ? "avatar" : "d-none"}
                      alt="user"
                    />
                    <div className="avatar-edit">
                      <label htmlFor="imageUpload">
                        {type === "updateTeamMember" ? (
                          <div className="edit-profile-plus-icon">
                            <div className="circle-background">
                              <i
                                className="bi bi-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        ) : (
                          <div className="edit-profile-plus-icon">
                            <div className="circle-background">
                              <i className="bi bi-plus" aria-hidden="true"></i>
                            </div>
                          </div>
                        )}
                      </label>
                    </div>
                  </>
                )}

                <DragDropImage
                  handleChange={handleImageUpload}
                  accept={["JPG", "PNG", "JPEG", "WEBP"]}
                  files={selectedImage}
                  className={"d-none"}
                />
              </div>
            </div>
            <div className="form-row mt-5">
              <div className="form-group">
                <label className="width100">
                  <span className="">{t("field.fname")}</span>
                  <span className="custom-estric">*</span>
                  <input
                    type="text"
                    name="fname"
                    placeholder={t("field.fname")}
                    value={formValue?.fname}
                    onChange={(e) => handleChange("fname", e.target.value)}
                    className="form-input-team-member input-custom-size"
                  />
                  <div className="error">{formError?.fname}</div>
                </label>
              </div>
              <div className="form-group">
                <label className="width100">
                  <span>{t("field.lName")}</span>
                  <span className="custom-estric">*</span>
                  <input
                    type="text"
                    name="lname"
                    placeholder={t("field.lName")}
                    value={formValue?.lname}
                    onChange={(e) => handleChange("lname", e.target.value)}
                    className="form-input-team-member input-custom-size"
                  />
                  <div className="error">{formError?.lname}</div>
                </label>
              </div>
            </div>

            <div className="phone-feild-div">
              <label className="width100">
                <span>{t("field.phoneNumber")}</span>
                <span className="custom-estric">*</span>
                <div className="row ">
                  <div className="col-12 finace-IntlTelInput-form">

                    <IntlTelInput
                      key={preferredCountries}
                      id="phoneNumber"
                      name="phoneNumber"
                      preferredCountries={[preferredCountries]}
                      separateDialCode={true}
                      containerClassName={`intl-tel-input ${formError.phone ? "error-class" : ""
                        }`}
                      onPhoneNumberChange={(isValid, phoneNumber, countryData) => {
                        handleChange(
                          'phone',
                          phoneNumber,
                          countryData,
                          'phoneNumber'
                        );
                      }}
                      onSelectFlag={(isValid, countryCode, countryData) => {
                        handlePhoneCountryCodeChange(countryCode, countryData,formValue?.countrycode);
                      }}
                      value={(formValue?.phone) ? (formValue?.phone) : ''}

                      // value={formValue?.phone}
                    />
                  </div>
                  <div className="error">{formError?.phone}</div>
                </div>
              </label>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label className="width100">
                    <span className="">{t("field.gender")}</span>
                    <span className="custom-estric">*</span>
                    <Select
                      id="dropdown"
                      name="gender"
                      placeholder={t("header.select")}
                      className="select-margin"
                      value={formValue?.gender || undefined}
                      onChange={(value) => handleChange("gender", value)}
                    >
                      <Select.Option value="male">
                        {t("teamSetupField.male")}
                      </Select.Option>
                      <Select.Option value="female">
                        {t("teamSetupField.female")}
                      </Select.Option>
                      <Select.Option value="other">
                        {t("teamSetupField.other")}
                      </Select.Option>
                    </Select>
                    <div className="error">{formError?.gender}</div>
                  </label>
                </div>
              </div>
              <div className="col-6">
                <label className="width100">
                  {t("header.designation")}
                  <span className="custom-estric">*</span>
                  <input
                    id="designation"
                    type="text"
                    placeholder={`${t("field.enter")} ${t(
                      "header.designation"
                    )}`}
                    name="designation"
                    value={formValue?.designation}
                    onChange={(e) =>
                      handleChange("designation", e.target.value)
                    }
                    className="form-input-phone input-custom-size-desig"
                  />
                </label>
                <div className="error">{formError?.designation}</div>
              </div>
            </div>

            <div>
              <label className="width100">
                {t("field.email")}
                <span className="custom-estric">*</span>
                <input
                  disabled={type === 'updateTeamMember'}
                  type="email"
                  name="email"
                  placeholder={t("field.email")}
                  value={formValue?.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                  className={`form-input-team-member input-custom-size ${type === 'updateTeamMember' && 'team-member-disable-email'}`}

                />
                <div className="error">{formError?.email}</div>
              </label>
            </div>

            {!role && (
              <div>
                <label className="width100">
                  {t('vendor.branch')}
                  <Select
                    className="select-margin"
                    showSearch
                    placeholder={t("field.branch")}
                    optionFilterProp="children"
                    onChange={(value) => handleChange("branchId", value)}
                    filterOption={filterOption}
                    options={options}
                    value={formValue?.branchId || undefined}
                  />
                </label>
              </div>
            )}

            <div>
              <div>
                {t("field.workingHours")}
                <WorkingHours
                  openingHourseFormValue={openingHourseFormValue}
                  setOpeningHourseFormValue={setOpeningHourseFormValue}
                />
              </div>
            </div>
            <div className="mt-3">
              <label className="width100">
                <span>{t("teamSetupField.availableWhatForService")}</span>
                <span className="custom-estric">*</span>
              </label>
              <div className="mt-1">
                <CustomSelectCheckbox
                  selectedServices={selectedServices}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  services={services}
                  selectItemVisible={selectItemVisible}
                  setSelectItemVisible={setSelectItemVisible}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  toggleSelectItemVisibility={toggleSelectItemVisibility}
                  className={"form-check-input openingHourse-day-checkbox"}
                />
                <div className="error">{formError?.availableForService}</div>
              </div>
              <div className="form-group mt-2">
                <center>
                  <input
                    type="button"
                    value={t("button.cancelButton")}
                    className="form-cancel-team-member"
                    onClick={handleOk}
                  />

                  <input
                    type="submit"
                    onClick={(e) =>
                      handleSubmit(
                        e,
                        type === "updateTeamMember"
                          ? "updateTeamMember"
                          : "addMember"
                      )
                    }
                    value={
                      type === "updateTeamMember"
                        ? t("button.updateButton")
                        : t("button.saveButton")
                    }
                    className={`form-submit-team-member ${isBtnDisable &&
                      "disable"}`}
                  />
                </center>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};
VendorTeamListModal.propTypes = {
  filterOption: PropTypes.func,
  showModal: PropTypes.bool,
  crosButton: PropTypes.func,
  handleOk: PropTypes.func,
  type: PropTypes.string,
  selectedImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(File),
  ]),
  handleImageUpload: PropTypes.func,
  onChange: PropTypes.func,
  countryCode: PropTypes.array,
  image: PropTypes.string,
  formError: PropTypes.object,
  formValue: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  selectedServices: PropTypes.array,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  services: PropTypes.array,
  selectItemVisible: PropTypes.bool,
  setSelectItemVisible: PropTypes.func,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  toggleSelectItemVisibility: PropTypes.func,
  handleCloseModal: PropTypes.func,
};

export default VendorTeamListModal;