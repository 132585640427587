const indiaCities=[
    "Abhaneri",
    "Abhayapuri",
    "Abiramam",
    "Abohar",
    "Abrama",
    "Abu",
    "Abu Road",
    "Achalpur",
    "Achhnera",
    "Adalaj",
    "Adampur",
    "Adawad",
    "Addanki",
    "Adilabad",
    "Adirampattinam",
    "Adoni",
    "Adur",
    "Aduthurai",
    "Afzalgarh",
    "Afzalpur",
    "Agar",
    "Agar Panchaitan",
    "Agartala",
    "Agol",
    "Agra",
    "Aheri",
    "Ahiri",
    "Ahmadnagar",
    "Ahmadpur",
    "Ahmedabad",
    "Ahmednagar",
    "Ahmedpur",
    "Ahraura",
    "Ahwa",
    "Aidalpur",
    "Airoli",
    "Airwa",
    "Aistala",
    "Aizawl",
    "Ajaigarh",
    "Ajitgarh",
    "Ajjampur",
    "Ajmer",
    "Ajnala",
    "Ajra",
    "Akalgarh",
    "Akalkot",
    "Akaltara",
    "Akasahebpet",
    "Akbarpur",
    "Akhnur",
    "Akividu",
    "Akkalkot",
    "Akkarampalle",
    "Aklera",
    "Akluj",
    "Aknapur",
    "Akodia",
    "Akola",
    "Akola",
    "Akolner",
    "Akot",
    "Akrani",
    "Akrund",
    "Alagapuram",
    "Alampur",
    "Alampur",
    "Aland",
    "Alandi",
    "Alandur",
    "Alanganallur",
    "Alangayam",
    "Alangudi",
    "Alangulam",
    "Alappakkam",
    "Alappuzha",
    "Alawalpur",
    "Aldona",
    "Ale",
    "Alibag",
    "Aliganj",
    "Aligarh",
    "Alipur",
    "Alipurduar",
    "Alirajpur",
    "Alkuti",
    "Allahabad",
    "Allahganj",
    "Allapalli",
    "Almora",
    "Alnavar",
    "Along",
    "Alot",
    "Alur",
    "Aluva",
    "Alwa Tirunagari",
    "Alwar",
    "Alwaye",
    "Amalapuram",
    "Amalner",
    "Amanganj",
    "Amanpur",
    "Amarkantak",
    "Amarnath",
    "Amarpatan",
    "Amarpur",
    "Amarpur",
    "Amarwara",
    "Amauli",
    "Ambad",
    "Ambagarh Chauki",
    "Ambah",
    "Ambahta",
    "Ambajogai",
    "Ambala",
    "Ambarnath",
    "Ambasa",
    "Ambasamudram",
    "Ambattur",
    "Ambedkar Nagar",
    "Ambegaon",
    "Ambikapur",
    "Ambur",
    "Amet",
    "Amethi",
    "Amgaon",
    "Amguri",
    "Amla",
    "Amlagora",
    "Amli",
    "Amloh",
    "Ammapettai",
    "Amod",
    "Amod,",
    "Amravati",
    "Amravati Division",
    "Amreli",
    "Amritsar",
    "Amroha",
    "Amroli",
    "Amta",
    "Amtala",
    "Amudalavalasa",
    "Anakapalle",
    "Anamalais",
    "Anand",
    "Anandnagar",
    "Anandpur Sahib",
    "Anantapur",
    "Anantnag",
    "Andal",
    "Andheri",
    "Andippatti",
    "Andol",
    "Andura",
    "Anekal",
    "Angamali",
    "Angul",
    "Angul District",
    "Anjad",
    "Anjangaon",
    "Anjar",
    "Anjarle",
    "Anjaw",
    "Ankleshwar",
    "Ankola",
    "Annamalainagar",
    "Annavasal",
    "Annigeri",
    "Annur",
    "Anshing",
    "Anta",
    "Anthiyur",
    "Antri",
    "Antu",
    "Anupgarh",
    "Anuppur",
    "Anupshahr",
    "Aonla",
    "Arag",
    "Arakkonam",
    "Arambagh community development block",
    "Arambol",
    "Arang",
    "Arangaon",
    "Arantangi",
    "Araria",
    "Araul",
    "Arcot",
    "Ardhapur",
    "Argaon",
    "Arimalam",
    "Ariyalur",
    "Arkalgud",
    "Arki",
    "Arni",
    "Aron",
    "Aroor",
    "Arrah",
    "Arsikere",
    "Artist Village",
    "Arukutti",
    "Arumbavur",
    "Arumuganeri",
    "Aruppukkottai",
    "Aruvankad",
    "Arvi",
    "Arwal",
    "Asalatganj",
    "Asandh",
    "Asansol",
    "Asarganj",
    "Ashoknagar",
    "Ashoknagar Kalyangarh",
    "Ashta",
    "Ashta",
    "Ashti",
    "Asifabad",
    "Asika",
    "Asind",
    "Asoda",
    "Assaye",
    "Astagaon",
    "Atarra",
    "Ateli Mandi",
    "Athagarh",
    "Athni",
    "Atmakur",
    "Atrauli",
    "Atraulia",
    "Attayyampatti",
    "Attili",
    "Attingal",
    "Attur",
    "Aundh, Satara",
    "Aurad",
    "Auraiya",
    "Aurangabad",
    "Aurangabad",
    "Auras",
    "Auroville",
    "Ausa",
    "Avadi",
    "Avanigadda",
    "Avanoor",
    "Avinashi",
    "Awantipur",
    "Ayakudi",
    "Ayodhya",
    "Ayyampettai",
    "Azamgarh",
    "Azhikkal",
    "Azizpur",
    "Babai",
    "Baberu",
    "Babina",
    "Babra",
    "Babrala",
    "Babugarh",
    "Bachhraon",
    "Bachhrawan",
    "Bada Barabil",
    "Badagara",
    "Badami",
    "Badarpur",
    "Badarwas",
    "Baddi",
    "Badgam",
    "Badhni Kalan",
    "Badkulla",
    "Badlapur",
    "Badnapur",
    "Badnawar",
    "Badnera",
    "Baduria",
    "Badvel",
    "Bag",
    "Baga",
    "Bagaha",
    "Bagalkot",
    "Bagar",
    "Bagasara",
    "Bagasra",
    "Bagdogra",
    "Bagepalli",
    "Bageshwar",
    "Bagewadi",
    "Bagha Purana",
    "Baghpat",
    "Baghra",
    "Bagli",
    "Bagnan",
    "Bagra",
    "Bagula",
    "Bah",
    "Bahadurganj",
    "Bahadurgarh",
    "Baheri",
    "Bahjoi",
    "Bahraich",
    "Bahraigh",
    "Bahsuma",
    "Bahua",
    "Bahula",
    "Baidyabati",
    "Baihar",
    "Baikunthpur",
    "Baikunthpur",
    "Bail-Hongal",
    "Bairagnia",
    "Baisi",
    "Bajna",
    "Bakani",
    "Bakewar",
    "Bakharla",
    "Bakhtiyarpur",
    "Bakloh",
    "Bakreswar",
    "Baksa",
    "Baksar",
    "Bakshwaho",
    "Balachor",
    "Balagam",
    "Balaghat",
    "Balamau",
    "Balangir",
    "Balapur",
    "Balapur",
    "Balapur, Akola district",
    "Balarampur",
    "Balasinor",
    "Balasore",
    "Baldeo",
    "Baldeogarh",
    "Baldev",
    "Baleshwar",
    "Bali",
    "Bali Chak",
    "Balimila",
    "Balisana",
    "Ballalpur",
    "Ballard Estate",
    "Ballari",
    "Ballarpur",
    "Ballary",
    "Ballia",
    "Bally",
    "Balod",
    "Baloda",
    "Baloda Bazar",
    "Balotra",
    "Balrampur",
    "Balugaon",
    "Balurghat",
    "Bamanbore",
    "Bamangola community development block",
    "Bambolim",
    "Bamboo Flat",
    "Bamna",
    "Bamor Kalan",
    "Bamora",
    "Banapur",
    "Banas Kantha",
    "Banat",
    "Banavar",
    "Banbasa",
    "Banda",
    "Banda",
    "Banda, Maharashtra",
    "Bandia",
    "Bandikui",
    "Bandipore",
    "Bandipura",
    "Bandora",
    "Bandra",
    "Baner",
    "Baneswar",
    "Banga",
    "Bangalore Rural",
    "Bangalore Urban",
    "Banganapalle",
    "Bangaon",
    "Bangaon",
    "Bangarapet",
    "Bangarmau",
    "Banihal",
    "Banjar",
    "Banka",
    "Banki",
    "Bankot",
    "Bankra",
    "Bankura",
    "Banmankhi",
    "Bannur",
    "Banposh",
    "Bansberia",
    "Bansdih",
    "Bansgaon",
    "Bansi",
    "Bansihari community development block",
    "Banswada",
    "Banswara",
    "Banthra",
    "Bantva",
    "Bantval",
    "Banur",
    "Bapatla",
    "Bar Bigha",
    "Bara Banki",
    "Bara Uchana",
    "Barabazar",
    "Baragaon",
    "Baragarh",
    "Baramati",
    "Baramula",
    "Baran",
    "Baranagar",
    "Barasat",
    "Barauli",
    "Baraut",
    "Barbil",
    "Bardhaman",
    "Bardoli",
    "Bareilly",
    "Barela",
    "Bargarh",
    "Barghat",
    "Bargi",
    "Barh",
    "Barhalganj",
    "Barhi",
    "Barhiya",
    "Bari",
    "Bari Sadri",
    "Bariarpur",
    "Barjala",
    "Barjora",
    "Barka Kana",
    "Barkhera",
    "Barkhera Kalan",
    "Barki Saria",
    "Barkot",
    "Barmer",
    "Barnala",
    "Barokhar",
    "Barpali",
    "Barpathar",
    "Barpeta",
    "Barpeta Road",
    "Barrackpore",
    "Barsana",
    "Barsi",
    "Baruipur",
    "Baruni",
    "Barwadih",
    "Barwala",
    "Barwani",
    "Barwar (Lakhimpur Kheri)",
    "Basanti",
    "Basar",
    "Basavakalyan",
    "Basavana Bagevadi",
    "Basi",
    "Basirhat",
    "Basmat",
    "Basmath",
    "Basna",
    "Basni",
    "Basoda",
    "Bastar",
    "Basti",
    "Basudebpur",
    "Basugaon",
    "Baswa",
    "Batala",
    "Batoti",
    "Baud",
    "Baudh",
    "Bavdhan",
    "Bawal",
    "Bawali",
    "Bawana",
    "Bawanbir",
    "Bayana",
    "Bazpur",
    "Beawar",
    "Bedi",
    "Beed",
    "Begamganj",
    "Begampur",
    "Begowal",
    "Begun",
    "Begusarai",
    "Behat",
    "Behror",
    "Bela",
    "Belagavi",
    "Belaguntha",
    "Belda",
    "Beldanga",
    "Beliatore",
    "Bellampalli",
    "Belluru",
    "Belonia",
    "Belsand",
    "Beltangadi",
    "Belthara",
    "Belur",
    "Belur",
    "Bemetara",
    "Benaulim",
    "Bengaluru",
    "Beniganj",
    "Beohari",
    "Berasia",
    "Berhampore",
    "Beri Khas",
    "Beswan",
    "Betamcherla",
    "Betma",
    "Bettiah",
    "Betul",
    "Betul Bazar",
    "Bewar",
    "Beypore",
    "Bhabhra",
    "Bhabhua",
    "Bhachau",
    "Bhadarsa",
    "Bhadarwah",
    "Bhadasar",
    "Bhadaur",
    "Bhadgaon, Maharashtra",
    "Bhadohi",
    "Bhadra",
    "Bhadrachalam",
    "Bhadradri Kothagudem",
    "Bhadrak",
    "Bhadrakh",
    "Bhadran",
    "Bhadravati",
    "Bhadreswar",
    "Bhagalpur",
    "Bhagirathpur",
    "Bhagwantnagar",
    "Bhainsdehi",
    "Bhaisa",
    "Bhalki",
    "Bhandara",
    "Bhandardaha",
    "Bhandardara",
    "Bhander",
    "Bhandu",
    "Bhandup",
    "Bhanjanagar",
    "Bhanpura",
    "Bhanpuri",
    "Bhanvad",
    "Bharatpur",
    "Bharatpura",
    "Bhargain",
    "Bharthana",
    "Bharuch",
    "Bharwari",
    "Bhasawar",
    "Bhatapara",
    "Bhatgaon",
    "Bhatha",
    "Bhatinda",
    "Bhatkal",
    "Bhatpara",
    "Bhattiprolu",
    "Bhaupur",
    "Bhavani",
    "Bhavnagar",
    "Bhawaniganj",
    "Bhawanigarh",
    "Bhawanipatna",
    "Bhawanipur",
    "Bhayandar",
    "Bhayavadar",
    "Bhigvan",
    "Bhikangaon",
    "Bhikhi",
    "Bhilai",
    "Bhildi",
    "Bhilwara",
    "Bhim Tal",
    "Bhimavaram",
    "Bhimtal",
    "Bhimunipatnam",
    "Bhind",
    "Bhindar",
    "Bhinga",
    "Bhinmal",
    "Bhitarwar",
    "Bhiwadi",
    "Bhiwandi",
    "Bhiwani",
    "Bhiwapur",
    "Bhognipur",
    "Bhogpur",
    "Bhojpur",
    "Bhojpur Dharampur",
    "Bhojudih",
    "Bhokar",
    "Bhokardan",
    "Bholath",
    "Bhongaon",
    "Bhongir",
    "Bhoom",
    "Bhopal",
    "Bhor",
    "Bhowali",
    "Bhuban",
    "Bhubaneshwar",
    "Bhudgaon",
    "Bhugaon",
    "Bhuj",
    "Bhum",
    "Bhuma",
    "Bhusaval",
    "Bhusawal",
    "Biaora",
    "Bid",
    "Bidar",
    "Bidhnu",
    "Bidhuna",
    "Bighapur",
    "Bighapur Khurd",
    "Bihar Sharif",
    "Bihariganj",
    "Bihpuriagaon",
    "Bijapur",
    "Bijawar",
    "Bijbehara",
    "Bijni",
    "Bijnor",
    "Bijrauni",
    "Bijur",
    "Bikaner",
    "Bikapur",
    "Bikramganj",
    "Bilara",
    "Bilari",
    "Bilariaganj",
    "Bilashi",
    "Bilasipara",
    "Bilaspur",
    "Bilaspur",
    "Bilaspur",
    "Bilaspur",
    "Bilgi",
    "Bilgram",
    "Bilhaur",
    "Bilimora",
    "Bilkha",
    "Biloli",
    "Bilsanda",
    "Bilsi",
    "Bilthra",
    "Binauli",
    "Binaur",
    "Bindki",
    "Binka",
    "Birbhaddar",
    "Birbhum district",
    "Birdpur",
    "Birmitrapur",
    "Birpara",
    "Birpur",
    "Birpur",
    "Birur",
    "Bisalpur",
    "Bisanda Buzurg",
    "Bisauli",
    "Bisenda Buzurg",
    "Bishnah",
    "Bishnupur",
    "Bishnupur",
    "Bishunpur Urf Maharajganj",
    "Bissau",
    "Biswan",
    "Bithur",
    "Bobbili",
    "Bodhan",
    "Bodinayakkanur",
    "Bodri",
    "Boisar",
    "Bokajan",
    "Bokakhat",
    "Bokaro",
    "Bolanikhodan",
    "Bolpur",
    "Bomdila",
    "Bongaigaon",
    "Borgaon Manju",
    "Borivali",
    "Borivli",
    "Borkhera",
    "Borsad",
    "Botad",
    "Brahmapur",
    "Brahmapuri",
    "Brajarajnagar",
    "Breach Candy",
    "Budaun",
    "Buddh Gaya",
    "Budge Budge",
    "Budhana",
    "Budhlada",
    "Buguda",
    "Bulandshahr",
    "Buldana",
    "Buldhana",
    "Bundi",
    "Bundu",
    "Burhanpur",
    "Burhar",
    "Buriya",
    "Burla",
    "Buxar",
    "Byadgi",
    "Byculla",
    "Byndoor",
    "Cachar",
    "Calangute",
    "Canacona",
    "Candolim",
    "Canning",
    "Captainganj",
    "Carapur",
    "Cavelossim",
    "Central Delhi",
    "Chabua",
    "Chaibasa",
    "Chail",
    "Chakan",
    "Chakapara",
    "Chakdaha",
    "Chakia",
    "Chakia",
    "Chaklasi",
    "Chakradharpur",
    "Chakrata",
    "Chaksu",
    "Chakulia",
    "Chakur",
    "Chalala",
    "Chalisgaon",
    "Challakere",
    "Challapalle",
    "Chaloda",
    "Chamba",
    "Chamoli",
    "Champa",
    "Champadanga",
    "Champahati",
    "Champaner",
    "Champawat",
    "Champdani",
    "Champhai",
    "Champua",
    "Chamrajnagar",
    "Chanasma",
    "Chanda",
    "Chandannagar",
    "Chandauli",
    "Chandauli District",
    "Chandausi",
    "Chandbali",
    "Chanderi",
    "Chandgad",
    "Chandia",
    "Chandigarh",
    "Chandil",
    "Chandla",
    "Chandor",
    "Chandpur",
    "Chandrakona",
    "Chandrapur",
    "Chanduasi",
    "Chandur",
    "Chandur",
    "Chandur Bazar",
    "Changanacheri",
    "Changlang",
    "Channagiri",
    "Channapatna",
    "Channarayapatna",
    "Chapar",
    "Chapra",
    "Charkhari",
    "Charkhi Dadri",
    "Charthawal",
    "Chas",
    "Chatakonda",
    "Chatra",
    "Chatrapur",
    "Chaupal",
    "Chausala",
    "Chechat",
    "Chembur",
    "Chemmumiahpet",
    "Chengam",
    "Chengannur",
    "Chennai",
    "Chennimalai",
    "Cherpulassery",
    "Cherrapunji",
    "Cherthala",
    "Chetput",
    "Chettipalaiyam",
    "Chetwayi",
    "Cheyyar",
    "Cheyyur",
    "Chhabra",
    "Chhachhrauli",
    "Chhala",
    "Chhapar",
    "Chhaprauli",
    "Chharra",
    "Chhata",
    "Chhatapur",
    "Chhatarpur",
    "Chhibramau",
    "Chhindwara",
    "Chhitauni",
    "Chhota Udepur",
    "Chhoti Sadri",
    "Chhuikhadan",
    "Chhutmalpur",
    "Chicalim",
    "Chichli",
    "Chicholi",
    "Chichondi Patil",
    "Chidambaram",
    "Chidawa",
    "Chik Ballapur",
    "Chikhli",
    "Chikhli (Buldhana)",
    "Chikhli (Jalna)",
    "Chikitigarh",
    "Chikkaballapur",
    "Chikkamagaluru",
    "Chiknayakanhalli",
    "Chikodi",
    "Chilakalurupet",
    "Chillupar",
    "Chima",
    "Chimur",
    "Chinchani",
    "Chinchinim",
    "Chincholi",
    "Chinchpokli",
    "Chingleput",
    "Chinna Salem",
    "Chinnachowk",
    "Chinnamanur",
    "Chinnasekkadu",
    "Chintamani",
    "Chiplun",
    "Chipurupalle",
    "Chirala",
    "Chirang",
    "Chirgaon",
    "Chiria",
    "Chitapur",
    "Chitradurga",
    "Chitrakoot",
    "Chitrakoot Dham",
    "Chittaranjan",
    "Chittarkonda",
    "Chittaurgarh",
    "Chittoor",
    "Chittur",
    "Chodavaram",
    "Cholapuram",
    "Chopan",
    "Chopda",
    "Chorhat",
    "Chotila",
    "Chowari",
    "Chuari Khas",
    "Chuda",
    "Chunar",
    "Churachandpur",
    "Churk",
    "Churu",
    "Churulia",
    "Chēlakara",
    "Clement Town",
    "Closepet",
    "Cochin",
    "Coimbatore",
    "Colaba",
    "Colachel",
    "Colgong",
    "Colonelganj",
    "Colovale",
    "Colva",
    "Contai",
    "Cooch Behar",
    "Coondapoor",
    "Cortalim",
    "Cossimbazar",
    "Cuddalore",
    "Cuddapah",
    "Cumbum",
    "Cumbum",
    "Cuncolim",
    "Curchorem",
    "Curti",
    "Cuttack",
    "Dabhoda",
    "Dabhoi",
    "Dabhol",
    "Daboh",
    "Dabra",
    "Dabwali",
    "Daddi",
    "Dadra",
    "Dadra & Nagar Haveli",
    "Dadri",
    "Dagshai",
    "Dahanu",
    "Dahegam",
    "Dahivel",
    "Dahod",
    "Daitari",
    "Dakor",
    "Dakshin Dinajpur district",
    "Dakshina Kannada",
    "Dalhousie",
    "Dalkola",
    "Dalmau",
    "Dalsingh Sarai",
    "Daltonganj",
    "Dam Dam",
    "Daman",
    "Daman District",
    "Damnagar",
    "Damoh",
    "Dandeli",
    "Dandi",
    "Dangs (India)",
    "Dankaur",
    "Danta",
    "Dapoli",
    "Daraganj",
    "Daranagar",
    "Darbhanga",
    "Dariba",
    "Darjeeling",
    "Darlawn",
    "Darrang",
    "Darsi",
    "Darwha",
    "Daryapur",
    "Dasna",
    "Dasnapur",
    "Dasuya",
    "Dataganj",
    "Datia",
    "Dattapur",
    "Daudnagar",
    "Daulatabad",
    "Daulatpur",
    "Daulatpur",
    "Daund",
    "Daurala",
    "Dausa",
    "Davanagere",
    "Davorlim",
    "Dayal Bagh",
    "Dayapar",
    "Debagram",
    "Debipur",
    "Deccan Gymkhana",
    "Deglur",
    "Dehradun",
    "Dehri",
    "Dehu",
    "Delhi",
    "Delvada",
    "Delwada",
    "Denkanikota",
    "Deoband",
    "Deogarh",
    "Deogarh",
    "Deogarh",
    "Deolali",
    "Deolapar",
    "Deoli",
    "Deoli",
    "Deoli",
    "Deoni",
    "Deoranian",
    "Deori",
    "Deori Khas",
    "Deoria",
    "Depalpur",
    "Dera Baba Nanak",
    "Dera Bassi",
    "Dera Gopipur",
    "Derapur",
    "Dergaon",
    "Deshnoke",
    "Desur",
    "Detroj",
    "Deulgaon Raja",
    "Devadanappatti",
    "Devakottai",
    "Devanhalli",
    "Devaprayag",
    "Devarkonda",
    "Devbhumi Dwarka",
    "Devgadh Bariya",
    "Devgarh",
    "Devrukh",
    "Dewa",
    "Dewas",
    "Dhaka",
    "Dhalai",
    "Dhali",
    "Dhamnod",
    "Dhampur",
    "Dhamtari",
    "Dhana",
    "Dhanaula",
    "Dhanaura",
    "Dhanbad",
    "Dhandhuka",
    "Dhanera",
    "Dhanghata",
    "Dhaniakhali community development block",
    "Dhansura",
    "Dhanwar",
    "Dhar",
    "Dharampur",
    "Dharampuri",
    "Dharamsala",
    "Dharangaon",
    "Dharapuram",
    "Dharasana",
    "Dharau",
    "Dharavi",
    "Dharchula",
    "Dhari",
    "Dhariwal",
    "Dharmabad",
    "Dharmadam",
    "Dharmanagar",
    "Dharmapuri",
    "Dharmavaram",
    "Dharuhera",
    "Dharur",
    "Dharwad",
    "Dhasa",
    "Dhaulpur",
    "Dhaurahra",
    "Dhawalpuri",
    "Dhekiajuli",
    "Dhemaji",
    "Dhenkanal",
    "Dhilwan",
    "Dhing",
    "Dhola",
    "Dholera",
    "Dholka",
    "Dhone",
    "Dhoraji",
    "Dhrangadhra",
    "Dhrol",
    "Dhubri",
    "Dhudi",
    "Dhulagari",
    "Dhule",
    "Dhulia",
    "Dhulian",
    "Dhupguri",
    "Dhuri",
    "Dhuwaran",
    "Diamond Harbour",
    "Dibai",
    "Dibang Valley",
    "Dibrugarh",
    "Dicholi",
    "Didwana",
    "Dig",
    "Digapahandi",
    "Digboi",
    "Digha",
    "Dighori",
    "Dighwara",
    "Diglur",
    "Digras",
    "Diguvametta",
    "Dima Hasao District",
    "Dimapur",
    "Dina Nagar",
    "Dinapore",
    "Dindigul",
    "Dindori",
    "Dindori, Maharashtra",
    "Dinhata",
    "Diphu",
    "Dirba",
    "Disa",
    "Dispur",
    "Diu",
    "Diveagar",
    "Divrasai",
    "Dod Ballapur",
    "Doda",
    "Dohad",
    "Dohrighat",
    "Doiwala",
    "Domariaganj",
    "Dombivli",
    "Domjur",
    "Dondaicha",
    "Dongargaon",
    "Dongargarh",
    "Dongri",
    "Doraha",
    "Dornakal",
    "Dostpur",
    "Dubrajpur",
    "Dudhani",
    "Dudhi",
    "Dugadda",
    "Dugda",
    "Duliagaon",
    "Dum Duma",
    "Dumka",
    "Dumkhal",
    "Dumra",
    "Dumraon",
    "Dungarpur",
    "Dungarpur",
    "Durg",
    "Durgapur",
    "Durgapur",
    "Durgapur, Chandrapur",
    "Dusi",
    "Dwarahat",
    "Dwarka",
    "East Delhi",
    "East District",
    "East Garo Hills",
    "East Godavari",
    "East Jaintia Hills",
    "East Kameng",
    "East Khasi Hills",
    "East Siang",
    "Edakkulam",
    "Egra",
    "Elamanchili",
    "Elayirampannai",
    "Ellenabad",
    "Ellore",
    "Elumalai",
    "Elur",
    "Emmiganur",
    "Eral",
    "Erandol",
    "Eraniel",
    "Erattupetta",
    "Ernakulam",
    "Erode",
    "Erraguntla",
    "Erumaippatti",
    "Etah",
    "Etawa",
    "Etawah",
    "Etikoppaka",
    "Etmadpur",
    "Ettaiyapuram",
    "Faizabad",
    "Faizpur",
    "Falakata",
    "Farah",
    "Farakka",
    "Faridabad",
    "Faridabad District",
    "Faridkot",
    "Faridnagar",
    "Faridpur",
    "Farrukhabad",
    "Farrukhnagar",
    "Farrukhnagar",
    "Fatehabad",
    "Fatehabad",
    "Fatehabad District",
    "Fatehganj West",
    "Fatehgarh",
    "Fatehgarh Churian",
    "Fatehgarh Sahib",
    "Fatehpur",
    "Fatehpur",
    "Fatehpur (Barabanki)",
    "Fatehpur Chaurasi",
    "Fatehpur Sikri",
    "Fatwa",
    "Fazilka",
    "Ferokh",
    "Firozabad",
    "Firozpur",
    "Firozpur District",
    "Firozpur Jhirka",
    "Forbesganj",
    "Fort",
    "Fort Gloster",
    "French Rocks",
    "Fyzabad",
    "Gadag",
    "Gadag-Betageri",
    "Gadarwara",
    "Gadchiroli",
    "Gaddi Annaram",
    "Gadhada",
    "Gadhinglaj",
    "Gadwal",
    "Gagret",
    "Gahlon",
    "Gahmar",
    "Gaighata community development block",
    "Gaini",
    "Gairkata",
    "Gajapati",
    "Gajendragarh",
    "Gajraula",
    "Gajuwaka",
    "Galiakot",
    "Gandai",
    "Gandarbal",
    "Ganderbal",
    "Gandevi",
    "Gandhi Nagar",
    "Gandhidham",
    "Gandhinagar",
    "Gangadharpur",
    "Gangaikondan",
    "Gangakher",
    "Ganganagar",
    "Gangapur",
    "Gangapur",
    "Gangarampur",
    "Gangavalli",
    "Gangawati",
    "Gangoh",
    "Gangolli",
    "Gangtok",
    "Ganguvada",
    "Ganj Dundawara",
    "Ganj Dundwara",
    "Ganj Muradabad",
    "Ganjam",
    "Gannavaram",
    "Ganpatipule",
    "Garautha",
    "Gardhiwala",
    "Garha Brahman",
    "Garhakota",
    "Garhi Pukhta",
    "Garhmuktesar",
    "Garhshankar",
    "Garhwa",
    "Garhwa",
    "Garhwal",
    "Gariaband",
    "Gariadhar",
    "Garui",
    "Garulia",
    "Gaurela",
    "Gauriganj",
    "Gauripur",
    "Gautam Buddha Nagar",
    "Gautampura",
    "Gawan",
    "Gaya",
    "Gevrai",
    "Ghanaur",
    "Ghansor",
    "Gharaunda",
    "Ghargaon",
    "Gharghoda",
    "Ghatal",
    "Ghatampur",
    "Ghatanji",
    "Ghatkesar",
    "Ghatkopar",
    "Ghatsila",
    "Ghaziabad",
    "Ghazipur",
    "Ghiror",
    "Gho Brahmanan de",
    "Ghodasar",
    "Ghoga",
    "Ghogha",
    "Ghorawal",
    "Ghosi",
    "Ghoti Budrukh",
    "Ghugus",
    "Ghumarwin",
    "Gidam",
    "Giddalur",
    "Giddarbaha",
    "Gingee",
    "Gir Somnath",
    "Girgaon",
    "Giria",
    "Giridih",
    "Goa Velha",
    "Goalpara",
    "Gobardanga",
    "Gobichettipalayam",
    "Gobindapur",
    "Gobindpur",
    "Godda",
    "Godhra",
    "Gogapur",
    "Gohadi",
    "Gohana",
    "Gohand",
    "Gohpur",
    "Gokak",
    "Gokarna",
    "Gokavaram",
    "Gokul",
    "Gola Bazar",
    "Gola Gokarannath",
    "Golaghat",
    "Golakganj",
    "Gomati",
    "Gomoh",
    "Gonda",
    "Gonda (Aligarh)",
    "Gonda City",
    "Gondal",
    "Gondia",
    "Gondiya",
    "Gopalganj",
    "Gopalpur",
    "Gopalpur",
    "Gopalur",
    "Gopamau",
    "Gopinathpur",
    "Gopinathpur",
    "Gorai",
    "Gorakhpur",
    "Gorakhpur",
    "Gorantla",
    "Goregaon",
    "Goribidnur",
    "Gorubathan",
    "Gorur",
    "Gorwa",
    "Gosaba",
    "Gosainganj",
    "Gosanimari",
    "Goshainganj",
    "Goshaingaon",
    "Govardhan",
    "Govindapuram,Chilakaluripet,Guntur",
    "Govindgarh",
    "Govindgarh",
    "Greater Noida",
    "Gua",
    "Gubbi",
    "Gudalur",
    "Gudari",
    "Gudibanda",
    "Gudivada",
    "Gudiyatham",
    "Gudlavalleru",
    "Gudur",
    "Gudur",
    "Guduvancheri",
    "Guhagar",
    "Guirim",
    "Gulabpura",
    "Gulaothi",
    "Guledagudda",
    "Gumia",
    "Gumla",
    "Gummidipundi",
    "Guna",
    "Gundlupēt",
    "Gunnaur",
    "Guntakal Junction",
    "Guntur",
    "Gunupur",
    "Gurdaha",
    "Gurdaspur",
    "Gurgaon",
    "Gurh",
    "Gurmatkal",
    "Gursahaiganj",
    "Gursarai",
    "Guru Har Sahai",
    "Guruvayur",
    "Guskhara",
    "Guwahati",
    "Gwalior",
    "Gyalshing",
    "Gyanpur",
    "Habra",
    "Hadagalli",
    "Hadapsar, Pune",
    "Hadgaon",
    "Haflong",
    "Hailakandi",
    "Hajan",
    "Hajipur",
    "Hajipur",
    "Hajo",
    "Haldaur",
    "Haldia",
    "Haldibari",
    "Haldwani",
    "Halenda",
    "Halisahar",
    "Haliyal",
    "Halkarni",
    "Halol",
    "Halvad",
    "Hamirpur",
    "Hamirpur",
    "Hampi",
    "Handia",
    "Hangal",
    "Hansi",
    "Hansot",
    "Hanumangarh",
    "Hapur",
    "Haraipur",
    "Haraiya",
    "Harangul",
    "Harbatpur",
    "Harchandpur",
    "Harda",
    "Harda Khas",
    "Hardoi",
    "Harduaganj",
    "Hariana",
    "Haridwar",
    "Harihar",
    "Harij",
    "Harindanga",
    "Haringhata",
    "Haripur",
    "Harnai",
    "Harpalpur",
    "Harpanahalli",
    "Harrai",
    "Harsol",
    "Harsud",
    "Harur",
    "Hasanpur",
    "Hasanpur",
    "Hasimara",
    "Hassan",
    "Hastinapur",
    "Hata",
    "Hata (India)",
    "Hathras",
    "Hathuran",
    "Hatod",
    "Hatta",
    "Haveri",
    "Hayuliang",
    "Hazaribag",
    "Hazaribagh",
    "Heggadadevankote",
    "Helwak",
    "Hesla",
    "Hilsa",
    "Himatnagar",
    "Hindaun",
    "Hindoria",
    "Hindupur",
    "Hindusthan Cables Town",
    "Hinganghat",
    "Hingoli",
    "Hinjilikatu",
    "Hirakud",
    "Hiranagar",
    "Hirapur",
    "Hirapur Hamesha",
    "Hirekerur",
    "Hiriyur",
    "Hisar",
    "Hisua",
    "Hodal",
    "Hojai",
    "Holalkere",
    "Hole Narsipur",
    "Homnabad",
    "Honavar",
    "Honnali",
    "Hooghly district",
    "Hosanagara",
    "Hosangadi",
    "Hosdurga",
    "Hoshangabad",
    "Hoshiarpur",
    "Hoskote",
    "Hospet",
    "Hosur",
    "Hotgi",
    "Howli",
    "Howrah",
    "Hubballi",
    "Hukeri",
    "Hulas",
    "Hungund",
    "Hunsur",
    "Husainabad",
    "Hyderabad",
    "Iawar",
    "Ibrahimpur",
    "Ichalkaranji",
    "Ichapur",
    "Ichchapuram",
    "Ichhawar",
    "Idappadi",
    "Idar",
    "Idukki",
    "Igatpuri",
    "Iglas",
    "Ikauna",
    "Iklehra",
    "Ilampillai",
    "Ilkal",
    "Iluppur",
    "Imphal",
    "Inda Chhoi",
    "Indapur",
    "Indergarh",
    "Indi",
    "Indore",
    "Indpur community development block",
    "Indragarh",
    "Indri",
    "Ingraj Bazar",
    "Injambakkam",
    "Iringal",
    "Irinjalakuda",
    "Irugur",
    "Isagarh",
    "Ishanpur",
    "Islamnagar",
    "Islamnagar (Badaun)",
    "Islampur",
    "Islampur",
    "Itanagar",
    "Itarsi",
    "Itaunja",
    "Itimadpur",
    "Jabalpur",
    "Jafarpur",
    "Jagadhri",
    "Jagalur",
    "Jagannathpur",
    "Jagatsinghapur",
    "Jagatsinghpur",
    "Jagdalpur",
    "Jagdishpur",
    "Jagdispur",
    "Jaggayyapeta",
    "Jagnair",
    "Jagraon",
    "Jagtial",
    "Jahanabad",
    "Jahanabad",
    "Jahanabad (Pilibhit)",
    "Jahangirabad",
    "Jahangirpur",
    "Jahazpur",
    "Jaigaon",
    "Jainpur",
    "Jaipur",
    "Jais",
    "Jaisalmer",
    "Jaisinghnagar",
    "Jaisingpur",
    "Jaitapur",
    "Jaitaran",
    "Jaithari",
    "Jaito",
    "Jajpur",
    "Jakhal",
    "Jakhangaon",
    "Jakhau",
    "Jalakandapuram",
    "Jalalabad",
    "Jalalabad",
    "Jalali",
    "Jalalpore",
    "Jalalpur",
    "Jalalpur",
    "Jalandhar",
    "Jalarpet",
    "Jalaun",
    "Jalesar",
    "Jaleshwar",
    "Jalgaon",
    "Jalgaon Jamod",
    "Jalia",
    "Jalkot",
    "Jalna",
    "Jalor",
    "Jalore",
    "Jalpaiguri",
    "Jamadoba",
    "Jamai",
    "Jamalpur",
    "Jambuda",
    "Jambusar",
    "Jamkhandi",
    "Jamkhed",
    "Jammalamadugu",
    "Jammu",
    "Jamnagar",
    "Jamod",
    "Jamshedpur",
    "Jamtara",
    "Jamui",
    "Jamuria",
    "Jandiala",
    "Jandiala Guru",
    "Janephal",
    "Jangaon",
    "Janghai",
    "Jangipur",
    "Jangoan",
    "Janjgir",
    "Janjgir-Champa",
    "Jansath",
    "Jaoli",
    "Jaora",
    "Jarod,",
    "Jarwa",
    "Jarwal",
    "Jasdan",
    "Jashpur",
    "Jashpurnagar",
    "Jasidih",
    "Jaspur",
    "Jasrana",
    "Jaswantnagar",
    "Jat, Sangli",
    "Jatani",
    "Jatara",
    "Jategaon",
    "Jaunpur",
    "Jaurian",
    "Jawad",
    "Jawala Mukhi",
    "Jawhar",
    "Jayamkondacholapuram",
    "Jayashankar Bhupalapally",
    "Jaynagar",
    "Jaynagar Majilpur",
    "Jaysingpur",
    "Jehanabad",
    "Jejur",
    "Jejuri",
    "Jetalpur",
    "Jetalsar",
    "Jetpur",
    "Jetpur (Navagadh)",
    "Jevargi",
    "Jewar",
    "Jeypore",
    "Jha-Jha",
    "Jhabua",
    "Jhajhar",
    "Jhajjar",
    "Jhalawar",
    "Jhalida",
    "Jhalod",
    "Jhalrapatan",
    "Jhalu",
    "Jhanjharpur",
    "Jhansi",
    "Jhargram",
    "Jharia",
    "Jharsuguda",
    "Jharsuguda District",
    "Jhilimili",
    "Jhinjhak",
    "Jhinjhana",
    "Jhulasan",
    "Jhunjhunun",
    "Jhusi",
    "Jind",
    "Jintur",
    "Jiran",
    "Jiyanpur",
    "Jobat",
    "Jobner",
    "Jodhpur",
    "Jodhpur",
    "Jodhpur (Ahmedabad)",
    "Jodia",
    "Jodiya Bandar",
    "Jogbani",
    "Jogeshwari",
    "Jogighopa",
    "Jogindarnagar",
    "Jogulamba Gadwal",
    "Jorethang",
    "Jorhat",
    "Joshimath",
    "Jua",
    "Jubbal",
    "Jugsalai",
    "Juhu",
    "Jumri Tilaiya",
    "Junagadh",
    "Junagarh",
    "Junnar",
    "Jutogh",
    "Jyotiba Phule Nagar",
    "Kabeerdham",
    "Kabrai",
    "Kachchh",
    "Kachholi",
    "Kachhwa",
    "Kachurwahi",
    "Kadakkavoor",
    "Kadambur",
    "Kadaura",
    "Kadayanallur",
    "Kadegaon",
    "Kadi",
    "Kadipur",
    "Kadiri",
    "Kadod",
    "Kadur",
    "Kadus",
    "Kagal",
    "Kagarol",
    "Kagaznagar",
    "Kaij",
    "Kaikalur",
    "Kailaras",
    "Kailashahar",
    "Kaimganj",
    "Kaimori",
    "Kaimur District",
    "Kaintragarh",
    "Kairana",
    "Kaithal",
    "Kakching",
    "Kakdwip",
    "Kakinada",
    "Kakori",
    "Kakrala",
    "Kalaburgi",
    "Kaladhungi",
    "Kalagarh Project Colony",
    "Kalahandi",
    "Kalaikunda",
    "Kalakkadu",
    "Kalamassery",
    "Kalamb",
    "Kalamb, Osmanabad",
    "Kalamboli",
    "Kalamnuri",
    "Kalanaur",
    "Kalanaur",
    "Kalanwali",
    "Kalas",
    "Kalavad",
    "Kalavai",
    "Kalavoor",
    "Kalghatgi",
    "Kali(DK)",
    "Kaliaganj",
    "Kalikapur",
    "Kalimpong",
    "Kalinagar",
    "Kalka",
    "Kallakkurichchi",
    "Kallidaikurichi",
    "Kallupatti",
    "Kalmeshwar",
    "Kalna",
    "Kalol",
    "Kalpatta",
    "Kalpi",
    "Kalugumalai",
    "Kalundri",
    "Kalyan",
    "Kalyandurg",
    "Kalyani",
    "Kalyani Nagar",
    "Kalyanpur",
    "Kamakhyanagar",
    "Kamalapuram",
    "Kamalganj",
    "Kamalpur",
    "Kaman",
    "Kamareddi",
    "Kamareddy",
    "Kamargaon",
    "Kamarhati",
    "Kamarpukur",
    "Kamatgi",
    "Kampil",
    "Kampli",
    "Kamptee",
    "Kamrup",
    "Kamrup Metropolitan",
    "Kamthi",
    "Kamuthi",
    "Kanadukattan",
    "Kancheepuram",
    "Kanchipuram",
    "Kanchrapara",
    "Kandhamal",
    "Kandhla",
    "Kandi",
    "Kandla",
    "Kandla port",
    "Kandra",
    "Kandri",
    "Kandukur",
    "Kangar",
    "Kangayam",
    "Kangra",
    "Kanigiri",
    "Kanina Khas",
    "Kankanhalli",
    "Kankauli",
    "Kankavli",
    "Kanke",
    "Kanker",
    "Kankipadu",
    "Kankon",
    "Kannad",
    "Kannangad",
    "Kannauj",
    "Kannavam",
    "Kanniyakumari",
    "Kannod",
    "Kannur",
    "Kanodar",
    "Kanor",
    "Kanpur",
    "Kanpur Dehat",
    "Kant",
    "Kantabanji",
    "Kanth",
    "Kantilo",
    "Kanuru",
    "Kapadvanj",
    "Kapren",
    "Kaptanganj",
    "Kapurthala",
    "Kara",
    "Karad",
    "Karaikal",
    "Karaikkudi",
    "Karajagi",
    "Karamadai",
    "Karambakkudi",
    "Karamsad",
    "Karanja",
    "Karanja Lad",
    "Karanpur",
    "Karari",
    "Karauli",
    "Karbi Anglong",
    "Karbigwan",
    "Karchana",
    "Kareli",
    "Karera",
    "Kargil",
    "Karhal",
    "Kariana",
    "Kariapatti",
    "Karimganj",
    "Karimnagar",
    "Karimpur",
    "Karjan",
    "Karjat",
    "Karkala",
    "Karkamb",
    "Karmala",
    "Karnal",
    "Karol Bagh",
    "Karrapur",
    "Kartarpur",
    "Karumbakkam",
    "Karur",
    "Karwar",
    "Kasara",
    "Kasaragod",
    "Kasaragod District",
    "Kasauli",
    "Kasba",
    "Kasganj",
    "Kashipur",
    "Kasoda",
    "Kasrawad",
    "Katangi",
    "Katghora",
    "Kathor",
    "Kathua",
    "Kati",
    "Katihar",
    "Katni",
    "Katol",
    "Katpadi",
    "Katpur",
    "Katra",
    "Katra",
    "Katral",
    "Katras",
    "Kattanam",
    "Kattivakkam",
    "Kattupputtur",
    "Katwa",
    "Kausani",
    "Kaushambi District",
    "Kavali",
    "Kavalur",
    "Kavant",
    "Kavaratti",
    "Kaveripatnam",
    "Kawant",
    "Kawardha",
    "Kayalpattinam",
    "Kayankulam",
    "Kayattar",
    "Kayavarohan",
    "Keelakarai",
    "Kekri",
    "Kelamangalam",
    "Kemri",
    "Kenda",
    "Kendrapara",
    "Kendraparha",
    "Kenduadih",
    "Kendujhar",
    "Kerur",
    "Kerwada",
    "Keshabpur",
    "Keshod",
    "Keshorai Patan",
    "Kesinga",
    "Khachrod",
    "Khada",
    "Khadki",
    "Khaga",
    "Khagaria",
    "Khagaul",
    "Khailar",
    "Khailar",
    "Khair",
    "Khairabad",
    "Khairagarh",
    "Khajuraho Group of Monuments",
    "Khalapur",
    "Khalilabad",
    "Khallar",
    "Khallikot",
    "Khamanon",
    "Khamaria",
    "Khambhalia",
    "Khambhat",
    "Khamgaon",
    "Khamharia",
    "Khammam",
    "Khanapur",
    "Khanapur",
    "Khandala",
    "Khandela",
    "Khandwa",
    "Khandwa district",
    "Khangaon",
    "Khanna",
    "Khanpur",
    "Khanpur",
    "Khapa",
    "Kharagpur",
    "Kharagpur",
    "Kharakvasla",
    "Kharar",
    "Kharar",
    "Kharba",
    "Kharda",
    "Khardaha",
    "Kharela",
    "Khargapur",
    "Kharghar",
    "Khargone",
    "Khargupur",
    "Kharhial",
    "Kharkhauda",
    "Kharkhauda",
    "Kharod",
    "Kharsawan",
    "Kharsia",
    "Kharsundi",
    "Kharupatia",
    "Khatauli",
    "Khategaon",
    "Khatima",
    "Khatra",
    "Khaur",
    "Khavda",
    "Khawhai",
    "Khed",
    "Kheda",
    "Khedbrahma",
    "Khede",
    "Khedoi",
    "Khekra",
    "Khemkaran",
    "Kherali",
    "Kheralu",
    "Kheri",
    "Kheri Sampla",
    "Khetia",
    "Khetri",
    "Khilchipur",
    "Khirkiyan",
    "Khoni",
    "Khonsa",
    "Khopoli",
    "Khordha",
    "Khowai",
    "Khudaganj",
    "Khujner",
    "Khuldabad",
    "Khunti",
    "Khurai",
    "Khurda",
    "Khurja",
    "Khusropur",
    "Khutar",
    "Kichha",
    "Kil Bhuvanagiri",
    "Kilvelur",
    "Kinnaur",
    "Kinwat",
    "Kirakat",
    "Kirandul",
    "Kiranur",
    "Kiraoli",
    "Kiratpur",
    "Kiri Buru",
    "Kirnahar",
    "Kishanganj",
    "Kishangarh",
    "Kishanpur",
    "Kishanpur baral",
    "Kishni",
    "Kishtwar",
    "Kithor",
    "Kizhake Chalakudi",
    "Koath",
    "Kodagu",
    "Kodaikanal",
    "Kodala",
    "Kodar",
    "Kodarma",
    "Kodigenahalli",
    "Kodinar",
    "Kodlipet",
    "Kodoli",
    "Kodumudi",
    "Kodungallur",
    "Koelwar",
    "Kohima",
    "Kokrajhar",
    "Kolanukonda",
    "Kolar",
    "Kolaras",
    "Kolasib",
    "Kolasib district",
    "Kolhapur",
    "Kolkata",
    "Kollam",
    "Kollegal",
    "Kombai",
    "Konanur",
    "Konarka",
    "Konch",
    "Kondagaon",
    "Kondalwadi",
    "Kondapalle",
    "Kondhali",
    "Konganapuram",
    "Konnagar",
    "Konnur",
    "Koothanallur",
    "Kopaganj",
    "Kopar Khairane",
    "Kopargaon",
    "Kopela",
    "Koppa",
    "Koppal",
    "Koradachcheri",
    "Koradi",
    "Korampallam",
    "Koraput",
    "Koratagere",
    "Koratla",
    "Korba",
    "Koregaon",
    "Koriya",
    "Korukollu",
    "Korwai",
    "Kosamba",
    "Kosi",
    "Kosigi",
    "Kot Isa Khan",
    "Kota",
    "Kota",
    "Kota",
    "Kotagiri",
    "Kotamangalam",
    "Kotaparh",
    "Kotar",
    "Kotdwara",
    "Kothapet",
    "Kothara",
    "Kotharia",
    "Kothi",
    "Kotkapura",
    "Kotkhai",
    "Kotla",
    "Kotma",
    "Kotputli",
    "Kotra",
    "Kottagudem",
    "Kottaiyur",
    "Kottapalli",
    "Kottayam",
    "Kotturu",
    "Kotwa",
    "Kovalam",
    "Kovilpatti",
    "Kovvur",
    "Koynanagar",
    "Kozhikode",
    "Krishna",
    "Krishnagiri",
    "Krishnanagar",
    "Krishnapur",
    "Krishnarajpet",
    "Kshirpai",
    "Kuchaiburi",
    "Kuchaman",
    "Kuchera",
    "Kuchesar",
    "Kuchinda",
    "Kud",
    "Kudachi",
    "Kudal",
    "Kudarkot",
    "Kudligi",
    "Kuhi",
    "Kuju",
    "Kukarmunda",
    "Kukatpalli",
    "Kukma",
    "Kukshi",
    "Kulattur",
    "Kulgam",
    "Kulittalai",
    "Kulpahar",
    "Kulpi",
    "Kultali",
    "Kulti",
    "Kulu",
    "Kumaralingam",
    "Kumbakonam",
    "Kumbalam",
    "Kumbhraj",
    "Kumhari",
    "Kumher",
    "Kumsi",
    "Kumta",
    "Kunda",
    "Kundarkhi",
    "Kundarki",
    "Kundgol",
    "Kundla",
    "Kunigal",
    "Kunnamangalam",
    "Kunnamkulam",
    "Kunnattur",
    "Kunnumma",
    "Kuppam",
    "Kupwara",
    "Kurandvad",
    "Kurankhed",
    "Kurara",
    "Kurduvadi",
    "Kurebhar,saidkhanpur",
    "Kurgunta",
    "Kurinjippadi",
    "Kurnool",
    "Kurseong",
    "Kurud",
    "Kurukshetra",
    "Kurung Kumey",
    "Kushalgarh",
    "Kushalnagar",
    "Kushinagar",
    "Kushtagi",
    "Kusmara",
    "Kusumba",
    "Kutch district",
    "Kuthaund",
    "Kutiatodu",
    "Kutiyana",
    "Kuttalam",
    "Kuttampuzha",
    "Kuzhithurai",
    "Kyathampalle",
    "Kyelang",
    "Lachhmangarh Sikar",
    "Ladakh",
    "Ladnun",
    "Ladol",
    "Ladwa",
    "Lahar",
    "Laharpur",
    "Lahul and Spiti",
    "Lakhandur",
    "Lakheri",
    "Lakhimpur",
    "Lakhimpur",
    "Lakhipur",
    "Lakhisarai",
    "Lakhna",
    "Lakhnadon",
    "Lakhpat",
    "Lakhtar",
    "Laksar",
    "Lakshadweep",
    "Lakshettipet",
    "Lakshmeshwar",
    "Lal Bahadur Nagar",
    "Lala",
    "Lalam",
    "Lalganj",
    "Lalganj",
    "Lalgarh",
    "Lalgola",
    "Lalgudi",
    "Lalitpur",
    "Lalpur",
    "Lalsot",
    "Lambhua",
    "Langhnaj",
    "Lanja",
    "Lansdowne",
    "Lar",
    "Lasalgaon",
    "Latehar",
    "Lathi",
    "Latur",
    "Laungowal",
    "Lavasa",
    "Lawar",
    "Lawar Khas",
    "Lawngtlai",
    "Leh",
    "Leteri",
    "Limbdi",
    "Limkheda",
    "Lingsugur",
    "Lodhikheda",
    "Lohaghat",
    "Lohardaga",
    "Loharu",
    "Lohit District",
    "Lohogaon",
    "Lonar",
    "Lonavla",
    "Londa",
    "Loni",
    "Lormi",
    "Losal",
    "Lower Dibang Valley",
    "Lower Subansiri",
    "Loyabad",
    "Luckeesarai",
    "Lucknow",
    "Lucknow District",
    "Ludhiana",
    "Lumding Railway Colony",
    "Lunavada",
    "Lunglei",
    "Machalpur",
    "Macherla",
    "Machhali Shahar",
    "Machhiwara",
    "Machhlishahr",
    "Machilipatnam",
    "Madambakkam",
    "Madanapalle",
    "Madanpur",
    "Maddagiri",
    "Maddur",
    "Madgaon",
    "Madhavpur Ghed",
    "Madhepura",
    "Madhi",
    "Madhipura",
    "Madhoganj",
    "Madhogarh",
    "Madhogarh",
    "Madhubani",
    "Madhupur",
    "Madhyamgram",
    "Madikeri",
    "Madipakkam",
    "Madugula",
    "Madukkarai",
    "Madukkur",
    "Madurai",
    "Madurantakam",
    "Magadi",
    "Magam",
    "Maghar",
    "Mahabaleshwar",
    "Mahaban",
    "Mahabubabad",
    "Mahad",
    "Mahalingpur",
    "Maham",
    "Mahape",
    "Maharajgani",
    "Maharajganj",
    "Mahasamund",
    "Mahbubnagar",
    "Mahe",
    "Mahemdavad",
    "Mahendragarh",
    "Mahesana",
    "Maheshwar",
    "Mahgawan",
    "Mahiari",
    "Mahim",
    "Mahisa",
    "Mahishadal community development block",
    "Mahmudabad",
    "Mahoba",
    "Maholi",
    "Mahrajganj",
    "Mahrajganj (Raebareli)",
    "Mahroni",
    "Mahudha",
    "Mahul",
    "Mahur",
    "Mahuva",
    "Mahuva (Surat)",
    "Mahwah",
    "Mahē",
    "Maibong",
    "Maihar",
    "Mailani",
    "Mainaguri",
    "Maindargi",
    "Mainpuri",
    "Mairang",
    "Mairwa",
    "Majalgaon",
    "Majholi",
    "Majhupur",
    "Majitha",
    "Makanpur",
    "Makhjan",
    "Makhu",
    "Makrana",
    "Maksi",
    "Makum",
    "Malabar Hill",
    "Malad",
    "Malakanagiri",
    "Malappuram",
    "Malasa",
    "Malaut",
    "Malavalli",
    "Malegaon",
    "Malerkotla",
    "Malhargarh",
    "Malihabad",
    "Malkajgiri",
    "Malkangiri",
    "Malkapur",
    "Malkera",
    "Mallapuram",
    "Mallasamudram",
    "Mallur",
    "Malpe",
    "Malpur",
    "Malpura",
    "Malur",
    "Malvan",
    "Mamit",
    "Manali",
    "Manali",
    "Manalurpettai",
    "Manamadurai",
    "Manappakkam",
    "Manapparai",
    "Manasa",
    "Manavadar",
    "Manavalakurichi",
    "Manawar",
    "Manchar",
    "Mancheral",
    "Mandal",
    "Mandal",
    "Mandalgarh",
    "Mandamarri",
    "Mandangad",
    "Mandapam",
    "Mandapeta",
    "Mandasa",
    "Mandawar",
    "Mandawar",
    "Mandhal",
    "Mandholi Kalan",
    "Mandi",
    "Mandideep",
    "Mandla",
    "Mandleshwar",
    "Mandsaur",
    "Mandvi",
    "Mandvi (Surat)",
    "Mandwa",
    "Mandya",
    "Maner",
    "Mangalagiri",
    "Mangalam",
    "Mangaldai",
    "Mangaluru",
    "Mangan",
    "Mangaon",
    "Mangawan",
    "Manglaur",
    "Mangrol",
    "Mangrol",
    "Mangrol (Junagadh)",
    "Mangrul Pir",
    "Maniar",
    "Manihari",
    "Manikpara",
    "Manikpur",
    "Manipal",
    "Manjeri",
    "Manjhanpur",
    "Manjlegaon",
    "Manjēshvar",
    "Mankachar",
    "Mankapur",
    "Mankeshwar",
    "Mankhurd",
    "Manmad",
    "Mannarakkat",
    "Mannargudi",
    "Manohar Thana",
    "Manoharpur",
    "Manoharpur",
    "Manor",
    "Manpur",
    "Mansa",
    "Mansa",
    "Mansar",
    "Manthani",
    "Manuguru",
    "Manvi",
    "Manwat",
    "Manwath",
    "Mapuca",
    "Marahra",
    "Marakkanam",
    "Marandahalli",
    "Marayur",
    "Maregaon",
    "Margherita",
    "Marhaura",
    "Mariahu",
    "Mariani",
    "Markapur",
    "Masaurhi Buzurg",
    "Masila",
    "Masinigudi",
    "Mastiholi",
    "Masur, India",
    "Mataundh",
    "Mathabhanga",
    "Matheran",
    "Mathura",
    "Matiali community development block",
    "Matigara community development block",
    "Mattanur",
    "Mattur",
    "Matunga",
    "Mau",
    "Mau",
    "Mau Aima",
    "Mau Aimma",
    "Maudaha",
    "Mauganj",
    "Maur Mandi",
    "Mauranwan",
    "Maurawan",
    "Mavelikara",
    "Mavoor",
    "Mawana",
    "Mawar",
    "Mayakonda",
    "Mayang Imphal",
    "Mayiladuthurai",
    "Mayurbhanj",
    "Mazagaon",
    "Medak",
    "Medchal",
    "Medchal Malkajgiri",
    "Medinipur",
    "Meerut",
    "Meethari Marwar",
    "Meghraj",
    "Mehdawal",
    "Mehekar",
    "Mehergaon",
    "Mehkar",
    "Mehnagar",
    "Mehndawal",
    "Mehsana",
    "Mejia community development block",
    "Melukote",
    "Melur",
    "Memari",
    "Mendarda",
    "Merta",
    "Mettuppalaiyam",
    "Mettur",
    "Mhasla",
    "Mhasvad",
    "Mihona",
    "Milak",
    "Milkipur",
    "Minjur",
    "Miraj",
    "Miranpur",
    "Miranpur Katra",
    "Mirganj",
    "Mirialguda",
    "Mirik",
    "Mirzapur",
    "Misrikh",
    "Mithapur",
    "Modasa",
    "Moga",
    "Mohadi",
    "Mohali",
    "Mohan",
    "Mohanpur",
    "Mohanpur community development block",
    "Mohanur",
    "Mohgaon",
    "Mohiuddinnagar",
    "Mohol",
    "Mohpa",
    "Moirang",
    "Mokameh",
    "Mokhada taluka",
    "Mokokchung",
    "Mon",
    "Monghyr",
    "Monoharpur",
    "Mora, Maharashtra",
    "Moradabad",
    "Moram",
    "Moranha",
    "Morar",
    "Morbi",
    "Morena",
    "Morigaon",
    "Morinda",
    "Morjim",
    "Mormugao",
    "Morsi",
    "Morva (Hadaf)",
    "Morwa",
    "Moth",
    "Mothihari",
    "Mowad",
    "Mubarakpur",
    "Mudbidri",
    "Muddebihal",
    "Mudgal",
    "Mudgere",
    "Mudhol",
    "Mudkhed",
    "Mudukulattur",
    "Mughal Sarai",
    "Mugma",
    "Muhammadabad",
    "Mukerian",
    "Mukher",
    "Mukteshwar",
    "Mul",
    "Mulanur",
    "Mulbagal",
    "Mulgund",
    "Mulher",
    "Mulki",
    "Multai",
    "Mulund",
    "Muluppilagadu",
    "Mumbai",
    "Mumbai Suburban",
    "Mundargi",
    "Mundgod",
    "Mundi",
    "Mundra",
    "Mundwa",
    "Mungaoli",
    "Mungeli",
    "Munger",
    "Mungra Badshahpur",
    "Munirabad",
    "Munnar",
    "Munsyari",
    "Muradabad",
    "Muradnagar",
    "Muragacha",
    "Murbad",
    "Murgud",
    "Muri",
    "Murliganj",
    "Mursan",
    "Murshidabad",
    "Murtajapur",
    "Murud (Raigad)",
    "Murud (Ratnagiri)",
    "Murudeshwara",
    "Murum",
    "Murwara",
    "Musafir-Khana",
    "Musafirkhana",
    "Mushabani",
    "Musiri",
    "Mussoorie",
    "Mustafabad",
    "Muttupet",
    "Muvattupula",
    "Muvattupuzha",
    "Muzaffarnagar",
    "Muzaffarpur",
    "Mysuru",
    "Nabadwip",
    "Nabagram",
    "Nabarangpur",
    "Nabha",
    "Nabinagar",
    "Nadapuram",
    "Nadaun",
    "Nadbai",
    "Nadgaon",
    "Nadia district",
    "Nadiad",
    "Nadiad,",
    "Nadigaon",
    "Naduvannur",
    "Naduvattam",
    "Nagamangala",
    "Nagaon",
    "Nagapattinam",
    "Nagapur",
    "Nagar",
    "Nagar",
    "Nagar Karnul",
    "Nagari",
    "Nagarukhra",
    "Nagaur",
    "Nagda",
    "Nagercoil",
    "Nagina",
    "Nagireddipalli",
    "Nagla",
    "Nagod",
    "Nagothana",
    "Nagpur",
    "Nagpur Division",
    "Nagrakata",
    "Nagram",
    "Nagrota",
    "Nagwa",
    "Nahan",
    "Naharlagun",
    "Nahorkatiya",
    "Naigarhi",
    "Naihati",
    "Naini Tal",
    "Nainpur",
    "Nainwa",
    "Najafgarh",
    "Najibabad",
    "Nakodar",
    "Naksalbari",
    "Nakur",
    "Nala Sopara",
    "Nalagarh",
    "Nalanda",
    "Nalbari",
    "Naldhara",
    "Naldurg",
    "Nalegaon",
    "Nalgonda",
    "Nalhati",
    "Naliya",
    "Nalpur",
    "Namagiripettai",
    "Namakkal",
    "Nambiyur",
    "Nambutalai",
    "Namchi",
    "Namkhana community development block",
    "Namli",
    "Nampur",
    "Namrup",
    "Nanauta",
    "Nandambakkam",
    "Nanded",
    "Nandgaon",
    "Nandgaon",
    "Nandigama",
    "Nandigram",
    "Nandikotkur",
    "Nandnee",
    "Nandura",
    "Nandura Buzurg",
    "Nandurbar",
    "Nandyal",
    "Nangal",
    "Nangavalli",
    "Nangi",
    "Nangilickondan",
    "Nangloi Jat",
    "Nanguneri",
    "Nanjangud",
    "Nannilam",
    "Nanpara",
    "Napasar",
    "Naraina",
    "Naraini",
    "Narasannapeta",
    "Narasapur",
    "Narasaraopet",
    "Narasimharajapura",
    "Narasingapuram",
    "Narauli",
    "Naraura",
    "Naravarikuppam",
    "Narayanavanam",
    "Narayangaon",
    "Narayangarh",
    "Narayangarh",
    "Narayanpet",
    "Narayanpur",
    "Nardana",
    "Naregal",
    "Narela",
    "Narendranagar",
    "Nargol",
    "Nargund",
    "Narharpur",
    "Nariman Point",
    "Narkhed",
    "Narmada",
    "Narnaul",
    "Narnaund",
    "Naroda",
    "Narora",
    "Narsimhapur",
    "Narsinghgarh",
    "Narsingi",
    "Narsipatnam",
    "Narwana",
    "Narwar",
    "Nashik",
    "Nashik Division",
    "Nasirabad",
    "Naspur",
    "Nasriganj",
    "Nasrullahganj",
    "Nathdwara",
    "Nattam",
    "Nattarasankottai",
    "Naugachhia",
    "Naugama",
    "Naurangpur",
    "Nautanwa",
    "Navalgund",
    "Navapur",
    "Navelim",
    "Navi Mumbai",
    "Navsari",
    "Nawa",
    "Nawabganj",
    "Nawabganj (Barabanki)",
    "Nawabganj (Bareilly)",
    "Nawada",
    "Nawalgarh",
    "Nawanshahr",
    "Nawanshahr",
    "Naya Bazar",
    "Nayagarh",
    "Nayagarh District",
    "Nayagram community development block",
    "Nayudupet",
    "Nazira",
    "Nedumangad",
    "Needamangalam",
    "Neelankarai",
    "Neem ka Thana",
    "Neemuch",
    "Negapatam",
    "Nelamangala",
    "Nellikkuppam",
    "Nellore",
    "Nepanagar",
    "Neral",
    "Nerur",
    "Neturhat",
    "Nevasa",
    "New Delhi",
    "Newara",
    "Neyyattinkara",
    "Nichlaul",
    "Nicobar",
    "Nidadavole",
    "Nighoj",
    "Nigoh",
    "Nihtaur",
    "Nikora",
    "Nilakottai",
    "Nilanga",
    "Nilgiri",
    "Nilgiris",
    "Nilokheri",
    "Nilēshwar",
    "Nimaj",
    "Nimaparha",
    "Nimbahera",
    "Nipani",
    "Niphad",
    "Nira Narsingpur",
    "Nirmal",
    "Nirmali",
    "Nirsa",
    "Niwai",
    "Niwari",
    "Nizamabad",
    "Nizamabad",
    "Nizar",
    "Noamundi",
    "Nohar",
    "Noida",
    "Nokha",
    "Nongpoh",
    "Nongstoin",
    "Noria",
    "North 24 Parganas district",
    "North Delhi",
    "North District",
    "North East Delhi",
    "North Garo Hills",
    "North Goa",
    "North Guwahati",
    "North Lakhimpur",
    "North Tripura",
    "North Vanlaiphai",
    "North West Delhi",
    "Nowrangapur",
    "Nuapada",
    "Nuh",
    "Numaligarh",
    "Nurmahal",
    "Nurpur",
    "Nurpur Kalan",
    "Nuzvid",
    "Nyamti",
    "Obra",
    "Odadar",
    "Odlabari",
    "Odugattur",
    "Okha",
    "Olpad",
    "Omalur",
    "Ongole",
    "Ooty",
    "Orai",
    "Oran",
    "Orchha",
    "Osmanabad",
    "Ottappalam",
    "Ozar",
    "Pabal",
    "Pachmarhi",
    "Pachora",
    "Pachperwa",
    "Padam",
    "Padampur",
    "Padampur",
    "Paddhari",
    "Padmanabhapuram",
    "Padra",
    "Padrauna",
    "Pahasu",
    "Pahlgam",
    "Pahur, Maharashtra",
    "Paigaon",
    "Paikpara",
    "Paithan",
    "Pakala",
    "Pakur",
    "Palackattumala",
    "Palakkad district",
    "Palakkodu",
    "Palakollu",
    "Palamedu",
    "Palampur",
    "Palamu",
    "Palani",
    "Palanpur",
    "Palanswa",
    "Palasa",
    "Palasbari",
    "Palavakkam",
    "Palera",
    "Palghar",
    "Palghat",
    "Pali",
    "Pali",
    "Pali",
    "Pali, Raigad",
    "Palia Kalan",
    "Palitana",
    "Paliyad",
    "Paliyad (Bhavnagar)",
    "Palkonda",
    "Palladam",
    "Pallappatti",
    "Pallattur",
    "Pallavaram",
    "Palle",
    "Pallevada",
    "Pallikondai",
    "Pallipattu",
    "Pallippatti",
    "Palmaner",
    "Paloncha",
    "Palsana",
    "Palso",
    "Palwal",
    "Palwancha",
    "Panagar",
    "Panagarh",
    "Panaji",
    "Panamaram",
    "Panara",
    "Panch Mahals",
    "Panchgani",
    "Panchkula",
    "Panchla",
    "Panchmahal district",
    "Panchmura",
    "Pandaria",
    "Pandatarai",
    "Pandhana",
    "Pandharpur",
    "Pandhurli",
    "Pandhurna",
    "Pandoh",
    "Pandua",
    "Pangala",
    "Panhala",
    "Panihati",
    "Panipat",
    "Panna",
    "Panruti",
    "Pansemal",
    "Panskura",
    "Panvel",
    "Paonta Sahib",
    "Papanasam",
    "Papireddippatti",
    "Papparappatti",
    "Pappinisshēri",
    "Paradip Garh",
    "Paramagudi",
    "Paras Rampur",
    "Parasia",
    "Paravur Tekkumbhagam",
    "Parbatipur",
    "Parbhani",
    "Pardi",
    "Parel",
    "Parichha",
    "Parichhatgarh",
    "Pariyapuram",
    "Parlakimidi",
    "Parli Vaijnath",
    "Parner",
    "Parnera",
    "Parol",
    "Parola",
    "Parseoni",
    "Parshadepur",
    "Partapur",
    "Partur",
    "Parvatipuram",
    "Parvatsar",
    "Parwanoo",
    "Pasan",
    "Paschim Medinipur district",
    "Pashchim Champaran",
    "Pashchim Singhbhum",
    "Pasighat",
    "Patamundai",
    "Patan",
    "Patan",
    "Patan",
    "Patan",
    "Patancheru",
    "Pataudi",
    "Pathakpura",
    "Pathalgaon",
    "Pathanamthitta",
    "Pathankot",
    "Pathardi",
    "Pathardih",
    "Patharia",
    "Pathri",
    "Patiala",
    "Patiali",
    "Patiram",
    "Patna",
    "Patnagarh",
    "Patrasaer",
    "Pattan",
    "Pattanamtitta",
    "Patti",
    "Patti",
    "Pattukkottai",
    "Patuli",
    "Patur",
    "Paturda",
    "Paud",
    "Pauni",
    "Pauri",
    "Pavi Jetpur",
    "Pavugada",
    "Pavuluru",
    "Pawai",
    "Pawayan",
    "Pawni",
    "Payagpur",
    "Payyannur",
    "Pedana",
    "Peddapalli",
    "Peddapuram",
    "Pedgaon",
    "Pehowa",
    "Peint",
    "Pen",
    "Pendra",
    "Pennadam",
    "Pennagaram",
    "Pennathur",
    "Penugonda",
    "Penukonda",
    "Peraiyur",
    "Perambalur",
    "Peranamallur",
    "Peranampattu",
    "Peravurani",
    "Peren",
    "Periyakulam",
    "Periyanayakkanpalaiyam",
    "Periyanegamam",
    "Periyapatti",
    "Periyapattinam",
    "Pernem",
    "Perumbavoor",
    "Perumpavur",
    "Perundurai",
    "Perungudi",
    "Perur",
    "Perya",
    "Petlad",
    "Petlawad",
    "Phagwara",
    "Phalauda",
    "Phalodi",
    "Phaltan",
    "Phaphamau",
    "Phaphund",
    "Phariha",
    "Phek",
    "Phek",
    "Pheona",
    "Phillaur",
    "Phirangipuram",
    "Phulambri",
    "Phulbani",
    "Phulera",
    "Phulpur",
    "Pichhaura",
    "Pihani",
    "Pilani",
    "Pilibangan",
    "Pilibhit",
    "Piliv",
    "Pilkhua",
    "Pilkhuwa",
    "Pimpalgaon Baswant",
    "Pimpalgaon Raja",
    "Pimpri",
    "Pimpri-Chinchwad",
    "Pinahat",
    "Pindwara",
    "Pinjaur",
    "Pipalkoti",
    "Pipar",
    "Pipavav",
    "Pipili",
    "Piplod",
    "Piploda",
    "Pippara",
    "Pipraich",
    "Pipri",
    "Pipri",
    "Piravam",
    "Pirawa",
    "Piriyapatna",
    "Piro",
    "Pitampura",
    "Pithampur",
    "Pithapuram",
    "Pithora",
    "Pithoragarh",
    "Pokaran",
    "Polasara",
    "Polavaram",
    "Pollachi",
    "Polur",
    "Ponda",
    "Ponmana",
    "Ponnamaravati",
    "Ponnampet",
    "Ponnani",
    "Ponneri",
    "Ponnur",
    "Ponnuru",
    "Poonamalle",
    "Porbandar",
    "Porsa",
    "Port Blair",
    "Porur",
    "Powai",
    "Prabhadevi",
    "Prabhas Patan",
    "Prakasam",
    "Prakasha",
    "Prantij",
    "Pratapgarh",
    "Pratapgarh",
    "Prayagraj (Allahabad)",
    "Proddatur",
    "Puducherry",
    "Pudukkottai",
    "Puduppatti",
    "Pudur",
    "Puduvayal",
    "Pujali",
    "Pukhrayan",
    "Pulgaon",
    "Pulivendla",
    "Puliyangudi",
    "Puliyur",
    "Pullambadi",
    "Pulwama",
    "Punahana",
    "Punalur",
    "Punasa",
    "Punch",
    "Puncha community development block",
    "Pundri",
    "Pune",
    "Pune Division",
    "Punganuru",
    "Punjai Puliyampatti",
    "Puntamba",
    "Pupri",
    "Pural",
    "Puranpur",
    "Purba Champaran",
    "Purba Medinipur district",
    "Purba Singhbhum",
    "Puri",
    "Purmafi",
    "Purna",
    "Purnia",
    "Purulia",
    "Purushottampur",
    "Purwa",
    "Pusad",
    "Pushkar",
    "Puttaparthi",
    "Puttur",
    "Puttur",
    "Qadian",
    "Qadirganj",
    "Qazigund",
    "Quepem",
    "Queula",
    "Quthbullapur",
    "Rabkavi",
    "Rabupura",
    "Radaur",
    "Radha Kund",
    "Radhakund",
    "Radhanagari",
    "Radhanpur",
    "Raebareli",
    "Rafiganj",
    "Raghogarh",
    "Raghudebbati",
    "Raghunathpur",
    "Raghunathpur",
    "Raha",
    "Rahata",
    "Rahatgarh",
    "Rahimatpur",
    "Rahon",
    "Rahuri",
    "Raia",
    "Raichur",
    "Raiganj",
    "Raigarh",
    "Raigarh",
    "Raikot",
    "Raipur",
    "Raipur",
    "Raipur",
    "Raireshwar",
    "Raisen",
    "Raisinghnagar",
    "Raiwala Bara",
    "Raj Nandgaon",
    "Raj-Nandgaon",
    "Rajahmundry",
    "Rajakhera",
    "Rajaldesar",
    "Rajanna Sircilla",
    "Rajaori",
    "Rajapalaiyam",
    "Rajapur",
    "Rajapur",
    "Rajasansi",
    "Rajauri",
    "Rajgarh",
    "Rajgarh",
    "Rajgarh",
    "Rajgir",
    "Rajgurunagar",
    "Rajkot",
    "Rajmahal",
    "Rajnagar",
    "Rajnagar community development block",
    "Rajpipla",
    "Rajpur",
    "Rajpura",
    "Rajsamand",
    "Rajula",
    "Rajur",
    "Rajura",
    "Ralegaon",
    "Ram Das",
    "Ramachandrapuram",
    "Ramagundam",
    "Ramamangalam",
    "Ramanagara",
    "Ramanathapuram",
    "Ramanayyapeta",
    "Ramanuj Ganj",
    "Ramapuram",
    "Ramban",
    "Rambha",
    "Ramchandrapur",
    "Rameswaram",
    "Ramewadi",
    "Ramganj Mandi",
    "Ramgarh",
    "Ramgarh",
    "Ramgarh",
    "Ramgundam",
    "Ramjibanpur",
    "Ramkola",
    "Ramnagar",
    "Ramnagar",
    "Ramnagar",
    "Ramnagar",
    "Ramnagar",
    "Rampachodavaram",
    "Rampur",
    "Rampur",
    "Rampur Hat",
    "Rampura",
    "Rampura",
    "Rampura",
    "Ramtek",
    "Ranaghat",
    "Ranapur",
    "Ranavav",
    "Ranchi",
    "Rangapara",
    "Rangareddi",
    "Rangia",
    "Rangpo",
    "Rani",
    "Rania",
    "Ranibennur",
    "Raniganj",
    "Ranikhet",
    "Ranipet",
    "Ranipur",
    "Ranipur Barsi",
    "Ranir Bazar",
    "Ranpur",
    "Rapar",
    "Rasipuram",
    "Rasra",
    "Rasulabad",
    "Ratangarh",
    "Ratangarh",
    "Ratanpur",
    "Rath",
    "Ratia",
    "Ratlam",
    "Ratnagiri",
    "Raurkela",
    "Raver",
    "Rawatbhata",
    "Rawatsar",
    "Raxaul",
    "Ray",
    "Raya",
    "Rayachoti",
    "Rayadrug",
    "Rayagada",
    "Raybag",
    "Raypur",
    "Razam",
    "Razampeta",
    "Razole",
    "Reha",
    "Rehar",
    "Rehli",
    "Rehti",
    "Remuna",
    "Renapur",
    "Renavi",
    "Rengali",
    "Renigunta",
    "Renukoot",
    "Renukut",
    "Reoti",
    "Reotipur",
    "Repalle",
    "Revadanda",
    "Revdanda",
    "Revelganj",
    "Rewa",
    "Rewari",
    "Rewari District",
    "Ri-Bhoi",
    "Riasi",
    "Richha",
    "Ringas",
    "Rishikesh",
    "Rishra",
    "Risod",
    "Robertsganj",
    "Robertsonpet",
    "Roha",
    "Roha",
    "Rohini",
    "Rohru",
    "Rohtak",
    "Rohtas",
    "Ron",
    "Roorkee",
    "Rudarpur",
    "Rudauli",
    "Rudraprayag",
    "Rupnagar",
    "Rura",
    "Rusera",
    "Sabalgarh",
    "Sabalpur",
    "Sabar Kantha",
    "Sabathu",
    "Sabrum",
    "Sachendi",
    "Sachin",
    "Sadabad",
    "Sadalgi",
    "Sadaseopet",
    "Sadat",
    "Sadri",
    "Safidon",
    "Safipur",
    "Sagar",
    "Sagar",
    "Sagauli",
    "Sahapur",
    "Saharanpur",
    "Saharsa",
    "Sahaspur",
    "Sahaswan",
    "Sahawar",
    "Sahibabad",
    "Sahibganj",
    "Sahpau",
    "Saidpur",
    "Saiha",
    "Sailana",
    "Sailu",
    "Saint Thomas Mount",
    "Sainthia",
    "Sairang",
    "Saitlaw",
    "Sakhanu",
    "Sakit",
    "Sakleshpur",
    "Sakol",
    "Sakoli",
    "Sakri",
    "Sakti",
    "Salanpur community development block",
    "Salaya",
    "Salem",
    "Salempur",
    "Saligao",
    "Salon",
    "Salumbar",
    "Salur",
    "Samakhiali",
    "Samalkha",
    "Samalkot",
    "Samastipur",
    "Samba",
    "Sambalpur",
    "Sambhal",
    "Sambhar",
    "Samdari",
    "Samrala",
    "Samthar",
    "Samudrapur",
    "Sanand",
    "Sanaur",
    "Sanawad",
    "Sanchi",
    "Sanchor",
    "Sancoale",
    "Sandi",
    "Sandila",
    "Sandur",
    "Sangameshwar",
    "Sangamner",
    "Sangareddi",
    "Sangaria",
    "Sangli",
    "Sangod",
    "Sangola",
    "Sangole",
    "Sangrampur, Maharashtra",
    "Sangrur",
    "Sanguem",
    "Sanivarsante",
    "Sankarpur",
    "Sankeshwar",
    "Sankheda",
    "Sankrail",
    "Sanquelim",
    "Sant Kabir Nagar",
    "Sant Ravi Das Nagar",
    "Santipur",
    "Santokhgarh",
    "Santoshpur",
    "Santuri community development block",
    "Sanvordem",
    "Sanwer",
    "Saoli",
    "Saoner",
    "Sapatgram",
    "Sarahan",
    "Sarai Akil",
    "Sarai Ekdil",
    "Sarai Mir",
    "Saraikela",
    "Saraipali",
    "Saran",
    "Sarangarh",
    "Sarangkheda",
    "Sarangpur",
    "Sarauli",
    "Sarbhon",
    "Sardarshahr",
    "Sardhana",
    "Sardoi",
    "Sardulgarh",
    "Sarenga",
    "Sargur",
    "Sarila",
    "Sarka Ghat",
    "Sarkhej",
    "Sarubera",
    "Sarupathar",
    "Sarurpur",
    "Sarwar",
    "Sasni",
    "Sasvad",
    "Saswad",
    "Satana",
    "Satara",
    "Satara Division",
    "Sathamba",
    "Sathankulam",
    "Sathupalli",
    "Sathyamangalam",
    "Satna",
    "Satpati",
    "Satrikh",
    "Sattenapalle",
    "Sattur",
    "Satwas",
    "Saugor",
    "Saundatti",
    "Saurikh",
    "Sausar",
    "Savantvadi",
    "Savanur",
    "Savarkundla",
    "Savda",
    "Savlaj",
    "Savli",
    "Sawai Madhopur",
    "Sawantvadi",
    "Sayalkudi",
    "Sayla",
    "Sector",
    "Secunderabad",
    "Sehore",
    "Selu",
    "Senapati",
    "Sendhwa",
    "Seohara",
    "Seondha",
    "Seoni",
    "Seoni",
    "Seoni Malwa",
    "Seorinarayan",
    "Seram",
    "Serampore",
    "Serchhip",
    "Serilingampalle",
    "Serpur",
    "Serula",
    "Sevagram",
    "Seven Pagodas",
    "Sewri",
    "Shadipur Julana",
    "Shahabad",
    "Shahabad",
    "Shahabad",
    "Shahada",
    "Shahapur",
    "Shahbazpur",
    "Shahdol",
    "Shahganj",
    "Shahgarh",
    "Shahi",
    "Shahid Bhagat Singh Nagar",
    "Shahjahanpur",
    "Shahkot",
    "Shahpur",
    "Shahpur",
    "Shahpur",
    "Shahpur",
    "Shahpur",
    "Shahpura",
    "Shahpura",
    "Shajapur",
    "Sham Churasi",
    "Shamgarh",
    "Shamli",
    "Shamsabad",
    "Shankargarh",
    "Shedbal",
    "Shegaon",
    "Sheikhpura",
    "Sheoganj",
    "Sheohar",
    "Sheopur",
    "Shergarh",
    "Sherghati",
    "Sherkot",
    "Shertallai",
    "Shevgaon",
    "Shibnagar",
    "Shiggaon",
    "Shikarpur",
    "Shikarpur",
    "Shikarpur (Bulandshahr)",
    "Shikohabad",
    "Shikrapur",
    "Shillong",
    "Shimla",
    "Shimoga",
    "Shiraguppi",
    "Shirala",
    "Shirdi",
    "Shirgaon",
    "Shirhatti",
    "Shirol",
    "Shirpur",
    "Shirud",
    "Shirwal",
    "Shishgarh",
    "Shivaji Nagar",
    "Shivpuri",
    "Shivrajpur",
    "Shivrajpur",
    "Sholinghur",
    "Shorapur",
    "Shrawasti",
    "Shrigonda",
    "Shrirangapattana",
    "Shujalpur",
    "Shupiyan",
    "Shyamnagar, West Bengal",
    "Shōranur",
    "Sibsagar",
    "Siddapur",
    "Siddharthnagar",
    "Siddhaur",
    "Siddhpur",
    "Siddipet",
    "Sidhauli",
    "Sidhi",
    "Sidhpura",
    "Sidlaghatta",
    "Sihor",
    "Sihora",
    "Sijua",
    "Sikandarabad",
    "Sikandarpur",
    "Sikandra",
    "Sikandra Rao",
    "Sikandrabad",
    "Sikar",
    "Sikka",
    "Silao",
    "Silapathar",
    "Silchar",
    "Siliguri",
    "Sillod",
    "Silvassa",
    "Simaria",
    "Simdega",
    "Simga",
    "Sindewahi",
    "Sindgi",
    "Sindhnur",
    "Sindhudurg",
    "Sindi",
    "Sindkheda",
    "Singanallur",
    "Singapperumalkovil",
    "Singapur",
    "Singarayakonda",
    "Singoli",
    "Singrauli",
    "Singtam",
    "Singur",
    "Sini",
    "Sinnar",
    "Sinor",
    "Sion, Mumbai",
    "Sira",
    "Sirathu",
    "Sirhind-Fategarh",
    "Sirkazhi",
    "Sirmaur",
    "Sirmaur",
    "Sirohi",
    "Sironcha",
    "Sironj",
    "Sirpur",
    "Sirsa",
    "Sirsa",
    "Sirsaganj",
    "Sirsi",
    "Sirsi",
    "Sirsilla",
    "Siruguppa",
    "Sirumugai",
    "Sirur",
    "Sisauli",
    "Siswa Bazar",
    "Sitamarhi",
    "Sitamau",
    "Sitapur",
    "Sitarganj",
    "Sivaganga",
    "Sivagiri",
    "Sivakasi",
    "Sivala, East Godavari district",
    "Siwan",
    "Siwana",
    "Soalkuchi",
    "Sodpur",
    "Sohagi",
    "Sohagpur",
    "Sohna",
    "Sojat",
    "Sojitra",
    "Solan",
    "Solap",
    "Solapur",
    "Solim",
    "Someshwar",
    "Sompeta",
    "Somvarpet",
    "Sonada",
    "Sonala",
    "Sonamukhi",
    "Sonamura",
    "Sonari",
    "Sonarpur community development block",
    "Sonbhadra",
    "Sonegaon",
    "Sonepur",
    "Songadh",
    "Songir",
    "Sonipat",
    "Sonitpur",
    "Sonvad",
    "Sopur",
    "Sorab",
    "Sorada",
    "Sorbhog",
    "Soro",
    "Soron",
    "South 24 Parganas district",
    "South Andaman",
    "South Delhi",
    "South District",
    "South Garo Hills",
    "South Goa",
    "South Tripura",
    "South West Delhi",
    "South West Garo Hills",
    "South West Khasi Hills",
    "Soygaon",
    "Soyibug",
    "Sravana Belgola",
    "Sri Dungargarh",
    "Sri Madhopur",
    "Sri Muktsar Sahib",
    "Srikakulam",
    "Srikhanda",
    "Srimushnam",
    "Srinagar",
    "Srinagar",
    "Sringeri",
    "Srinivaspur",
    "Sriperumbudur",
    "Sriramnagar",
    "Srirampur",
    "Srisailain",
    "Srivaikuntam",
    "Srivardhan",
    "Srivilliputhur",
    "Suar",
    "Subarnapur",
    "Suchindram",
    "Sujangarh",
    "Suket",
    "Sultanpur",
    "Sultanpur",
    "Sultanpur",
    "Sultanpur Lodhi",
    "Sulur",
    "Suluru",
    "Sulya",
    "Sumbal",
    "Sunam",
    "Sundargarh",
    "Sundarnagar",
    "Sunel",
    "Suntikoppa",
    "Supaul",
    "Supedi",
    "Surajgarh",
    "Surandai",
    "Surat",
    "Suratgarh",
    "Surendranagar",
    "Surgana",
    "Surguja",
    "Suri",
    "Surianwan",
    "Suriapet",
    "Susner",
    "Sutrapada",
    "Swamimalai",
    "Swarupnagar community development block",
    "Tadepalle",
    "Tadepallegudem",
    "Tadpatri",
    "Tajpur",
    "Takdah",
    "Takhatgarh",
    "Takhatpur",
    "Taki",
    "Taklibhan",
    "Tal",
    "Talaja",
    "Talbahat",
    "Talbid",
    "Talcher",
    "Talegaon Dabhade",
    "Talegaon Dhamdhere",
    "Taleigao",
    "Talen",
    "Talgram",
    "Talikota",
    "Talipparamba",
    "Taloda",
    "Talode",
    "Talwandi Bhai",
    "Talwara",
    "Tambaram",
    "Tamenglong",
    "Tamluk",
    "Tanakpur",
    "Tanda",
    "Tandur",
    "Tanjore",
    "Tankara",
    "Tanuku",
    "Taoru",
    "Tapi",
    "Tarabha",
    "Tarakeswar",
    "Taramangalam",
    "Tarana",
    "Taranagar",
    "Tarapur",
    "Tardeo",
    "Tarikere",
    "Tarn Taran Sahib",
    "Tasgaon",
    "Tattayyangarpettai",
    "Tawang",
    "Teghra",
    "Tehri",
    "Tehri-Garhwal",
    "Tekanpur",
    "Tekari",
    "Tekkalakote",
    "Tekkali",
    "Telhara",
    "Tellicherry",
    "Tendukheda",
    "Teonthar",
    "Terdal",
    "Terha",
    "Tezpur",
    "Tezu",
    "Thakurdwara",
    "Thakurganj",
    "Thalner",
    "Than",
    "Thana Bhawan",
    "Thandla",
    "Thane",
    "Thanesar",
    "Thang",
    "Thangadh",
    "Thanjavur",
    "Thanna Mandi",
    "Thanniyam",
    "Tharad",
    "Tharangambadi",
    "Thasra",
    "The Dangs",
    "Theni",
    "Thenkasi",
    "Thenzawl",
    "Theog",
    "Thirukattupalli",
    "Thiruthani",
    "Thiruvaiyaru",
    "Thiruvallur",
    "Thiruvananthapuram",
    "Thiruvarur",
    "Thiruvidaimaruthur",
    "Thoothukudi",
    "Thoubal",
    "Thrissur",
    "Thrissur District",
    "Tigri",
    "Tijara",
    "Tikaitnagar",
    "Tikamgarh",
    "Tikri",
    "Tilhar",
    "Tilsahri",
    "Tindivanam",
    "Tindwari",
    "Tinnanur",
    "Tinsukia",
    "Tiptur",
    "Tira Sujanpur",
    "Tirap",
    "Tirodi",
    "Tirthahalli",
    "Tiruchchendur",
    "Tiruchengode",
    "Tiruchirappalli",
    "Tirukkoyilur",
    "Tirumakudal Narsipur",
    "Tirumala",
    "Tirumullaivasal",
    "Tirunelveli",
    "Tirunelveli Kattabo",
    "Tirupati",
    "Tiruppalaikudi",
    "Tirupparangunram",
    "Tiruppur",
    "Tiruppuvanam",
    "Tirur",
    "Tiruttangal",
    "Tiruvalla",
    "Tiruvannamalai",
    "Tiruvottiyur",
    "Tisaiyanvilai",
    "Titabar",
    "Titagarh",
    "Titlagarh",
    "Titron",
    "Todabhim",
    "Todaraisingh",
    "Tohana",
    "Tondi",
    "Tonk",
    "Topchanchi",
    "Tori Fatehpur",
    "Tori-Fatehpur",
    "Tosham",
    "Tral",
    "Trimbak",
    "Trombay",
    "Tsrar Sharif",
    "Tuensang",
    "Tuensang District",
    "Tufanganj",
    "Tulin",
    "Tuljapur",
    "Tulsipur",
    "Tumakuru",
    "Tumsar",
    "Tundla",
    "Tuni",
    "Tura",
    "Turaiyur",
    "Turuvekere",
    "Uchalan",
    "Udaipur",
    "Udaipur",
    "Udaipura",
    "Udalguri",
    "Udangudi",
    "Udayagiri",
    "Udgir",
    "Udham Singh Nagar",
    "Udhampur",
    "Udpura",
    "Udumalaippettai",
    "Udupi",
    "Ugu",
    "Ujhani",
    "Ujjain",
    "Ukhrul",
    "Uklana",
    "Ukwa",
    "Ula",
    "Ulhasnagar",
    "Ullal",
    "Uluberia",
    "Umarga",
    "Umaria",
    "Umaria District",
    "Umarkhed",
    "Umarkot",
    "Umarpada",
    "Umrala",
    "Umred",
    "Umreth",
    "Umri",
    "Umri",
    "Un",
    "Un",
    "Una",
    "Una",
    "Unakoti",
    "Unhel",
    "Uniara",
    "Unjha",
    "Unnao",
    "Upleta",
    "Uppal Kalan",
    "Upper Siang",
    "Upper Subansiri",
    "Uppiliyapuram",
    "Uran",
    "Uravakonda",
    "Uri",
    "Uruli Kanchan",
    "Usawan",
    "Usehat",
    "Usilampatti",
    "Uska",
    "Utran",
    "Utraula",
    "Uttamapalaiyam",
    "Uttar Bastar Kanker",
    "Uttar Dinajpur district",
    "Uttar Kannada",
    "Uttarkashi",
    "Uttarpara Kotrung",
    "Uttiramerur",
    "Uttukkuli",
    "V.S.K.Valasai (Dindigul-Dist.)",
    "Vada",
    "Vadakku Valliyur",
    "Vadakku Viravanallur",
    "Vadamadurai",
    "Vadgam",
    "Vadgaon",
    "Vadigenhalli",
    "Vadippatti",
    "Vadlapudi",
    "Vadnagar",
    "Vadner",
    "Vadodara",
    "Vagator",
    "Vaghodia",
    "Vaghodia INA",
    "Vaijapur",
    "Vaikam",
    "Vairag",
    "Vaishali",
    "Valangaiman",
    "Valavanur",
    "Vallabh Vidyanagar",
    "Vallabhipur",
    "Vallam",
    "Valparai",
    "Valpoy",
    "Valsad",
    "Valsang",
    "Vanala",
    "Vandalur",
    "Vandavasi",
    "Vangaon",
    "Vaniyambadi",
    "Vansda",
    "Vanthli",
    "Vapi",
    "Varanasi",
    "Varangaon",
    "Varca",
    "Varkala",
    "Vartej",
    "Vasa",
    "Vasavad",
    "Vasco da Gama",
    "Vashi",
    "Vasind",
    "Vaso",
    "Vasudevanallur",
    "Vataman",
    "Vattalkundu",
    "Vatul",
    "Vayalar",
    "Vedaraniyam",
    "Vedasandur",
    "Vejalpur",
    "Velankanni",
    "Velas, Maharashtra",
    "Vellanur",
    "Vellore",
    "Velneshwar",
    "Velur",
    "Vemalwada",
    "Vengavasal",
    "Vengurla",
    "Venkatagiri",
    "Vepagunta",
    "Veraval",
    "Vetapalem",
    "Vettaikkaranpudur",
    "Vettavalam",
    "Vettur",
    "Vidisha",
    "Vijapur",
    "Vijayapura",
    "Vijayapuri",
    "Vijayawada",
    "Vijaydurg",
    "Vikarabad",
    "Vikasnagar",
    "Vikhroli",
    "Vikravandi",
    "Vilattikulam",
    "Vile Parle",
    "Villupuram",
    "Vinchhiya",
    "Vinchia",
    "Vinchur",
    "Vindhyachal",
    "Vinukonda",
    "Viraganur",
    "Viramgam",
    "Virar",
    "Virarajendrapet",
    "Virpur",
    "Virudhunagar",
    "Visakhapatnam",
    "Visavadar",
    "Visnagar",
    "Vita, Maharashtra",
    "Vite",
    "Vizianagaram",
    "Vizianagaram District",
    "Vriddhachalam",
    "Vrindavan",
    "Vuyyuru",
    "Vyara",
    "Wadala",
    "Wadgaon",
    "Wadhai",
    "Wadhwan",
    "Wadi",
    "Wadner",
    "Wadwani",
    "Waghai",
    "Wagholi",
    "Wai",
    "Wakad",
    "Walajapet",
    "Walgaon",
    "Walki",
    "Wallajahbad",
    "Walterganj",
    "Wangjing",
    "Wani",
    "Wankaner",
    "Wanparti",
    "Warangal",
    "Waraseoni",
    "Wardha",
    "Waris Aliganj",
    "Warora",
    "Warud",
    "Washim",
    "Wayanad",
    "Wazirganj",
    "Wellington",
    "Wer",
    "West Delhi",
    "West District",
    "West Garo Hills",
    "West Godavari",
    "West Jaintia Hills",
    "West Kameng",
    "West Khasi Hills",
    "West Siang",
    "West Tripura",
    "Wokha",
    "Worli",
    "Yadgir",
    "Yairipok",
    "Yamunanagar",
    "Yanam",
    "Yanam",
    "Yanamalakuduru",
    "Yarada",
    "Yaval",
    "Yavatmal",
    "Yelahanka",
    "Yelandur",
    "Yelbarga",
    "Yellandu",
    "Yellapur",
    "Yeola",
    "Yol",
    "Yusufpur",
    "Zafarabad",
    "Zahirabad",
    "Zaidpur",
    "Zamania",
    "Zira",
    "Ziro",
    "Zunheboto",
    "patamda",
    "pedda nakkalapalem",
    "vadlamuru"
]

export default indiaCities;