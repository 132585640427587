import { apiCall } from '../base';

const getFavouriteServices = async (params) => {
  const response = await apiCall(
    `customer/favourites/services?page_number=${params.page_number}&per_page=${params.per_page}`,
    'GET',
  );
  return response;
};

const getFavouriteVendor = async (params) => {
  const response = await apiCall(
    `customer/favourites/vendors?page_number=${params.page_number}&per_page=${params.per_page}`,
    'GET',
  );
  return response;
};

const deleteFavourites = async (param) => {
  const response = await apiCall(
    `customer/favourites/${param.service_id}`,
    'DELETE',
  );
  return response;
};

const addToFavourite = async (params) => {
  const response = await apiCall('customer/favourites', 'POST', params);
  return response;
};

export {
  getFavouriteServices,
  addToFavourite,
  deleteFavourites,
  getFavouriteVendor,
};