import { keys } from "highcharts";
import { error } from "jquery";

export const isValidName = (name, error, key, keyValue, t) => {
  const nameRegex = /^[A-Za-z\s-]{2,}$/; // Removed the unnecessary escape character
  if (key === 'city' && !name) {
    error[key] = `${t('validation.subDistrictMsg')}`;
    return error;
  }
  if (!name) {
    if (keyValue) {
      error[key] = `${t('field.pleaseEnter')} ${keyValue}`;
    } else {
      error[key] = 'Please Enter Valid Name';
    }
    return error;
  }
  if (!nameRegex.test(name)) {
    if (keyValue) {
      error[key] = `${t('validation.minCharMsg')}`;
    } else {
      error[key] = `${t('validation.minCharMsg')}`;
    }
  }
  return error;
};

export const isValidVenueName = (name, error, key, keyValue, t) => {
  const nameRegex = /^[A-Za-z0-9\s@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`!]{2,50}$/;

  if (!name) {
    if (keyValue) {
      error[key] = `${t('field.pleaseEnter')} ${keyValue}`;
    } else {
      error[key] = 'Please Enter Valid Name';
    }
    return error;
  }
  if (!nameRegex.test(name)) {
    if (keyValue) {
      error[key] = `${t('validation.minCharMsg')}`;
    } else {
      error[key] = `${t('validation.minCharMsg')}`;
    }
  }

  return error;
};


export const isValidEmail = (email, error, key, keyValue, t) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  email = email?.trim();
  if (!email) {
    if (keyValue) {
      error[key] = `${t('field.pleaseEnter')} ${t('field.email')}`;
    } else error[key] = `${t('field.pleaseEnter')} ${t('field.email')}`;
    return error;
  }
  if (email === 'username') {
    error[key] = `${t('validation.invalidSignup')}`;
    return error;
  }
  if (!(email && emailRegex.test(email))) {
    error[key] = `${t('validation.emailMust')}`;
    return error;
  }
  return error;
};

export const isCurrentPassword = (password, error, key, t) => {
  password = password?.trim();
  if (!password) {
    error[key] = `${t('validation.passwordMsg')}`;
    return error;
  }
  return error;
};


export const isStrongPassword = (password, error, key, t) => {
  const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,16}$/;

  password = password.trim();
  if (!password) {
    error[key] = `${t('validation.passwordMsg')}`;
    return error;
  }
  if (!passwordRegex.test(password) && key === 'password') {
    error[key] = `${t('validation.passwordMust')}`;
    return error;
  }
  if (!passwordRegex.test(password) && key === 'upassword') {
    error[key] = `${t('validation.passwordMust')}`;
    return error;
  }
  if (!passwordRegex.test(password)) {
    error[key] = `${t('validation.passwordMust')}`;
    return error;
  }
  return error;
};

export const isValidConformPassword = (formValue, checkErr, key, t) => {
  if (!formValue.cpassword)
    checkErr[key] = `${t('validation.confirmPasswordMsg')}`;
  if (formValue.password !== formValue.cpassword)
    checkErr[key] = `${t('validation.doNotMatch')}`;
  return checkErr;
};

export const isValidContactWithOutCountryCode = (phoneNumber, error, key, t) => {
  const phoneNumberRegex = /^[\d\s\-()+]+$/;

  if (!phoneNumber) {
    error[key] = `${t('validation.phoneNumber')}`;
  }
  else if (!phoneNumberRegex.test(phoneNumber) || phoneNumber.length < 8 || phoneNumber.length > 10) {
    error[key] = `${t('validation.phoneMust')}`;
  }
  return error;
}

export const isValidPhoneNumber = (phoneNumber, error, key, keyValue, t) => {
  if (phoneNumber && phoneNumber.split(' ').length > 1) {
    phoneNumber = phoneNumber.split(' ')[1];
  }
  const phoneNumberRegex = /^[\d\s\-()+]+$/;
  const cleanPhoneNumber = phoneNumber && phoneNumber.replace(/\D/g, '');
  if (!phoneNumber) {
    if (keyValue) {
      error[key] = `Please enter ${keyValue}`;
    }
    error[key] = `${t('validation.phoneNumber')}`;
    return error;
  }
  if (
    !phoneNumberRegex.test(phoneNumber) ||
    cleanPhoneNumber.length < 8 ||
    cleanPhoneNumber.length > 10
  ) {
    error[key] = `${t('validation.phoneMust')}`;

    return error;
  }

  return error;
};



export const isValidAddress = (address, error, key, keyValue, t) => {
  if (!address) {
    if (keyValue) error[key] = `${t('field.pleaseEnter')} ${keyValue}`;
    else error[key] = 'Please filled this field';
    return error;
  }
  return error;
};

export const isSelectService = (serviceArray, error, key, keyValue, t) => {
  if (!(serviceArray.length)) {
    error[key] = `please select service ${keyValue}`;
    return error;
  }
  return error;
}

export const isSelect = (value, error, key, keyValue, t) => {
  if (!value || value === 'Select') {
    if (keyValue === 'dob') keyValue = 'DOB';
    if (keyValue || value === 'Select') {
      error[key] = `${t('field.pleaseSelect')} ${keyValue}`;
    } else error[key] = 'Please select this field';
    return error;
  }
  return error;
};

export const isSelectAddress = (value, error, key, keyValue, t) => {
  if (!value) {
    error[key] = `${t('field.pleaseSelect')} ${keyValue}`;
  } else {
    if (error?.hasOwnProperty(key)) {
      delete error[key];
    }
  }
  return error;
};

export const isAddressPostalCode = (value, error, key, keyValue, t) => {
  if (!value) {
    error[key] = `${t('field.pleaseSelect')} ${keyValue}`;
  }
  else if (value?.length < 5 || value.length > 8) {
    error[key] = `${t('validation.postalCodeMustThai')}`;
  }
  else {
    if (error?.hasOwnProperty(key)) {
      delete error[key];
    }
  }
  return error;
};


export const isValidPostalCode = (value, error, key, keyValue, t, country) => {

  if (!value) {
    error[key] = `${t('validation.postalCode')}`;
  }
  // Check if the value contains only digits
  else if (!/^\d+$/.test(String(value))) {
    error[key] = `${t('validation.postalCodeDigitsOnly')}`;
  }
  // else if (country === 'India' && String(value)?.length !== 6) {
  //   error[key] = `${t('validation.postalCodeMust')}`;
  // }
  else if (String(value)?.length < 5 || String(value)?.length >8) {
    error[key] = `${t('validation.postalCodeMustThai')}`;
  }
  return error;
};


export const isConditionCheck = (value, error, key, t) => {
  if (!value && key !== 'capture') {
    error[key] = `${t('validation.clickCheckbox')}`;
    return error;
  }
  if (key === 'capture' && !value) {
    error[key] = `${t('validation.clickRecaptcha')}`;
    return error;
  }
  return error;
};

export const isCheckAgree = (value, error, key, t) => {
  if (!value) {
    error[key] = `${t('validation.clickCheckbox')}`;
  }
  return error;
};



export const isRequiredCheckboxMultiple = (
  value1,
  value2,
  { ...checkErr },
  key,
  t,
) => {
  if (!value1 || !value2) {
    checkErr[key] = `${t('validation.clickCheckbox')}`;
  }
  return checkErr;
};
