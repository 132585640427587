const homeRoutes = {
    home: '/',
    shopNearMe: '/show_map',
    bestSeller: '/Best Sellers',
    search: '/search/',
    blog: '/blog',
    blogDetails: '/blog-details',
    termCondition: '/term_condition',
    cancelPolicy: '/cancel_policy',
    businessPolicy: '/business_policy',
    faqsSetting: '/setting',
    faqs: '/faq',
    contact: '/contact_us',
    about: '/about_us',
    privacy: '/privacy',
    serviceDescription: `/services/details`,
    services: '/services',
    service: '/service',
    dealOfDay:'/deal-of-the-day',
    topReviewShop:'/highly-rated-vendor',
    tripPlan: '/trip-plan',
    happyHours:'/happy-hours'
}

export default homeRoutes;