import React, { useEffect, useRef } from "react"; // Ensure React is imported
import './ServiceSection.css'
import { CustomImage, CustomSpinner, Footer, PageNotFound, ShareButtons } from "../../common";
import { MetaTag } from "../../customHooks";
import { NavbarLogic, SignIn } from "../../lazyComponent";
import { BookNowModalLogic, CustomShare, ServiceSectionLogic } from "../../components";
import { Locale } from "../Locale/Locale";
import { Link } from "react-router-dom";
import { HomeRoutes } from "../../routes";
import { DefaultServiceImg } from "../../assets/Images";
import { Rate } from "antd";
import { CurrencyDisplay, DiscountPercent } from "../../jsonData";
import InfiniteScroll from "react-infinite-scroll-component";


const ServiceSection = () => {
    const {
        isPageNotFound,
        getServiceName,
        handleAddToCartCount,
        serviceData,
        previousBreadCrumb,
        getHearIconClassName,
        showModal,
        setShowModal,
        type,
        commonId,
        userType,
        getOrganizationName,
        getOrganizationLocation,
        cartItem,
        navigateOrganizationSection,
        handleAddToCart,
        getServiceDesc,
        getServiceSpecialTerm,
        loader,
        relatedServices,
        handleLoadMore,
        currentPage,
        navigateServiceSection,
        handleLikeClick
    } = ServiceSectionLogic();
    
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const { t } = Locale()
    const shareUrl = `${process.env.REACT_APP_SHARE_URL}${window.location.pathname}`;
    const title = `${getServiceName(serviceData)}`;
    const deletionRequest = localStorage.getItem("status") === "requested";
    const role = localStorage.getItem("role");
    const selectedLanguage = localStorage.getItem("i18nextLng");
    const currentCountry = sessionStorage.getItem('country')

    const shareItems = [
        { key: "1", label: <ShareButtons url={shareUrl} title={title} /> },
    ];
    const getBreadCrumb = (previousBreadCrumb, index) => {
        return (
            <>
                {Array.isArray(previousBreadCrumb.breadCrumbName) && <>
                    <Link to={`${previousBreadCrumb?.breadCrumbNamePath}`} className="breadcrumb-link-tag cursor-pointer">
                        {(selectedLanguage === "thai")
                            ? previousBreadCrumb?.breadCrumbName[1]  // Show Thai breadcrumb
                            : previousBreadCrumb?.breadCrumbName[0]}
                    </Link>
                    {previousBreadCrumb?.breadCrumbName[1] && index === 1 &&
                        <span aria-hidden="true" className="breadcrumb-separator">
                            &gt;
                        </span>
                    }
                </>

                }
            </>
        );
    };

    const daysOfWeek = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
    ];

    const getCartDisable = (serviceIs) => {
        if (cartItem?.includes(serviceIs)) {
            return 'disabled-icon';
        } else {
            return 'inabled-icon';
        }
    };




    const getFullAddress = (vendorInfo) => {
        return vendorInfo?.location
        return `${vendorInfo?.address} ${vendorInfo?.city} ${vendorInfo?.postcode}`
    }

    const getDiscountPercent = (serviceInfo) => {
        return (serviceInfo?.discount_on_service) ? (serviceInfo?.discount_on_service) : '0.00'
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {isPageNotFound ? (
                <PageNotFound />
            ) : (loader ?
                <CustomSpinner />
                : (
                    <>
                        <NavbarLogic handleAddToCartCount={handleAddToCartCount} />
                        <MetaTag
                            custom_title={getServiceName(serviceData)}
                            custom_img={serviceData?.service?.service_image}
                        />
                        <div
                            className={`search-display-des-outer-div after-category-new-div container ${deletionRequest && "deletionRequest-not-show-filter"
                                } ${role && "search-display-desc-role"}`}
                        >
                            <div className="breadcrumb-outer-div">
                                <nav
                                    lang="sk"
                                    role="navigation"
                                    aria-label="Omrvinková navigácia"
                                    className="breadcrumb"
                                >
                                    <ol>
                                        <>
                                            <li>
                                                <Link to={HomeRoutes.home} className="breadcrumb-link-tag">
                                                    {t("breadcrumb.home")}
                                                </Link>
                                            </li>
                                            {previousBreadCrumb &&
                                                previousBreadCrumb.length > 0 &&
                                                previousBreadCrumb.map((obj, index) => (
                                                    <React.Fragment key={index}>
                                                        <span aria-hidden="true" className="breadcrumb-separator">
                                                            &gt;
                                                        </span>
                                                        <li className="breadcrumb-li-tag">
                                                            {getBreadCrumb(obj)}
                                                        </li>
                                                    </React.Fragment>
                                                ))}
                                            {previousBreadCrumb?.length > 0 && (
                                                <span aria-hidden="true" className="breadcrumb-separator">
                                                    &gt;
                                                </span>
                                            )}
                                            {!previousBreadCrumb && <>
                                                <span aria-hidden="true" className="breadcrumb-separator">
                                                    &gt;
                                                </span>
                                            </>}
                                            <li className="breadcrumb-li-tag">
                                                {serviceData && Object.values(serviceData)?.length > 0 && (
                                                    <>{getServiceName(serviceData)}</>
                                                )}
                                            </li>
                                        </>
                                    </ol>
                                </nav>
                            </div>
                            <div id="overview">
                                <div className="view-all-service-container service-section-service-details">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="service-section-service-img-div">
                                                <img
                                                    width={'unset'}
                                                    height={'unset'}
                                                    alt={'shop image'}
                                                    src={(serviceData?.service?.service_image) ? (serviceData?.service?.service_image) : (DefaultServiceImg)}
                                                    className={
                                                        'img-fluid '
                                                    }
                                                />
                                                <div className="service-section-heart-icon">
                                                    <i
                                                        className={`${getHearIconClassName(serviceData)}`}
                                                        onClick={() =>
                                                            handleLikeClick(serviceData)
                                                        }
                                                    ></i>
                                                    <CustomShare
                                                        className="search-display-desc-icon-share"
                                                        placement={"bottom"}
                                                        items={shareItems}
                                                    />
                                                    <div
                                                        className={`modal fade ${showModal ? 'd-block show' : 'd-none'}`}
                                                        id="exampleModal"
                                                        tabIndex="-1"
                                                        aria-labelledby="exampleModalLabel"
                                                        aria-hidden="true"
                                                    >
                                                        <SignIn
                                                            setShowModal={
                                                                setShowModal
                                                            }
                                                            showModal={
                                                                showModal
                                                            }
                                                            type={type}
                                                            commonId={
                                                                commonId
                                                            }
                                                            userType={
                                                                userType
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-sm-4">
                                            <div className="service-section-content-section">
                                                <div className="service-name venue-name-h5">
                                                    {getServiceName(serviceData)}
                                                </div>
                                                <div className="organization-name" onClick={() => navigateOrganizationSection(serviceData)}>
                                                    <i class="bi bi-shop"></i> &nbsp;
                                                    {getOrganizationName(serviceData)}
                                                </div>
                                                <div className="location mt-1">
                                                    <i class="bi bi-geo-alt"></i> &nbsp;
                                                    {getOrganizationLocation(serviceData)}
                                                </div>
                                                <div className="service-rating-time mt-1">
                                                    <span className="view-all-star">
                                                        <Rate
                                                            allowHalf
                                                            disabled
                                                            value={serviceData?.service?.service_average_rating}
                                                        />
                                                        <span className="min-content">
                                                            &nbsp;&nbsp;&nbsp;
                                                            <i className="bi bi-clock-fill clock-icon me-1"></i>
                                                            {serviceData?.service?.duration}{" "}
                                                            {t("notification.minute")}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="mt-1 price-section display-service-price-outer">
                                                    <span className="search-display-best-deals-span">
                                                        <CurrencyDisplay
                                                            value={
                                                                serviceData?.service?.special_price
                                                                    ? serviceData?.service?.special_price
                                                                    : serviceData?.service?.price
                                                            }
                                                            subPrice={serviceData?.service?.price}
                                                        />
                                                    </span>
                                                    <span>
                                                        <DiscountPercent
                                                            value={serviceData?.service?.discount_on_service}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="service-section-action">
                                                    <div className="card-main__footer">

                                                        <div className='book-now-section'>
                                                            <div>
                                                                <BookNowModalLogic
                                                                    index={serviceData?.service?.id}
                                                                    className="display-service-service-section-bookn-button"
                                                                    service_id={serviceData?.service?.id}
                                                                    vender_details={serviceData?.organization_details?.organization}
                                                                    service_details={serviceData?.service}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='cart-section'>
                                                            <div
                                                                onClick={() =>
                                                                    handleAddToCart(
                                                                        serviceData?.service?.id
                                                                    )
                                                                }
                                                                data_bs_toggle="modal"
                                                                data_bs_target="#exampleModal"

                                                                className={` me-2 ${getCartDisable(serviceData?.service?.id)}`}
                                                            >
                                                                <span>
                                                                    <i
                                                                        className={`bi bi-cart-plus-fill shop-card-share`}
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-sm-4">
                                            <div className="service-opening-hours mt-2">
                                                <h6 className="search-display-review">
                                                    {`${t("header.timeHeading")} ${currentCountry} Time`}
                                                </h6>
                                                <div className="row">
                                                    <div className="col-4 days">
                                                        {daysOfWeek.slice(0, 7).map((day, index) => (
                                                            <div key={index}>{day}</div>
                                                        ))}
                                                    </div>
                                                    <div className="col-8 days">
                                                        {daysOfWeek.slice(0, 7).map((day, index) => (
                                                            <div key={index}>
                                                                {serviceData?.organization_details?.organization?.business_hours
                                                                    ? serviceData?.organization_details?.organization?.business_hours[
                                                                        day
                                                                    ]?.shop_open === "true"
                                                                        ? `${serviceData?.organization_details?.organization?.business_hours[day]?.open} to ${serviceData?.organization_details?.organization?.business_hours[day]?.close}`
                                                                        : t("globalItems.closed")
                                                                    : t("globalItems.closed")}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 mb-2 service-section-desc-section">
                                    <div className="venue-name-h5">
                                        {t("ServiceField.serviceDesc")}
                                    </div>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: getServiceDesc(serviceData),
                                        }}
                                    />
                                    <div className="venue-name-h5">
                                        {t("ServiceField.serviceTerm")}
                                    </div>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: getServiceSpecialTerm(serviceData),
                                        }}
                                    />
                                </div>
                                <div className="mt-4 mb-2 service-section-desc-section">
                                    <div className="venue-name-h5">
                                        {t('ServiceField.simillarService')}
                                    </div>
                                    <div className="service-section-related-services">
                                        <InfiniteScroll
                                            dataLength={relatedServices?.services?.length} // Current length of data
                                            next={handleLoadMore} // Function to fetch the next page
                                            hasMore={currentPage <= relatedServices?.totalPages} // Check if more data is available

                                        >
                                            <div className="service-related-services">
                                                <div className="row top-review-shop-row">
                                                    {relatedServices?.services?.length ? (
                                                        relatedServices?.services?.map((obj, index) => (
                                                            <React.Fragment key={index}>
                                                                <div className="col-lg-3 col-sm-12 col-md-4 box">
                                                                    <div className="top-review-shop-card">
                                                                        <div className="shop-card-inner">
                                                                            <div className="deal-of-day-image-section">
                                                                                <div
                                                                                    className="shop-image"
                                                                                    onClick={() =>
                                                                                        navigateServiceSection(
                                                                                            obj?.service?.id,
                                                                                            obj?.service?.service_name_en
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <CustomImage
                                                                                        src={obj?.service?.service_image || DefaultServiceImg}
                                                                                        className="img-fluid"
                                                                                    />
                                                                                </div>
                                                                                <div className="discount-section">
                                                                                    <div className="discount-section-inner">
                                                                                        <span className="discount-percent">
                                                                                            {getDiscountPercent(obj?.service)} % {t('field.off')}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="deal-of-the-day-content-section related-service-section">
                                                                                <h2
                                                                                    className="deal-of-the-day-service-heading"
                                                                                    onClick={() =>
                                                                                        navigateServiceSection(
                                                                                            obj?.service?.id,
                                                                                            obj?.service?.service_name_en
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {getServiceName(obj)}
                                                                                </h2>
                                                                                <span className="card-main-address-section">
                                                                                    <i
                                                                                        className="bi bi-geo-alt-fill"
                                                                                        aria-hidden="true"
                                                                                    ></i>
                                                                                    &nbsp;
                                                                                    {getFullAddress(obj?.organization)}
                                                                                </span>
                                                                                <div className="time-section">
                                                                                    <span className="view-all-star">
                                                                                        <Rate
                                                                                            allowHalf
                                                                                            disabled
                                                                                            value={serviceData?.service?.service_average_rating}
                                                                                        />
                                                                                        <span className="min-content">
                                                                                            &nbsp;&nbsp;&nbsp;
                                                                                            <i className="bi bi-clock-fill clock-icon me-1"></i>
                                                                                            {serviceData?.service?.duration}{" "}
                                                                                            {t("notification.minute")}
                                                                                        </span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="price-section mt-1">
                                                                                    <CurrencyDisplay
                                                                                        component={'dealOfTheDay'}
                                                                                        value={
                                                                                            obj?.service?.special_price
                                                                                                ? obj?.service?.special_price
                                                                                                : obj?.service?.price
                                                                                        }
                                                                                        subPrice={obj?.service?.price}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-main__footer">
                                                                            <div className="card-main-footer-section">
                                                                                <div className="btn-section">
                                                                                    <div
                                                                                        className="btn-text"
                                                                                        onClick={() =>
                                                                                            navigateServiceSection(
                                                                                                obj?.service?.id,
                                                                                                obj?.service?.service_name_en
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {t("button.viewDetails")}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        <div className="text-center">
                                                            {t("ServiceField.noServiceAvailable")}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            <div
                className={`${!serviceData || loader ? 'alterNative-footer-div-20' : 'alterNative-footer-div-1'}`}
            >
                <div  id="footer">
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default ServiceSection;
