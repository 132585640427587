import './DeleteTeamMember.css';
import { React,PropTypes,Locale, useEffect, useRef } from '../../../libraries';

const DeleteTeamMember = ({ handleCancel, handleSubmit }) => {
  const { t } = Locale();
  const closeButtonRef = useRef(null);

  useEffect(() => {
    closeButtonRef.current = document.getElementById(
      'team_member_delete_modal_close_button',
    );
  }, []);

  const closeDeleteModal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };

  
  return (
    <>
      <div
        className="modal fade"
        id="deleteTeamMember"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delete-modal-dialog">
          <div className="modal-content">
            <div className="d-flex justify-content-end modal-header delete-modal-header">
              <div>
                <button
                  type="button"
                  id="team_member_delete_modal_close_button"
                  className="btn-close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
            </div>
            <div className="modal-body">
              <div className="container delete-container p-4 text-center">
                <p>
                  {t('teamSetupField.areYouSureYouWantTo')}
                  <br />
                  {t('teamSetupField.deleteThisMember')}
                </p>
              </div>
              <div className="upload-file-button-outer-div mt-2">
                <span className="upload-file-cancel-button">
                  <button
                    className="btn upload-modal-cancel-button"
                    onClick={(e) => handleCancel(e, closeDeleteModal)}
                  >
                    {t('button.cancelButton')}
                  </button>
                </span>
                <span className="upload-file-upload-button">
                  <button
                    className="btn upload-modal-upload-button"
                    onClick={(e) => {
                      handleSubmit(e, closeDeleteModal);
                    }}
                  >
                    {t('button.deleteButton')}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
DeleteTeamMember.propTypes = {
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default DeleteTeamMember;