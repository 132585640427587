import {
  useLocation,
  useEffect,
  useState,
  useNavigate,
  useParams,
  Locale,
} from "../../libraries";
import { City, Country, State } from "country-state-city";

import {
  addCartItem,
  getAllCategory,
  getBestSellerSubCategory,
  getSearchService,
  getSubCategory,
  getSubCategoryService,
  handleErrorResponse,
  handleSuccessResponse,
  showCartList,
} from "../../services";
import { AddOnFavouriteList, RemoveFromFavouriteList } from "../index";
import { HomeRoutes } from "../../routes/index.js";
import { decryptData, encryptData, organizationUrlFormat, urlDeFormat, urlFormat } from "../../customHooks/index.js";
export const DisplayServices = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = Locale();
  const [likes, setLikes] = useState({});
  const [sortBy, setSortBy] = useState("");
  const [filteredServices, setFilteredServices] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCity, setSelectedCity] = useState([]);
  const [allData, setAllData] = useState([]);
  const [defaultOpenAccordianA, setdefaultOpenAccordianA] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [featuredService, setFeaturedServices] = useState(false);
  const [type, setType] = useState("");
  const [commonId, setCommonId] = useState("");
  const [userType, setUserType] = useState("");
  const [areaSearch, setSearchTermArea] = useState("");
  const [handleAddToCartCount, sethandleAddToCartCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [categoryIds, setCategoryIds] = useState([]);
  const [serviceType, setServiceType] = useState("");
  const selectedLanguage = localStorage.getItem("i18nextLng");
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [previousState, setPreviousState] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [breadcrumbm, setBreadCrumbm] = useState([]);
  const [previousValue, setPreviousValue] = useState(null);
  const [isReplaceServices, setIsReplaceServices] = useState(false);
  const [useAllData, setUseAllData] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [cartItem, setCartItem] = useState();
  const [updateCartItem, setUpdateItem] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const itemsPerPage = 5;
  const displayedItems = 5;
  const [categoryNameBread, setCategoryNameBread] = useState({
    categoryNameEn: "",
    categoryNameTh: "",
  });
  const params = useParams();
  const { encryptedData, searchText, value } = params || {};
  const decryptedData = encryptedData && decryptData(encryptedData);
  const { categoryId } = decryptedData || {};
  const { showCategoryName, showCategoryNameThai } = location?.state || {};
  const isServicePage = params?.subCategoryName || searchText;
  const role = localStorage.getItem("role");
  const isCategoryPage = !params?.subCategoryName;
  const formattedValue = urlDeFormat(value);
  const cartCount = localStorage.getItem("cartCount") || 0;

  const navbarSelectedCity = sessionStorage.getItem("selectedCity");
  // const countryName = sessionStorage.getItem("country");
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedStateCount, setSelectedStateCount] = useState([0]);

  const handleStateClick = (e, state) => {
    setSelectedState(state);
    const cities = City.getCitiesOfState(state.countryCode, state.isoCode);
    setCities(cities);
    if (e.target.checked) {
      const citiesLength = cities.length;
      setSelectedStateCount(citiesLength);
    } else {
      setSelectedStateCount([0]);
    }
  };

  const handleFeaturedServicesChange = () => {
    setFilterLoading(true);
    setFeaturedServices(!featuredService);
  };
  const handleAllSubcategoriesCheck = (e, categoryId, name) => {
    setIsLoading(false);
    setFilterLoading(true);
    if (!serviceType) return;

    const filteredItems = serviceType.find(
      (item) => item.category.id === categoryId
    );
    if (!filteredItems) return;

    const subcategoryIds = filteredItems.subcategory.map((sub) => sub.id);
    const allSubcategoriesPresent = subcategoryIds.every((id) =>
      allData.includes(id)
    );
    const allSubcategories = subcategoryIds.every((id) =>
      categoryIds.includes(id)
    );

    setAllData((prevData) => {
      if (allSubcategoriesPresent)
        return prevData.filter((id) => !subcategoryIds.includes(id));
      else return [...prevData, ...subcategoryIds];
    });

    setCategoryIds((prevData) => {
      if (allSubcategories)
        return prevData.filter((id) => !subcategoryIds.includes(id));
      else return [...prevData, ...subcategoryIds];
    });

    setUseAllData(allData.length > 0);

    breadcrumbChangeOnSelect(name);
  };


  const breadcrumbChangeOnSelect = (name) => {
    let tempArray = [...breadcrumbm];
    let index = tempArray.indexOf(name);

    if (index === -1) {
      tempArray.push(name);
    } else {
      tempArray.splice(index, 1);
    }

    setBreadCrumbm(tempArray);

    if (tempArray.length === 0) {
      setCategoryName(decryptedData?.categoryName);
      setSubCategoryName(decryptedData?.subCategoryName);
      setPreviousState(decryptedData);
    } else {
      if (selectedLanguage === "en") {
        setCategoryName({ categoryNameEn: tempArray[0] });
        setSubCategoryName("");
        setPreviousState("");
      } else if (selectedLanguage === "thai") {
        setCategoryName({ categoryNameTh: tempArray[0] });
        setSubCategoryName("");
        setPreviousState("");
      }
    }
  };

  const defaultOpenAccordian = () => {
    if (!serviceType) return;

    const indices = serviceType.flatMap((item, index) =>
      item.subcategory.some((sub) => sub.name_en.includes(searchTerm))
        ? [index]
        : []
    );
    setdefaultOpenAccordianA(indices);
  };

  const handleAddToCart = async (service_id) => {
    if (role === "Customer") {
      try {
        const response = await addCartItem({ service_id: service_id });
        if (!response.data.status) {
          sethandleAddToCartCount((pre) => pre + 1);
          setUpdateItem((prev) => prev + 1);
          const newCartCount = (parseInt(cartCount, 10) || 0) + 1;
          localStorage.setItem("cartCount", newCartCount);
          handleSuccessResponse(response, response.data.message);
        } else {
          handleSuccessResponse(response, response.data.message);
        }
      } catch (err) {
        handleErrorResponse("errr");
      }
    } else {
      setShowModal(true);
      setType("cart");
      setCommonId(service_id);
    }
  };

  const handleLikeClick = async (
    isVendorFavourite,
    favourite_id,
    id,
    type,
    role
  ) => {
    if (role === "Customer") {
      try {
        const newLikes = { ...likes };
        newLikes[id] = !isVendorFavourite;
        setLikes(newLikes);

        if (!isVendorFavourite) {
          const err = await AddOnFavouriteList(id, type);
          if (err) await RemoveFromFavouriteList(err.favorite_id);
        } else await RemoveFromFavouriteList(favourite_id);
      } catch (error) {
        const revertedLikes = { ...likes };
        revertedLikes[id] = !revertedLikes[id];
        setLikes(revertedLikes);
      }
    } else {
      setShowModal(true);
      setType("like");
      setCommonId(id);
      setUserType("service");
    }
  };

  const handleSortChange = (sortOption) => {
    setFilterLoading(true);
    if (sortOption === null) {
      setSortBy({});
    } else {
      setSortBy(sortOption);
    }
  };

  const handleCategoryCheckboxChange = (categoryId) => {
    try {
      let newData = [...allData];
      let index = newData.indexOf(categoryId);
      if (index !== -1) newData.splice(index, 1);
      else newData.push(categoryId);
      setAllData(newData);
      setCategoryIds(newData);
    } catch (err) { }
  };

  const fetchCategoryData = async () => {
    try {
      if (formattedValue === "Best Sellers") {

        const response1 = await getBestSellerSubCategory();

        const response = await getAllCategory();
        const subCategoryResponses = await Promise.all(
          response.data.map(async (category) => {
            const res = await getSubCategory(category.id);
            return { category: category, subcategory: res.data };
          })
        );
        const sortedSubCategoryResponses = subCategoryResponses.sort((a, b) =>
          a.category.name_en.localeCompare(b.category.name_en)
        );
        setServiceType(sortedSubCategoryResponses);

        return response1.data.data;
      } else {
        const response = await getAllCategory();
        const subCategoryResponses = await Promise.all(
          response.data.map(async (category) => {
            const res = await getSubCategory(category.id);
            return { category: category, subcategory: res.data };
          })
        );
        const sortedSubCategoryResponses = subCategoryResponses.sort((a, b) =>
          a.category.name_en.localeCompare(b.category.name_en)
        );
        setServiceType(sortedSubCategoryResponses);
        return subCategoryResponses;
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  };

  const handleCityCheckboxChange = (city) => {
    setFilterLoading(true);
    setIsReplaceServices(true)
    const isSelected = selectedCity.includes(city);
    const updatedCities = isSelected
      ? selectedCity.filter((selected) => selected !== city)
      : [...selectedCity, city];
    setSelectedCity(updatedCities);
    if (selectedCity.length) {
      applyFilterByCity();
    }
  };

  const applyFilter = async (serviceType, navbarSelectedCity) => {
    setIsLoading(false);
    let queryParams;
    if (selectedCity?.length) {
      if (searchText) {
        queryParams = new URLSearchParams({
          page_number: currentPage,
          per_page: itemsPerPage,
          is_featured_service: featuredService,
          ...sortBy,
          search: searchText,
        });
      } else {
        queryParams = new URLSearchParams({
          page_number: currentPage,
          per_page: itemsPerPage,
          sub_category_id: categoryId ? categoryId : '',
          is_featured_service: featuredService,
          service_at_customer_location: "false",
          ...sortBy,
        });
      }

      if (allData.length) {
        allData?.forEach((id) => {
          queryParams.append("sub_category_ids[]", id);
        });
      }
      if (selectedCity.length) {
        selectedCity?.forEach((city) => {
          queryParams.append("shop_districts[]", city);

        });
      }

      try {
        if (searchText) {
          const response = await getSearchService(queryParams);
          if (isReplaceServices) {
            setFilteredServices(response.data);
            setIsReplaceServices(false)
          }
          else {
            if (currentPage > 1 ) {
              setFilteredServices((prevFilteredServices) => {
                // Ensure shops_info is defined and is an array
                const newShopsInfo = Array.isArray(response?.data?.shops_info)
                  ? response?.data?.shops_info
                  : []; // Fallback to an empty array if it's not valid

                return {
                  ...prevFilteredServices,
                  shops_info: [
                    ...prevFilteredServices?.shops_info,
                    ...newShopsInfo,
                  ],
                  total_pages: response?.data?.total_pages,
                  dynamic_description: response?.data?.dynamic_description,
                };
              });
            } else {
              setFilteredServices(response.data);
            }
          }
          setLoader(false);
          setIsLoading(false);
          setFilterLoading(false);
        }

        else {
          if (!categoryId) {
            const subCategoriesIds = [];
            if (serviceType) {
              if (formattedValue === "Best Sellers") {
                serviceType?.forEach((obj) => {
                  subCategoriesIds.push(obj?.id);
                });
                subCategoriesIds.forEach((id) => {
                  queryParams.append("sub_category_ids[]", id);
                });
              }
              else {
                serviceType?.forEach((obj) => {
                  if (obj?.category.name_en === formattedValue.toString()) {
                    obj.subcategory?.forEach((sObj) => {
                      subCategoriesIds.push(sObj.id);
                    });
                  }
                });
                subCategoriesIds.forEach((id) => {
                  queryParams.append("sub_category_ids[]", id);
                });

              }
            }
          }

          const response = await getSubCategoryService(queryParams);
          if (currentPage > 1 && !(response.data.message === 'No Services Are Available !!!')) {
            setFilteredServices((prevFilteredServices) => {
              // Ensure shops_info is defined and is an array
              const newShopsInfo = Array.isArray(response?.data?.shops_info)
                ? response.data.shops_info
                : []; // Fallback to an empty array if it's not valid
              if (!(prevFilteredServices.message)) {
                return {
                  ...prevFilteredServices,
                  shops_info: [
                    ...prevFilteredServices?.shops_info,
                    ...newShopsInfo,
                  ],
                  total_pages: response.data.total_pages,
                  dynamic_description: response.data.dynamic_description,
                };
              }
              else {
                return {
                  shops_info: response?.data?.shops_info,
                  total_pages: response.data.total_pages,
                  dynamic_description: response.data.dynamic_description,
                };
              }

            });
          } else {
            setFilteredServices(response.data);
          }
          setLoader(false);
          setIsLoading(false);
          setFilterLoading(false);
        }
        window.scrollTo(0, 0);
      } catch (err) { }
    }

    else if ((isServicePage) || (selectedCity.length)) {
      if (searchText) {
        queryParams = new URLSearchParams({
          page_number: currentPage,
          per_page: itemsPerPage,
          is_featured_service: featuredService,
          ...sortBy,
          search: searchText,
        });
      } else {
        queryParams = new URLSearchParams({
          page_number: currentPage,
          per_page: itemsPerPage,
          sub_category_id: categoryId && categoryId,
          is_featured_service: featuredService,
          service_at_customer_location: "false",
          ...sortBy,
        });
      }

      if (allData.length) {
        allData?.forEach((id) => {
          queryParams.append("sub_category_ids[]", id);
        });
      }

      if ((!(selectedCity?.length) && navbarSelectedCity && !(searchText))) {
        queryParams.append("shop_districts[]", navbarSelectedCity);
      }

      try {
        if (searchText) {
          const response = await getSearchService(queryParams);
          if (currentPage > 1) {
            setFilteredServices((prevFilteredServices) => {
              // Ensure shops_info is defined and is an array
              const newShopsInfo = Array.isArray(response?.data?.shops_info)
                ? response?.data?.shops_info
                : []; // Fallback to an empty array if it's not valid

              return {
                ...prevFilteredServices,
                shops_info: [
                  ...prevFilteredServices?.shops_info,
                  ...newShopsInfo,
                ],
                total_pages: response?.data?.total_pages,
                dynamic_description: response?.data?.dynamic_description,
              };
            });
          } else {
            setFilteredServices(response.data);
          }
          setLoader(false);
          setIsLoading(false);
          setFilterLoading(false);
        }

        else {

          const response = await getSubCategoryService(queryParams);
          if (currentPage > 1 && !(response.data.message === 'No Services Are Available !!!')) {
            setFilteredServices((prevFilteredServices) => {
              // Ensure shops_info is defined and is an array
              const newShopsInfo = Array.isArray(response?.data?.shops_info)
                ? response.data.shops_info
                : []; // Fallback to an empty array if it's not valid
              if (!(prevFilteredServices.message)) {
                return {
                  ...prevFilteredServices,
                  shops_info: [
                    ...prevFilteredServices?.shops_info,
                    ...newShopsInfo,
                  ],
                  total_pages: response.data.total_pages,
                  dynamic_description: response.data.dynamic_description,
                };
              }
              else {
                return {
                  shops_info: response?.data?.shops_info,
                  total_pages: response.data.total_pages,
                  dynamic_description: response.data.dynamic_description,
                };
              }

            });
          } else {
            setFilteredServices(response.data);
          }

          setLoader(false);
          setIsLoading(false);
          setFilterLoading(false);
          window.scrollTo(0, 0);
        }
      } catch (err) { }
    }
    else {

      const subCategoriesIds = [];
      if (formattedValue === "Best Sellers") {
        serviceType?.forEach((obj) => {
          if (obj.name === "Best Sellers") {
            obj.subcategories?.forEach((subObj) => {
              subCategoriesIds.push(subObj.id);
            });
          }
        });

        if (subCategoriesIds.length) {
          try {
            queryParams = new URLSearchParams({
              page_number: currentPage,
              per_page: itemsPerPage,
              is_featured_service: featuredService,
              ...sortBy,
              search: searchText,
            });

            if (allData.length > 0) {
              allData?.forEach((id) => {
                queryParams.append("sub_category_ids[]", id);
              });
            } else {
              subCategoriesIds?.forEach((id) => {
                queryParams.append("sub_category_ids[]", id);
              });
            }

            if ((!(selectedCity?.length) && navbarSelectedCity && !(searchText))) {
              queryParams.append("shop_districts[]", navbarSelectedCity);
            }
            setCategoryIds(allData.length > 0 ? allData : subCategoriesIds);
            const response = await getSubCategoryService(queryParams);
            if (currentPage > 1) {
              setFilteredServices((prevFilteredServices) => ({
                ...prevFilteredServices,
                shops_info: [
                  ...prevFilteredServices?.shops_info,
                  ...response?.data?.shops_info,
                ],
                total_pages: response.data.total_pages,
                dynamic_description: response.data.dynamic_description,
              }));
            } else {
              setFilteredServices(response.data);
            }
            setLoader(false);
            setIsLoading(false);
            setFilterLoading(false);
          } catch (err) {
            handleErrorResponse(err);
          }
          window.scrollTo(0, 0);
        }
      } else {
        serviceType &&
          serviceType?.forEach((obj) => {
            if (obj.category.name_en === formattedValue.toString()) {
              obj.subcategory?.forEach((sObj) => {
                subCategoriesIds.push(sObj.id);
              });
            }
          });

        if (serviceType && formattedValue) {
          for (let i = 0; i < serviceType.length; i++) {
            if (formattedValue === serviceType[i].category.name_en) {
              setCategoryNameBread((prev) => ({
                ...prev,
                categoryNameEn: serviceType[i].category.name_en,
                categoryNameTh: serviceType[i].category.name_thai,
              }));
            }
          }
        }
        if (subCategoriesIds.length) {
          try {
            queryParams = new URLSearchParams({
              page_number: currentPage,
              per_page: itemsPerPage,
              is_featured_service: featuredService,
              ...sortBy,
              search: searchText,
            });

            if (allData.length > 0) {
              allData.forEach((id) => {
                queryParams.append("sub_category_ids[]", id);
              });
            } else {
              subCategoriesIds.forEach((id) => {
                queryParams.append("sub_category_ids[]", id);
              });
            }
            if ((!(selectedCity?.length) && navbarSelectedCity && !(searchText))) {
              queryParams.append("shop_districts[]", navbarSelectedCity);
            }
            setCategoryIds(allData.length > 0 ? allData : subCategoriesIds);
            const response = await getSubCategoryService(queryParams);
            if (currentPage > 1) {
              let tempData = filteredServices.shops_info;
              if (!Array.isArray(tempData)) {
                console.error("tempData is not an array:", tempData);
                tempData = []; // Initialize tempData as an empty array if it's not already an array
              }
              const newShopsInfo = response?.data?.shops_info;
              if (Array.isArray(newShopsInfo)) {
                setFilteredServices((prevFilteredServices) => ({
                  ...prevFilteredServices,
                  shops_info: [...tempData, ...newShopsInfo],
                  total_pages: response.data.total_pages,
                  dynamic_description: response.data.dynamic_description,
                }));
              }
            } else {
              setFilteredServices(response.data);
            }
            setLoader(false);
            setIsLoading(false);
            setFilterLoading(false);
          } catch (err) {
            handleErrorResponse(err);
          }
          window.scrollTo(0, 0);
        }
      }
    }
  };

  const applyFilterByCity = async () => {
    let queryParams = new URLSearchParams({});
    try {
      if (selectedCity.length) {
        selectedCity.forEach((city) => {
          queryParams.append("shop_districts[]", city);
          queryParams.append("search", city);
        });
        const response = await getSearchService(queryParams);
        if (!response.data.message) {
          setFilteredServices(response.data);
        }
        setIsLoading(false);
        setFilterLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const sortOptions = [
    { label: "header.priceHighLow", value: "price", order: "high" },
    { label: "header.priceLowHigh", value: "price", order: "low" },
    { label: "header.ratingHighLow", value: "vendor_rating", order: "high" },
    { label: "header.ratingLowHigh", value: "vendor_rating", order: "low" },
  ];

  const viewAllFunction = (id, previousBreadcrumb, previousState, venurName) => {
    const encryptedId = encryptData(id);
    const formatOrganizationName = organizationUrlFormat(venurName);
    if (searchText) {
      navigate(`${HomeRoutes.serviceDescription}/${encryptedId}/${formatOrganizationName}`, {
        state: {
          previousBreadcrumb,
          previousState,
          search: searchText,
          categoryId,
          showCategoryName,
          showCategoryNameThai,
          searchText,
        },
      });
    } else {
      navigate(`${HomeRoutes.serviceDescription}/${encryptedId}/${formatOrganizationName}`, {
        state: { previousBreadcrumb, previousState },
      });
    }
  };

  const navigateServiceSection = (id, previousBreadcrumb, previousState, serviceName) => {
    const encryptedId = encryptData(id);
    const formatServiceName = organizationUrlFormat(serviceName);
    if (searchText) {
      navigate(`${HomeRoutes.service}/${encryptedId}/${formatServiceName}`, {
        state: {
          previousBreadcrumb,
          previousState,
          search: searchText,
          categoryId,
          showCategoryName,
          showCategoryNameThai,
          searchText,
        },
      });
    } else {
      navigate(`${HomeRoutes.service}/${encryptedId}/${formatServiceName}`, {
        state: { previousBreadcrumb, previousState },
      });
    }
  }

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const showSort = () => {
    setOpenSort(true);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setOpenSort(false);
  };
  useEffect(() => { }, [categoryIds, t]);



  useEffect(() => {
    if (localStorage.getItem("isSection")) {
      localStorage.removeItem("isSection");
    }
    else if (decryptedData === null && !value) {
      navigate(HomeRoutes.home); // Navigate to the home page if location.state is null
    } else {
      const fetchData = async () => {
        try {
          const categoryData = await fetchCategoryData();
          await applyFilter(categoryData, navbarSelectedCity);
        } catch (err) { }
      };
      if (previousValue !== null && previousValue !== formattedValue) {
        setIsLoading(true);
        setAllData([]);
        setCategoryIds([]);
      }
      setPreviousValue(formattedValue);

      fetchData();
    }

  }, [
    selectedCity,
    allData,
    sortBy,
    featuredService,
    categoryId,
    likes,
    location.pathname,
    role,
    previousValue,
    value,
    currentPage,
    sessionStorage.getItem("selectedCity")
  ]);



  useEffect(() => {
    if (decryptedData) {
      setCategoryName(decryptedData.categoryName);
      setSubCategoryName(decryptedData.subCategoryName);
      setPreviousState(decryptedData);
    }
    setSelectedCity([])
    setSortBy("");
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setOpen(false);
        setOpenSort(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    role && addtocart();
  }, [updateCartItem]);

  const addtocart = async () => {
    try {
      const response = await showCartList();
      let arr = [];
      response?.data?.data?.forEach((obj) => {
        arr.push(obj.service_id);
      });
      setCartItem(arr);
    } catch (err) { }
  };

  const getBreadCrumbName = (categoryName, type) => {
    if (type === "category")
      return selectedLanguage === "thai" &&
        selectedLanguage !== undefined &&
        selectedLanguage !== null
        ? categoryName?.categoryNameTh
        : categoryName?.categoryNameEn;
    else if (type === "subcategory")
      return selectedLanguage === "thai" &&
        selectedLanguage !== undefined &&
        selectedLanguage !== null
        ? subCategoryName?.subCategoryNameTh
        : subCategoryName?.subCategoryNameEn;
    else if (type === "value")
      return selectedLanguage === "thai" &&
        selectedLanguage !== undefined &&
        selectedLanguage !== null
        ? categoryName?.categoryNameTh
        : categoryName?.categoryNameEn;
    else if (searchText) return searchText;
  };

  const getBreadCrumbArgument = (item) => {
    const encryptionData = {
      categoryId: previousState?.categoryId,
      serviceType: previousState?.serviceType,
      subCategoriesItems: previousState?.subCategoriesItems,
      categoriesItems: previousState?.categoriesItems,
      subCategoriesDatas: previousState?.subCategoriesDatas,
      categoryName: previousState?.categoryName,
      subCategoryName: previousState?.subCategoryName,
    };
    const encryptedData = encryptData(encryptionData);
    return searchText
      ? [

        {
          breadCrumbName: [searchText, searchText],
          breadCrumbNamePath: `${HomeRoutes.services}${HomeRoutes.search}${searchText}`,
        },
      ]
      : isCategoryPage
        ? [
          {
            breadCrumbName: [formattedValue, formattedValue],
            breadCrumbNamePath: `${HomeRoutes.services}/${formattedValue}`,
          },
          {
            breadCrumbName: item.vendor_info?.venue_name,
            breadCrumbNamePath: "",
          },
        ]
        : [
          {
            breadCrumbName: [
              categoryName?.categoryNameEn,
              categoryName?.categoryNameTh,
            ],
            breadCrumbNamePath: `${HomeRoutes.services}/${urlFormat(
              categoryName?.categoryNameEn
            )}`,
          },
          {
            breadCrumbName: [
              subCategoryName?.subCategoryNameEn,
              subCategoryName?.subCategoryNameTh,
            ],
            breadCrumbNamePath: `${HomeRoutes.services}/${urlFormat(
              categoryName?.categoryNameEn
            )}/${urlFormat(
              subCategoryName?.subCategoryNameEn
            )}/${encryptedData}`,
          },
          {
            subCategory: "subCategory",
          },
        ];
  };

  const getVenueName = (item) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null &&
      item.vendor_info.name_thai
      ? item.vendor_info.name_thai
      : item.vendor_info.venue_name;
  };

  const getServiceName = (service) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null &&
      service.service_name_thai
      ? service.service_name_thai
      : service.service_name;
  };

  const getHearIconClassName = (service) => {
    return role === "Customer" && service.favourite.is_service_favourite
      ? "bi bi-suit-heart-fill"
      : "bi bi-suit-heart";
  };

  const getSpecialPrice = (service) => {
    return service.special_price ? service.special_price : service.price;
  };


  const handleNavbarCityChange = async (latLong, city) => {
    const categoryData = await fetchCategoryData();
    await applyFilter(categoryData, navbarSelectedCity);
  }

  return {
    defaultOpenAccordianA,
    serviceType,
    defaultOpenAccordian,
    setSearchTermArea,
    areaSearch,
    sortBy,
    filteredServices,
    handleLikeClick,
    handleSortChange,
    searchTerm,
    setSearchTerm,
    selectedCity,
    handleCityCheckboxChange,
    allData,
    handleCategoryCheckboxChange,
    handleAllSubcategoriesCheck,
    handleFeaturedServicesChange,
    showModal,
    setShowModal,
    handleAddToCart,
    type,
    commonId,
    userType,
    handleAddToCartCount,
    viewAllFunction,
    categoryId,
    categoryIds,
    sortOptions,
    handleLoadMore,
    showSort,
    showDrawer,
    onClose,
    open,
    openSort,
    selectedLanguage,
    displayedItems,
    categoryName,
    subCategoryName,
    previousState,
    isLoading,
    useAllData,
    filterLoading,
    cartItem,
    getSpecialPrice,
    getHearIconClassName,
    getServiceName,
    getVenueName,
    getBreadCrumbArgument,
    getBreadCrumbName,
    showCategoryName,
    showCategoryNameThai,
    currentPage,
    loader,
    setLoader,
    categoryNameBread,
    navigateServiceSection,
    cities,
    selectedState,
    selectedStateCount,
    handleStateClick,
    handleNavbarCityChange
  };
};
export default DisplayServices;
