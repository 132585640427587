import './OuterFooter.css';
import { React, useState, Locale } from '../../libraries';
import { handleErrorResponse, handleSuccessResponse, creteNewsLetter } from '../../services';
const OuterFooter = () => {
  const { t } = Locale();
  const [outerfooterValue, setOuterfooterValue] = useState();
  const [formError, setFormError] = useState();

  const handleChange = (e) => {
    const { value } = e.target;
    setOuterfooterValue(value)
  }

  const handleValidation = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var error;
    let email = outerfooterValue?.trim();
    if (!outerfooterValue) {
      error = `${t('userEdit.validPassword')}`;
      setFormError(error);
      return true
    }
    else if (!(outerfooterValue && emailRegex.test(email))) {
      error = `${t('userEdit.validPassword')}`;
      setFormError(error);
      return true
    }
    return false
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let err = handleValidation(e);
    if (!err) {
      try {
        const params = {
          email: outerfooterValue
        }
        const response = await creteNewsLetter(params)
        setOuterfooterValue("");
        handleSuccessResponse(response, response.data.message);
        setFormError("")
      }
      catch (error) {
        handleErrorResponse(error);
      }
    }
  }

  return <>
    <div className='outer-footer-outer-container mt-5'>
      <div className='outer-footer-inner-container'>
        <div className='container p-2'>
          <div className='outer-footer-heading mt-4 d-flex justify-content-center text-center heading-font'>
           {t('header.getInstant')}
          </div>
          <div>
            <span className='outer-footer-sub-heading d-flex justify-content-center text-center paragraph-font'>
              {t('field.outerFooterSubHeading')}
            </span>
          </div>
          <div className='outer-footer-action-outer-div mt-3 mb-4'>
            <div className='d-flex justify-content-center  gap-3 '>
              <div className="signup mt-2 mb-2 ">
                <form onSubmit={handleSubmit} className='d-flex justify-content-between' method='POST'>
                  <input name='outerfooter' value={outerfooterValue} onChange={handleChange} className='outer-footer-email-field' type="text" id="email-signup"
                    placeholder={`${t('payment.your')} ${t('payment.emailAddress')}...`}
                  />
                  <input type="submit" className='read-more-font outer-footer-form-submit-btn' value={t('header.subscribe')} id="btn" />
                </form>
                <div className='outerfooter-error mt-1'>{formError}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
};
export default OuterFooter;