import { React } from '../libraries';

const Home = React.lazy(() => import('../pages/NewHome/Home.js'));
const AboutUsLogic = React.lazy(() => import('../components/aboutUs/aboutUsLogic.js'));
const PrivacyPolicyLogic = React.lazy(() => import('../components/privacyPolicy/privacyPolicyLogic.js'));
const ContactUsLogic = React.lazy(() => import('../components/contactUs/contactUsLogic.js'));
const FAQLogic = React.lazy(() => import('../vendor/component/faq/faq.js'));
const BussinessPolicyLogic = React.lazy(() => import('../components/bussinessPolicy/bussinessPolicyLogic.js'));
const CancelPolicyLogic = React.lazy(() => import('../components/cancelPolicy/cancelPolicyLogic.js'));
const TermConditionLogic = React.lazy(() => import('../components/termCondition/termConditionLogic.js'));
const DisplayServiceLogic = React.lazy(() => import('../components/displayService/displayService.js'));
const SearchAndDisplayDescLogic = React.lazy(() => import('../components/searchAndDisplayDesc/searchAndDisplayDesc.js'));
const VendorShopMap = React.lazy(() => import('../pages/Map/VendorShopMap.js'));
const HomeOurService = React.lazy(() => import('../pages/NewHome/OurService/HomeOurService.js'));
const HomeCategory = React.lazy(() => import('../pages/NewHome/Category/HomeCategory'));
const HomeSlider = React.lazy(() => import('../pages/NewHome/Slider/HomeSlider'));
const Recommended=React.lazy(()=>import('../pages/NewHome/Recommended/Recommended.js'))
const HomeBestSeller = React.lazy(() => import('../pages/NewHome/BestSelling/HomeBestSelling.js'))
const WebSiteUpdate = React.lazy(() => import('../pages/NewHome/WebsiteUpdate/WebsiteUpdate'))
const NewCarousal = React.lazy(() => import('../common/textCarousal/newCarousal.js'));
const Blog = React.lazy(() => import('../pages/Blog/Blog.js'))
const ServiceSection = React.lazy(() => import('../pages/ServiceSection/ServiceSection.js'))
const BlogDetails=React.lazy(()=>import('../pages/Blog/blogDetails.js'))
const PaymentSuccess=React.lazy(()=>import('../vendor/paymentSuccess/paymentSuccess.js'));
const MobileResponseId=React.lazy(()=>import('../vendor/paymentSuccess/getMobileResponseId.js'));
const DealOfTheDayLogic=React.lazy(()=>import('../components/dealOfTheDay/dealOfTheDay.js'));
const HappyHoursLogic=React.lazy(()=>import('../components/happyHours/happyHoursLogic.js'))
const TopReviewShopLogic=React.lazy(()=>import('../components/topReviewShop/topReviewShop.js'))
const TripPlanLogic=React.lazy(()=>import('../components/tripPlan/tripPlanLogic.js'))
export {
    Home,
    DealOfTheDayLogic,
    HappyHoursLogic,
    TopReviewShopLogic,
    TripPlanLogic,
    AboutUsLogic,
    PrivacyPolicyLogic,
    ContactUsLogic,
    FAQLogic,
    BussinessPolicyLogic,
    CancelPolicyLogic,
    TermConditionLogic,
    DisplayServiceLogic,
    SearchAndDisplayDescLogic,
    VendorShopMap,
    HomeOurService,
    HomeCategory,
    HomeSlider,
    HomeBestSeller,
    WebSiteUpdate,
    NewCarousal,
    Blog,
    BlogDetails,
    PaymentSuccess,
    MobileResponseId,
    ServiceSection,
    Recommended
}