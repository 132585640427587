import { AddressJson } from "../../jsonData";
import { Locale, useEffect, useState } from "../../libraries";
import { isSelectAddress, isValidAddress, isValidPostalCode } from "../validation/fieldRegex";
import AddressSelect from "./addressSelect";
import { fetchCountriesDropdown } from "./util";
import { fetchCities, fetchCitiesDropdown, fetchCountries, fetchStateDropdown, fetchStates } from "./util";

const AddressSelectLogic = ({ section, addressFormValue, setAddressFormValue, formErrors, setFormErrors }) => {
    const { t } = Locale();
    const currentCountry = (addressFormValue?.country?.name) ? (addressFormValue?.country?.name) : (sessionStorage.getItem('country'));
    const [citiesDropdown, setCitiesDropdown] = useState([]);
    const [stateDropdown, setStateDropdown] = useState([]);
    const [coutryDropDown, setCountryDropdown] = useState([]);
    const [subDistrictOption, setSubDistrictOption] = useState([]);

    useEffect(() => {
        fetchCountryDatas();
        fetchCountryDropdownData();
    }, [addressFormValue?.country?.name])

    const fetchCountryDatas = async () => {
        const tempCountryData = await fetchCountries(currentCountry);
        if (tempCountryData) {
            setAddressFormValue((prev) => ({
                ...prev,
                country: { id: tempCountryData.id, name: tempCountryData.name, code: tempCountryData?.iso2 },
            }));
            fetchStateDropdownData(tempCountryData.id)
        }

        if (addressFormValue?.state?.name) {
            fetchStateDatas(tempCountryData.id, addressFormValue?.state?.name)
        }
    }

    const fetchStateDatas = async (id, stateName) => {
        const tempStateData = await fetchStates(id, stateName);
        if (tempStateData) {
            setAddressFormValue((prev) => ({
                ...prev,
                state: { id: tempStateData.id, name: tempStateData.name, state_code: tempStateData?.state_code }
            }))
            fetchCitiesDropdownData(id, tempStateData.id)
        }
        if ((addressFormValue?.city?.name) && (tempStateData.id)) {
            fetchCityDatas(id, tempStateData.id, addressFormValue?.city?.name)
        }

    }

    const fetchStateDropdownData = async (countryId) => {
        let tempStateDropdown = await fetchStateDropdown(countryId)
        setStateDropdown(tempStateDropdown)
    }

    const handleStateChange = (value) => {
        const selectedOption = stateDropdown.find((option) => option.value === value);
        if (selectedOption) {
            setAddressFormValue((prev) => ({
                ...prev,
                state: { id: selectedOption.id, name: selectedOption.name, code: selectedOption?.state_code },
                city: { name: '' },
                postalCode: { value: '' }
            }));
            let tempError = isSelectAddress(selectedOption.name, formErrors, 'state', `${t('field.state')}`, t);
            setFormErrors(tempError);
            let tempCityDataDropdown = fetchCitiesDropdownData(addressFormValue?.country?.id, selectedOption.id);
            setCitiesDropdown(tempCityDataDropdown);
        }
    };

    const fetchCityDatas = async (countryId, stateId, cityName) => {
        const tempCityData = await fetchCities(countryId, stateId, cityName);
        if (tempCityData) {
            setAddressFormValue((prev) => ({
                ...prev,
                city: { id: tempCityData?.id, latitude: tempCityData?.latitude, longitude: tempCityData?.longitude, name: tempCityData?.name },
            }))
        }
    }

    const fetchCitiesDropdownData = async (countryId, stateId) => {
        let tempCityDataDropdown = await fetchCitiesDropdown(countryId, stateId)
        setCitiesDropdown(tempCityDataDropdown)
    }

    const handleCityTypeChange = (e) => {
        const { value } = e.target;
        setAddressFormValue((prev) => ({
            ...prev,
            city: { name: value }
        }))
        let tempError = isValidAddress(value, formErrors, 'city', t('field.city'), t);
        setFormErrors(tempError)
    }

    const handleCityChange = async (e) => {
        if (!(addressFormValue?.country?.name) === "Thailand") {
            await setAddressFormValue((prev) => ({
                ...prev,
                city: { name: e },
                postalCode: { value: '' }
            }));
        }
        else {
            await setAddressFormValue((prev) => ({
                ...prev,
                city: { name: e }
            }));
        }
        let tempError = isSelectAddress(
            e,
            formErrors,
            'city',
            addressFormValue?.country?.name === "Thailand"
                ? t('field.city')
                : t('field.subDistrict'),
            t
        );
        setFormErrors(tempError)
    };

    const handleChangeValidation = (name, value) => {
        let error = { ...formErrors };

        if (error.hasOwnProperty(name)) {
            delete error?.[name];
        }
        switch (name) {
            case 'postalCode':
                error = isValidPostalCode(value, error, 'postalCode', `${t('field.postalCode')}`, t, addressFormValue?.country?.name)
                break;
        }
        return error
    }

    const handlePostalCode = (e) => {
        let tempError = handleChangeValidation('postalCode', e);
        if ((addressFormValue?.country?.name) === "Thailand") {
            const selectedPostalCode = e;
            const selectedPostalCodeData = AddressJson.filter(
                (item) => item.postalCode === selectedPostalCode,
            );
            const subDistrictOP = selectedPostalCodeData.map((item) => ({
                value: item.districtNameEn,
                label: item.districtNameEn,
            }));
            setSubDistrictOption(subDistrictOP);
            setAddressFormValue((prev) => ({
                ...prev,
                postalCode: { value: e },
                city: ""

            }));
        }
        else {
            setAddressFormValue((prev) => ({
                ...prev,
                postalCode: { value: e },
            }));

        }
        setFormErrors(tempError)
    }

    const handleCountryChange = (value) => {
        const selectedOption = coutryDropDown.find((option) => option.value === value);
        if (selectedOption) {
            setAddressFormValue((prev) => ({
                ...prev,
                country: { id: selectedOption.id, name: selectedOption.name, code: selectedOption?.code },
                state: { name: '' },
                city: { name: '' },
                postalCode: { value: '' }
            }));
            let tempError = isSelectAddress(selectedOption.name, formErrors, 'country', `${t('field.country')}`, t);
            setFormErrors(tempError);
            let tempStateData = fetchStateDropdownData(selectedOption.id);
            setStateDropdown(tempStateData);
        }
    }

    const fetchCountryDropdownData = async () => {
        let tempCountryDropdown = await fetchCountriesDropdown()
        setCountryDropdown(tempCountryDropdown)
    }

    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        if ((addressFormValue?.state?.name) && (!(citiesDropdown?.length))) {
            fetchCountryDatas();
        }
    }, [addressFormValue?.state?.name])

    return <>
        <AddressSelect
            section={section}
            handleCityTypeChange={handleCityTypeChange}
            subDistrictOption={subDistrictOption}
            coutryDropDown={coutryDropDown}
            handleCountryChange={handleCountryChange}
            filterOption={filterOption}
            stateDropdown={stateDropdown}
            citiesDropdown={citiesDropdown}
            addressFormValue={addressFormValue}
            formErrors={formErrors}
            handleStateChange={handleStateChange}
            handleCityChange={handleCityChange}
            handlePostalCode={handlePostalCode}
        />
    </>
}

export default AddressSelectLogic;