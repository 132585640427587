import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const CustomSelect = ({
  placeholder,
  options,
  value,
  onChange,
  error,
  className,
  name
}) => {
  return (
    <div>
      <Select
        className={className}
        showSearch
        value={value || undefined} 
        placeholder={placeholder}
        onChange={(e)=>onChange(name,e)}
        style={{ width: '100%' }}
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        {options &&
          options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
      </Select>
      {error && <small className="small-err">{error}</small>}
    </div>
  );
};

export default CustomSelect;
