import { useState, Locale, useNavigate, toast } from "../../libraries";
import type { DatePickerProps } from 'antd';
import { handleErrorResponse, handleSuccessResponse, editProfile } from "../../services";
import {
  isSelect,
  isStrongPassword,
  isValidAddress,
  isValidConformPassword,
  isValidName,
  isValidPhoneNumber,
} from "../../common/validation/fieldRegex";
import { checkFileSize } from "../../common/uploadFileModal/fileSizeLogic";
import { useCropImage } from "../../common";
import { getGeoAddress, getLatLngByAddress } from "../../customHooks";
const UserEditProfile = () => {
  const navigate = useNavigate();
  const { t } = Locale();
  const initialValues = {
    fname: "",
    lname: "",
    gender: "",
    dob: "",
    countrycode: "",
    phoneNumber: "",
    address: "",
    email: "",
    area: "",
    password: "",
    currentPasswords: "",
    confirmPassword: "",
    file: "",
    image: "",

  };
  const previousValue = {
    fname: "",
    lname: "",
    gender: "",
    dob: "",
    phoneNumber: "",
    address: "",
    email: "",
    area: "",
    password: "",
    countrycode: "",
    currentPasswords: "",
    confirmPassword: "",
    file: "",
    image: "",
  };

  const [previousFormValue, setPreviousFormValue] = useState(previousValue)
  const [formValue, setFormValue] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [profileVisible, setProfileVisible] = useState(true);
  const [showLocationBlockModal, setShowLocationBlockModal] = useState(false)
  const { crop, setCrop, zoom, setZoom, onCropComplete, showCroppedImage } = useCropImage(selectedFile, setSelectedFile, formValue?.file?.name);

  const navigateFunction = (url) => {
    navigate(url);
  };

  const handleDateSelect = async (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    let errorLogs = handleChangeValidation('dob', dateString);
    setFormErrors(errorLogs);
    await setFormValue((prevValues) => ({
      ...prevValues,
      dob: dateString,
    }));
  };

  const handleChange = (e, value, countryData, id) => {
    const formData = new FormData();
    if (e instanceof File) {
      setProfileVisible(false)
      if (e?.type?.includes('image') && checkFileSize(e, t)) {
        setFormValue((prevValues) => ({
          ...prevValues,
          file: e,
        }));
        formData.append("image", e);
        setSelectedFile(URL.createObjectURL(e));
      } else {
        return;
      }
    }
    else if (id === "phoneNumber") {
      const phoneNumber = value.replace(/\s+/g, '');;
      let errorLogs = handleChangeValidation('phoneNumber', phoneNumber);
      setFormErrors(errorLogs);
      setFormValue((prevValues) => ({
        ...prevValues,
        phoneNumber,
      }));

    }
    else if (e.target) {
      const { name, value } = e.target;
      const errorLogs = checkValidation({
        ...formValue,
        [name]: value,
      });
      setFormErrors(errorLogs);
      setFormValue((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

  };

  const checkValidation = (values) => {
    var errors = {};

    if (Object.keys(values).length > 3) {
      errors = isValidName(values.fname, errors, "fname", `${t("field.fname")}`, t);
      errors = isValidName(values.lname, errors, "lname", `${t("field.lName")}`, t);
      errors = isValidPhoneNumber(
        values.phoneNumber,
        errors,
        "phoneNumber",
        `${t("field.phoneNumber")}`,
        t
      );
      errors = isValidAddress(values.address, errors, "address", `${t("field.address")}`, t);
      errors = isSelect(values.dob, errors, "dob", "dob", t);
      errors = isSelect(values.gender, errors, "gender", `${t("field.gender")}`, t);
    } else {
      errors = isStrongPassword(
        values.currentPassword,
        errors,
        "currentPassword",
        t
      );
      errors = isStrongPassword(values.password, errors, "password", t);
      errors = isValidConformPassword(
        { password: values.password, cpassword: values.confirmPassword },
        errors,
        "confirmPassword",
        t
      );
    }
    return errors;
  };

  const checkIfUpdated = () => {
    return (JSON.stringify(formValue) !== JSON.stringify(previousFormValue));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let cropFile = await showCroppedImage();
    let err = {};
    err = checkValidation(formValue);
    setProfileVisible(true)
    setFormErrors(err);
    if (Object.keys(err).length === 0) {
      try {
        if (checkIfUpdated()) {
          formValue.phoneNumber =
            formValue.phoneNumber.split(" ").length <= 1
              ? formValue.countrycode.split("(")[0].trim() +
              " " +
              formValue.phoneNumber
              : formValue.phoneNumber;

          let response;
          let formDatas;
          formDatas = await prepareFormData(formValue, cropFile);
          response = await editProfile(formDatas);
          localStorage.setItem(
            "name",
            response.data.user.first_name + " " + response.data.user.last_name
          );
          if (response.data.images)
            localStorage.setItem("profilePhoto", response.data.images);
          setFormValue((pre) => ({
            ...pre,
            image: response.data?.images,
          }));
          handleSuccessResponse(response, response.data.message);
        }
        else {
          toast.error(t('userEdit.noUpdateMessage'));
        }
      } catch (err) {
        handleErrorResponse(err);
      }
    }
  };

  const prepareFormData = async (formValues, cropFile) => {
    const formData = new FormData();
    formData.append("role", localStorage.getItem("role"));
    formData.append("phone_number", formValues.phoneNumber);
    formData.append("first_name", formValues.fname);
    formData.append("last_name", formValues.lname);
    formData.append("gender", formValues.gender.toLowerCase());
    formData.append("date_of_birth", formValues.dob);
    formData.append("address", formValues.address);

    if (!formValues.latitude || !formValues.longitude) {
      const latLong = await fetchLatLngByAddress(formValues.address);
      if (latLong) {
        formData.append("latitude", latLong.latitude);
        formData.append("longitude", latLong.longitude);
      }
    } else {
      formData.append("latitude", formValues.latitude);
      formData.append("longitude", formValues.longitude);
    }

    if (formValues.file) {
      formData.append("image", cropFile);
    }

    return formData;
  };

  const fetchLatLngByAddress = async (address) => {
    try {
      const response = await getLatLngByAddress(address);
      if (response?.lat && response?.lng) {
        return { latitude: response.lat, longitude: response.lng };
      }
    } catch (error) {
      console.error("Error fetching lat/lng:", error);
      return null;
    }
  };

  const onChange = (name, value) => {
    let errorLogs = handleChangeValidation(name, value);
    setFormErrors(errorLogs);
    setFormValue({ ...formValue, [name]: value });
  };

  const handleChangeValidation = (name, value) => {
    let error = { ...formErrors };
    if (error.hasOwnProperty(name)) {
      delete error?.[name];
    }


    switch (name) {
      case 'gender':
        error = isSelect(value, error, "gender", `${t("field.gender")}`, t);
        break;
      case 'dob':
        error = isSelect(value, error, "dob", "dob", t);
        break;
      case 'phoneNumber':
        error = isValidPhoneNumber(value, error, "phoneNumber", `${t("field.phoneNumber")}`, t);
        break;
    }
    return error
  }

  const handlePhoneCountryCodeChange = (countryCode, countryData) => {
    setFormValue((prevValues) => ({
      ...prevValues,
      countrycode: `+${countryCode?.dialCode}`,
    }));
  }

  const handleChangePlace = async (e, onSelect) => {
    let longitude;
    let latitude;
    if (onSelect) {
      latitude = e?.geometry?.location?.lat();
      longitude = e?.geometry?.location?.lng();
      setFormValue((prev) => ({
        ...prev,
        address: e.formatted_address,
        latitude: latitude,
        longitude: longitude,
      }));
    } else {
      setFormValue((prev) => ({
        ...prev,
        address: e.target.value,
        latitude: null,
        longitude: null
      }));
    }
  };

  const handleClickUseMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            let response = await getGeoAddress(latitude, longitude);
            setFormValue((prev) => ({
              ...prev,
              address: response.address,
              latitude: latitude,
              longitude: longitude,
            }));
          } catch (error) {
            console.error('Error getting geocode response:', error);
          }
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            setShowLocationBlockModal(true)
          }
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleClosePermissionModal = () => {
    setShowLocationBlockModal(false);
  }

  return {
    setFormValue,
    setPreviousFormValue,
    selectedFile,
    navigateFunction,
    formErrors,
    formValue,
    handleDateSelect,
    handleSubmit,
    handleChange,
    onChange,
    profileVisible,
    crop,
    setCrop,
    zoom,
    setZoom,
    onCropComplete,
    handlePhoneCountryCodeChange,
    handleClickUseMyLocation,
    showLocationBlockModal,
    handleChangePlace,
    handleClosePermissionModal,
  };
};
export default UserEditProfile;
