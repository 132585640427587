import './CartPayment.css';
import '../Payment/Payment.css'
import { Locale, Search, Autocomplete, Button, Link, React, useEffect, DateTime, useState } from '../../libraries/index.js';
import { CustomCheckbox, CustomSpinner, InputField, RadioButton, Small, PermissionDenied } from '../../common';
import { DefaultShop } from '../../assets/Images';
import {  CurrencyDisplay } from '../../../src/jsonData';
import IntlTelInput from 'react-intl-tel-input';
import { HomeRoutes } from '../../routes/index.js';
import { CheckOutHeader } from '../index.js';
import { CustomImage } from '../../lazyComponent';
import { CheckoutCalendarModalLogic } from '../../components';
import { dynamicTimeZone, getPhoneCode } from '../../customHooks/timeZone.js';
import SavedAddressComponent from '../SavedAddress/SavedAddress.js';

const CartPayment = ({
  handlePhoneNumberChange,
  handlePhoneCountryCodeChange,
  apiCalculationData,
  isLoading,
  handleSubmit,
  formValue,
  handleChange,
  handleChangeDetailsSection,
  err,
  onChange,
  handleSearch,
  onCheckUserCashback,
  buttonDisable,
  suraCashback,
  discountPrice,
  serviceDetails,
  handleClickUseMyLocation,
  handleChangePlace,
  handleNevigateTermCondition,
  userCashBackDetails,
  handleClosePermissionModal,
  showModal,
  calendarFormValue,
  setCalendarFormValue,
  setApiCalculationData,
  handleSavedAddress
}) => {


  const { t } = Locale();
  const deletionRequest = localStorage.getItem('status') === 'requested';
  const selectedLanguage = localStorage.getItem('i18nextLng');
  const [isCalendarModal, setIsCalendarModal] = useState(false)
  const [selectServiceDetails, setSelectedServiceDetails] = useState();
  const [count, setCount] = useState(0);
  const currentLocation = sessionStorage.getItem('country');
  const currencyIcon = currentLocation === 'India' ? '₹' : '฿';
  const [preferredCountries, setPreferredCountry] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getOrganizationName = (obj) => {
    return ((selectedLanguage === "thai") ? ((obj?.shop_name_thai) ? ((obj?.shop_name_thai)) : (obj?.shop_name)) : (obj?.shop_name))
  };

  const getServiceName = (obj) => {
    return selectedLanguage === 'en'
      ? obj?.service_name
      : obj?.service_name_thai;
  };

  const formatTime = (isoString) => {
    const dateObject = DateTime.fromISO(isoString, { zone: dynamicTimeZone() });
    const thailandTime = dateObject.setZone(dynamicTimeZone());
    const formattedTime = thailandTime.toFormat('HH:mm:ss');
    return formattedTime;
  };
  const getBranchName = (obj) => {
    return calendarFormValue?.[obj?.service_id]?.branch?.branch_name
  }
  
  const openCalendarModal = (obj) => {
    setCount((prev) => prev + 1)
    setSelectedServiceDetails(obj)
    setIsCalendarModal(true)

  }

  const closeCalendarModal = () => {
    setCount((prev) => prev + 1)
    setSelectedServiceDetails(null)
    setIsCalendarModal(false)
  }

  useEffect(() => {
    let tempCode = getPhoneCode(formValue.countrycode)
    setPreferredCountry(tempCode)
  }, [formValue.countrycode])


  return (
    <>
      <CheckOutHeader
        formValue={formValue}
      />
      <div
        className={`container-fluid checkout-page-outer ${deletionRequest && 'deletionRequest-not-show-filter'
          } ${isLoading && 'processBlur'}`}
      >
        <div className={`row `}>
          <div className="col-md-4 order-md-1">
            <div className="cart-payment-common-outer-div">
              <div className="cart-payment-common-inner-div">
                <div className="user-details">
                  <div className="cart-payment-heading">
                    <div>{t('payment.yourDetails')}</div>
                  </div>
                  <div className="cart-payment-content">
                    <form>
                      <div className="row">
                        <div className="col-6">
                          <label>
                            {
                              <>
                                {t('field.fname')}
                                <small className="small-err">*</small>
                              </>
                            }
                          </label>
                          <InputField
                            name="fName"
                            className="book-name "
                            onChange={handleChangeDetailsSection}
                            value={formValue.fName}
                          />
                          <Small className="payment-address-err" err={err?.fName} />
                        </div>
                        <div className="col-6">
                          <label>
                            {
                              <>
                               {t('field.lName')}
                                <small className="small-err">*</small>
                              </>
                            }
                          </label>
                          <InputField
                            name="lName"
                            className="book-name "
                            onChange={handleChangeDetailsSection}
                            value={formValue.lName}
                          />
                          <Small className="payment-address-err" err={err?.lName} />

                        </div>
                      </div>
                      <div className="row mt-2">
                        <label htmlFor="email">
                          {t('payment.emailAddress')}
                        </label>
                        <InputField
                          className="book-email"
                          onChange={handleChange}
                          value={formValue.email}
                          disabled={true}
                        />
                      </div>
                      <div className="row">
                        <div className="col-lg-12 mt-2 finace-IntlTelInput-form">
                          <label>
                            {
                              <>
                                {t('field.phoneNumber')}
                                <small className="small-err">*</small>
                              </>
                            }
                          </label>
                          <IntlTelInput
                            key={preferredCountries} 
                            id="phone"
                            name="phoneNumber"
                            preferredCountries={[preferredCountries]} // Use the state variable
                            separateDialCode={true}
                            containerClassName={`intl-tel-input ${err.phoneNumber ? "error-class" : ""
                              }`}
                            onPhoneNumberChange={(isValid, phoneNumber, countryData) => {
                              handlePhoneNumberChange(null, phoneNumber, countryData, "phoneNumber");
                            }}
                            onSelectFlag={(isValid, countryCode, countryData) => {
                              handlePhoneCountryCodeChange(countryCode, countryData, formValue?.countrycode);
                            }}
                            value={(formValue.phoneNumber) ? (formValue.phoneNumber) : ''}

                            // value={formValue.phoneNumber}
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <label>
                          {
                            <>
                              {t('field.address')}
                              <small className="small-err">*</small>
                            </>
                          }
                        </label>
                        <div className="div d-flex justify-content-end">
                          <div className="booking-location-outer-use-my-btn">
                            <div
                              className="location-btn"
                              onClick={handleClickUseMyLocation}
                            >
                              <i className="bi bi-crosshair2"></i>&nbsp;{' '}
                              {t('payment.useMyLocation')}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2 ">
                        <div>
                          <Autocomplete
                            onChange={(e) => handleChangePlace(e)}
                            className="ant-input form-control"
                            apiKey={'AIzaSyAkbbDVfPFTWOL3xgB_GUEJzTXWYK98_gw'}
                            onPlaceSelected={(place) => {
                              handleChangePlace(place, true);
                            }}
                            options={{
                              types: ['geocode'],
                              componentRestrictions: { country: ['th', 'in'] },
                            }}
                            value={formValue.address}
                          />
                          <Small
                            className="payment-address-err"
                            name={'address'}
                            err={err?.address}
                          />
                        </div>
                      </div>
                      {/* <div className="row mt-2 mb-3">
                        <label>{<>{t('field.landmark')}</>}</label>
                        <InputField
                          type="text"
                          className="form-control"
                          placeholder={t('field.landmarkPlace')}
                          name="landmark"
                          value={formValue.landmark}
                          onChange={(e) => onChange('landmark', e.target.value)}
                        />
                      </div> */}
                      <div className="mt-3 mb-2">
                        <SavedAddressComponent handleSavedAddress={handleSavedAddress} />
                      </div>
                      <div className="mt-1">
                        <input
                          type="checkbox"
                          className="form-check-input mt-1 payment-checkbox"
                          name="checkbox"
                          onChange={(e) => onChange('checkbox', e.target.checked)}
                        />
                        <small>&nbsp; &nbsp;{t('field.setPermentAddress')}</small>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 order-md-2">
            <div className="cart-payment-common-outer-div">
              <div className="cart-payment-common-inner-div">
                <div className="payment-details">
                  <div className="cart-payment-heading">
                    <div> {t('payment.paymentDetails')} </div>
                  </div>
                  <div className="cart-payment-content">
                    <p>{t('payment.continueYour')}:</p>
                    <div className="row">
                      <div className="payment-details-heading">
                        {t('payment.selectPayment')}
                      </div>
                    </div>
                    <div>
                      <div className="row mt-3">
                        {apiCalculationData?.online_payment && (
                          <div className="col-lg-6 ">
                            <RadioButton
                              name="paymentMethod"
                              checked={
                                formValue.paymentType === 'online' ? true : false
                              }
                              onChange={handleChange}
                              value={'online'}
                              bgValue={t('payment.online')}
                            />
                          </div>
                        )}
                        {apiCalculationData?.cash_payment && (
                          <div className="col-lg-6 ">
                            <RadioButton
                              name="paymentMethod"
                              checked={
                                formValue.paymentType === 'pay_at_shop'
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              value={'pay_at_shop'}
                              bgValue={t('payment.cashOn')}
                            />
                          </div>
                        )}
                      </div>

                    </div>
                    <Small err={err.paymentType} />
                  </div>
                  <hr className="accordian-hr"></hr>
                  <div>{t('payment.enterDiscount')}</div>
                  <Search
                    className="book-now-search-button mt-1"
                    allowClear
                    enterButton={
                      <button className="book-now-apply-button">
                        {t('payment.apply')}
                      </button>
                    }
                    size="large"
                    onSearch={handleSearch}
                  />
                  <Small name={'refralCode'} err={err.refralCode} />
                  <div className="mt-1 payment-checkbox-term-condition">
                    <input
                      type="checkbox"
                      className="form-check-input mt-1 payment-checkbox"
                      id="6hourseCheckbox"
                      checked={
                        formValue.termAndConditon
                          ? formValue.termAndConditon
                          : false
                      }
                      onChange={() =>
                        handleChange({
                          target: { name: 'termAndConditon', value: true },
                        })
                      }
                    />
                    <small className="payment-term-condition-small-tag">
                      <span className="payment-term-condition-span-tag">
                        {t('payment.iAgree')}
                      </span>
                      <span>
                        <Link
                          className="payment-term-condition"
                          to={HomeRoutes.termCondition}
                          onClick={handleNevigateTermCondition}
                        >
                          {t('payment.byClick')}
                        </Link>
                      </span>

                    </small>
                  </div>
                  <Small err={err.termAndConditon} />
                  <div className="book-now-order mt-2">
                    <Button
                      onClick={(e) => handleSubmit(e, formValue)}
                      disabled={buttonDisable}
                    >
                      {t('payment.placeOrder')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 order-md-3">
            <div className="cart-payment-common-outer-div">
              <div className="cart-payment-common-inner-div">
                <div className="booking-details">
                  <div className="cart-payment-heading">
                    <div> {t('payment.yourBookingDetails')}</div>
                  </div>
                  <div className="cart-payment-content">
                    {serviceDetails &&
                      serviceDetails.length > 0 &&
                      serviceDetails.map((obj, index) => (
                        <div key={index}>
                          <div className="yourbooking-details-section mt-1">
                            <div className="yourbooking-details-section-img-div">
                              <CustomImage
                                className="img-fluid cart-payment-image w-100"
                                src={obj?.shop_image || DefaultShop}
                                alt="sub_content_image_url"
                                width={'unset'}
                                height={'unset'}
                              />
                            </div>
                            <div className="yourbooking-details-section-name-div">
                              <p className="book-now-h5">
                              {`${getOrganizationName(obj)}${( getBranchName(obj)) ? ` (${getBranchName(obj)})` : ''}`}
                              </p>
                              <p>{getServiceName(obj)}</p>
                              <p className="icon">
                                <i className="bi bi-clock-fill"></i>{' '}
                                {obj.duration} {t('paymentSection.min')}
                              </p>
                            </div>
                            <div className="d-flex gap-2">
                              <div className="yourbooking-details-section-date-div">
                                <p className="book-now-p">

                                  {(calendarFormValue?.[obj?.service_id]?.booking_date)}
                                </p>
                                <p className="book-now-p">
                                  {formatTime(calendarFormValue?.[obj?.service_id]?.booking_time)}
                                </p>
                              </div>
                              <div className="checkout-date-edit">
                                <i
                                  onClick={() => openCalendarModal(obj)}
                                  className={"bi bi-pencil-fill"}
                                  aria-hidden="true"
                                ></i>
                                {isCalendarModal && <>
                                  <CheckoutCalendarModalLogic
                                    count={count}
                                    calendarFormValue={calendarFormValue}
                                    setCalendarFormValue={setCalendarFormValue}
                                    isModalVisible={isCalendarModal}
                                    handleCancel={closeCalendarModal}
                                    selectServiceDetails={selectServiceDetails}
                                    setApiCalculationData={setApiCalculationData}
                                  />
                                </>}
                              </div>
                            </div>

                          </div>
                          <hr />
                        </div>
                      ))}
                    <div className="row">
                      <div className="book-div3-price">
                        <p className="cart-payment-price-label">
                          {t('payment.fullPrice')}
                        </p>

                        <CurrencyDisplay
                          value={apiCalculationData?.total_price}
                        />
                      </div>
                      <div className="book-div3-price mt-1">
                        <p className="cart-payment-price-label">
                          {t('payment.suraDiscount')}
                        </p>
                        <CurrencyDisplay
                          value={apiCalculationData?.total_discount_amount}
                        />
                      </div>
                      <div className="book-div3-price mt-1">
                        <p className="cart-payment-price-label">
                          {t('venderBooking.peakDiscount')}
                        </p>
                        <CurrencyDisplay
                          value={apiCalculationData?.total_peak_discount}
                        />
                      </div>
                      <p className="book-div3-text mt-1 mb-1">
                        <CustomCheckbox
                          disabled={
                            suraCashback >
                            apiCalculationData?.total_paid_amount ||
                            suraCashback === 0
                          }
                          onClick={onCheckUserCashback}
                          className={'cashback-checkbox'}
                        />
                        &nbsp;&nbsp;&nbsp; {t('payment.useCashback')} ฿
                        {suraCashback || 0} {t('payment.perCheckout')}(Available
                        {currencyIcon}{' '}
                        {userCashBackDetails?.cashback?.available_balance || 0})
                      </p>
                      <div>
                        <hr></hr>

                      </div>
                      <div className="book-div3-price">
                        <div>
                          <p className="fw-bold cart-payment-price-label">
                            {t('payment.finalPrice')} <br />
                            <small className="book-div3-small">
                              {t('payment.includeVat')}
                            </small>
                          </p>
                        </div>
                        <CurrencyDisplay
                          value={
                            discountPrice
                              ? discountPrice
                              : apiCalculationData?.total_paid_amount
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <>
          <CustomSpinner />
        </>
      )}
      {(showModal) && <>
        <PermissionDenied showModal={showModal} handleClose={handleClosePermissionModal} />
      </>}
    </>
  );
};
export default CartPayment;
