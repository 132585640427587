import { Checkbox } from "antd";
import { CustomSpinner, InputField, RadioButton } from "../../common";
import { Accordion, Locale } from "../../libraries/index";
import { useEffect, useState } from "react";
import { AddressJson, IndiaCities } from "../../jsonData";
import InfiniteScroll from "react-infinite-scroll-component";

export const SidebarFilter = ({
  handleCategoryCheckboxChange,
  handleFeaturedServicesChange,
  handleSortChange,
  handleAllSubcategoriesCheck,
  sortBy,
  allData,
  handleCityCheckboxChange,
  selectedCity,
  searchTerm,
  setSearchTerm,
  defaultOpenAccordian,
  defaultOpenAccordianA,
  serviceType,
  subCategoryId,
  categoryId,
  sortOptions,
  selectedStateCount,
}) => {
  const { t } = Locale();
  const isCurrentCountryThailand = sessionStorage.getItem('country') === 'Thailand'
  const [displayedCities, setDisplayedCities] = useState([]);
  const [areaSearch, setAreaSearch] = useState('');
  const [hasMore, setHasMore] = useState(true); // To handle infinite scrolling
  const [totalCities] = useState(isCurrentCountryThailand ? AddressJson : IndiaCities); // All cities data
  const [visibleCitiesCount, setVisibleCitiesCount] = useState(20); // Number of cities to show at once
  const [filteredCities, setFilteredCities] = useState(totalCities);
  const selectedLanguage = localStorage.getItem("i18nextLng");
  const [isAccordianOpen, setIsAccordianOpen] = useState(false);

  const serviceTypeAscending =
    serviceType.length > 0 &&
    serviceType.sort((a, b) => {
      return a?.category?.name?.localeCompare(b?.category?.name);
    });

  // Search filter function
  const filterCities = (searchTerm) => {
    const filtered = isCurrentCountryThailand ? totalCities.filter((city) =>
      city?.districtNameTh.toLowerCase().includes(searchTerm.toLowerCase())
    ) : totalCities.filter((city) =>
      city.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredCities(filtered);
    setHasMore(filtered.length > visibleCitiesCount); // Update pagination status
  };

  // Infinite scrolling fetch function

  const fetchMoreCities = () => {
    const nextCities = filteredCities.slice(0, visibleCitiesCount + 20);
    setDisplayedCities(nextCities); // Update the displayed cities with the new batch
    setVisibleCitiesCount((prevCount) => prevCount + 20); // Load 20 more cities
    if (nextCities.length >= filteredCities.length) {
      setHasMore(false); // Disable scrolling if all cities are loaded
    }
  };


  useEffect(() => {
    // Update displayed cities based on search term and pagination
    filterCities(areaSearch);
  }, [areaSearch]);

  useEffect(() => {
    // Set initial filtered cities on first load
    filterCities(areaSearch);
  }, [totalCities]);


  return (
    <div className={`container-fluid display-service-sidebar`}>
      <div className="row filter-header">
        <h5 className="mt-2 display-service-sidebar-row1">
          {t("field.filters")}
        </h5>
      </div>
      <div className="filter-content">
        <Checkbox
          className="checkbox-button showFeaturedServiceTag"
          onChange={(e) => handleFeaturedServicesChange(e.target.checked)}
        >
          {t("header.showFeaturedService")}
        </Checkbox>
        <div className="row">
          <div className="container display-service-sidebar-container1">
            <div className="row mt-2">
              <p className="showFeaturedService">{t("header.sortBy")}</p>
            </div>
            <div className="sort-filter">
              {sortOptions.map((option, index) => (
                <div className="row" key={index}>
                  <RadioButton
                    className="checkbox-button"
                    onChange={() =>
                      handleSortChange({ [option.value]: option.order })
                    }
                    checked={sortBy[option.value] === option.order}
                    bgValue={`${t(option.label)}`}
                    name="sortOption"
                  />
                </div>
              ))}
            </div>
          </div>
          <div>
            <hr className="mt-3" />
          </div>
          <div className="row mt-2 mb-3 container-fluid">
            <p className="mt-2 showFeaturedService">
              {t("header.area")} ({selectedStateCount})
            </p>
            <InputField
              placeholder={`${t("header.search")} ${t("header.area")} ${t("header.here")}`}
              value={areaSearch}
              onChange={(e) => setAreaSearch(e.target.value)}
            />

            <div id="scrollableDiv" className="display-service-sidebar-container2">
              <InfiniteScroll
                dataLength={displayedCities.length}
                next={fetchMoreCities} // Function to load more data
                hasMore={hasMore}
                loader={<div><CustomSpinner /></div>} // Loader component
                scrollThreshold={0.9} // Trigger next fetch when 50% of the scroll is done
                scrollableTarget="scrollableDiv" // Make sure this matches the div ID above
              >
                {filteredCities
                  .slice(0, visibleCitiesCount) // Show a limited number of cities
                  .map((city, index) => (
                    <div key={index} className="display-service-sidebar-container2-row mt-2">
                      {isCurrentCountryThailand ? <>
                        <Checkbox
                          className="checkbox-button"
                          onChange={() => {
                            handleCityCheckboxChange(city?.districtNameEn);
                          }}
                          isChecked={selectedCity.includes(city)}
                        >
                          {selectedLanguage === 'thai' ? city?.districtNameTh : city?.districtNameEn}
                        </Checkbox>
                      </> : <>
                        <Checkbox
                          className="checkbox-button"
                          onChange={() => {
                            handleCityCheckboxChange(city);
                          }}
                          isChecked={selectedCity.includes(city)}
                        >
                          {city}
                        </Checkbox>
                      </>}
                    </div>
                  ))}
              </InfiniteScroll>
            </div>
          </div>
          <div>
            <hr />

          </div>
          <div className="container-fluid display-service-sidebar-container3Outer">
            <p className="mt-2 showFeaturedService">
              {t("header.serviceType")} (
              {allData.length > 0
                ? allData.length
                : subCategoryId
                  ? 1
                  : categoryId
                    ? categoryId.length
                    : 0}
              )
            </p>
            <div className="mb-2">
              <InputField
                placeholder={`${t("header.search")} ${t(
                  "header.serviceType"
                )} ${t("header.here")}`}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  defaultOpenAccordian();
                }}
              />
            </div>
            <div className="display-service-filter-service-outer container-fluid  display-service-sidebar-container2">
              <div className="row">
                <Accordion
                  onClick={() => setIsAccordianOpen(!isAccordianOpen)}
                  defaultActiveKey={defaultOpenAccordianA?.map((a) =>
                    a.toString()
                  )}
                >
                  {serviceTypeAscending.length > 0 &&
                    serviceTypeAscending
                      ?.filter((item) => {
                        let status = false;
                        item?.subcategory?.some((sub) => {
                          if (
                            sub?.name_en
                              ?.toLowerCase()
                              ?.includes(searchTerm.toLowerCase())
                          ) {
                            status = true;
                            return true; // Return true when the condition is met
                          }
                          return false; // Ensure that false is returned when the condition is not met
                        });
                        return status;
                      })
                      ?.map((category, index) => {
                        return (
                          <Accordion.Item
                            key={category.category.id}
                            eventKey={index.toString()}
                            className="accordion-header"
                          >
                            <Accordion.Header className="accordion-header-icon mt-0 m-0 p-0">
                              <Checkbox
                                className="checkbox-button"
                                onChange={(e) =>
                                  handleAllSubcategoriesCheck(
                                    e,
                                    category.category.id,
                                    selectedLanguage === "thai" &&
                                      selectedLanguage !== undefined &&
                                      selectedLanguage !== null
                                      ? category.category.name_thai
                                      : category.category.name_en
                                  )
                                }
                                checked={category.subcategory.every((sub) =>
                                  categoryId?.includes(sub.id)
                                )}
                              >
                                {selectedLanguage === "thai" &&
                                  selectedLanguage !== undefined &&
                                  selectedLanguage !== null ? (
                                  <> {category.category.name_thai}</>
                                ) : (
                                  <>{category.category.name_en}</>
                                )}
                              </Checkbox>
                            </Accordion.Header>
                            <Accordion.Body className="accordion-body mt-0 mb-0">
                              {category.subcategory
                                .sort((a, b) =>
                                  a.name_en.localeCompare(b.name_en)
                                )
                                .map((subCategory) => (
                                  <div key={subCategory.id}>
                                    <Checkbox
                                      className="mt-0 checkbox-button"
                                      onChange={() =>
                                        handleCategoryCheckboxChange(
                                          subCategory.id
                                        )
                                      }
                                      checked={
                                        subCategoryId === subCategory.id
                                          ? true
                                          : categoryId?.includes(subCategory.id)
                                      }
                                    >
                                      {selectedLanguage === "thai" &&
                                        selectedLanguage !== undefined &&
                                        selectedLanguage !== null ? (
                                        <>{subCategory.name_thai} </>
                                      ) : (
                                        <> {subCategory.name_en}</>
                                      )}
                                    </Checkbox>
                                    <br />
                                  </div>
                                ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                </Accordion>
              </div>
            </div>
            <hr
              className={`hr-transition ${isAccordianOpen &&
                "sidebar-filter-hr-tag"}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
