import { apiCall } from "../base";

const createBillingInformation = async (params) => {
    const response = await apiCall(
        'business/billing_informations',
        'POST',
        params,
    );
    return response;
};

const updateBillingInformation = async (params) => {
    const response = await apiCall(
        'business/billing_informations',
        'PUT',
        params,
    );
    return response;
};

const fetchBillingInformation = async () => {
    const response = await apiCall('business/billing_informations', 'GET');
    return response;
};

export { createBillingInformation, updateBillingInformation, fetchBillingInformation }