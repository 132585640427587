import { apiCall } from '../base.js';

const getAllCategory = async () => {
    const response = await apiCall('customer/categories', 'GET');
    return response;
};

const getAllSubCategory = async (params) => {
    const response = await apiCall(`customer/sub_categories/${params}`, 'GET');
    return response;
};

const getSearchService = async (params) => {
    const queryString = new URLSearchParams(params).toString();
    const response = await apiCall(`customer/search?${queryString}`, 'GET');
    return response;
};

const getSubCategory = async (params) => {
    const response = await apiCall(`customer/sub_categories/${params}`, 'GET');
    return response;
};

const getBestSellerSubCategory = async () => {
    const response = await apiCall('customer/categories/details', 'GET');
    return response;
};

const getSubCategoryService = async (params) => {
    const queryString = new URLSearchParams(params).toString();
    const response = await apiCall(`customer/sub_category/services?${queryString}`, 'GET');
    return response;
};

const getViewAllDetails = async (params) => {
    const response = await apiCall(`customer/organization/${params}`, 'GET');
    return response;
};

const serviceIdVendorDetails = async (params) => {
    const response = await apiCall(`customer/service/${params}`, 'GET');
    return response;
}
const fetchRelatedServiceData = async (params) => {
    const response = await apiCall(`customer/service/${params?.serviceId}/related_services?page_number=${params?.page_number}&per_page=${params?.per_page}`, 'GET');
    return response;
}
const shopNearMe = async (currentLocation) => {
    const response = await apiCall(
        `customer/near_shops?latitude=${currentLocation.latitude}&longitude=${currentLocation.longitude}`,
        'GET',
    );
    return response;
};

export {
    getAllCategory,
    getAllSubCategory,
    getBestSellerSubCategory,
    getSubCategory,
    getSubCategoryService,
    getSearchService,
    getViewAllDetails,
    serviceIdVendorDetails,
    shopNearMe,
    fetchRelatedServiceData
};