import './Style.css';
import '../SignIn/Style.css'
import "react-intl-tel-input/dist/main.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { React, PropTypes, Locale, ReCAPTCHA, useLocation, Select, Link, IntlTelInput } from '../../libraries';
import { Label, InputField, PasswordField, CustomLink, CustomButton, CustomSpan, CustomSpinner, AddressSelectLogic } from '../../common';
import { SuraSign } from '../../assets/Images';
import { CustomerRoutes, AuthRoutes, BusinessRoutes, HomeRoutes } from '../../routes/index.js';

const React_App_Recaptcha = process.env.REACT_APP_RECAPTCHA;
const SignUpForm = ({
  isLoader,
  suraInfo,
  onChange,
  filterOption,
  formValues,
  formErrors,
  setFormErrors,
  handleChange,
  handleSubmit,
  setFormValues,
  setFormType,
  isContact,
  setIsContact,
  subDistrictOption,
  buttonClicked,
  breadcrumbCheck,
  setBreadcrumbCheck,
  breadcrumbCheckContact,
  setBreadcrumbCheckContact,
  addressFormValue,
  setAddressFormValue,
  handlePhoneCountryCodeChange
}) => {
  const { t } = Locale();
  const location = useLocation();
  const currentPath = location.pathname;
  const isUser = currentPath.includes(CustomerRoutes.user);
  const isVendor = currentPath.includes(BusinessRoutes.vendor);


  // ------------------------------------------- new updation code in default country--------------

  const isUserCondition = () => {

    return (
      <>
        <form className="login-form" onSubmit={handleSubmit} method="POST">
          <div className="row">
            <div className="col-lg-6 col-md-12 mt-2">
              <Label
                text={
                  <>
                    {t("field.fname")}
                    <small className="input-field-err">*</small>
                  </>
                }
              />
              <InputField
                id="fname"
                name="name"
                placeholder={`${t("field.placeholder")} ${t("field.fname")}`}
                type=""
                className="form-control "
                value={formValues.name}
                onChange={handleChange}
                error={formErrors.name}
              />
            </div>
            <div className="col-lg-6 col-md-12 mt-2">
              <Label
                text={
                  <>
                    {t("field.lName")}
                    <small className="input-field-err">*</small>
                  </>
                }
              />
              <InputField
                id="lname"
                name="lastName"
                placeholder={`${t("field.placeholder")} ${t("field.lName")}`}
                type=""
                className="form-control "
                value={formValues.lastName}
                onChange={handleChange}
                error={formErrors.lastName}
              />
            </div>
          </div>
          <div className="mt-2">
            <Label
              text={
                <>
                  {t("field.semail")}
                  <small className="input-field-err">*</small>
                </>
              }
            />
            <InputField
              name="email"
              placeholder={`${t("field.placeholder")} ${t("field.semail")}`}
              type=""
              className="form-control "
              value={formValues.email}
              onChange={handleChange}
              error={formErrors.email}
              errcss={"none"}
            />
          </div>
          <div className="row">
            <div className="mt-2 col-6">
              <Label
                text={
                  <>
                    {t("field.cpassword")}
                    <small className="input-field-err">*</small>
                  </>
                }
              />
              <PasswordField
                id="password"
                name="password"
                placeholder={`${t("field.placeholder")} ${t("field.password")}`}
                className="password-field "
                value={formValues.password}
                onChange={handleChange}
                error={formErrors.upassword}
              />
            </div>
            <div className="mt-2 col-6">
              <Label
                text={
                  <>
                    {t("field.confirmPassword")}
                    <small className="input-field-err">*</small>
                  </>
                }
              />
              <PasswordField
                id="cpassword"
                name="cpassword"
                placeholder={`${t("field.placeholder")} ${t(
                  "field.confirmPassword"
                )}`}
                className="password-field "
                value={formValues.cpassword}
                type="password"
                onChange={handleChange}
                error={formErrors.cpassword}
              />
            </div>
          </div>

          <div className="signup-user-text-area mt-2">
            <Label text={t("signMessage.knowAbout")} />
            <Select
              className="signUp-customeSelect-ant"
              showSearch
              placeholder={`${t("header.select")} ${t(
                "signMessage.commentHere"
              )}.....`}
              optionFilterProp="children"
              onChange={(value) => onChange("cinfo", value)}
              filterOption={filterOption}
              options={suraInfo}
              value={formValues.cinfo || undefined}
              error={formErrors.cinfo}
            ></Select>
          </div>
          <div className="term-condition mt-3">
            <input
              type="checkbox"
              className="form-check-input"
              name="agree"
              id="myCheckbox"
              onChange={handleChange}
            />
            <CustomLink
              target="_blank"
              path={HomeRoutes.privacy}
              text={t('signMessage.termCondition')}
              className="mx-1"
            ></CustomLink>
          </div>
          <div>
            <small className="input-field-err">
              {formErrors.agree ? formErrors.agree : " "}
            </small>
          </div>
          <div className="mt-4 recaptcha">
            <ReCAPTCHA
              sitekey={`${React_App_Recaptcha}`}
              value={formValues.capture}
              name="capture"
              onChange={() => {
                setFormValues((prevValues) => ({
                  ...prevValues,
                  capture: "true",
                }));
              }}
              error={
                formErrors.capture && (
                  <p className="input-field-err">{formErrors.capture}</p>
                )
              }
            />
          </div>

          <div>
            <small className="input-field-err">
              {formErrors.capture ? formErrors.capture : false}
            </small>
          </div>
          <div className="sign-up-user-sign-btn-div">
            <CustomButton
              color="white"
              onClick={() => setFormType("business-information")}
              text={t("header.signup")}
              buttonClicked={buttonClicked}
            ></CustomButton>
          </div>
          <div className="text-center account mt-2 mb-1">
            <CustomSpan text={t("signMessage.alreadyAccount")} />
            <CustomLink
              className="mx-1"
              text={t('header.signin')}
              path={AuthRoutes.signIn}
              color="#EE0A89"
            ></CustomLink>
          </div>
        </form>
      </>
    );
  };

  const isValidationBussines = (isContact) => {
    if (isContact) {
      return (
        <>
          <form className="login-form" method="POST" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6 col-md-12 mt-2">
                <Label
                  text={
                    <>
                      {t("field.fname")}
                      <small className="input-field-err">*</small>
                    </>
                  }
                />
                <InputField
                  id={"name"}
                  name="name"
                  placeholder={`${t("field.placeholder")} ${t("field.fname")}`}
                  type="text"
                  className="form-control "
                  value={formValues.name}
                  onChange={handleChange}
                  error={formErrors.name}
                />
              </div>
              <div className="col-lg-6 col-md-12 mt-2">
                <Label
                  text={
                    <>
                      {t("field.lName")}
                      <small className="input-field-err">*</small>
                    </>
                  }
                />
                <InputField
                  id={"last_name"}
                  name="lastName"
                  placeholder={`${t("field.placeholder")} ${t("field.lName")}`}
                  type="text"
                  className="form-control "
                  value={formValues.lastName}
                  onChange={handleChange}
                  error={formErrors.lastName}
                />
              </div>
            </div>
            <div className="mt-2">
              <Label
                text={
                  <>
                    {t("field.semail")}
                    <small className="input-field-err">*</small>
                  </>
                }
              />
              <InputField
                id="vemail"
                name="vemail"
                placeholder={`${t("field.placeholder")} ${t("field.semail")}`}
                type="email"
                className="form-control "
                value={formValues.vemail}
                onChange={handleChange}
                error={formErrors.vemail}
                errcss={"none"}
              />
            </div>

            <div className="mt-2">
              <Label
                text={
                  <>
                    {t("field.cpassword")}
                    <small className="input-field-err">*</small>
                  </>
                }
              />
              <PasswordField
                id="vpassword"
                name="vpassword"
                placeholder={`${t("field.placeholder")} ${t("field.password")}`}
                className="password-field "
                value={formValues.vpassword}
                onChange={handleChange}
                error={formErrors.vpassword}
              />
            </div>
            <div className="mt-2 mb-1">
              <Label
                text={
                  <>
                    {t("field.confirmPassword")}
                    <small className="input-field-err">*</small>
                  </>
                }
              />
              <PasswordField
                id="cpassword"
                name="cpassword"
                placeholder={`${t("field.placeholder")} ${t(
                  "field.confirmPassword"
                )}`}
                className="password-field "
                value={formValues.cpassword}
                onChange={handleChange}
                error={formErrors.cpassword}
              />
            </div>
            <div className="term-condition mt-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="myCheckbox"
                name="agree"
                onChange={handleChange}
              />
              <CustomLink
                target="_blank"
                path={HomeRoutes.termCondition}
                text={t('signMessage.termCondition')}
                className="mx-1"
              ></CustomLink>
              <div>
                <small className="input-field-err">
                  {formErrors.agree ? formErrors.agree : " "}
                </small>
              </div>
            </div>
            <div className="sign-up-vendor-sign-btn-div">
              <CustomButton
                color="white"
                onClick={() => setFormType("business-information")}
                text={t("header.signup")}
                buttonClicked={buttonClicked}
              ></CustomButton>
            </div>
            <div className="text-center account mt-2">
              {t("signMessage.alreadyAccount")}
              <CustomLink
                className="mx-1"
                text={t('header.signin')}
                path={AuthRoutes.signIn}
                color="#EE0A89"
              ></CustomLink>
            </div>
          </form>
        </>
      );
    } else if (isContact === false) {
      return (
        <>
          {isVendor && (
            <>
              <div className="mt-1">
                <Label
                  text={
                    <>
                      {t("field.venueName")}
                      <small className="input-field-err">*</small>
                    </>
                  }
                />
                <InputField
                  id="venuename"
                  name="venuename"
                  placeholder={`${t("field.placeholder")} ${t(
                    "field.venueName"
                  )}`}
                  type="text"
                  className="form-control "
                  value={formValues.venuename}
                  onChange={handleChange}
                  error={formErrors.venuename}
                />
              </div>
            </>
          )}
          <form className="login-form" method="POST" onSubmit={handleSubmit}>
            <div className="mt-2">
              <Label
                text={
                  <>
                    {t("field.semail")}
                    <small className="input-field-err">*</small>
                  </>
                }
              />
              <InputField
                id="cemail"
                name="cemail"
                placeholder={`${t("field.placeholder")} ${t("field.semail")}`}
                type=""
                className="form-control "
                value={formValues.cemail}
                onChange={handleChange}
                error={formErrors.cemail}
                errcss={"none"}
              />
            </div>
            <div className="row">
              <Label
                className='mt-2'
                text={
                  <>
                    {t("field.phoneNumber")}
                    <small className="input-field-err">*</small>
                  </>
                }
              />
              <div className="col-lg-12 mt-1">
                <div className="col-lg-12">
                  <IntlTelInput
                    id="phone"
                    name="phone"
                    preferredCountries={[
                      sessionStorage.getItem("country") === "India"
                        ? "in"
                        : "th",
                    ]}
                    separateDialCode={true}
                    containerClassName={`intl-tel-input ${formErrors.phone ? "error-class" : ""
                      }`}
                    onPhoneNumberChange={(
                      isValid,
                      phoneNumber,
                      countryData
                    ) => {
                      onChange(null, phoneNumber, countryData, "phoneNumber");
                    }}
                    onSelectFlag={(isValid, countryCode, countryData) => {
                      handlePhoneCountryCodeChange(countryCode, countryData, formValues?.ccountrycode);
                    }}
                    value={formValues?.phoneNumber}
                  />
                  {formErrors.phone && (
                    <small className="input-field-err">{formErrors.phone}</small>
                  )}

                </div>
              </div>
            </div>
            <AddressSelectLogic
              addressFormValue={addressFormValue}
              setAddressFormValue={setAddressFormValue}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <div className="mt-2">
              <Label
                text={
                  <>
                    {t("field.address")}
                    <small className="input-field-err">*</small>
                  </>
                }
              />
              <InputField
                id="address"
                name="address"
                placeholder={`${t("field.placeholder")} ${t("field.address")}`}
                type="text"
                className="form-control "
                value={formValues.address}
                onChange={handleChange}
                error={formErrors.address}
              />
            </div>
            <div className="mt-2">
              <Label text={t("signMessage.knowAbout")} />
              <Select
                className="signUp-customeSelect-ant"
                showSearch
                placeholder={`${t("header.select")} ${t(
                  "signMessage.commentHere"
                )}.....`}
                optionFilterProp="children"
                onChange={(value) => onChange("cinfo", value)}
                filterOption={filterOption}
                options={suraInfo}
                value={formValues.cinfo || undefined}
                error={formErrors.cinfo}
              ></Select>
            </div>
            <div className="sign-up-next-btn-div">
              <button
                className="btn"
                onClick={() => setFormType("contact-information")}
              >
                {t("header.nextButton")}
              </button>
            </div>
            <div className="navigate-signup mt-2">
              {t("signMessage.alreadyAccount")}
              <CustomLink
                text={t('header.signin')}
                path={AuthRoutes.signIn}

                color="#EE0A89"
                className="mx-1"
              ></CustomLink>
            </div>
          </form>
        </>
      );
    }
  };

  return (
    <>
      <div className={` div-container ${isLoader && "processBlur"}`}>
        <div className="inner-div container sign-in-final">
          <div className="row  mt-5">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className=" d-md-block bg-image text-center sign-in-img-div">
                <img
                  src={SuraSign}
                  alt="SignIn"
                  className="w-85 p-2 img-fluid signIn-image"
                />
                <div className="signUp-home-arrow-div">
                  <i className="bi bi-arrow-left-short"></i>&nbsp;
                  <Link to="/" className="back-to-home">
                    Back to Home
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="fdfdfdf signup-form-container">
                <h2 className="signup-form-heading mt-3">
                  {t("signMessage.welcome")}
                </h2>
                <h6 className="signup-form-heading2">
                  {t("signMessage.registerAccount")}
                </h6>

                {!isUser && (
                  <>
                    <div className="vendor-breadcrumbs-heading">
                      <div
                        className={`bussinessInfo-text ${breadcrumbCheck
                          ? "active bussinessInfo-text-info"
                          : ""
                          }`}
                      >
                        <span
                          className={`confirm-number ${breadcrumbCheck
                            ? "active bussinessInfo-text-info"
                            : ""
                            }`}
                        >
                          {breadcrumbCheck ? "✓" : "1"}
                        </span>
                        <span
                          className={`${breadcrumbCheck
                            ? "bussinessInfo-clickable"
                            : "bussinessInfo-text-info"
                            }`}
                          onClick={() => {
                            setIsContact(false);
                            setBreadcrumbCheck(false);
                            setBreadcrumbCheckContact(false);
                          }}
                        >
                          {t("signMessage.bussinessInfo")}
                        </span>
                      </div>
                      <div className="bussinessInfo"></div>
                      <div
                        className={`bussinessInfo-text ${breadcrumbCheckContact
                          ? "active bussinessInfo-text-info"
                          : breadcrumbCheck
                            ? ""
                            : "disable"
                          }`}
                      >
                        <span
                          className={`confirm-number ${breadcrumbCheckContact
                            ? "active bussinessInfo-text-info"
                            : breadcrumbCheck
                              ? ""
                              : "disable"
                            }`}
                        >
                          {breadcrumbCheckContact ? "✓" : "2"}
                        </span>
                        <span className="bussinessInfo-text-info">
                          {t("signMessage.personInfo")}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="signup-formfield">
                  {isUser && isUserCondition()}
                  {!isUser && isValidationBussines()}
                  {!isUser && isValidationBussines(isContact)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoader && (
        <>
          <CustomSpinner />
        </>
      )}
    </>
  );
};
SignUpForm.propTypes = {
  onChange: PropTypes.func,
  filterOption: PropTypes.func,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  setFormValues: PropTypes.func,
  setFormType: PropTypes.func,
  isContact: PropTypes.bool,
  setIsContact: PropTypes.func,
  subDistrictOption: PropTypes.array,
};
export default SignUpForm;