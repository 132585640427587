import './BussinessPolicyPage.css';
import { React, useEffect, useState } from '../../libraries';
import { CustomSpinner, Footer } from '../../common';
import { NavbarLogic } from '../../components';
import { BreadCrumb } from '../BreadCrumb/BreadCrumb.js';
import { CustomImage } from '../../lazyComponent/index.js';
import { MetaTag } from "../../customHooks/index.js";
const BussinessPolicyPage = ({ data, BradCrumbData, isLoading }) => {
  const selectedLanguage = localStorage.getItem('i18nextLng');
  const [privacy, setPrivacy] = useState(0);
  useEffect(() => { }, [selectedLanguage, privacy]);
  const removeHtmlTags = (input) => {
    const doc = new DOMParser().parseFromString(
      `<div charset="windows-874">${input}</div>`,
      "text/html"
    );
    const lines = (doc.body.textContent || "").split(/\r?\n/); // Split text into lines
    const resultLines = [];

    lines.forEach((line) => {
      if (line !== undefined && line !== null) {
        if (/\d/.test(line)) {
          resultLines.push("<br><br>"); // Add line break if line contains a number
        }
        resultLines.push(line);
      }
    });

    return resultLines.join("\n"); // Join lines back into a single string
  };

  const getBusinessPartnerPolicyTitle = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? data?.business_partner_policy?.title_in_thai
      : data?.business_partner_policy?.title;
  };

  const getBusinessPartnerPolicyDescitpion = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? (data?.business_partner_policy?.description_in_thai)
      : data?.business_partner_policy?.description;
  };

  const getSubContentSubHeading = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? removeHtmlTags(data?.sub_content?.sub_heading_in_thai)
      : data?.sub_content?.sub_heading;
  };

  const getSubContentDesc = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? (data?.sub_content?.description_in_thai)
      : data?.sub_content?.description;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaTag
        custom_title={data?.business_partner_policy?.title}
        custom_description={data?.business_partner_policy?.description}
      />

      <NavbarLogic setPrivacy={setPrivacy} />
      <div className="container about-us-outer-container">
        <BreadCrumb data={BradCrumbData} />
        {!(isLoading) ? <>
          <div className="aboutus-img-div">
            <div className="row">
              {data?.business_partner_image_url && (
                <CustomImage
                  src={data?.business_partner_image_url}
                  className="img-fluid"
                  alt="business_partner_image"
                  width={"unset"}
                  height={"unset"}
                />
              )}
              <div
                className={`${data?.business_partner_image_url
                  ? "aboutus-title text-center"
                  : "text-center title-business"
                  }`}
              >
                {data && getBusinessPartnerPolicyTitle(data)}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <>
              <p
                className="description_in_thai"
                dangerouslySetInnerHTML={{
                  __html: data && getBusinessPartnerPolicyDescitpion(data),
                }}
              ></p>
            </>
          </div>
          {data?.business_sub_content_data?.map((data, index) => (
            <div key={index} className="row mt-5 mb-5">
              {data?.sub_content_image_url && (
                <>
                  <div
                    className={`col-md-6 ${index % 2 === 0 ? "" : "order-md-2"}`}
                  >
                    <CustomImage
                      src={data?.sub_content_image_url}
                      className="img-fluid"
                      alt="sub_content_image_url"
                      width={"unset"}
                      height={"unset"}
                    />
                  </div>
                  <div
                    className={`col-md-6 ${index % 2 === 0 ? "" : "order-md-1"}`}
                  >
                    <div>
                      <p className="sub-heading-content">
                        {getSubContentSubHeading(data)}
                      </p>
                      <p
                        className="description_in_thai"
                        dangerouslySetInnerHTML={{
                          __html: getSubContentDesc(data),
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              {!data?.sub_content_image_url && (
                <div className="col-md-12">
                  <div>
                    <p className="sub-heading-content">
                      {getSubContentSubHeading(data)}
                    </p>
                    <p
                      className="description_in_thai"
                      dangerouslySetInnerHTML={{
                        __html: getSubContentDesc(data),
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </> : <>
          <CustomSpinner />
        </>}
      </div>
      <div className={`${(isLoading) ? 'loader-blog-section' : ''}`}>
        <Footer />
      </div>
    </>
  );
};
export default BussinessPolicyPage;