import { useEffect, axios } from '../libraries'
const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;

export const useGeolocation = () => {
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          sessionStorage.setItem('location', JSON.stringify(userLocation));
          fetchCountryFromLocation(userLocation.latitude, userLocation.longitude);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            fetchIpBasedLocation();
          } else {
            console.log("Error fetching location:", error.message);
          }
        }
      );

    }
  }, []);

};

export const fetchIpBasedLocation = async () => {
  try {
    const latLong = JSON.parse(sessionStorage.getItem('location'));

    // If latitude and longitude are available in sessionStorage, use it
    if (latLong) {
      await fetchCountryFromLocation(latLong.latitude, latLong.longitude);
    } else {
      const apiKey = googleMapApiKey;

      // Fetch location using the Google Maps API
      const response = await fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      });

      const data = await response.json();

      // Check if the location data is available and fetch country
      if (data.location) {
        await fetchCountryFromLocation(data.location.lat, data.location.lng);
      } else {
        console.error("Location data unavailable:", data);
      }
    }
  } catch (error) {
    console.log("Error fetching location:", error);
  }
};


export const getGeoAddress = async (lat, lng) => {
  let removeCommaGoogleMapApiKey = googleMapApiKey.replace(/"/g, '');
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${removeCommaGoogleMapApiKey}`;
  try {
    const response = await axios.get(url);
    const results = response.data.results;
    let location = {};

    if (results.length > 0) {
      location.address = results[0].formatted_address;

      const addressComponents = results[0].address_components;
      const landmark = addressComponents.find(
        (component) =>
          component.types.includes('point_of_interest') ||
          component.types.includes('establishment'),
      );
      const city = addressComponents.find((component) =>
        component.types.includes('locality'),
      );
      const postcode = addressComponents.find((component) =>
        component.types.includes('postal_code'),
      );
      location.landmark = landmark ? landmark.long_name : '';
      location.city = city ? city.long_name : '';
      location.postcode = postcode ? postcode.long_name : '';
    } else {
      location.address = '';
      location.landmark = '';
      location.city = '';
      location.postcode = '';
    }
    return location;
  } catch (error) {
    console.error('Error fetching geocode data: ', error);
    return null;
  }
};

export const getCityName = async (latitude, longitude) => {
  let removeCommaGoogleMapApiKey = googleMapApiKey.replace(/"/g, '');

  const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${removeCommaGoogleMapApiKey}`;

  const response = await fetch(geocodeUrl);
  const data = await response.json();



  if (data.status === "OK") {
    const results = data.results[0];
    const addressComponents = results.address_components;

    // Find the city in the address components
    const city = addressComponents.find(component =>
      component.types.includes('locality')
    )?.long_name || '';

    return { city };
  } else {
    throw new Error("Geocoding failed");
  }
};

export const getLatLngByAddress = async (address) => {
  try {
    let removeCommaGoogleMapApiKey = googleMapApiKey.replace(/"/g, '');
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${removeCommaGoogleMapApiKey}`;
    const response = await axios.get(apiUrl);
    if (response.data.results && response.data.results.length > 0) {
      const lat = response.data.results[0].geometry.location.lat;
      const lng = response.data.results[0].geometry.location.lng;
      return { lat, lng }; // Return an object with lat and lng
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const fetchCountryFromLocation = async (latitude, longitude) => {
  try {
    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
    const data = await response.json();
    if (data && data.address && data.address.country) {
      sessionStorage.setItem('country', data.address.country);
    } else {
      console.error('Could not retrieve country from the API.');
      return null;
    }
  }
  catch (errr) {
    console.error('errrr----------------:', errr)
  }
};

export const fetchUserCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const organizationCurrentLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          sessionStorage.setItem('location', JSON.stringify(organizationCurrentLocation));
          fetchCountryFromLocation(position.coords.latitude, position.coords.longitude);
          resolve(organizationCurrentLocation);
        },
        (error) => {
          reject(error.message || "Failed to get location");
        }
      );
    } else {
      reject("Geolocation is not supported by this browser.");
    }
  });
};

export const getLatLongFromCity = async (cityName) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(cityName)}&key=${googleMapApiKey}`;
  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.status === "OK") {
      const location = data.results[0].geometry.location;
      const organizationCurrentLocation = {
        latitude: location.lat,
        longitude: location.lng,
      };
      sessionStorage.setItem('location', JSON.stringify(organizationCurrentLocation));
      fetchCountryFromLocation(location.lat, location.lng);
      return location; // { lat, lng }
    } else {
      console.error("Error fetching data:", data.status);
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
  }
}