import './cityModal.css'
import { useEffect, useState, Autocomplete, Locale } from '../../libraries';
import { AhmedabadImg, Bangkok, BengluruImg, ChandiGarhImg, ChennaiImg, Chiang_Mai, DelhiImg, HydrabadImg, Kanchanaburi, KochiImg, KolkataImg, Krabi, Mueang_Chiang_Rai, MumbaiImg, Pattaya_City, Phra_Nakhon_Si_Ayutthaya, Phuket, PuneImg, Ubon_Ratchathani } from '../../assets/Images';
import { getCityName } from '../../customHooks';
import { fetchAllCities } from '../addressSelect/util';
const CityModal = ({ handleSetSessionStorage, handleDisableLocation, handleClickOutside }) => {
    const currentCountry = sessionStorage.getItem('country');
    const isThailLanguage = localStorage.getItem('i18nextLng') === 'thai'
    const { t } = Locale();
    const [countryCode, setCountryCode] = useState();
    const [showCityName, setShowCityName] = useState(false);
    const [allCities, setAllCities] = useState([]);
    const [disableViewAll, setDisableViewAll] = useState(false)
    const cities = currentCountry !== 'Thailand' ? [
        { name: 'Mumbai', imgSrc: MumbaiImg },
        { name: 'Delhi', imgSrc: DelhiImg },
        { name: 'Bengaluru', imgSrc: BengluruImg },
        { name: 'Hyderabad', imgSrc: HydrabadImg },
        { name: 'Chandigarh', imgSrc: ChandiGarhImg },
        { name: 'Ahmedabad', imgSrc: AhmedabadImg },
        { name: 'Chennai', imgSrc: ChennaiImg },
        { name: 'Pune', imgSrc: PuneImg },
        { name: 'Kolkata', imgSrc: KolkataImg },
        { name: 'Kochi', imgSrc: KochiImg },
    ] : [
        { name: 'Bangkok', nameThai: 'กรุงเทพฯ', imgSrc: Bangkok },
        { name: 'Chiang Mai', nameThai: 'เชียงใหม่', imgSrc: Chiang_Mai },
        { name: 'Pattaya City', nameThai: 'เมืองพัทยา', imgSrc: Pattaya_City },
        { name: 'Phuket', nameThai: 'ภูเก็ต', imgSrc: Phuket },
        { name: 'Mueang Chiang Rai', nameThai: 'เมืองเชียงราย', imgSrc: Mueang_Chiang_Rai },
        { name: 'Phra Nakhon Si Ayutthaya', nameThai: 'พระนครศรีอยุธยา', imgSrc: Phra_Nakhon_Si_Ayutthaya },
        { name: 'Kanchanaburi', nameThai: 'กาญจนบุรี', imgSrc: Kanchanaburi },
        { name: 'Krabi', nameThai: 'กระบี่', imgSrc: Krabi },
        { name: 'Ubon Ratchathani', nameThai: 'อุบลราชธานี', imgSrc: Ubon_Ratchathani },
    ];

    useEffect(() => {
        if (currentCountry) {
            if (currentCountry === 'India') {
                setCountryCode('in')
            }
            else {
                setCountryCode('th')
            }
        }
    }, [currentCountry])


    useEffect(() => {
        if (handleClickOutside) {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, []);

    const detectCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    try {
                        const response = await getCityName(latitude, longitude);
                        const city = response.city; // Extract city name from the response
                        handleSetSessionStorage(city)
                    } catch (error) {
                        console.error('Error getting geocode response:', error);
                    }
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        handleDisableLocation()
                    } else {
                        console.error("Error retrieving geolocation: ", error);
                    }
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const handleSearch = async (e) => {
        try {
            // const latitude = e?.geometry?.location?.lat();
            // const longitude = e?.geometry?.location?.lng();
            // const response = await getCityName(latitude, longitude);
            const city = e.formatted_address.split(',')[0];
            handleSetSessionStorage(city)
        } catch (error) {
            console.error('Error getting geocode response:', error);
        }
    }

    const handleViewAllCities = async () => {
        try {
            setDisableViewAll(true)
            if (!allCities.length) {
                const allCities = await fetchAllCities()
                if (allCities) {
                    setAllCities(allCities)
                }
                else {
                    handleDisableLocation()
                }
            }
            setShowCityName(true)
            setDisableViewAll(false)
        }
        catch (err) {
            console.log('err------------: ', err)
        }
    }

    const CityItem = ({ name, nameThai, imgSrc }) => (

        <li className="bwc__sc-ttnkwg-18 KUowN">
            <div className="bwc__sc-ttnkwg-19 hkqhSR" onClick={() => handleSetSessionStorage(name)}>
                <div className="bwc__sc-ttnkwg-17 gvzyfS">
                    <img src={imgSrc} className="bwc__sc-ttnkwg-26 izPSOY" alt={name} />
                </div>
                <span className="bwc__sc-ttnkwg-16 dizuyr">{isThailLanguage ? nameThai : name}</span>
            </div>
        </li>
    );

    return <>
        <div class="modal fade city-modal" id="cityModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg city-modal-outer">
                <div class="modal-content">
                    <div class="modal-body">
                        <button type="button" class="btn-close d-none" data-bs-dismiss="modal" id="cityModalCloseBtn" aria-label="Close"></button>
                        <div className='city-modal-header-section'>
                            <div className='search-field-section'>
                                <div className='search-field-parent'>
                                    <div className="city-modal-autocomplete">
                                        <Autocomplete
                                            onPlaceSelected={(place) => {
                                                handleSearch(place, true);
                                            }}
                                            placeholder={t('cityModal.enterLocation')}
                                            apiKey={"AIzaSyAkbbDVfPFTWOL3xgB_GUEJzTXWYK98_gw"}
                                            options={{
                                                types: ["(cities)"],
                                                componentRestrictions: { country: countryCode }, // Ensure this is not an array
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className='detect-location-section'>
                                <div className='detect-location-parent' onClick={detectCurrentLocation}>
                                    <span>
                                        <i class="bi bi-crosshair2"></i>
                                    </span>
                                    <span>
                                        {t('cityModal.detectLocation')}
                                    </span>
                                </div>
                            </div>
                            <div className='city-modal-city-section'>
                                <div className='popular-city-section'>
                                    <div className='popular-city-heading'>
                                        {t('cityModal.popularCities')}
                                    </div>
                                    <div className='popular-cities'>
                                        <ul className="bwc__sc-ttnkwg-15 gZrltM">
                                            {cities.map((city, index) => (
                                                <CityItem key={index} nameThai={city?.nameThai} name={city.name} imgSrc={city.imgSrc} />
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className='view-all-city-section'>
                                    {showCityName ? <>
                                        <span className='other-cities-option' >
                                            Other Cities
                                        </span>
                                    </> : <>
                                        <span className={disableViewAll ? 'disable-view-all-cities' : `bwc__sc-ttnkwg-5 jhbnqL`} onClick={handleViewAllCities}>
                                            View All Cities
                                        </span>
                                    </>}
                                    <div className={showCityName ? `view-all-city-list-section mt-2` : 'd-none'}>
                                        <div className='row'>
                                            {allCities?.length && allCities?.map((city) => (
                                                <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
                                                    <span className='city-modal-city-name' onClick={() => handleSetSessionStorage(city?.value)}>{city?.value}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {showCityName && <>
                                        <span className='bwc__sc-ttnkwg-5 jhbnqL' onClick={() => setShowCityName(false)}>
                                            Hide All Cities
                                        </span>
                                    </>}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>
}

export default CityModal;