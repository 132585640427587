import './TripPlan.css'
import { Locale, ReCAPTCHA, Accordion, Checkbox, DatePicker, useState, useEffect } from '../../libraries/index'
import { CustomSelect, Footer } from "../../common";
import { NavbarLogic } from "../../components";
import { MetaTag } from "../../customHooks";
import { BreadCrumb } from "../BreadCrumb/BreadCrumb";
import { AboutUsImage } from '../../assets/Images';
const React_App_Recaptcha = process.env.REACT_APP_RECAPTCHA;

const TripPlanPage = ({
    setFormValues,
    breadCrumbData,
    formValues,
    handleSubmit,
    handleChange,
    formErrors,
    handleDateChange,
    disabledDate,
    categorySubCategoryList,
    countryCodeArray,
    handleAllSubcategoriesCheck,
    handleCategoryCheckboxChange,
    disabledTime,
    isAccordianOpenOuter,
    setIsAccordianOpenOuter,
    handleCountryCodeChange,
    isOuterAccordian,
    handleOuterAccordian
}) => {
    const { t } = Locale();
    const selectedLanguage = localStorage.getItem("i18nextLng");

    const isSelectedLanguageThai = selectedLanguage === 'thai';

    const serviceTypeAscending =
        categorySubCategoryList.length > 0 &&
        categorySubCategoryList.sort((a, b) => {
            return a?.category?.name?.localeCompare(b?.category?.name);
        });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return <>

        <MetaTag custom_title={"TripPlan"} custom_description={t("field.feelFree")} />
        <NavbarLogic />
        <section className="container mb-5 about-us-outer-container">
            <BreadCrumb data={breadCrumbData} />
            <div className="contact_inner">
                <div className="row">
                    <div className="col-md-10">
                        <div className="contact_form_inner">
                            <div className="contact_field">
                                <div className="venue-name-h3">{t("field.tripPlan")}</div>
                                <p>{t("field.feelFree")}</p>
                                <input
                                    type="text"
                                    className="form-control form-group"
                                    placeholder={`${t("field.name")}*`}
                                    id="name"
                                    name="name"
                                    value={formValues?.name}
                                    onChange={handleChange}
                                />
                                <small className="small-err">{formErrors?.name}</small>
                                <div>
                                    <div className="row mt-2 trip-plan-custom-select-outer-div  ">
                                        <div className="col-4 ">
                                            <CustomSelect
                                                name="countryCode"
                                                className="form-control country-code-trip-plan form-group mt-3"
                                                value={formValues?.countryCode}
                                                placeholder={`${t("header.select")}*`}
                                                onChange={handleCountryCodeChange}
                                                options={countryCodeArray}
                                            />

                                            <small className="small-err">
                                                {formErrors?.countryCode}
                                            </small>
                                        </div>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                name="contact"
                                                className="form-control form-group mt-3"
                                                value={formValues?.contact}
                                                onChange={handleChange}
                                                placeholder={`${t("field.phoneNumber")}*`}
                                            />
                                            <small className="small-err">{formErrors?.contact}</small>
                                        </div>

                                    </div>
                                </div>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control form-group mt-3"
                                    value={formValues?.email}
                                    onChange={handleChange}
                                    placeholder={`${t("field.semail")} ${t("field.address")}*`}
                                />
                                <small className="small-err">{formErrors?.email}</small>

                                {/* ------------------------------------ Accordian Point------------------ */}
                                <div className="mt-4  display-service-sidebar-container3Outer">
                                    <div
                                        className='container-fluid trip-section-service-label d-flex justify-content-between align-items-center'
                                        onClick={() => handleOuterAccordian()} // Toggle accordion on label click
                                        style={{ cursor: 'pointer' }} // Cursor pointer to indicate clickable
                                    >
                                        <label className={`trip-section-service-label ${formValues?.services.length > 0 && 'service-trip-plan'}`}>
                                            {formValues?.services.length > 0
                                                ? formValues?.services.join(', ')
                                                : <> {t('field.selectService')} </>
                                            }
                                        </label>

                                        {/* <label className='trip-section-service-label'>{(formValues?.services.length) ? (formValues?.services) : <> {t('field.selectService')}</>}</label> */}
                                        <span className='trip-plan-arrow'>
                                            {/* Toggle arrow icon based on isAccordianOpen state */}
                                            {(isOuterAccordian) ? (
                                                <i class="bi bi-chevron-up"></i> // Up arrow icon
                                            ) : (
                                                <i class="bi bi-chevron-down"></i> // Down arrow icon
                                            )}
                                        </span>
                                    </div>
                                    {(isOuterAccordian) &&
                                        <div className="mt-2 display-service-filter-service-outer container-fluid  display-service-sidebar-container2">
                                            <div className="row">
                                                <Accordion
                                                    onClick={() => setIsAccordianOpenOuter(!isAccordianOpenOuter)}
                                                // defaultActiveKey={defaultOpenAccordianA?.map((a) =>
                                                //     a.toString(),
                                                // )}
                                                >
                                                    {serviceTypeAscending.length > 0 &&
                                                        serviceTypeAscending
                                                            ?.filter((item) => {
                                                                let status = false;
                                                                item?.subcategory?.some((sub) => {
                                                                    if (
                                                                        sub?.name_en
                                                                            ?.toLowerCase()
                                                                    ) {
                                                                        status = true;
                                                                        return true; // Return true when the condition is met
                                                                    }
                                                                    return false; // Ensure that false is returned when the condition is not met
                                                                });
                                                                return status;
                                                            })
                                                            ?.map((category, index) => {
                                                                return (
                                                                    <Accordion.Item
                                                                        key={category.category.id}
                                                                        eventKey={index.toString()}
                                                                        className="accordion-header"
                                                                    >
                                                                        <Accordion.Header className="accordion-header-icon mt-0 m-0 p-0">
                                                                            <Checkbox
                                                                                className="checkbox-button"
                                                                                onChange={(e) =>
                                                                                    handleAllSubcategoriesCheck(
                                                                                        e,
                                                                                        category.category.id,
                                                                                        isSelectedLanguageThai &&
                                                                                            selectedLanguage !== undefined &&
                                                                                            selectedLanguage !== null
                                                                                            ? category.category.name_thai
                                                                                            : category.category.name_en,
                                                                                    )
                                                                                }
                                                                                checked={category.subcategory.every((sub) =>
                                                                                    formValues.services?.includes(sub.name_en)
                                                                                )}
                                                                            >
                                                                                {isSelectedLanguageThai &&
                                                                                    selectedLanguage !== undefined &&
                                                                                    selectedLanguage !== null ? (
                                                                                    <> {category.category.name_thai}</>
                                                                                ) : (
                                                                                    <>{category.category.name_en}</>
                                                                                )}
                                                                            </Checkbox>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body className="accordion-body mt-0 mb-0">
                                                                            {category.subcategory
                                                                                .sort((a, b) =>
                                                                                    a.name_en.localeCompare(b.name_en),
                                                                                )
                                                                                .map((subCategory) => (
                                                                                    <div key={subCategory.id}>
                                                                                        <Checkbox
                                                                                            className="mt-0 checkbox-button"
                                                                                            onChange={(e) =>
                                                                                                handleCategoryCheckboxChange(
                                                                                                    e,
                                                                                                    subCategory.id,
                                                                                                    subCategory
                                                                                                )
                                                                                            }
                                                                                            checked={formValues?.services?.includes(subCategory.name_en)}
                                                                                        >
                                                                                            {isSelectedLanguageThai &&
                                                                                                selectedLanguage !== undefined &&
                                                                                                selectedLanguage !== null ? (
                                                                                                <>{subCategory.name_thai} </>
                                                                                            ) : (
                                                                                                <> {subCategory.name_en}</>
                                                                                            )}
                                                                                        </Checkbox>
                                                                                        <br />
                                                                                    </div>
                                                                                ))}
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                );
                                                            })}
                                                </Accordion>
                                            </div>
                                        </div>
                                    }
                                    <div className='trip-section-service-hr mt-1'></div>
                                    <small className="small-err">{formErrors?.services}</small>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        name="moreService"
                                        className="form-control form-group mt-3"
                                        value={formValues?.moreService}
                                        onChange={handleChange}
                                        placeholder={`Do you want any more service`}
                                    />
                                </div>
                                <div className='mt-3 trip-plan-date-picker-plan'>
                                    <DatePicker
                                        //    getPopupContainer={() => document.getElementById(`date-popup-${}`)}
                                        format="DD/MM/YYYY HH:mm"
                                        placeholder="Please select trip date*"
                                        showTime={{
                                            format: 'HH:mm', // Show only hours and minutes
                                            hideDisabledOptions: true
                                        }}
                                        onChange={handleDateChange}
                                        disabledDate={disabledDate}
                                        disabledTime={disabledTime}
                                    // onOk={handleDateChange}  // Ensure handleDateChange is called when date is selected
                                    />
                                    <small className="small-err">{formErrors?.tripDate}</small>
                                </div>
                                <input
                                    type="text"
                                    name="contactDate"
                                    className="form-control form-group mt-3"
                                    value={formValues?.contactDate}
                                    onChange={handleChange}
                                    placeholder="Best time to get in touch with you*"
                                />
                                <small className="small-err">{formErrors?.contactDate}</small>
                                <textarea
                                    className="form-control form-group mt-3"
                                    name="askAnyThing"
                                    value={formValues?.askAnyThing}
                                    onChange={handleChange}
                                    placeholder={t("field.askAnything")}
                                ></textarea>
                                <div className="mt-4 recaptcha">
                                    <ReCAPTCHA
                                        sitekey={`${React_App_Recaptcha}`}
                                        value={formValues?.capture}
                                        name="capture"
                                        onChange={() => {
                                            setFormValues((prevValues) => ({
                                                ...prevValues,
                                                capture: 'true',
                                            }));
                                        }}
                                        error={
                                            formErrors?.capture && (
                                                <p className="small-err">{formErrors?.capture}</p>
                                            )
                                        }
                                    />
                                </div>
                                <div>
                                    <small className="small-err">
                                        {formErrors?.capture ? formErrors?.capture : false}
                                    </small>
                                </div>
                                <button
                                    onClick={(e) => handleSubmit(e)}
                                    className="contact_form_submit"
                                >
                                    {t("button.submitButton")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="right_conatct_social_icon d-flex align-items-end">
                            <div className="socil_item_inner d-flex"></div>
                        </div>
                    </div>
                </div>
                <div
                    className="trip-plan-image"
                    style={{ backgroundImage: `url(${AboutUsImage})` }}
                ></div>
            </div>
        </section>
        <Footer />
    </>
}

export default TripPlanPage;