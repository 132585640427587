import { React, FileUploader } from '../../libraries';

const DragDropImage = ({ handleChange, accept, files, className, name }) => {
  return (
    <FileUploader
      file={files}
      className={className}
      name={name}
      handleChange={handleChange}
      types={accept}
    />
  );
};
export default DragDropImage;
