import { apiCall } from '../base';
import { saveToken, clearStorageExcept } from '../index';
import { AuthRoutes } from "../../routes";
import { fetchIpBasedLocation } from '../../customHooks';

const signUpApi = async (params) => {
    const response = await apiCall(`authentication${AuthRoutes.signUp}`, 'POST', params);
    saveToken(response.data.access_token);
    return response;
};

const login = async (params) => {
    const credentials = { email: params.username, password: params.password };
    const response = await apiCall(`authentication${AuthRoutes.signIn}`, 'POST', credentials);
    saveToken(response.data.access_token);
    return response;
};

const googleSignIn = async (params) => {
    const response = await apiCall('authentication/google', 'POST', params);
    saveToken(response.data.access_token);
    return response;
};

const facebookSignIn = async (params) => {
    const response = await apiCall('authentication/facebook', 'POST', params);
    saveToken(response.data.access_token);
    return response;
};

const forgetPassword = async (params) => {
    const response = await apiCall('authentication/forget_password', 'POST', params);
    saveToken(response.data.access_token);
    localStorage.setItem('email', params.email);
    return response;
};

const codeVerification = async (params) => {
    return await apiCall('authentication/code_verification', 'POST', params);
};

const updateForgetPassword = async (params) => {
    return await apiCall('authentication/reset_password', 'PATCH', params);
};

const logout = async (params) => {
    const response = await apiCall('authentication/logout', 'POST', params);
    clearStorageExcept(['i18nextLng', 'selectedLanguage', 'cartCount']);
    sessionStorage.removeItem('country')
    fetchIpBasedLocation();
    return response;
};

  

export {
    signUpApi,
    login,
    googleSignIn,
    facebookSignIn,
    forgetPassword,
    codeVerification,
    updateForgetPassword,
    logout,
};
