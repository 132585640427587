import './Branches.css';
import { React, PropTypes, useState, Locale, Modal } from '../../../libraries';
import { BootTable } from '../../../common/bootstrapTable/bootTable';
import { CustomSpinner, Heading, EmptyComponent, SearchField, CustomImage } from '../../../common';
import { NewBranch, VendorHeader } from '../index';
import { UnderReviewLogic } from '../../component';
import { Filter } from '../../../assets/Images';
const Branches = ({
  feildblanck,
  isShowEmptyPage,
  isOrganizationStatus,
  loader,
  totalPage,
  handlePaginationChange,
  handleChangeFilter,
  filterFormValue,
  setFilterFormValue,
  setFormErrors,
  formErrors,
  setFormValues,
  formValues,
  setSelectedImages,
  setSelectedImage,
  selectedImage,
  selectedImages,
  handleSubmit,
  handleImageUpload,
  handleDeselectImage,
  handleChange,
  data,
  handleStatusChange,
  addressFormValue,
  setAddressFormValue
}) => {
  const { t } = Locale();
  const heading = [
    'Branch Id',
    'Branch Name',
    'Branch Address',
    'Status',
    'Action',
  ];
  const [editBookingModalOpen, setEditBookingModalOpen] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(false);

  const closeEditBookingModal = () => {
    feildblanck();
    setEditBookingModalOpen(!editBookingModalOpen);
  };

  const filterRowFunction = () => {
    setShowFilterRow(!showFilterRow);
  };
  return (
    <>
      <Modal
        open={editBookingModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        className="branch-modal"
      >
        <NewBranch
          setFormErrors={setFormErrors}
          formErrors={formErrors}
          setFormValues={setFormValues}
          formValues={formValues}
          setSelectedImages={setSelectedImages}
          setSelectedImage={setSelectedImage}
          selectedImages={selectedImages}
          selectedImage={selectedImage}
          handleSubmit={handleSubmit}
          closeEditBookingModal={closeEditBookingModal}
          handleImageUpload={handleImageUpload}
          handleDeselectImage={handleDeselectImage}
          handleChange={handleChange}
          addressFormValue={addressFormValue}
          setAddressFormValue={setAddressFormValue}
        />
      </Modal>
      <div className="heading-outer-div">
        <VendorHeader />
        <Heading
          text={t('vendor.branches')}
          icon={'bi bi-diagram-3'}
          button={
            <button
              onClick={() => closeEditBookingModal()}
              className="btn branches-buttton"
            >
              {t('vendor.newBranch')}
            </button>
          }
        />
        <div className="after-heading-outer-div">
          {isOrganizationStatus !== 'verified' ? (
            <>
              <UnderReviewLogic />
            </>
          ) : (
            <>
              <div className="user-common-box">
                <div className={''}>
                  <div className="user-common-box-inner">
                    {(data && data.length > 0) ||
                      isShowEmptyPage === 'emptyBranchFilter' ? (
                      <>
                        <div className="user-common-box-fix-content">
                          <div className="calendar-row">
                            <div className="calendar-col"> </div>
                            <div className="calendar-col d-flex">
                              <div className="boot-table-filter">
                                <CustomImage
                                  className="boot-table-filter-image"
                                  alt="filter"
                                  onClick={filterRowFunction}
                                  src={Filter}
                                  width={'unset'}
                                  height={'unset'}
                                />
                              </div>
                              <SearchField
                                placeholder={t('header.placeholder')}
                                onChange={(e) => {
                                  handleChangeFilter({
                                    target: { name: 'search', value: e.target.value },
                                  });
                                }}
                                onSubmit={(value) => handleChangeFilter(value)}
                              />
                            </div>
                          </div>
                        </div>

                        {heading && (
                          <BootTable
                          showFilterRow={showFilterRow}
                           setShowFilterRow={setShowFilterRow}
                            totalPage={totalPage}
                            handlePaginationChange={handlePaginationChange}
                            handleChangeFilter={handleChangeFilter}
                            filterFormValue={filterFormValue}
                            setFilterFormValue={setFilterFormValue}
                            formErrors={formErrors}
                            setFormValues={setFormValues}
                            formValues={formValues}
                            setSelectedImages={setSelectedImages}
                            setSelectedImage={setSelectedImage}
                            selectedImage={selectedImage}
                            selectedImages={selectedImages}
                            handleSubmit={handleSubmit}
                            handleImageUpload={handleImageUpload}
                            handleDeselectImage={handleDeselectImage}
                            handleChange={handleChange}
                            handleStatusChange={handleStatusChange}
                            setFormErrors={setFormErrors}
                            bootData={data}
                            addressFormValue={addressFormValue}
                            setAddressFormValue={setAddressFormValue}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {isShowEmptyPage === 'emptyBranch' && (
                          <EmptyComponent type="isBranch" />
                        )}
                      </>
                    )}
                  </div>
                </div>
                {loader && <CustomSpinner />}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
Branches.propTypes = {
  subDistrictOption: PropTypes.array,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  filterOption: PropTypes.func,
  filterOption: PropTypes.string,
  postalCode: PropTypes.array.isRequired,
  totalPage: PropTypes.number,
  handlePaginationChange: PropTypes.func,
  handleChangeFilter: PropTypes.func,
  filterFormValue: PropTypes.object,
  setFilterFormValue: PropTypes.func,
  setFormErrors: PropTypes.func,
  formErrors: PropTypes.object,
  setFormValues: PropTypes.func,
  formValues: PropTypes.object,
  setSelectedImages: PropTypes.func,
  setSelectedImage: PropTypes.func,
  selectedImage: PropTypes.string,
  selectedImages: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleImageUpload: PropTypes.func,
  handleDeselectImage: PropTypes.func,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  handleStatusChange: PropTypes.func,
};

export default Branches;