import './TeamList.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';  
import { React,useEffect, useState, Locale, Pagination, PropTypes } from '../../../libraries/index';
import { User } from '../../../assets/Images';
import { VendorHeader, VendorTeamListModal } from '../index';
import { CustomSpinner, Heading, EmptyComponent } from '../../../common';
import { UnderReviewLogic } from '../../component';
const DeleteTeamMemberProfile = React.lazy(() => import('../../component/teamMember/deleteMember/deleteMember'))


const VendorTeamList = ({
  isOrganizationStatus,
  loader,
  setLoader,
  filterOption,
  handleAddButtonClick,
  showModal,
  handleOk,
  selectedImage,
  handleImageUpload,
  formValue,
  formError,
  type,
  handleChange,
  handleSubmit,
  selectedServices,
  selectedItems,
  setSelectedItems,
  services,
  selectItemVisible,
  setSelectItemVisible,
  searchText,
  setSearchText,
  toggleSelectItemVisibility,
  memberItem,
  handlePaginationChange,
  totalPage,
  itemsPerPage,
  currentPage,
  deleteFromFarray,
  editTeamMember,
  countryCode,
  setShowModal,
  getBranchData,
  setOpeningHourseFormValue,
  openingHourseFormValue,
  isBtnDisable,
  profileVisible,
  handlePhoneCountryCodeChange,
  crop, setCrop, zoom, setZoom, onCropComplete
}) => {
  const { t } = Locale();
  const options = getBranchData?.map((branch) => ({
    label: branch.branch.branch_name, // Use the branch name as the label
    value: branch.branch.id, // Use the branch id as the value
  }));

  const colors = [
    'linear-gradient(45deg, #a52dd8, #e29bf1)', // Purple
    'linear-gradient(45deg, #ff7f50, #ff4500)', // Orange
    'linear-gradient(45deg, #1e90ff, #00bfff)', // Blue
    'linear-gradient(45deg, #00fa9a, #3cb371)', // Green
    'linear-gradient(45deg, #ff1493, #ff69b4)', // Pink
    'linear-gradient(45deg, #ffd700, #ffa500)', // Yellow
    'linear-gradient(45deg, #7f00ff, #e100ff)', // Indigo
    'linear-gradient(45deg, #dcdcdc, #c0c0c0)', // Gray
  ];

  const [deletedId, setDeletedId] = useState();

  const getDeletedId = (id) => {
    setDeletedId(id);
    const modalElement = document.getElementById('deleteTeamMember');
    if (modalElement) {
      const modal = new window.bootstrap.Modal(modalElement);
      modal.show();
    } else {
      console.error('Modal element not found');
    }
  };
  
  useEffect(()=>{
  },[deletedId])
  return (
    <>
      <div className="heading-outer-div">
        <VendorHeader />
        <Heading
          text={t('teamSetupField.teamSetup')}
          icon={'bi bi-people'}
          button={
            <>
              {' '}
              <button
                className="btn branches-buttton "
                onClick={handleAddButtonClick}
              >
                {' '}
                {t('button.addButton')} {t('field.member')}{' '}
              </button>
            </>
          }
        />
        <div className="after-heading-outer-div">

          {isOrganizationStatus !== 'verified' ? (
            <>
              <UnderReviewLogic />
            </>
          ) : (
            <>
              <div className="user-common-box">
                <div className="user-common-box-inner user-inner-content-scrollar">
                  <VendorTeamListModal
                  handlePhoneCountryCodeChange={handlePhoneCountryCodeChange}
                    isBtnDisable={isBtnDisable}
                    filterOption={filterOption}
                    countryCode={countryCode}
                    showModal={showModal}
                    handleOk={handleOk}
                    selectedImage={selectedImage}
                    handleImageUpload={handleImageUpload}
                    formValue={formValue}
                    formError={formError}
                    type={type}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    selectedServices={selectedServices}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    services={services}
                    selectItemVisible={selectItemVisible}
                    setSelectItemVisible={setSelectItemVisible}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    toggleSelectItemVisibility={toggleSelectItemVisibility}
                    setShowModal={setShowModal}
                    options={options}
                    setOpeningHourseFormValue={setOpeningHourseFormValue}
                    openingHourseFormValue={openingHourseFormValue}
                    profileVisible={profileVisible}
                    crop={crop} setCrop={setCrop} zoom={zoom} setZoom={setZoom} onCropComplete={onCropComplete}
                  ></VendorTeamListModal>
                  <div
                    className={showModal ? `fade modal-backdrop show` : ''}
                  ></div>
                  <div className={`${loader ? 'blure-bacground' : ''}`}>
                    <div className={loader ? 'd-none' : ''}>
                      <div id="pagination-div">
                        <div className="row">
                          {memberItem && memberItem.length > 0 ? (
                            <>
                              {memberItem.map((item, index) => (
                                <div
                                  key={index}
                                  className="col-lg-3 col-md-4 col-sm-6 p-2"
                                >
                                  <div className='vendor-team-list-outer-div'>
                                    <div className=" team-card">
                                      <div className="m-b-20">
                                        <div className="doctor-profile">
                                          <div
                                            className="profile-header "
                                            style={{
                                              background:
                                                colors[index % colors.length],
                                            }}
                                          >
                                            <div className="user-name">
                                              {' '}
                                              <h4>
                                                {item?.team_member?.team_member
                                                  ?.first_name +
                                                  ' ' +
                                                  item?.team_member?.team_member
                                                    ?.last_name}
                                              </h4>
                                            </div>
                                            <div className="name-center">
                                              {item.team_member?.designation}
                                            </div>
                                          </div>
                                          <img
                                            src={
                                              item.image_url.image_url
                                                ? item.image_url.image_url
                                                : User
                                            }
                                            className="user-img-team"
                                            alt="sub_content_image_url"
                                            width={'unset'}
                                            height={'unset'}
                                          />
                                          <div>
                                            {options &&
                                              options.map((option) =>
                                                option.value ===
                                                  item.team_member.branch_id ? (
                                                  <small key={option.value}>
                                                    <i className="bi bi-geo-alt-fill"></i>
                                                    {option.label}
                                                  </small>
                                                ) : null,
                                              )}
                                            <h6 className="mt-2">
                                              {' '}
                                              <i className="bi bi-telephone-fill"></i>
                                              <a href="tel:(123)456-7890">
                                                {' '}
                                                {
                                                  item?.team_member?.team_member?.phone_number?.split(
                                                    ' ',
                                                  )[1]
                                                }
                                              </a>{' '}
                                            </h6>
                                          </div>

                                        </div>
                                      </div>

                                          <DeleteTeamMemberProfile
                                            setLoader={setLoader}
                                            id={deletedId}
                                            deleteFromFarray={deleteFromFarray}
                                          />
                                    </div>
                                    <div className="profile-userbuttons">
                                      <div className="ms-3">
                                        <span
                                          id="icon-color"
                                        >
                                          <i
                                            className="bi bi-trash-fill cursor-point-class"
                                            aria-hidden="true"
                                            onClick={()=>getDeletedId(item.team_member.id)}
                                          ></i>
                                        </span>
                                        {/* <span s>
                                          <button
                                            onClick={()=>getDeletedId(item.team_member.id)}
                                          ></button>
                                        </span> */}
                                      </div>
                                      <div className="ms-2">
                                        <span>
                                          <i
                                            onClick={() =>
                                              editTeamMember(
                                                item.team_member.id,
                                              )
                                            }
                                            className="bi bi-pencil-square edit-icon cursor-point-class"
                                          ></i>
                                        </span>
                                        <span>
                                          <button
                                            onClick={() =>
                                              editTeamMember(
                                                item.team_member.id,
                                              )
                                            }
                                            className="btn team-list-button-edit"
                                          ></button>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <>
                              <EmptyComponent type="teammember" />
                            </>
                          )}
                        </div>
                      </div>

                      {totalPage > 8 && (
                        <Pagination
                          current={currentPage}
                          pageSize={itemsPerPage}
                          total={totalPage}
                          showSizeChanger={false}
                          defaultCurrent={3}
                          onChange={handlePaginationChange}
                        />
                      )}
                    </div>
                    <div />
                  </div>
                  {loader && <CustomSpinner />}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <VendorHeader />
    </>
  );
};
VendorTeamList.propTypes = {
  filterOption: PropTypes.func,
  handleAddButtonClick: PropTypes.func,
  showModal: PropTypes.bool,
  handleOk: PropTypes.func,
  crosButton: PropTypes.func,
  selectedImage: PropTypes.string,
  handleImageUpload: PropTypes.func,
  formValue: PropTypes.object,
  formError: PropTypes.object,
  type: PropTypes.string,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  selectedServices: PropTypes.array,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  services: PropTypes.array,
  selectItemVisible: PropTypes.bool,
  setSelectItemVisible: PropTypes.func,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  toggleSelectItemVisibility: PropTypes.func,
  memberItem: PropTypes.array,
  handlePaginationChange: PropTypes.func,
  totalPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  deleteFromFarray: PropTypes.func,
  editTeamMember: PropTypes.func,
  countryCode: PropTypes.array,
};

export default VendorTeamList;