import './Calender.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { React, Select, Option, DatePicker, dayjs, Locale, useLocation, useEffect, moment, Calendar, momentLocalizer } from '../../../libraries/index';
import { Modal, Button, Form } from 'react-bootstrap';
import { VendorHeader } from '../index';
import { CustomSpinner, Heading } from '../../../common';
import { useCalender, UnderReviewLogic } from '../../component';
const localizer = momentLocalizer(moment);
const MyCalendar = ({ deletionRequest }) => {
  const {
    loader,
    timeSlots,
    vendorOpenignHours,
    onChangeBlockTime,
    onChange,
    filterOption,
    handleCloseModalBooking,
    showModalBooking,
    handleSubmit,
    handleChange,
    formErrors,
    formValues,
    teamMemberForFilter,
    handleCloseModal,
    selectedEvent,
    teamMembers,
    showModal,
    isNewEvent,
    events,
    formError,
    handleSelectEvent,
    handleSelectSlot,
    handleEventDelete,
    handleEventSave,
    handleInputChange,
    handleAddNewEvent,
    isDatePast,
    fetchDataForMonthView,
    isOrganizationStatus,

  } = useCalender();
  const location = useLocation();
  const { t } = Locale();
  const isTeamMember = localStorage.getItem('role') === 'TeamMember';
  const isSection = localStorage.getItem('isSection');

  useEffect(() => {
    if (isSection !== 'isBusinessCalender') {
      localStorage.setItem('isSection', 'isBusinessCalender');
    }
  }, [location.pathname]);

  const getEventStyle = (event) => {
    let style = {
      backgroundColor: 'black',
    };
    if (event.checkStatus === '(confirmed)') {
      style.background = '#FDF4EB';
      style.color = 'var(--colors-warning, #F29339)';
    } else if (event.checkStatus === 'red') {
      style.color = '#B31312';
      style.background = '#F8E8E8';
    } else if (event.checkStatus === '(completed)') {
      style.background = '#EBF4ED';
      style.color = '#3A974C';
    } else if (event.checkStatus === '(expired)') {
      style.background = 'rgba(235,87,87,0.1)';
      style.color = '#EB5757';
    } else if (event.checkStatus === '(Cancel)') {
      style.color = '#B31312';
      style.background = '#F8E8E8';
    } else if (event.checkStatus === '(refunded)') {
      style.color = '#B31312';
      style.background = '#F8E8E8';
    } else if (event.checkStatus === '(initial)') {
      style.color = '#B31312';
      style.background = '#F8E8E8';
    }
    return { style };
  };

  const openModalOnClick = (event) => {
    if (isTeamMember) {
      event.isBlocktime && handleSelectEvent(event);
    } else if (
      event.checkStatus === '(confirmed)' ||
      event.checkStatus === 'red'
    ) {
      handleSelectEvent(event);
    }
  };


  return (
    <>
      <div className={`heading-outer-div  ${deletionRequest && 'vendor-deletionRequest-user-header'}`}>
        <VendorHeader />
        <Heading
          text={t('vendor.calender')}
          icon={'bi bi-calendar3'}
          button={
            <>
              <Button
                className="btn branches-buttton"
                onClick={handleAddNewEvent}
                variant=""
              >
                {t('calender.blockTime')}
              </Button>
            </>
          }
        />
        <div className="after-heading-outer-div">
          {isOrganizationStatus !== 'verified' ? (
            <>
              <UnderReviewLogic />
            </>
          ) : (
            <>
              <div className="user-common-box">
                <div className="user-common-box-inner">
                  <div
                    className={!loader ? ` user-inner-content-scrollar-d` : ''}
                  >
                    <div
                      className={`calendar-row ${loader && 'blure-bacground'}`}
                    >
                      {!isTeamMember && (
                        <>
                          {' '}
                          <div className="calendar-col"> </div>
                          <div className="calendar-col d-flex">
                            <Select
                              className="calender-ant-select-search"
                              showSearch
                              placeholder={t('calender.selectTeamMember')}
                              optionFilterProp="children"
                              onChange={onChange}
                              filterOption={filterOption}
                              options={teamMembers}
                            />
                          </div>{' '}
                        </>
                      )}
                    </div>
                    <div className="user-inner-content-scrollar user-inner-content-scrollar-after-fix-content">
                      <Calendar
                        eventPropGetter={getEventStyle}
                        defaultDate={moment().toDate()}
                        defaultView="month"
                        events={events}
                        localizer={localizer}
                        startAccessor="start"
                        endAccessor="end"
                        onSelectEvent={openModalOnClick}
                        onSelectSlot={handleSelectSlot}
                        className="custom-width-event custom-width-event-month dnCalender-heigth"
                        views={['month', 'week', 'day']}
                        onNavigate={(date, view) => {
                          if (view === 'month') {
                            const endOfMonth = moment(date)
                              .endOf('month')
                              .toISOString();
                            fetchDataForMonthView(endOfMonth);
                          }
                        }}
                        formats={{
                          timeGutterFormat: (date, culture, localizer) =>
                            localizer.format(date, 'HH:mm', culture),
                          eventTimeRangeFormat: (
                            { start, end },
                            culture,
                            localizer,
                          ) => {
                            const formattedStart = localizer.format(
                              start,
                              'HH:mm',
                              culture,
                            );
                            const formattedEnd = localizer.format(
                              end,
                              'HH:mm',
                              culture,
                            );
                            return `${formattedStart} - ${formattedEnd}`;
                          },
                          agendaTimeRangeFormat: (
                            { start, end },
                            culture,
                            localizer,
                          ) => {
                            const formattedStart = localizer.format(
                              start,
                              'HH:mm',
                              culture,
                            );
                            const formattedEnd = localizer.format(
                              end,
                              'HH:mm',
                              culture,
                            );
                            return `${formattedStart} - ${formattedEnd}`;
                          },
                          dayRangeHeaderFormat: (
                            { start, end },
                            culture,
                            localizer,
                          ) => {
                            const formattedStart = localizer.format(
                              start,
                              'HH:mm',
                              culture,
                            );
                            const formattedEnd = localizer.format(
                              end,
                              'HH:mm',
                              culture,
                            );
                            return `${formattedStart} - ${formattedEnd}`;
                          },
                        }}
                      />
                    </div>
                    <Modal
                      show={showModal}
                      onHide={handleCloseModal}
                      backdrop="static"
                      keyboard={false}
                      className="custom-modal-clander"
                    >
                      <div className="row custom-modal-header-row">
                        <div className="col-12 text-center p-3">
                          {isNewEvent
                            ? `${t('calender.blockTime')}`
                            : `${t('calender.editBlockTime')}`}
                        </div>
                      </div>
                      <Modal.Body className="modal-body-custom-line">
                        <Form onSubmit={handleEventSave}>
                          {isTeamMember ? (
                            <></>
                          ) : (
                            <>
                              <Form.Group
                                controlId="formTeamMember"
                                className="space-between-feilds-calander"
                              >
                                <label>
                                  {t('calender.selectTeamMember')}
                                  <span className="required-asterisk">*</span>
                                </label>
                                <Select
                                  disabled={isDatePast(selectedEvent?.date)}
                                  className="calender-ant-select-form"
                                  showSearch
                                  placeholder={t('calender.selectTeamMember')}
                                  optionFilterProp="children"
                                  onChange={(value) =>
                                    onChangeBlockTime('block', value)
                                  }
                                  filterOption={filterOption}
                                  options={teamMemberForFilter}
                                  value={
                                    selectedEvent?.team_member_id || undefined
                                  }
                                />
                                <small
                                  className={`validation-message ${!formError?.team_member_id && 'validation-message-display'}`}
                                >
                                  {formError.team_member_id}
                                </small>
                              </Form.Group>
                            </>
                          )}

                          <Form.Group controlId="formEventStart">
                            <label>
                              {t('calender.date')}
                              <span className="required-asterisk">*</span>
                            </label>
                            <Form.Control
                              disabled={isDatePast(selectedEvent?.date)}
                              className="calander-feilds-border space-between-feilds-calander"
                              type="date"
                              onChange={(e) =>
                                handleInputChange('date', e.target.value)
                              }
                              value={selectedEvent?.date}
                              min={new Date().toISOString().split('T')[0]}
                            />
                            <small
                              className={`validation-message ${!formError?.date && 'validation-message-display'}`}
                            >
                              {formError?.date}
                            </small>
                          </Form.Group>

                          <div className="row space-between-feilds-calander">
                            <div className="col-6">
                              <Form.Group controlId="formEventStart">
                                <label>
                                  {t('calender.startTime')}
                                  <span className="required-asterisk">*</span>
                                </label>
                                <Form.Control
                                  disabled={isDatePast(selectedEvent?.date)}
                                  className="calander-feilds-border"
                                  type="time"
                                  value={selectedEvent?.start}
                                  onChange={(e) =>
                                    handleInputChange('start', e.target.value)
                                  }
                                />
                                <small
                                  className={`validation-message ${!formError?.start && 'validation-message-display'}`}
                                >
                                  {formError?.start}
                                </small>
                              </Form.Group>
                            </div>
                            <div className="col-6">
                              <Form.Group controlId="formEventEnd">
                                <label>
                                  {t('calender.endTime')}
                                  <span className="required-asterisk">*</span>
                                </label>
                                <Form.Control
                                  disabled={isDatePast(selectedEvent?.date)}
                                  className="calander-feilds-border"
                                  type="time"
                                  value={selectedEvent?.end}
                                  onChange={(e) =>
                                    handleInputChange('end', e.target.value)
                                  }
                                />
                                <small
                                  className={`validation-message ${!formError?.end && 'validation-message-display'}`}
                                >
                                  {formError?.end}
                                </small>
                              </Form.Group>
                            </div>
                          </div>
                          <Form.Group
                            controlId="formComment"
                            className="space-between-feilds-calander"
                          >
                            <label>
                              {t('calender.comment')}
                              <span className="required-asterisk">*</span>
                            </label>
                            <Form.Control
                              disabled={isDatePast(selectedEvent?.date)}
                              as="textarea"
                              className="calender-comment-input calander-feilds-border"
                              value={selectedEvent?.comment || ''}
                              onChange={(e) =>
                                handleInputChange('comment', e.target.value)
                              }
                            />
                            <small
                              className={`validation-message ${!formError?.comment && 'validation-message-display'}`}
                            >
                              {formError?.comment}
                            </small>
                          </Form.Group>
                          <div className="calander-footer mt-4 mb-1">
                            <Button
                              variant="secondary"
                              onClick={handleCloseModal}
                              className="calender-footer-button-cancel"
                            >
                              {t('button.cancelButton')}
                            </Button>
                            <Button
                              disabled={isDatePast(selectedEvent?.date)}
                              variant="primary"
                              type="submit"
                              className="calender-footer-button"
                            >
                              {isNewEvent
                                ? `${t('calender.create')}`
                                : `${t('calender.save')}`}
                            </Button>
                            {!isNewEvent && (
                              <Button
                                disabled={isDatePast(selectedEvent?.date)}
                                variant="danger"
                                className="calender-footer-button-delete"
                                onClick={handleEventDelete}
                              >
                                {t('calender.remove')}
                              </Button>
                            )}
                          </div>
                        </Form>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      backdrop="static"
                      keyboard={false}
                      show={showModalBooking}
                      onHide={handleCloseModalBooking}
                      className="custom-modal-clander"
                    >
                      <div className="row custom-modal-header-row">
                        <div className="col-12 col-sm-11 text-center p-3">
                          {t('venderBooking.editBooking')}
                        </div>
                      </div>
                      <Modal.Body className="modal-body-custom-line">
                        <Form onSubmit={handleEventSave}>
                          <div className="row space-between-feilds-calander">
                            <div className="col-6">
                              <Form.Group controlId="formEventEnd">
                                <label>
                                  {t('venderBooking.scheduledDate')}{' '}
                                  <span className="custom-estric">*</span>
                                </label>
                                <DatePicker
                                  placeholder={`${t('header.select')} ${t('ServiceField.date')}`}
                                  onChange={(date, dateString) =>
                                    handleChange('scheduleDate', dateString)
                                  }
                                  value={
                                    formValues?.scheduleDate &&
                                    dayjs(formValues?.scheduleDate, {
                                      format: 'YYYY-MM-DD',
                                    })
                                  }
                                  disabledDate={(current) => {
                                    if (
                                      current &&
                                      current < moment().startOf('day')
                                    ) {
                                      return true;
                                    }
                                    if (
                                      current &&
                                      ((!vendorOpenignHours?.sunday &&
                                        current.day() === 0) ||
                                        (!vendorOpenignHours.monday &&
                                          current.day() === 1) ||
                                        (!vendorOpenignHours.tuesday &&
                                          current.day() === 2) ||
                                        (!vendorOpenignHours.wednesday &&
                                          current.day() === 3) ||
                                        (!vendorOpenignHours.thursday &&
                                          current.day() === 4) ||
                                        (!vendorOpenignHours.friday &&
                                          current.day() === 5) ||
                                        (!vendorOpenignHours.saturday &&
                                          current.day() === 6))
                                    )
                                      return true;
                                    return false;
                                  }}
                                  className="user-profile-date-picker-ant form-control mt-1"
                                />
                                <small
                                  className={`validation-message ${!formError?.scheduleDate && 'validation-message-display'}`}
                                >
                                  {formError?.scheduleDate}
                                </small>
                              </Form.Group>
                            </div>
                            <div className="col-6">
                              <Form.Group
                                controlId="formTeamMember"
                                className="space-between-feilds-calander"
                              >
                                <label>
                                  {t('venderBooking.resheluleTime')}
                                  <span className="required-asterisk">*</span>
                                </label>
                                <Select
                                  value={formValues?.scheduleTime}
                                  onChange={(value) =>
                                    handleChange('scheduleTime', value)
                                  }
                                  className="mt-1 calender-ant-select-form"
                                  placeholder="Select Schedule Time"
                                >
                                  {timeSlots &&
                                    timeSlots.map((time, index) => (
                                      <Option key={index} value={time}>
                                        {time}
                                      </Option>
                                    ))}
                                </Select>
                                <small
                                  className={`validation-message ${!formErrors?.scheduleTime && 'validation-message-display'}`}
                                >
                                  {formErrors.scheduleTime}
                                </small>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group">
                              <label>
                                <span>{t('venderBooking.teamMember')}</span>
                                <span className="custom-estric">*</span>
                              </label>
                              <Select
                                className="calender-ant-select-form"
                                showSearch
                                placeholder={t('calender.selectTeamMember')}
                                optionFilterProp="children"
                                onChange={(value) =>
                                  onChangeBlockTime('booking', value)
                                }
                                filterOption={filterOption}
                                options={teamMemberForFilter}
                                value={formValues?.teamMember || undefined}
                              />
                              <small
                                className={`validation-message ${!formErrors?.teamMember && 'validation-message-display'}`}
                              >
                                {formErrors.teamMember}
                              </small>
                            </div>
                          </div>
                          <div className="calander-footer mt-4 mb-1">
                            <Button
                              variant="primary"
                              className="calender-footer-button-cancel"
                              onClick={handleCloseModalBooking}
                            >
                              {t('button.cancelButton')}
                            </Button>
                            <Button
                              variant="primary"
                              className="calender-footer-button"
                              onClick={handleSubmit}
                            >
                              {t('button.update')}
                            </Button>
                          </div>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {loader && <CustomSpinner />}
    </>
  );
};

export default MyCalendar;
