import "./MenuSlider.css";
import { useLocation, useNavigate, useEffect, useState, Locale, Drawer, useRef } from "../../../libraries/index";
import { logout, handleErrorResponse, handleSuccessResponse } from "../../../services";
import { CustomerRoutes, HomeRoutes, BusinessRoutes, AuthRoutes } from "../../../routes/index";
import { NavbarCategory } from "../../../components";
import { ProductOutlined } from "@ant-design/icons";
const MenuSlider = ({
  displayName,
  handleNearbyShopClick,
  visibleSlide,
  setVisibleSlide,
  hideDisplayName,
  setHideDisplayName
}) => {
  const [showCategory, setShowCategory] = useState(false);
  const { t } = Locale();
  const [isOPenBusiness, setIsOpenBusiness] = useState(false);
  const [isSection, setIsSection] = useState();
  const [sectionState, setSectionState] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const isCustomer = localStorage.getItem("role") === "Customer";
  const isTeamMember = localStorage.getItem("role") === "TeamMember";
  const [placement, setPlacement] = useState("left");

  const onClose = () => {
    setShowCategory(false);
    setVisibleSlide(false);
  };
  useEffect(() => {

  }, [showCategory])

  const handleCategoryClick = () => {
    setShowCategory(!showCategory);
    setHideDisplayName(true)
  };

  const showDrawer = () => {
    setVisibleSlide(true);
  };

  useEffect(() => {
    const updatePlacement = () => {
      setPlacement(window.innerWidth < 1125 ? "left" : "right");
    };

    updatePlacement(); // Set initial placement based on screen size
    window.addEventListener("resize", updatePlacement);

    return () => window.removeEventListener("resize", updatePlacement);
  }, [placement]);

  const handleSectionClick = async (e, section, path, beforeLogIn) => {
    e.preventDefault();
    e.stopPropagation();
    if (!beforeLogIn) {
      if (section === "logout") {
        try {
          const response = await logout();
          handleSuccessResponse(response, response.data.message);
          window.history.pushState(null, null, `${AuthRoutes.signIn}`);
          navigate(AuthRoutes.signIn);
          const disableBack = (event) => {
            window.history.pushState(null, null, `${AuthRoutes.signIn}`);
            event.preventDefault();
          };
          return () => {
            window.removeEventListener("popstate", disableBack);
          };
        } catch (error) {
          handleErrorResponse(error);
        }
      } else {
        localStorage.setItem("isSection", section);
        onClose();
        setSectionState(!sectionState);
        navigate(`${isCustomer ? CustomerRoutes.user : BusinessRoutes.bussiness}${path}`);
      }
    } else {
      onClose();
      navigate(path);
    }
  };

  const mobileMenuHeadRef = useRef(null);
  const submenu1Ref = useRef({});
  useEffect(() => {
    submenu1Ref.current = {};
  }, []);

  useEffect(() => {
    setIsSection(localStorage.getItem("isSection"));
  }, [sectionState, localStorage.getItem("isSection"), location.pathname]);

  const isVendor =
    localStorage.getItem("type") === "false" ||
    localStorage.getItem("type") === false;
  const isHome = location.pathname === (HomeRoutes.home);

  return (
    <>
      {!visibleSlide && (
        <label
          onClick={() => showDrawer()}
          className={"vendor_Header_UserName"}
        >
          {displayName && displayName(role)} &nbsp;&nbsp;{" "}
          <i className="bi bi-chevron-down"></i>
        </label>
      )}
      <Drawer
        className={`menuBar-sidebar`}
        placement={placement}
        closable={false}
        onClose={onClose}
        open={visibleSlide}
      >
        {role ? (
          <>
            <div className={`${hideDisplayName ? 'd-none' : "menu-sidebar-header-section"}`}>
              <div className="menu-sidebar-header-content">
                <div>
                  <span className="sidebar-username">{displayName(role)}</span>
                </div>
                <div className="sidebar-log-out-div" onClick={(e) => handleSectionClick(e, "logout")}>
                  <i className={`bi bi-power sidebar-log-out-icon`}></i>
                  <div className="menubar-logout-text">{t('header.logout')}</div>
                </div>
              </div>
              <div className="mobile-menu-head" ref={mobileMenuHeadRef}>
                <i className="bi bi-arrow-left" onClick={onClose}></i>
                <div className="current-menu-title colorWhite">
                  {displayName(role)}
                </div>
                <i
                  className={`bi bi-power menu-sidebar-close`}
                  onClick={(e) => handleSectionClick(e, "logout")}
                ></i>
              </div>
            </div>
            <div className="menu-sidebar-body">
              {isCustomer ? (
                <>
                  {!isHome && (
                    <>
                      <div
                        className="d-flex menu-sidebar-outer-div"
                        onClick={(e) =>
                          handleSectionClick(e, "isHome", HomeRoutes.home, true)
                        }
                      >
                        <div className={`menubar-sidebar-link`}>
                          <div className={`menubar-sidebar-inner-div`}>
                            <i
                              className={`bi bi-house-door menubar-sidebar-icon`}
                            ></i>
                            <span className={`menubar-sidebar-text`}>
                              {t("header.message")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    onClick={(e) =>
                      handleSectionClick(e, "isProfile", CustomerRoutes.editProfile)
                    }
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isProfile" && "isActiveSection"
                      }`}
                  >
                    <div className={`menubar-sidebar-link`}>
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isProfile" && "isActiveSection"
                          }`}
                      >
                        <i className={`bi bi-person menubar-sidebar-icon`}></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.myProfile")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={(e) =>
                      handleSectionClick(e, "isBooking", BusinessRoutes.bussinessbookings)
                    }
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isBooking" && "isActiveSection"
                      }`}
                  >
                    <div className={`menubar-sidebar-link`}>
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isBooking" && "isActiveSection"
                          }`}
                      >
                        <i
                          className={`bi bi-calendar-week menubar-sidebar-icon`}
                        ></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("userBooking.myBookings")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={(e) =>
                      handleSectionClick(e, "isCashback", CustomerRoutes.cashback)
                    }
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isCashback" && "isActiveSection"
                      }`}
                  >
                    <div className={`menubar-sidebar-link`}>
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isCashback" && "isActiveSection"
                          }`}
                      >
                        <i className={`bi bi-wallet menubar-sidebar-icon`}></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.suraCashback")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={(e) => handleSectionClick(e, "isRefral", CustomerRoutes.referral)}
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isRefral" && "isActiveSection"
                      }`}
                  >
                    <div className={`menubar-sidebar-link`}>
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isRefral" && "isActiveSection"
                          }`}
                      >
                        <i
                          className={`bi-megaphone mt-1 menubar-sidebar-icon`}
                        ></i>

                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.referral")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={(e) =>
                      handleSectionClick(e, "isReview", CustomerRoutes.reviewShop)
                    }
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isReview" && "isActiveSection"
                      }`}
                  >
                    <div className={`menubar-sidebar-link`}>
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isReview" && "isActiveSection"
                          }`}
                      >
                        <i
                          className={`bi bi-hand-thumbs-up  menubar-sidebar-icon`}
                        ></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.review")} & {t("vendor.ratings")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={(e) =>
                      handleSectionClick(e, "isFavourite", CustomerRoutes.favouriteShop)
                    }
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isFavourite" && "isActiveSection"
                      }`}
                  >
                    <div className={`menubar-sidebar-link`}>
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isFavourite" && "isActiveSection"
                          }`}
                      >
                        <i
                          className={`bi bi-suit-heart menubar-sidebar-icon`}
                        ></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.favorite")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={(e) => handleSectionClick(e, "isCart", CustomerRoutes.addToCart)}
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isCart" && "isActiveSection"
                      }`}
                  >
                    <div className={`menubar-sidebar-link`}>
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isCart" && "isActiveSection"
                          }`}
                      >
                        <i className={`bi bi-cart3 menubar-sidebar-icon`}></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.carts")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="menu-sidebar-outer-div near-me-text">
                    <div
                      onClick={handleNearbyShopClick}
                      className={`menubar-sidebar-link`}
                    >
                      <div className={`menubar-sidebar-inner-div`}>
                        <i className={`bi bi-geo-alt menubar-sidebar-icon`}></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("header.nearbutton")}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      onClick={handleCategoryClick}
                      className={`menu-sidebar-outer-div mobile-menu-trigger-category ${isSection === "isCategory" && "isActiveSection"
                        }`}
                    >
                      <div className={`menubar-sidebar-link`}>
                        <div
                          className={`menubar-sidebar-inner-div ${isSection === "isCategory" && "isActiveSection"
                            }`}
                        >
                          <ProductOutlined className="menubar-sidebar-icon" />
                          <span className={`menubar-sidebar-text`}>
                            {t("ServiceField.category")}
                          </span>
                        </div>
                      </div>
                    </div>
                    {(showCategory) && (
                      <NavbarCategory hideDisplayName={hideDisplayName} setHideDisplayName={setHideDisplayName} showCategory={showCategory} />
                    )}
                  </div>
                  <div
                    onClick={handleNearbyShopClick}
                    className="d-flex menu-sidebar-outer-div near-me-section"
                  >
                    <div className={`menubar-sidebar-link`}>
                      <div className={`menubar-sidebar-inner-div`}>
                        <i className={`bi bi-geo-alt menubar-sidebar-icon`}></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("header.nearbutton")}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : isTeamMember ? (
                <>
                  <div
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isVendorProfile" && "isActiveSection"
                      }`}
                  >
                    <div
                      onClick={(e) =>
                        handleSectionClick(e, "isVendorProfile", CustomerRoutes.profile)
                      }
                      className={`menubar-sidebar-link`}
                    >
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isVendorProfile" && "isActiveSection"
                          }`}
                      >
                        <i className={`bi bi-person menubar-sidebar-icon`}></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.myProfile")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`d-flex menu-sidebar-outer-div  ${isSection === "isBusinessBooking" && "isActiveSection"
                      }`}
                  >
                    <div
                      onClick={(e) =>
                        handleSectionClick(
                          e,
                          "isBusinessBooking",
                          BusinessRoutes.bussinessbookings
                        )
                      }
                      className={`menubar-sidebar-link`}
                    >
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isBusinessBooking" && "isActiveSection"
                          }`}
                      >
                        <i
                          className={`bi bi-calendar-week menubar-sidebar-icon`}
                        ></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.bookings")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isBusinessCalender" && "isActiveSection"
                      }`}
                  >
                    <div
                      onClick={(e) =>
                        handleSectionClick(e, "isBusinessCalender", BusinessRoutes.calender)
                      }
                      className={`menubar-sidebar-link`}
                    >
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isBusinessCalender" &&
                          "isActiveSection"
                          }`}
                      >
                        <i
                          className={`bi bi-calendar3 menubar-sidebar-icon`}
                        ></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.calender")}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isDashboard" && "isActiveSection"
                      }`}
                  >
                    <div
                      onClick={(e) =>
                        handleSectionClick(e, "isDashboard", BusinessRoutes.dashboard)
                      }
                      className={`menubar-sidebar-link`}
                    >
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isDashboard" && "isActiveSection"
                          }`}
                      >
                        <i
                          className={`bi  bi-speedometer2 menubar-sidebar-icon`}
                        ></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.dashboard")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isVendorProfile" && "isActiveSection"
                      }`}
                  >
                    <div
                      onClick={(e) =>
                        handleSectionClick(e, "isVendorProfile", CustomerRoutes.profile)
                      }
                      className={`menubar-sidebar-link`}
                    >
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isVendorProfile" && "isActiveSection"
                          }`}
                      >
                        <i className={`bi bi-person menubar-sidebar-icon`}></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.myProfile")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isBusinessAccount" && "isActiveSection"
                      }`}
                  >
                    <div
                      onClick={(e) =>
                        handleSectionClick(
                          e,
                          "isBusinessAccount",
                          BusinessRoutes.accountDetails
                        )
                      }
                      className={`menubar-sidebar-link`}
                    >
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isBusinessAccount" && "isActiveSection"
                          }`}
                      >
                        <i
                          className={`bi bi-shop-window menubar-sidebar-icon`}
                        ></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.accountSetting")}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isBusinessFinance" && "isActiveSection"
                      }`}
                  >
                    <div
                      onClick={(e) =>
                        handleSectionClick(e, "isBusinessFinance", BusinessRoutes.finance)
                      }
                      className={`menubar-sidebar-link`}
                    >
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isBusinessFinance" && "isActiveSection"
                          }`}
                      >
                        <i className={`bi bi-bank menubar-sidebar-icon`}></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.finance")}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isBusinessPayout" && "isActiveSection"
                      }`}
                  >
                    <div
                      onClick={(e) =>
                        handleSectionClick(e, "isBusinessPayout", BusinessRoutes.payout)
                      }
                      className={`menubar-sidebar-link`}
                    >
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isBusinessPayout" && "isActiveSection"
                          }`}
                      >
                        <i className={`bi bi-wallet menubar-sidebar-icon`}></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.payout")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isBusinessService" && "isActiveSection"
                      }`}
                  >
                    <div
                      onClick={(e) =>
                        handleSectionClick(
                          e,
                          "isBusinessService",
                          BusinessRoutes.bussinessServices
                        )
                      }
                      className={`menubar-sidebar-link`}
                    >
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isBusinessService" && "isActiveSection"
                          }`}
                      >
                        <i
                          className={`bi bi-collection menubar-sidebar-icon`}
                        ></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("ServiceField.services")}
                        </span>
                      </div>
                    </div>
                  </div>
                  {isVendor && (
                    <>
                      <div
                        className={`d-flex menu-sidebar-outer-div ${isSection === "isBusinessBranch" && "isActiveSection"
                          }`}
                      >
                        <div
                          onClick={(e) =>
                            handleSectionClick(e, "isBusinessBranch", BusinessRoutes.branches)
                          }
                          className={`menubar-sidebar-link`}
                        >
                          <div
                            className={`menubar-sidebar-inner-div ${isSection === "isBusinessBranch" &&
                              "isActiveSection"
                              }`}
                          >
                            <i
                              className={`bi bi-diagram-3 menubar-sidebar-icon`}
                            ></i>
                            <span className={`menubar-sidebar-text`}>
                              {t("vendor.branches")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isBusinessTeamSetup" && "isActiveSection"
                      }`}
                  >
                    <div
                      onClick={(e) =>
                        handleSectionClick(
                          e,
                          "isBusinessTeamSetup",
                          BusinessRoutes.teamMembers
                        )
                      }
                      className={`menubar-sidebar-link`}
                    >
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isBusinessTeamSetup" &&
                          "isActiveSection"
                          }`}
                      >
                        <i className={`bi bi-people menubar-sidebar-icon`}></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("teamSetupField.teamSetup")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`d-flex menu-sidebar-outer-div  ${isSection === "isBusinessBooking" && "isActiveSection"
                      }`}
                  >
                    <div
                      onClick={(e) =>
                        handleSectionClick(
                          e,
                          "isBusinessBooking",
                          BusinessRoutes.bussinessbookings
                        )
                      }
                      className={`menubar-sidebar-link`}
                    >
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isBusinessBooking" && "isActiveSection"
                          }`}
                      >
                        <i
                          className={`bi bi-calendar-week menubar-sidebar-icon`}
                        ></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.bookings")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isBusinessCalender" && "isActiveSection"
                      }`}
                  >
                    <div
                      onClick={(e) =>
                        handleSectionClick(e, "isBusinessCalender", BusinessRoutes.calender)
                      }
                      className={`menubar-sidebar-link`}
                    >
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isBusinessCalender" &&
                          "isActiveSection"
                          }`}
                      >
                        <i
                          className={`bi bi-calendar3 menubar-sidebar-icon`}
                        ></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.calender")}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`d-flex menu-sidebar-outer-div ${isSection === "isBusinessReview" && "isActiveSection"
                      }`}
                  >
                    <div
                      onClick={(e) =>
                        handleSectionClick(
                          e,
                          "isBusinessReview",
                          BusinessRoutes.businessReview
                        )
                      }
                      className={`menubar-sidebar-link`}
                    >
                      <div
                        className={`menubar-sidebar-inner-div ${isSection === "isBusinessReview" && "isActiveSection"
                          }`}
                      >
                        <i
                          className={`bi-hand-thumbs-up menubar-sidebar-icon`}
                        ></i>
                        <span className={`menubar-sidebar-text`}>
                          {t("vendor.review")} & ${t("vendor.ratings")}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="menu-sidebar-header-section">
              <i className="bi bi-x menu-sidebar-close" onClick={onClose}></i>
            </div>
            <div className="menu-sidebar-body mt-2">
              <div className="d-flex menu-sidebar-outer-div">
                <div
                  onClick={(e) => handleSectionClick(e, "signIn", AuthRoutes.signIn, true)}
                  className={`menubar-sidebar-link`}
                >
                  <div className={`menubar-sidebar-inner-div `}>
                    <i
                      className={`bi bi-box-arrow-right menubar-sidebar-icon`}
                    ></i>
                    <span className={`menubar-sidebar-text`}>
                      {t("header.signin")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex menu-sidebar-outer-div">
                <div
                  onClick={(e) =>
                    handleSectionClick(e, "signIn", `${AuthRoutes.signUp}${CustomerRoutes.user}`, true)
                  }
                  className={`menubar-sidebar-link`}
                >
                  <div className={`menubar-sidebar-inner-div`}>
                    <i className={`bi bi-person menubar-sidebar-icon`}></i>
                    <span className={`menubar-sidebar-text`}>
                      {t("header.signup")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex menu-sidebar-outer-div">
                <div
                  onClick={() => setIsOpenBusiness(!isOPenBusiness)}
                  className={`menubar-sidebar-link`}
                >
                  <div className={`menubar-sidebar-inner-div`}>
                    <i className={`bi bi-person menubar-sidebar-icon`}></i>
                    <span className={`menubar-sidebar-text`}>
                      {t("header.becomPartner")} &nbsp;&nbsp;
                      <i
                        className={
                          isOPenBusiness
                            ? `bi bi-chevron-up`
                            : `bi bi-chevron-down issignup`
                        }
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={
                  isOPenBusiness
                    ? `signup-sub-menu-outer-div`
                    : `not-open-sign-sub-menu`
                }
              >
                <div className="d-flex menu-sidebar-outer-div">
                  <div
                    onClick={(e) =>
                      handleSectionClick(
                        e,
                        "signIn",
                        `${AuthRoutes.signUp}${BusinessRoutes.vendor}`,
                        true
                      )
                    }
                    className={`menubar-sidebar-link`}
                  >
                    <div className={`menubar-sidebar-inner-div`}>
                      <i
                        className={`bi bi-shop-window menubar-sidebar-icon`}
                      ></i>
                      <span className={`menubar-sidebar-text`}>
                        {t("header.asVendor")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex menu-sidebar-outer-div">
                  <div
                    onClick={(e) =>
                      handleSectionClick(
                        e,
                        "signIn",
                        `${AuthRoutes.signUp}${BusinessRoutes.freelancer}`,
                        true
                      )
                    }
                    className={`menubar-sidebar-link`}
                  >
                    <div className={`menubar-sidebar-inner-div`}>
                      <i className={`bi bi-person menubar-sidebar-icon`}></i>
                      <span className={`menubar-sidebar-text`}>
                        {t("header.asFreelancer")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={handleCategoryClick}
                className={`menu-sidebar-outer-div mobile-menu-trigger-category ${isSection === "isCategory" && "isActiveSection"
                  }`}
              >
                <div className={`menubar-sidebar-link`}>
                  <div
                    className={`menubar-sidebar-inner-div ${isSection === "isCategory" && "isActiveSection"
                      }`}
                  >
                    <ProductOutlined className="menubar-sidebar-icon" />
                    <span className={`menubar-sidebar-text`}>
                      {t("ServiceField.category")}
                    </span>
                  </div>
                </div>
              </div>
              {(showCategory) && <NavbarCategory hideDisplayName={hideDisplayName} setHideDisplayName={setHideDisplayName} showCategory={showCategory} />}

              <div className="d-flex menu-sidebar-outer-div">
                <div
                  onClick={handleNearbyShopClick}
                  className={`menubar-sidebar-link`}
                >
                  <div className={`menubar-sidebar-inner-div`}>
                    <i className={`bi bi-geo-alt menubar-sidebar-icon`}></i>
                    <span className={`menubar-sidebar-text`}>
                      {t("header.nearbutton")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};
export default MenuSlider;