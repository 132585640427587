import './PrivacyPolicyPage.css';
import { React, PropTypes, useEffect, useState } from '../../libraries';
import { CustomSpinner, Footer } from '../../common';
import { NavbarLogic } from '../../components';
import { BreadCrumb } from '../BreadCrumb/BreadCrumb';
import { CustomImage } from '../../lazyComponent';
import { MetaTag } from "../../customHooks/index";
const PrivacyPolicyPage = ({ data, breadCrumbData, isLoading }) => {
  const selectedLanguage = localStorage.getItem('i18nextLng');
  const [privacy, setPrivacy] = useState(0);
  useEffect(() => { }, [selectedLanguage, privacy]);

  const removeHtmlTags = (input) => {
    const doc = new DOMParser().parseFromString(
      `<div charset="windows-874">${input}</div>`,
      "text/html"
    );
    const lines = (doc.body.textContent || "").split(/\r?\n/); // Split text into lines
    const resultLines = [];

    lines.forEach((line) => {
      if (line !== undefined && line !== null) {
        if (/\d/.test(line)) {
          resultLines.push("<br><br>"); // Add line break if line contains a number
        }
        resultLines.push(line);
      }
    });

    return resultLines.join("\n"); // Join lines back into a single string
  };

  const privacy_policies_titleName = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? data?.privacy_policy?.title_in_thai
      : data?.privacy_policy?.title;
  };

  const privacy_policies_desc = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? (data?.privacy_policy?.description_in_thai)
      : data?.privacy_policy?.description;
  };
  const sub_heading =
    selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? removeHtmlTags(data?.sub_content?.sub_heading_in_thai)
      : data?.sub_content?.sub_heading;

  const sub_content_des = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? (data?.sub_content?.description_in_thai)
      : data?.sub_content?.description;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaTag custom_title={data?.privacy_policy?.title} custom_description={data?.privacy_policy?.description} />

      <NavbarLogic setPrivacy={setPrivacy} />
      <div className="container about-us-outer-container">
        <BreadCrumb data={breadCrumbData} />
        {!(isLoading) ? <>
          <div className="aboutus-img-div">
            <div className="row">
              <CustomImage
                alt="Privacy and policy"
                src={data?.privacy_policy_image_url}
                className={
                  data?.privacy_policy_image_url ? `img-fluid` : "d-none"
                }
                width={"unset"}
                height={"unset"}
              />
              <div
                className={`${data?.privacy_policy_image_url
                  ? "aboutus-title text-center"
                  : "text-center title-business"
                  }`}
              >
                {privacy_policies_titleName(data)}
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <>
              <p
                className="description_in_thai"
                dangerouslySetInnerHTML={{
                  __html: privacy_policies_desc(data),
                }}
              ></p>
            </>
          </div>
          {data?.privacy_policy_sub_content_data?.map((data, index) => (
            <div key={index} className="row mt-5 mb-5">
              {data?.sub_content_image_url ? (
                <>
                  <div className="col-md-6">
                    <p className="sub-heading-content">{sub_heading(data)}</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sub_heading(data),
                      }}
                    ></p>
                  </div>
                  <div className="col-md-6">
                    <CustomImage
                      alt="Sub Content"
                      src={data?.sub_content_image_url}
                      className="img-fluid"
                      width={"unset"}
                      height={"unset"}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-12">
                    <p className="sub-heading-content">{sub_heading(data)}</p>
                    <p
                      className="description_in_thai"
                      dangerouslySetInnerHTML={{
                        __html: sub_content_des(data),
                      }}
                    ></p>
                  </div>
                </>
              )}
            </div>
          ))}
        </> : <>
          <CustomSpinner />
        </>}
      </div>
      <div className={`${(isLoading) ? 'loader-blog-section' : ''}`}>
        <Footer />
      </div>
    </>
  );
};
PrivacyPolicyPage.propTypes = {
  data: PropTypes.shape({
    privacy_policy_image_url: PropTypes.string,
    privacy_policy: PropTypes.shape({
      title_in_thai: PropTypes.string,
      title: PropTypes.string,
      description_in_thai: PropTypes.string,
      description: PropTypes.string,
      sub_content: PropTypes.shape({
        sub_heading_in_thai: PropTypes.string,
        sub_heading: PropTypes.string,
        description_in_thai: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
    privacy_policy_sub_content_data: PropTypes.arrayOf(
      PropTypes.shape({
        sub_content_image_url: PropTypes.string,
        sub_content: PropTypes.shape({
          sub_heading_in_thai: PropTypes.string,
          sub_heading: PropTypes.string,
          description_in_thai: PropTypes.string,
          description: PropTypes.string,
        }),
      })
    ),
  }),
};
export default PrivacyPolicyPage;