import './AddToCart.css';
import { React, useEffect, Locale } from '../../libraries/index.js';
import { VendorHeader } from '../../vendor/pages';
import { CustomSpinner, Heading, EmptyComponent } from '../../common';
import { AddToCartModalLogic } from '../../components';
import { DefaultServiceImg, DefaultShop } from '../../assets/Images';
import { CurrencyDisplay, DiscountPercent } from '../../jsonData';
import { CustomImage } from '../../lazyComponent/index.js';
const AddToCart = ({
  handleAddToCartCount,
  isEmpty,
  isLoader,
  removeSelectedItems,
  handleCheckboxChange,
  handleSelectAll,
  calculateSubtotal,
  selectAll,
  products,
  checkboxState,
  deleteServiceId,
  navigatePaymentPage,
  showBookNowModel,
  setShowBookNowModel,
  cartPaymentDetails,
  whereLocation,
  navigateOrganizationSection
}) => {
  const { t } = Locale();
  const selectedLanguage = localStorage.getItem("i18nextLng")
  const deletionRequest = localStorage.getItem('status') === 'requested';

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("isSection", "isCart")
  }, []);

  const checkActiveCheckout = (checkboxState) => {
    if (Object.values(checkboxState).some((value) => value === true)) {
      return 'checkout-btn-active';
    } else return 'checkout-btn';
  };

  const getItemServiceName = (service) => {
    return (selectedLanguage === "en") ? (service?.service_name) : (service?.service_name_thai)
  }
  const getItemOrganizationName = (service) => {
    return ((selectedLanguage === "thai") ? ((service?.organization_thai) ? (service?.organization_thai) : (service?.organization_name)) : (service?.organization_name))

  }
  return (
    <>
      <div
        className={`heading-outer-div user-outer-div ${deletionRequest ? 'deletionRequest-user-header' : ''}`}
      >
        <VendorHeader handleAddToCartCount={handleAddToCartCount} />
        <Heading text={t('breadcrumb.cart')} icon={'bi bi-cart3'} />
        <div className="after-heading-outer-div">
          <div className="user-common-box user-add-to-cart-common-box">
            <div className="user-common-box-inner">
              <div className="add-to-cart-outer-most-container">
                {showBookNowModel && (
                  <AddToCartModalLogic
                    showBookNowModel={showBookNowModel}
                    setShowBookNowModel={setShowBookNowModel}
                    products={products}
                    deleteServiceId={deleteServiceId}
                    cartPaymentDetails={cartPaymentDetails}
                    whereLocation={whereLocation}
                  />
                )}
                <div
                  className={showBookNowModel ? `fade modal-backdrop show` : ''}
                ></div>
                <div className="container-fluid user-add-to-cart-outer-container">
                  <div
                    className={
                      !isLoader ? `user-add-to-cart-inner-div mt-3` : 'd-none'
                    }
                  >
                    {isEmpty && (
                      <>
                        <EmptyComponent type="isCart" />
                      </>
                    )}
                    {products && products?.length > 0 && (
                      <>
                        <section className="h-100 gradient-custom">
                          <div className="row d-flex justify-content-center">
                            <div className="col-lg-8">
                              <div className="card mb-4">
                                <div className="card-header py-3">
                                  <h5 className="cart-heading">
                                    {t('cart.yourCart')} {products?.length}{' '}
                                    {t('cart.item')}
                                  </h5>
                                  <div className="mt-1 row product">
                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                      <div className="col-9 select-all-div">
                                        <input
                                          className="add-to-cart-checkbox form-check-input"
                                          type="checkbox"
                                          onChange={handleSelectAll}
                                          checked={selectAll}
                                          id="selectAllCheck"
                                        />
                                        <label
                                          htmlFor="selectAllCheck"
                                          className="selectAll-lable cursor-point-class"
                                        >
                                          {t('header.select')}{' '}
                                          {t('ServiceField.all')}
                                        </label>
                                      </div>
                                      <div className="col-3 delete-icon text-end">
                                        <i
                                          className="bi bi-trash3 cursor-point-class"
                                          onClick={() =>
                                            removeSelectedItems(deleteServiceId)
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body  user-inner-content-scrollar user-inner-content-scrollar-add-to-cart">
                                  {products && products?.length > 0 ? (
                                    products.map((item, index) => (item?.cart_services_id &&
                                      <div key={index}>
                                        <div className="row add-to-cart-outer-row-map">
                                          <div className="col-1  mx-2">
                                            <input
                                              className="add-to-cart-checkbox form-check-input"
                                              type="checkbox"
                                              id={`checkbox-${index}`}
                                              name={`checkbox-${index}`}
                                              checked={
                                                checkboxState[index] || false
                                              }
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  index,
                                                  item.cart_services_id,
                                                  item.service_image,
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-3">
                                            <div className="bg-image-cart hover-overlay hover-zoom ripple rounded add-to-cart-shop-img">
                                              <CustomImage
                                                src={
                                                  item.service_image || DefaultServiceImg
                                                }
                                                alt="Product"
                                                width={'unset'}
                                                className="view-all-function-class"
                                                onClick={(e) =>
                                                  navigateOrganizationSection(
                                                    e,
                                                    item.organization_id,
                                                    item.organization_name
                                                      ? item.organization_name
                                                      : item.service_name,
                                                  )
                                                }
                                                height={'unset'}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-6 ">
                                            <h6
                                              className="view-all-function-class"
                                              onClick={(e) =>
                                                navigateOrganizationSection(
                                                  e,
                                                  item.organization_id,
                                                  item.organization_name
                                                    ? item.organization_name
                                                    : item.service_name,
                                                )
                                              }
                                            >
                                              <strong
                                              >
                                                {getItemServiceName(item)}
                                              </strong>
                                            </h6>
                                            <div>
                                              {item.organization_name && (
                                                <p className="cursor-point-class add-font-size add-to-cart-org-name"
                                                  onClick={(e) =>
                                                    navigateOrganizationSection(
                                                      e,
                                                      item.organization_id,
                                                      item.organization_name
                                                        ? item.organization_name
                                                        : item.service_name,
                                                    )
                                                  }
                                                >
                                                  {getItemOrganizationName(item)}
                                                </p>
                                              )}
                                            </div>
                                            <div className="">
                                              <div className="me-2 mt-1">
                                                <span className="add-font-size1">
                                                  <i className="bi bi-clock-fill"></i>
                                                  &nbsp;{item.duration}{' '}
                                                  <span className="add-font-size-price">
                                                    {t('paymentSection.min')}
                                                  </span>
                                                </span>
                                                <br />
                                              </div>
                                              <div
                                                className="add-to-cart-currency text-truncate add-font-size mt-1 display-service-price-outer"
                                              >
                                                <CurrencyDisplay
                                                  value={
                                                    item?.special_price === 0 ||
                                                      !(item?.special_price)
                                                      ? item.price
                                                      : item.special_price
                                                  }
                                                  subPrice={item.price}
                                                />
                                                <span>
                                                  <DiscountPercent value={item?.discount_on_service} />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-1  display-end-class add-to-cart-remove">
                                            <i
                                              className="bi bi-trash3 cursor-point-class"
                                              onClick={() =>
                                                removeSelectedItems(
                                                  [item.cart_services_id],
                                                  'singleSelect',
                                                )
                                              }
                                            ></i>
                                          </div>
                                          <hr className="m-4 add-to-cart-product-hr-tag" />
                                        </div>
                                        <div className="add-to-cart-outer-row-map-mobile">
                                          <div className="add-to-cart-action-div">
                                            <div>
                                              <input
                                                className="add-to-cart-checkbox form-check-input"
                                                type="checkbox"
                                                id={`checkbox-${index}`}
                                                name={`checkbox-${index}`}
                                                checked={
                                                  checkboxState[index] || false
                                                }
                                                onChange={() =>
                                                  handleCheckboxChange(
                                                    index,
                                                    item.cart_services_id,
                                                    item.service_image,
                                                  )
                                                }
                                              />
                                            </div>
                                            <div>
                                              <div className="add-cart-img-div-mobile">
                                                <CustomImage
                                                  src={
                                                    item.service_image ||
                                                    DefaultShop
                                                  }
                                                  className="add-to-cart-shop-img-mobile img-fluid"
                                                  alt="Product"
                                                  width={'unset'}
                                                  height={'unset'}
                                                />
                                              </div>
                                            </div>
                                            <div>
                                              <i
                                                className="bi bi-trash3 cursor-point-class"
                                                onClick={() =>
                                                  removeSelectedItems(
                                                    [item.cart_services_id],
                                                    'singleSelect',
                                                  )
                                                }
                                              ></i>
                                            </div>
                                          </div>
                                          <div className="add-to-cart-service-div mt-2">
                                            <div
                                              className="display-service-heading cursor-point-class venue-name-h4"
                                              onClick={(e) =>
                                                navigateOrganizationSection(
                                                  e,
                                                  item.organization_id,
                                                  item.organization_name
                                                    ? item.organization_name
                                                    : item.service_name,
                                                )
                                              }
                                            >
                                              <strong>
                                                {item.service_name}
                                              </strong>
                                            </div>
                                          </div>
                                          <div className="add-to-cart-organisation-and-time-div">
                                            <div>
                                              {item.organization_name && (
                                                <p className="add-font-size">
                                                  {item.organization_name}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="add-to-cart-price-div-mobile">
                                            <div>
                                              <span className="add-font-size">
                                                <i className="bi bi-clock-fill"></i>
                                                &nbsp;{item.duration}{' '}
                                                <span className="add-font-size-price">
                                                  {t('notification.minute')}
                                                </span>
                                              </span>
                                              <br />
                                            </div>
                                            <div
                                              className=" text-truncate add-font-size"
                                            >
                                              <CurrencyDisplay
                                                value={
                                                  item?.special_price === 0 ||
                                                    !(item?.special_price)
                                                    ? item.price
                                                    : item.special_price
                                                }
                                                subPrice={item.price}
                                              />
                                            </div>
                                          </div>

                                          <hr
                                            className={`add-to-cart-product-hr-tag ${index + 1 === products?.length ? 'd-none' : ''}`}
                                          />
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <p></p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="card mb-4">
                                <div className="card-header py-3">
                                  <h5 className="mb-0 cart-heading">
                                    {t('cart.yourOrderSummary')}
                                  </h5>
                                </div>
                                <div className="card-body">
                                  <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                                      <span>
                                        {t('cart.subtotal')}: (
                                        {calculateSubtotal().count}{' '}
                                        {t('teamSetupField.services')})
                                      </span>
                                      <span>
                                        <CurrencyDisplay
                                          formatedValue={
                                            cartPaymentDetails?.total_amount
                                          }
                                        />
                                      </span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                                      <span>{t('cart.discount')}</span>
                                      <span>
                                        <CurrencyDisplay
                                          formatedValue={
                                            cartPaymentDetails?.total_discount
                                          }
                                        />
                                      </span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0"></li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                                      <span>{t('cart.grandTotal')}</span>
                                      <span>
                                        <CurrencyDisplay
                                          formatedValue={
                                            cartPaymentDetails?.paid_amount
                                          }
                                        />
                                      </span>
                                    </li>
                                  </ul>
                                  <button
                                    onClick={() => navigatePaymentPage()}
                                    className={checkActiveCheckout(
                                      checkboxState,
                                    )}
                                  >
                                    {t('button.checkOutButton')}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </>
                    )}
                  </div>
                  {isLoader && (
                    <>
                      <CustomSpinner />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddToCart;