import './confirm.css';
import { React, PropTypes, Locale, useEffect, useState } from '../../libraries';
import { Modal, Button } from 'react-bootstrap';
import { VarificationCodeInput } from '../index';

const MyModal = ({
  otp,
  iscompleteModal,
  handleOptChange,
  isComplte,
  service_component,
  reason,
  isCancel,
  showModal,
  handleClose,
  deleteServices,
  message,
  deleteItemId,
  component,
  navigateFun,
  singleSelect,
}) => {
  const { t } = Locale();
  const [isError, setIsError] = useState(true);
  const [updateCancelReason, setUpdateCancelReason] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    setUpdateCancelReason(value);
    setIsError(value.trim() === '');
  };
  useEffect(() => {
    isComplte ? setIsError(false) : setIsError(true);
  }, [isComplte]);


  return (
    <>
      {iscompleteModal ? (
        <>
          <Modal
            show={showModal}
            onHide={() => handleClose({ status: 'cancel' })}
            dialogClassName="custom-modal customModal"
          >
            <Modal.Header
              closeButton
              className="headerLine"
              onClick={() => handleClose({ status: 'cancel' })}
            />
            <Modal.Body>
              <div className="text-center">
                <div className="delete-pop-up-message">
                  {t('venderBooking.enterOtp')}
                </div>
                <div>
                  <VarificationCodeInput
                    id="verificationCode"
                    name="verificationCode"
                    value={otp?.verificationCode}
                    onChange={(e) =>
                      handleOptChange({
                        target: { name: 'verificationCode', value: e },
                      })
                    }

                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center footerLine">
              <Button
                className="btn btn-light cancelbutton"
                onClick={() => handleClose({ status: 'cancel' })}
              >
                {t('button.cancelButton')}
              </Button>
              <Button
                className="btn btn-light deletebutton"
                disabled={(otp?.verificationCode).length < 6}
                onClick={() => deleteServices(deleteItemId, updateCancelReason)}
              >
                {t('button.yes')}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <>
          {(component === 'addToCart' && deleteItemId?.length > 0) ||
            !component ||
            singleSelect ? (
            <>
              <Modal
                show={showModal}
                onHide={() => handleClose({ status: 'cancel' })}
                dialogClassName="custom-modal customModal"
              >
                <Modal.Header
                  closeButton
                  className="headerLine"
                  onClick={() => handleClose({ status: 'cancel' })}
                />
                <Modal.Body>
                  <div className="text-center">
                    <p className="delete-pop-up-message">
                      {t('teamSetupField.areYouSureYouWantTo')}
                      <br />
                      {message}
                    </p>
                    {isCancel &&
                      message === `${t('userBooking.areYouSure')}` && (
                        <div className="form-floating mb-3">
                          <textarea
                            name="cancel_eason"
                            className="form-control height100px"
                            id="floatingTextArea"
                            onChange={handleInputChange}
                            value={reason}
                          />
                          <label htmlFor="floatingTextArea">
                            {t('signMessage.commentHere')}
                            <span className="gl-form-asterisk">
                              <span className="small-err">*</span>
                            </span>
                          </label>
                        </div>
                      )}
                  </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center footerLine">
                  <Button
                    className="btn btn-light cancelbutton"
                    onClick={() => handleClose({ status: 'cancel' })}
                  >
                    {isCancel ? t('button.no') : t('button.cancelButton')}
                  </Button>

                  {service_component === true ? (
                    <Button
                      className="btn btn-light deletebutton"
                      onClick={() =>
                        deleteServices(deleteItemId, updateCancelReason)
                      }
                    >
                      {isCancel ? t('button.yes') : t('button.deleteButton')}
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-light deletebutton"
                      disabled={isError}
                      onClick={() =>
                        deleteServices(deleteItemId, updateCancelReason)
                      }
                    >
                      {isCancel ? t('button.yes') : t('button.deleteButton')}
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <>
              <Modal
                show={showModal}
                dialogClassName="custom-modal customModal"
              >
                <Modal.Header className="headerLine" />
                <Modal.Body>
                  <div className="text-center">
                    <p className="delete-pop-up-message">
                      <br />
                      {message}
                    </p>
                  </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center footerLine">
                  <Button
                    className="btn btn-light deletebutton"
                    onClick={() =>
                      navigateFun ? navigateFun() : handleClose()
                    }
                  >
                    {t('button.ok')}
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
};
MyModal.propTypes = {
  otp: PropTypes.string,
  iscompleteModal: PropTypes.bool,
  isComplte: PropTypes.bool,
  service_component: PropTypes.bool,
  handleOptChange: PropTypes.func,
  showModal: PropTypes.bool,
  handleClose: PropTypes.func,
  deleteServices: PropTypes.func,
  message: PropTypes.string,
  deleteItemId: PropTypes.number,
};
export default MyModal;
