import './WorkingHours.css';
import { React, Locale, Option, Select } from '../../../libraries';

const TeamMemberWorkingHours = ({
  working_hours,
  openingHourseFormValue,
  setOpeningHourseFormValue,
  day,
  thaiDay,
  value,
  teamMemberServices,
  teamMemberBranch
}) => {
  const { t } = Locale();
  const selectedLanguage = localStorage.getItem('i18nextLng');
  const toggleShopOpen = (currentDay) => {
    const currentValue =
      openingHourseFormValue.bussiness_hours?.[currentDay]?.shop_open;
    setOpeningHourseFormValue((prevState) => ({
      ...prevState,
      bussiness_hours: {
        ...prevState.bussiness_hours,
        [currentDay]: {
          ...prevState.bussiness_hours?.[currentDay],
          shop_open: !currentValue,
        },
      },
    }));
  };
  const getServiceName = (obj) => {
    return selectedLanguage === 'en' ? obj.name_en : obj.name_thai;
  };

  const renderServiceColumn = (obj, index) => (
    <div
      className="col-lg-3 col-md-4 col-sm-4 team-member-service-name"
      key={index}
    >
      {getServiceName(obj)}
    </div>
  );

  const renderNoServiceAssigned = () => (
    <div className="d-flex justify-content-center">
      {t('vendor.noServiceAssigned')}
    </div>
  );

  const renderBranchName = (teamMemberBranch) => (
    <div className="team-member-service-inner-div">
      <div className='team-member-service-name' >
        {teamMemberBranch}
      </div>
    </div>
  );

  const renderNoBranchAssigned = () => (
    <div className="d-flex justify-content-center">
      {t('vendor.noBranchAssigned')}
    </div>
  )

  const getHideWorkingHoursClassName = (currentDay) => {
    return working_hours[currentDay.toLowerCase()]?.shop_open === 'true' ||
      working_hours[currentDay.toLowerCase()]?.shop_open === true
      ? ''
      : 'd-none';
  };

  return (
    <div className="container-fluid mt-2">
      <div className="OpeningHoursPolicies_BuisnesHourOuterDiv p-2">
        <div>
          <h5 className="mt-2 opening-hours-outer-heading">
            {t('vendor.workingHour')}
          </h5>
          <div className="OpeningHoursPolicies_DayWise_Time">
            {day &&
              day.map((currentDay, key) => (
                <div
                  className={`row mt-2 ${getHideWorkingHoursClassName(currentDay)}`}
                  key={key}
                >
                  <div className="col openingHourseDayDiv mt-2">
                    <span
                      className="openign-hourse-span-current-day cursor-point-class"
                      onClick={() => toggleShopOpen(currentDay.toLowerCase())}
                    >
                      {thaiDay[key]}
                    </span>
                  </div>
                  <div className="col a vendor-opening-horse-open-time">
                    <Select
                      className="opening-hours-select-tag-open"
                      disabled={true}
                      value={
                        working_hours &&
                        working_hours[currentDay.toLowerCase()]?.open
                      }
                    >
                      {value.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="col a opening-hourse-to-div text-color">
                    {t('vendor.to')}
                  </div>
                  <div className="col a vendor-opening-horse-close-time">
                    <Select
                      className="opening-hours-select-tag-open"
                      disabled={true}
                      value={
                        working_hours &&
                        working_hours[currentDay.toLowerCase()]?.close
                      }
                      placeholder={t('header.select')}
                    >
                      {value
                        .filter(
                          (item) =>
                            openingHourseFormValue?.bussiness_hours &&
                            item >
                            openingHourseFormValue.bussiness_hours[
                              currentDay.toLowerCase()
                            ]?.open,
                        )
                        .map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="team-member-services-outer-div p-2">
        <h5 className="mt-2 opening-hours-outer-heading">
          {t('vendor.branch')}
        </h5>
        <div
          className={
            teamMemberServices &&
            teamMemberServices.length > 0 &&
            `team-meber-service-list-outer-div`
          }
        >
          {teamMemberBranch ? renderBranchName(teamMemberBranch) : renderNoBranchAssigned()}
        </div>
      </div>

      <div className="team-member-services-outer-div p-2">
        <h5 className="mt-2 opening-hours-outer-heading">
          {t('teamSetupField.services')}
        </h5>
        <div
          className={
            teamMemberServices &&
            teamMemberServices.length > 0 &&
            `team-meber-service-list-outer-div`
          }
        >
          {teamMemberServices && teamMemberServices.length > 0 ? (
            <div className="team-member-service-inner-div">
              <div className="row">
                {teamMemberServices.map((obj, index) =>
                  renderServiceColumn(obj, index),
                )}
              </div>
            </div>
          ) : (
            renderNoServiceAssigned()
          )}
        </div>
      </div>
    </div>
  );
};
export default TeamMemberWorkingHours;