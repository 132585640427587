import { React, Locale, useEffect, useMemo, Highcharts, HighchartsAccessibility } from '../../../libraries';

HighchartsAccessibility(Highcharts);

const DashBoardGraph = ({ filterRevenue }) => {
  const { t } = Locale();
  const currentLocation=sessionStorage.getItem('country');
  const currencyIcon = currentLocation === 'India' ? '₹' : '฿';
  const months = useMemo(
    () => [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    [],
  );

  const salesData = useMemo(() => {
    const data = [];
    if (filterRevenue) {
      months.forEach((month) => {
        data.push(filterRevenue.revenue[month]?.total_sale);
      });
    }
    return data;
  }, [filterRevenue, months]);

  const commissionData = useMemo(() => {
    const data = [];
    if (filterRevenue) {
      months.forEach((month) => {
        data.push(filterRevenue.revenue[month]?.total_commission);
      });
    }
    return data;
  }, [filterRevenue, months]);

  const revenueData = useMemo(() => {
    const data = [];
    if (filterRevenue) {
      months.forEach((month) => {
        data.push(filterRevenue.revenue[month]?.total_revenue);
      });
    }
    return data;
  }, [filterRevenue, months]);

  useEffect(() => {
    const chart = Highcharts.chart('container', {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: months,
        crosshair: true,
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: '',
        },
      },
      tooltip: {
        valuePrefix:currencyIcon,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: `${t('vendor.totalSales')}`,
          data: salesData,
        },
        {
          name: `${t('vendor.commissions')}`,
          data: commissionData,
        },
        {
          name: `${t('vendor.revenue')}`,
          data: revenueData,
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
              },
              yAxis: {
                labels: {
                  align: 'left',
                  x: 0,
                  y: -5,
                },
                title: {
                  text: null,
                },
              },
              subtitle: {
                text: null,
              },
              credits: {
                enabled: false,
              },
            },
          },
        ],
      },
    });

    return () => {
      chart.destroy();
    };
  }, [commissionData, months, revenueData, salesData, t]);

  return (
    <div>
      <div id="container"></div>
    </div>
  );
};

export default DashBoardGraph;
