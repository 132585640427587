import {
  React,
  Locale,
  useEffect,
  useLocation,
  useState,
  toast,
} from '../../../libraries/index';
import { Finance } from '../../pages/index';
import {
  isRequired,
  isRequiredImage,
  isRequiredSelect,
  isValidEmail1,
  isValidNumber,
  isValidPhoneNumber,
} from '../../pages/Validation/Validation';
import {
  handleErrorResponse,
  handleSuccessResponse,
  createBillingInformation,
  fetchBillingInformation,
  updateBillingInformation,
} from '../../../services/index';
import { AddressJson, CountryCodeJson } from '../../../jsonData';
import { fetchCitiesDropdown, fetchCountries, fetchStateDropdown, fetchStates } from '../../../common/addressSelect/util';
import { isSelect, isValidPostalCode } from '../../../common/validation/fieldRegex';
import { CancelDeletionAction } from '../../../lazyComponent';


const Finances = ({ deletionRequest, setDeletionRequest }) => {
  const { t } = Locale();
  const location = useLocation();
  const initialValues = {
    title: '',
    taxId: '',
    billingAddress: '',
    company_country: '',
    bank_country: '',
    contactName: '',
    email: '',
    phoneCountryCode: '',
    phone: '',
    billingContactPhoneCountryCode: '',
    billingContactPhone: '',
    accountHolderName: '',
    accountNumber: '',
    bankBranchCode: '',
    iban: '',
    bankName: '',
    bankAddress: '',
    postal_code: '',
    bank_postal_code: '',
    cheque: '',
    chequeName: '',
    state: '',
    city: '',
    bankState: '',
    bankCity: '',

    //--------------------- india new changes ----------- 
    gstNumber: '',
    micrCode: '',
    ifscCode: ''
  };

  const previousValue = {
    title: '',
    taxId: '',
    billingAddress: '',
    contactName: '',
    email: '',
    phoneCountryCode: '',
    phone: '',
    billingContactPhoneCountryCode: '',
    billingContactPhone: '',
    accountHolderName: '',
    accountNumber: '',
    bankBranchCode: '',
    iban: '',
    bankName: '',
    bankAddress: '',
    postal_code: '',
    bank_postal_code: '',
    cheque: '',
    chequeName: '',
    company_country: '',
    bank_country: '',

    // ------------ india launch field changes 
    gstNumber: '',
    micrCode: '',
    ifscCode: ''
  };

  const addressInitialValues = {
    country: { id: '', name: '' },

    companyState: { id: '', name: '', code: '' },
    companyCity: { name: '' },
    companyPostalCode: { value: '' },

    bankState: { id: '', name: '', code: '' },
    bankCity: { name: '' },
    bankPostalCode: { value: '' }
  }

  const previousAddressInitialValues = {
    country: { id: '', name: '' },


    companyState: { id: '', name: '', code: '' },
    companyCity: { name: '' },
    companyPostalCode: { value: '' },

    bankState: { id: '', name: '', code: '' },
    bankCity: { name: '' },
    bankPostalCode: { value: '' }
  }

  const [addressFormValue, setAddressFormValue] = useState(addressInitialValues);
  const [addressPreviousFormValue, setAddressPreviousFormValue] = useState(previousAddressInitialValues);
  const [previousFormValue, setPreviousFormValue] = useState(previousValue)
  const [billingInformationResponse, setBillingInformationResponse] = useState(null);
  const [formValue, setFormValue] = useState(initialValues);
  const [formError, setFormError] = useState({});
  const [uploadedCheque, setUploadedCheque] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [postalCode, setPostalCode] = useState([]);
  const [country, setCountry] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const isSection = localStorage.getItem('isSection');
  const currentCountry = sessionStorage.getItem('country');
  const [companyCityDropDown, setComapnyCityDropdown] = useState([])
  const [bankCityDropDown, setBankCityDropdown] = useState([])
  const [stateDropdown, setStateDropdown] = useState([]);

  const registeredCountry = sessionStorage.getItem('country')
  const deletionRequestStatus = localStorage.getItem('deletionRequestStatus') === 'requested'

  useEffect(() => {
    if (isSection !== 'isBusinessFinance') {
      localStorage.setItem('isSection', 'isBusinessFinance');
    }
  }, [location.pathname]);

  useEffect(() => {
    billingInformation();

    const countryArray = CountryCodeJson.map((item) => {
      return { value: item.name, label: item.name };
    });

    const uniquePostalCodes = [
      ...new Set(AddressJson.map((item) => item.postalCode)),
    ];
    const postalCodeArray = uniquePostalCodes.map((postalCode) => ({
      value: postalCode,
      label: postalCode.toString(),
    }));

    setPostalCode(postalCodeArray);
    setCountry(countryArray);
  }, []);

  useEffect(() => {
    if (
      billingInformationResponse &&
      billingInformationResponse?.billing_information
    ) {
      let phone_number =
        billingInformationResponse?.billing_information &&
        billingInformationResponse?.billing_information &&
        billingInformationResponse?.billing_information?.phone_number &&
        billingInformationResponse?.billing_information?.phone_number.split(' ')
          .length > 1 &&
        billingInformationResponse.billing_information.phone_number

          .split(' ')[1]
          .toString();

      let c_phone_number =
        billingInformationResponse.billing_information &&
        billingInformationResponse.billing_information.contact_number &&
        billingInformationResponse.billing_information.contact_number &&
        billingInformationResponse.billing_information.contact_number.split(' ')
          .length > 1 &&
        billingInformationResponse.billing_information.contact_number
          .split(' ')[1]
          .toString();

      let tempCountryCode =
        billingInformationResponse.billing_information &&
        billingInformationResponse.billing_information &&
        billingInformationResponse.billing_information.phone_number &&
        billingInformationResponse.billing_information.phone_number.split(' ')
          .length > 1 &&
        billingInformationResponse.billing_information.phone_number
          .split(' ')[0]
          .toString();
      let tempConCountryCode =
        billingInformationResponse.billing_information &&
        billingInformationResponse.billing_information &&
        billingInformationResponse.billing_information.contact_number &&
        billingInformationResponse.billing_information.contact_number.split(' ')
          .length > 1 &&
        billingInformationResponse.billing_information.contact_number
          .split(' ')[0]
          .toString();

      const updateValue = {
        accountHolderName:
          billingInformationResponse.billing_information.account_holder_name,
        title: billingInformationResponse.billing_information.company_name,
        taxId: billingInformationResponse.billing_information.vat_number,
        gstNumber: billingInformationResponse?.billing_information?.gst_number || '',
        micrCode: billingInformationResponse?.billing_information?.micr_code || '',
        ifscCode: billingInformationResponse?.billing_information?.ifsc_code || '',
        billingAddress:
          billingInformationResponse.billing_information.billing_address,
        company_country: billingInformationResponse.billing_information.country,
        contactName:
          billingInformationResponse.billing_information.contact_name,
        email: billingInformationResponse.billing_information.email,
        phone: phone_number,
        billingContactPhone: c_phone_number,
        accountNumber:
          billingInformationResponse.billing_information.account_number,
        bankBranchCode:
          billingInformationResponse.billing_information.bank_branch_code,
        iban: billingInformationResponse.billing_information.iban,
        bankName: billingInformationResponse.billing_information.bank_name,
        bankAddress:
          billingInformationResponse.billing_information.bank_address,
        bank_country:
          billingInformationResponse.billing_information.bank_country,
        postal_code: billingInformationResponse.billing_information.postal_code,
        bank_postal_code:
          billingInformationResponse.billing_information.bank_postal_code,
        phoneCountryCode: tempCountryCode ? tempCountryCode : '+66',
        billingContactPhoneCountryCode: tempConCountryCode ? tempConCountryCode : '+66',

        cheque:
          billingInformationResponse.image_url &&
          billingInformationResponse.image_url,
        chequeName: billingInformationResponse?.file_name,
      }
      const updateAddressValue = {
        country: { id: '', name: registeredCountry, code: '' },
        companyState: { id: '', name: billingInformationResponse.billing_information.state, code: '' },
        companyCity: { name: billingInformationResponse.billing_information.city },
        companyPostalCode: { value: billingInformationResponse.billing_information.postal_code },
        bankState: { id: '', name: billingInformationResponse.billing_information.bank_state, code: '' },
        bankCity: { name: billingInformationResponse.billing_information.bank_city },
        bankPostalCode: { value: billingInformationResponse.billing_information.bank_postal_code },
      }
      setFormValue(updateValue);
      setPreviousFormValue(updateValue)
      setAddressFormValue(updateAddressValue)
      setAddressPreviousFormValue(updateAddressValue)
    }
  }, [billingInformationResponse]);

  useEffect(() => {
    fetchCountryDatas()
  }, [currentCountry])

  const billingInformation = async () => {
    try {
      let response = await fetchBillingInformation();
      setBillingInformationResponse(response?.data);
      if (response?.data?.status === 200 || response?.data?.status === 201)
        setIsUpdate(true);
      setIsLoader(false);
      handleSuccessResponse(response);
    } catch (err) {
      setIsLoader(false);
      if (err?.response?.data?.status_code === 404) {
        handleErrorResponse(err);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = getValidationHandleChange(name, value);
    setFormError(error)
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (!file.type.includes('pdf')) {
        const maxSize = 2 * 1024 * 1024;
        if (file.size > maxSize) {
          const errorMessage = `${t('validation.imageSize')}`;
          setFormError((prevErrors) => ({
            ...prevErrors,
            cheque: errorMessage,
          }));
          setUploadedCheque(null);
          setFormValue((prev) => ({
            ...prev,
            cheque: null,
          }));

          return;
        }
      }

      setFormValue((prevValues) => ({
        ...prevValues,
        cheque: file,
      }));
      setUploadedCheque(URL.createObjectURL(file));
    } else {
      setFormValue({ ...formValue, [name]: value });
    }
  };

  const handlePhoneNumberChange = (phoneNumber, countryData, name, countrCodeName) => {
    // Remove spaces from the phone number
    const sanitizedPhoneNumber = phoneNumber.replace(/\s+/g, '');

    const errorLogs = getValidationHandleChange(name, sanitizedPhoneNumber);
    setFormError(errorLogs);
    setFormValue({
      ...formValue,
      [name]: sanitizedPhoneNumber,
      [countrCodeName]: `+${countryData.dialCode}`
    });
  };


  const handlePhoneCountryCodeChange = (countryCode, countryData, name) => {
    setFormValue((prevValues) => ({
      ...prevValues,
      [name]: `+${countryCode?.dialCode}`,
    }));
  }


  const getValidationHandleChange = (name, value, uploadedCheque) => {
    // Make a copy of the current form errors
    let error = { ...formError };

    // Clear the error for the specific field before validating it
    if (error.hasOwnProperty(name)) {
      delete error[name];
    }

    // Validate the current field and update errors
    switch (name) {
      case 'billingContactPhone':
        error = isValidPhoneNumber(
          value,
          error,
          'billingContactPhone',
          `${t('financeField.billContactPhoneNumber')}`,
          '',
          t
        );
        break;
      case 'phone':
        error = isValidPhoneNumber(
          value,
          error,
          'phone',
          `${t('field.phoneNumber')}`,
          '',
          t
        );
        break;
      case 'title':
        error = isRequired(
          value,
          error,
          'title',
          `${t('financeField.companyTitle')}`,
          t
        );
        break;
      case 'billingAddress':
        error = isRequired(
          value,
          error,
          'billingAddress',
          `${t('financeField.billingAddress')}`,
          t
        );
        break;

      case 'company_country':
        error = isRequiredSelect(
          value,
          error,
          'company_country',
          `${t('financeField.country')}`,
          t
        );
        break;
      case 'contactName':
        error = isRequired(
          value,
          error,
          'contactName',
          `${t('financeField.contactName')}`,
          t
        );
        break;
      case 'email':
        error = isValidEmail1(value, error, 'email', t);
        break;
      case 'accountHolderName':
        error = isRequired(
          value,
          error,
          'accountHolderName',
          `${t('financeField.accountHolderName')}`,
          t
        );
        break;
      case 'accountNumber':
        error = isRequired(
          value,
          error,
          'accountNumber',
          `${t('financeField.accountNumber')}`,
          t
        );
        break;
      case 'bankBranchCode':
        error = isRequired(
          value,
          error,
          'bankBranchCode',
          `${t('financeField.bankBranchCode')}`,
          t
        );
        break;
      case 'iban':
        error = isRequired(value, error, 'iban', 'IBAN', t);
        break;
      case 'bankName':
        error = isRequired(
          value,
          error,
          'bankName',
          `${t('financeField.bankName')}`,
          t
        );
        break;
      case 'companyState':
        error = isSelect(value, { ...error }, 'companyState', `${t("field.state")}`, t)
        break;
      case 'companyCity':
        error = isSelect((value), { ...error }, 'companyCity', `${t("field.city")}`, t)
        break;
      case 'companyPostalCode':
        error = isValidPostalCode(value, { ...error }, 'companyPostalCode', `${t("field.postalCode")}`, t, addressFormValue?.country?.name)
        break;
      case 'bankState':
        error = isSelect(value, { ...error }, 'bankState', `${t("field.state")}`, t)
        break;
      case 'bankCity':
        error = isSelect(value, { ...error }, 'bankCity', `${t("field.city")}`, t)
        break;
      case 'bankPostalCode':
        error = isValidPostalCode(value, { ...error }, 'bankPostalCode', `${t("field.postalCode")}`, t, addressFormValue?.country?.name)
        break;
      case 'bankAddress':
        error = isRequired(
          value,
          error,
          'bankAddress',
          `${t('financeField.bankAddress')}`,
          t
        );
        break;
      case 'bank_country':
        error = isRequiredSelect(
          value,
          error,
          'bank_country',
          `${t('financeField.country')}`,
          t
        );
        break;
      case 'cheque':
        error = isRequiredImage(
          uploadedCheque,
          error,
          'cheque',
          `${t('financeField.cheque')}`,
          value,
          t
        );
        break;
      default:
        break;
    }
    return error;
  };


  const handleCountryChange = (name, value) => {
    const err = getValidationHandleChange(name, value);
    setFormError(err);
    setFormValue((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const checkIfUpdated = () => {
    return (JSON.stringify(formValue) !== JSON.stringify(previousFormValue)) || (JSON.stringify(addressFormValue) !== JSON.stringify(addressPreviousFormValue));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    errors = validate(formValue, uploadedCheque);
    setFormError(errors);
    if (Object.keys(errors).length === 0) {
      setButtonDisabled(true);
      const formData = await appendData(formValue);
      try {
        let response;
        if (!isUpdate) {
          try {
            response = await createBillingInformation(formData);
            handleSuccessResponse(response, response.data.message);
            setIsUpdate(true);
            setButtonDisabled(false);
          } catch (err) {
            handleErrorResponse(err);
            setButtonDisabled(false);

          }
        } else {
          setButtonDisabled(true);
          if (checkIfUpdated()) {
            response = await updateBillingInformation(formData);
            handleSuccessResponse(response, response.data.message);
          }
          else {
            toast.error(t('userEdit.noUpdateMessage'));
          }
          setButtonDisabled(false);
        }
      } catch (err) {
        handleErrorResponse(err);
        setButtonDisabled(false);
      }
    }
  };

  const validate = (formValue, uploadedCheque) => {
    let error;
    error = isValidPhoneNumber(
      formValue.billingContactPhone,
      { ...error },
      'billingContactPhone',
      `${t('financeField.billContactPhoneNumber')}`,
      '',
      t,
    );

    error = isValidPhoneNumber(
      formValue.phone,
      error,
      'phone',
      `${t('field.phoneNumber')}`,
      '',
      t
    );

    error = isRequired(
      formValue.title,
      { ...error },
      'title',
      `${t('financeField.companyTitle')}`,
      t,
    );

    error = isRequired(
      formValue.billingAddress,
      { ...error },
      'billingAddress',
      `${t('financeField.billingAddress')}`,
      t,
    );
    error = isRequired(
      formValue.contactName,
      { ...error },
      'contactName',
      `${t('financeField.contactName')}`,
      t,
    );
    error = isValidEmail1(formValue.email, { ...error }, 'email', t);
    error = isRequired(
      formValue.accountHolderName,
      { ...error },
      'accountHolderName',
      `${t('financeField.accountHolderName')}`,
      t,
    );
    error = isRequired(
      formValue.accountNumber,
      { ...error },
      'accountNumber',
      `${t('financeField.accountNumber')}`,
      t,
    );


    error = isRequired(
      formValue.bankName,
      { ...error },
      'bankName',
      `${t('financeField.bankName')}`,
      t,
    );
    error = isRequired(
      formValue.bankAddress,
      { ...error },
      'bankAddress',
      `${t('financeField.bankAddress')}`,
      t,
    );
    error = isRequiredImage(
      uploadedCheque,
      { ...error },
      'cheque',
      `${t('financeField.cheque')}`,
      formValue.cheque,
      t,
    );
    if (addressFormValue?.country?.name !== 'Thailand') {
      // ------------------------ india launch validation 
      error = isRequired(formValue.ifscCode, { ...error }, 'ifscCode', 'IFSC Code', t);
      error = isRequired(formValue.micrCode, { ...error }, 'micrCode', `${t('financeField.micrCode')}`, t,);

      error = isSelect((addressFormValue?.companyState?.name), { ...error }, 'companyState', `${t("field.state")}`, t)
      error = isSelect((addressFormValue?.companyCity?.name), { ...error }, 'companyCity', `${t("field.city")}`, t)
      error = isSelect((addressFormValue?.bankState?.name), { ...error }, 'bankState', `${t("field.state")}`, t)
      error = isSelect((addressFormValue?.bankCity?.name), { ...error }, 'bankCity', `${t("field.city")}`, t)
    }
    else {
      error = isRequired(formValue.bankBranchCode, { ...error }, 'bankBranchCode', `${t('financeField.bankBranchCode')}`, t,);
      error = isRequired(formValue.iban, { ...error }, 'iban', 'IBAN', t);
      error = isSelect((formValue?.company_country), { ...error }, 'company_country', `${t("field.country")}`, t)
      error = isSelect((formValue?.bank_country), { ...error }, 'bank_country', `${t("field.country")}`, t)
    }
    error = isValidPostalCode((addressFormValue?.companyPostalCode?.value), { ...error }, 'companyPostalCode', `${t("field.postalCode")}`, t, addressFormValue?.country?.name)
    error = isValidPostalCode((addressFormValue?.bankPostalCode?.value), { ...error }, 'bankPostalCode', `${t("field.postalCode")}`, t, addressFormValue?.country?.name)
    return error;
  };

  const appendData = (formValue) => {
    const formData = new FormData();
    formData.append('company_name', formValue.title);

    formData.append('postal_code', addressFormValue?.companyPostalCode?.value);
    formData.append('billing_address', formValue.billingAddress);
    formData.append('contact_name', formValue.contactName);
    formData.append('email', formValue.email);
    let removeCountryNamePhoneCountryCode = formValue.phoneCountryCode
      ? formValue.phoneCountryCode.split('(')[0].trim()
      : '+66';

    formData.append(
      'phone_number',
      removeCountryNamePhoneCountryCode + ' ' + formValue.phone,
    );

    let removeCountryNameConCountryCode = formValue.billingContactPhoneCountryCode
      ? formValue.billingContactPhoneCountryCode.split('(')[0].trim()
      : '+66';

    formData.append(
      'contact_number',
      removeCountryNameConCountryCode + ' ' + formValue.billingContactPhone,
    );

    formData.append('account_holder_name', formValue.accountHolderName);
    formData.append('account_number', formValue.accountNumber);
    formData.append('bank_name', formValue.bankName);

    formData.append('bank_address', formValue.bankAddress);
    formData.append('bank_country', addressFormValue?.country?.name === 'Thailand' ? formValue.bank_country : addressFormValue?.country?.name);
    formData.append('country', addressFormValue?.country?.name === 'Thailand' ? formValue.company_country : addressFormValue?.country?.name);
    formData.append('bank_postal_code', addressFormValue?.bankPostalCode?.value);
    if (addressFormValue?.country?.name !== 'Thailand') {
      formData.append('gst_number', formValue?.gstNumber);
      formData.append('micr_code', formValue?.micrCode);
      formData.append('ifsc_code', formValue?.ifscCode);
      formData.append('state', addressFormValue?.companyState?.name);
      formData.append('city', addressFormValue?.companyCity?.name);
      formData.append('bank_state', addressFormValue?.bankState?.name);
      formData.append('bank_city', addressFormValue?.bankCity?.name);
    }
    else {
      formData.append('vat_number', formValue.taxId);
      formData.append('bank_branch_code', formValue.bankBranchCode);
      formData.append('iban', formValue.iban);
    }
    if (typeof formValue.cheque !== 'string') {
      formData.append('cheque', formValue.cheque);
    }
    return formData;
  };


  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const fetchCountryDatas = async () => {
    const tempCountryData = await fetchCountries(currentCountry);
    if (tempCountryData) {
      setAddressFormValue((prev) => ({
        ...prev,
        country: { id: tempCountryData.id, name: tempCountryData.name, code: tempCountryData?.iso2 },
      }));
      fetchStateDropdownData(tempCountryData.id)
    }
    if (addressFormValue?.companyState?.name) {
      fetchStateDatasCompany(tempCountryData.id, addressFormValue?.companyState?.name)
    }
    if (addressFormValue?.bankState?.name) {
      fetchStateDatasBank(tempCountryData.id, addressFormValue?.bankState?.name)
    }
  }

  const fetchStateDropdownData = async (countryId) => {
    let tempStateDropdown = await fetchStateDropdown(countryId)
    setStateDropdown(tempStateDropdown)
  }

  const fetchStateDatasCompany = async (id, stateName) => {
    const tempStateData = await fetchStates(id, stateName);
    if (tempStateData) {
      setAddressFormValue((prev) => ({
        ...prev,
        companyState: { id: tempStateData.id, name: tempStateData.name, state_code: tempStateData?.state_code }
      }))
      fetchCompanyCityDropdownData(id, tempStateData.id)
    }
  }

  const fetchCompanyCityDropdownData = async (countryId, stateId) => {
    let tempCityDataDropdown = await fetchCitiesDropdown(countryId, stateId)
    setComapnyCityDropdown(tempCityDataDropdown)
  }

  const fetchStateDatasBank = async (id, stateName) => {
    const tempStateData = await fetchStates(id, stateName);
    if (tempStateData) {
      setAddressFormValue((prev) => ({
        ...prev,
        bankState: { id: tempStateData.id, name: tempStateData.name, state_code: tempStateData?.state_code }
      }))
    }
  }

  const fetchBankCityDropdownData = async (countryId, stateId) => {
    let tempCityDataDropdown = await fetchCitiesDropdown(countryId, stateId)
    setBankCityDropdown(tempCityDataDropdown)
  }


  const handleStateChange = (value, section) => {
    const selectedOption = stateDropdown.find((option) => option.value === value);
    if (section === 'companyDetails') {
      let tempEror = getValidationHandleChange('companyState', value)
      setFormError(tempEror)
      setAddressFormValue((prev) => ({
        ...prev,
        companyState: { id: selectedOption.id, name: selectedOption.name, code: selectedOption?.state_code },
        companyCity: { name: undefined },
        companyPostalCode: { value: '' },
      }));
      fetchCompanyCityDropdownData(addressFormValue?.country?.id, selectedOption.id)
    }

    else {
      let tempEror = getValidationHandleChange('bankState', value)
      setFormError(tempEror)
      setAddressFormValue((prev) => ({
        ...prev,
        bankState: { id: selectedOption.id, name: selectedOption.name, code: selectedOption?.state_code },
        bankCity: { name: '' },
        bankPostalCode: { value: '' }
      }));
      fetchBankCityDropdownData(addressFormValue?.country?.id, selectedOption.id)
    }
  };

  const handleCityChange = async (e, section) => {
    if (section === 'companyDetails') {
      let tempEror = getValidationHandleChange('companyCity', e)
      setFormError(tempEror)
      setAddressFormValue((prev) => ({
        ...prev,
        companyCity: { name: e },
        companyPostalCode: { value: '' }
      }));
    }

    else {
      let tempEror = getValidationHandleChange('bankCity', e)
      setFormError(tempEror)
      setAddressFormValue((prev) => ({
        ...prev,
        bankCity: { name: e },
        bankPostalCode: { value: '' }
      }));
    }
  };

  const handleCityTypeChange = (e) => {
    const { name, value } = e.target;
    setAddressFormValue((prev) => ({
      ...prev,
      [name]: { name: value }
    }))
    let tempEror = getValidationHandleChange(name, value)
    setFormError(tempEror)
  }

  const handlePostalCode = (e, section) => {
    if (section === 'companyDetails') {
      let tempEror = getValidationHandleChange('companyPostalCode', e);
      setFormError(tempEror)
      setAddressFormValue((prev) => ({
        ...prev,
        companyPostalCode: { value: e }
      }));
    }
    else {
      let tempEror = getValidationHandleChange('bankPostalCode', e);
      setFormError(tempEror)
      setAddressFormValue((prev) => ({
        ...prev,
        bankPostalCode: { value: e }
      }));
    }

  }

  return (
    <>
      {deletionRequestStatus ? (
        <>
          <CancelDeletionAction
            setDeletionRequest={setDeletionRequest}
            deletionRequest={deletionRequest}
          />
        </>
      ) : (
        <Finance
          handleCityTypeChange={handleCityTypeChange}
          handlePhoneCountryCodeChange={handlePhoneCountryCodeChange}
          handlePhoneNumberChange={handlePhoneNumberChange}
          isLoader={isLoader}
          isUpdate={isUpdate}
          uploadedCheque={uploadedCheque}
          formValue={formValue}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          postalCode={postalCode}
          formError={formError}
          country={country}
          buttonDisabled={buttonDisabled}
          handleStateChange={handleStateChange}
          handleCityChange={handleCityChange}
          handlePostalCode={handlePostalCode}
          addressFormValue={addressFormValue}
          stateDropdown={stateDropdown}
          companyCityDropDown={companyCityDropDown}
          bankCityDropDown={bankCityDropDown}
          filterOption={filterOption}
          deletionRequest={deletionRequest}
          handleCountryChange={handleCountryChange}

        />
      )}

    </>
  );
};
export default Finances;
