import { React } from '../libraries'
const Vendor = React.lazy(() => import('../vendor/vendor'))
const DashBoardLogic = React.lazy(() => import('../vendor/component/dashBoard/dashBoard'));
const ReviewLogic = React.lazy(() => import('../vendor/component/review/review'));
const Branch = React.lazy(() => import('../vendor/component/branch/branch'));
const VendorAccountDetails = React.lazy(() => import('../vendor/component/accountDetails/accountDetail'));
const OpeningHoursPolicies = React.lazy(() => import('../vendor/component/openingHoursPolicies/openingHours'));
const Finances = React.lazy(() => import('../vendor/component/finance/finance'));
const VendorTeamListLogic = React.lazy(() => import('../vendor/component/teamList/vendorTeamList'));
const Payout = React.lazy(() => import('../vendor/pages/Payout/Payout'));
const PayoutSummary = React.lazy(() => import('../vendor/pages/Payout/PayoutSummary'));
const ServicesList = React.lazy(() => import('../vendor/pages/ServiceList/ServiceList'));
const AddNewService = React.lazy(() => import('../vendor/pages/AddService/AddService'));
const MyCalender = React.lazy(() => import('../vendor/pages/Calender/Calender'));
const VendorProfile = React.lazy(() => import('../vendor/pages/MyProfile/MyProfile'));
const VendorAccountDeletion = React.lazy(() => import('../vendor/pages/AccountDeletion/AccountDeletion.js'),);
const VendorAccountDeletionRequest = React.lazy(() => import('../vendor/component/deleteRequest/deleteRequest.js'))
const BookingsList = React.lazy(() => import('../vendor/pages/Bookings/BookingList/BookingList'))
export {
    Vendor,
    DashBoardLogic,
    ReviewLogic,
    Branch,
    VendorAccountDetails,
    OpeningHoursPolicies,
    Finances,
    VendorTeamListLogic,
    Payout,
    PayoutSummary,
    ServicesList,
    AddNewService,
    MyCalender,
    VendorProfile,
    VendorAccountDeletion,
    VendorAccountDeletionRequest,
    BookingsList,
}